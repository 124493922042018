import React, { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { MapContainer, Polygon, Marker, Popup, Tooltip, ZoomControl, ScaleControl, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import MapIncluder from './MapIncluder';
import Polyline from './PolyLineArrow';
import "./Map.css";
import { useMapEvents } from "react-leaflet";
import VesselIc from './images/vesselBlack.svg';
import VesselRedIc from './images/vesselRed.svg';
import VesselBlueIc from './images/vesselBlue.svg';
import VesselGreenIc from './images/vesselGreen.svg';
import VesselGreyIc from './images/vesselGreyIc.svg'
import TerminaBluelIc from './images/terminalBlue.svg'
import TerminaGraylIc from './images/terminalGray.svg'
import TerminalRedIc from './images/terminalRed.svg'

import VesselYellowIc from './images/vesselYellow.svg';
import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from "react-leaflet-custom-control";
import LayersIcon from '@mui/icons-material/Layers';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import VectorGrid from './VectorGrid';
import StarLinkGrid from './StarLinkGrid';
import { ButtonGroup, Button } from "@mui/material";
import './smoothWheelZoom';
import moment from "moment";
// import { BeamData } from './BeamData.js';
import VsatBeamLayer from "./VsatBeamLayer";
import { BeamNewData } from './ContorsData.js';
import CellTowerIcon from '@mui/icons-material/CellTower';
import MultiOptionsPolyline from './MultiOptionPolyLine';
import * as tj from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getSingleRecordChartLable } from '../../util/util';
import LayerDetailsMaps from "../../pages/TowerVesselDetails/LayerDetails/LayerDetails";

let DefaultIcon = L.icon({
   iconUrl: icon,
   shadowUrl: iconShadow,
   iconSize: [24,36],
   iconAnchor: [12,36]
 });

 L.Marker.prototype.options.icon = DefaultIcon; 


const MapLeaflet = (props) => {

  const [isOuterHexagonEnabled, setIsOuterhexagaonEnabled] = useState(true);
  const [isOuterStarLinkEnabled, setIsOuterStarLinkEnabled] = useState(true)
  const [isToolTip, setIsToolTip] = useState(false);
  const [innerPopUpContent, setInnerPopUpContent] = useState("Loading...");
  const [map, setMap] = useState(null);
  const [vesselChartDetails, SetVesselChartDetails] = useState();
  const [mapPosition, setMapPosition] = useState([35, -35]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedLastLocIndex, setSelectedLastLocIndex] = useState(-1);
  const [isOpenStreet, setIsopenStreet] = useState(true);
  const [highlitdRow, setHighlitdRow] = useState(-1);
  const [isTowerConected, setIsTowerConnected] = useState(false);
  const [kml, setKml] = useState(null);
  const [kml2, setKml2] = useState(null);

  const { polyColor, hexagons, isHexogonsEnabled, isVesselEnabled, isTowerEnabled, isLineEnabled,
    towerData, isOuterHex, handleTowerOnhover, isPlayBackEnabled, vesselData, vesselTrack,
    isVesselTrackEnabled, handleVesselClick, lineData, selectedvessels, handleChartOpen, isChartClosed,
    setIsChartClosed, handleInnerHexogonClicks, isInnerhexClick, handleOuterHexClick, outerHexData, isInnerHex,
    innerHexData, outerPopUpContent, vesselCurrentLocation, isAllVesselLocation, selectedLayer, handleVesselOnHover, vesselHoverContent, mapHeight, isMultiIcons,
    outerHexOpacity, innerHexOpacity, locationFilter, isPopUpOpen, handleLayerButton, handleHexClick, selectedTrack, selectedCarrier, h3Index, isVesselLastLocClickEnabled, handleLastLocVesselClick,
    selctedVesselId, isVsatDataSource, vsatCoverageData, vsatCovergaeInnerLayerData, isBeamsEnabled, uniqueContoursName, contorsOpacity, connectivity,vesselTrackPointsData, vesselPointsEnabled,
    isVesselNetworkEnabled, edgeEventsData, isEdgeEventsEnabled, defaultStartDate, defaultEndDate, isKmlEnabled, kmlLayersDetails, isSpecialPrrmEnabled,starLinkData, selectedDataSource,starLinkCoverageFour, userTimezone,terminalLocationDetails,
    isAllStarlinkLocation, isStarlinkLatencyMacro, isStarlinkLatencyMicro, edgeVal, isLteMicro, isLteMacro, selectedOu, k4Ids, 
    getLayerDetails, setLayersOpen, layerSelectionDetails, isLocationEnabled, layersOpen} = props;
    // console.log("terminalCurrentLocationDetails vesselL",vesselCurrentLocation)
    // console.log("terminalCurrentLocationDetails ",terminalLocationDetails)
    // console.log("outerHexData",outerHexData)
  // function SetView(props, zoom) {

  //   if (props.length > 0) {
  //     if (map !== undefined && map !== null) {
  //       if (props[0] !== undefined && props[1] !== undefined) {
  //         map.setView(props, zoom);
  //       }
  //     }
  //   }
  // }
  console.log("layerSelectionDetails",layerSelectionDetails)

  useEffect(()=> {
      if (terminalLocationDetails !== undefined && terminalLocationDetails.length > 0 && terminalLocationDetails?.length <= 2 ) {

        let indexPosition = terminalLocationDetails.length - 1
        let vesselDataLastCord = terminalLocationDetails[indexPosition];
        if (mapPosition[0] !== vesselDataLastCord?.lat) {
          if(vesselDataLastCord?.lat && vesselDataLastCord?.long) {
            // SetView([vesselDataLastCord[2], vesselDataLastCord[3]], 10);
               
            // let clickedLatLng = e.latlng;
            // let VariableMovement = calculateOffset(mapZoom);
            const mapContainer = map?.getContainer();
            if(mapContainer) {
              const fixedPosition = {
                x: mapContainer.clientWidth / 4,
                y: mapContainer.clientHeight / 2
             };
              let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
              // let newLat = vesselDataLastCord?.lat - (fixedPointLatLng.lat - map.getCenter().lat);
              // let newLng = vesselDataLastCord?.long - (fixedPointLatLng.lng - map.getCenter().lng);
              // console.log("newLat, newLng",newLat, newLng)
              map.setView([vesselDataLastCord?.lat , vesselDataLastCord?.long ], map.getZoom());
            }
          
          } else {
            console.log("CORDINATES EMPTY", vesselDataLastCord);
          }
          
        }
    }
  },[terminalLocationDetails])

console.log("terminalLocationDetails",terminalLocationDetails)
  const style = {
    width: "100%",
    height: mapHeight
  };

  useEffect(() => {
    setSelectedIndex(-1)
    setSelectedLastLocIndex(-1);
  },[selectedOu, k4Ids])


  function MyComponent() {
    const mapEvents = useMapEvents({
      zoomend: () => {
        let zoomLvl = mapEvents.getZoom();



        if (zoomLvl < 11) {
          if (!isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(true);
          }
        } else if (zoomLvl >= 11) {
          if (isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(false);
          }
        }
        if (zoomLvl < 9) {
          if (!isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(true);
          }
        } else if (zoomLvl >= 9) {
          if (isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(false);
          }
        }
        if (zoomLvl >= 8) {
          if (!isToolTip) {
            setIsToolTip(true);
          }
        } else if (zoomLvl < 8) {
          if (isToolTip) {
            setIsToolTip(false);
          }
        }
      }
    });
    return null
  }

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<span className="outerCircle" style={{height:8px,width: 8px, background: blue, borderRadius:50%, display: inline-block}}></span>`,
    })
  }

  const vesselIcon = (angle, index, isEdge) => {
    let angles = angle? angle+270:-90;
    return renderToStaticMarkup(
      isEdge? isEdgeEventsEnabled && <span className="outerCircle" style ={{ position:"absolute",left: "2px", top: "-3px"}}><div>&#x2742;</div></span>:
      selectedIndex === index ?  
      <span className="outerCircle"  style={{height:`16px`,width: `16px`, background: `red`, borderRadius:`50%`, display: `inline-block`}}><div style={{transform:"scale(2,2.4) rotate("+angles+"deg)",display:"block",color:"red", position:"absolute",left: "1px", top: "-3px"}}></div></span> :
      <span className="outerCircle" style={{height:`8px`,width: `8px`, background: `blue`, borderRadius:`50%`, display: `inline-block`}}><div style={{transform:"scale(1,1.2) rotate("+angles+"deg)",display:"block",color:"#00008B", position:"absolute",left: "1px", top: "-3px"}}></div></span>
    );
  }

  const CustomVesselIcon = (angle, index,isEdge) => divIcon({
    html: vesselIcon(angle, index,isEdge)
  });

  const getVesselRedIconOnCondition = (angle, index, iconClasfier, source) => {
        return selectedLastLocIndex === index ? <img src={source=='STARLINK'? TerminalRedIc :VesselRedIc} alt={'Tower'} className={source=='STARLINK'? "starlinklBigIcon":"vesselRedIcon"} style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source=='STARLINK'? TerminalRedIc:VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
  }
  const VesselRedIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselRedIconOnCondition(angle, index, iconClasfier, source)
  );

  const getVesselGrayIconOnCondition = (angle, index, iconClasfier, source) => {
    return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Tower'} className="vesselRedIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
}

  const VesselGrayIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselGrayIconOnCondition(angle, index, iconClasfier, source)
  );

  const TowerIcon = (props) => renderToStaticMarkup(
    <CellTowerIcon src={CellTowerIcon} alt={'Tower'} className="towerIcon" />
  );

  const getVesselIconOnCondition = (angle, index, iconClasfier, source) => {
    switch (iconClasfier) {
      case 1:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 2:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 3:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 4:
        return locationFilter?.alerting ? <img src={VesselGreenIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      default:
        return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaBluelIc:VesselBlueIc} alt={'Tower'} className="starlinklBigIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaBluelIc: VesselBlueIc} alt={'Vessel'} className={selectedLastLocIndex === index ? "starlinklBigIcon" : "vesselIcon"} style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
    }
  }

  const VesselBlueIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselIconOnCondition(angle, index, iconClasfier, source)
  );

  const CustomVesselBlueIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselBlueIcon(angle, index, iconClasfier, source)
  });

  const CusomVesselRedIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselRedIcon(angle, index, iconClasfier,source)
  });

  const CusomVesselGrayIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselGrayIcon(angle, index, iconClasfier, source)
  });

  const CustomVesselTowerIcon = (props) => divIcon({
    html: TowerIcon(props)
  });



  useEffect(() => {
    if (!isChartClosed) {
      setSelectedIndex(-1)
      setSelectedLastLocIndex(-1);
    }
  }, [isChartClosed])

  useEffect(() => {
    fetch(
      "https://cdn.k4mobility.com/cdn/S900_kasat_v22_coverage.kml"
    )
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        const converted = tj.kml(kml);
        rewind(converted, false);
        setKml(converted);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://cdn.k4mobility.com/cdn/vs2-sailor900.kml"
    )
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        const converted = tj.kml(kml);
        rewind(converted, false);
        setKml2(converted);
      });
  }, []);

  useEffect(() => {
    if (vesselCurrentLocation && vesselCurrentLocation.length > 0 && selctedVesselId) {
      setSelectedLastLocIndex(vesselCurrentLocation.findIndex(vessel => vessel?.locationId === selctedVesselId));
    } else {
      setSelectedLastLocIndex(-1);
    }
  }, [selctedVesselId, vesselCurrentLocation])


  const handleVesselClickInner = (e, vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails, isWindowEnabled) => {

    handleChartOpen(true);

    if (selectedIndex === e?.target?.options?.index) {
      setSelectedIndex(-1);
      // setIsChartClosed(true);
      setIsTowerConnected(false);
      handleChartOpen(false);
      return
    }
    setSelectedIndex(e?.target?.options?.index);
    // setIsChartClosed(false);
    // handleChartOpen(true);
    handleVesselClick(vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails,isWindowEnabled);
  }

  const handleLastLocationVesselClick = (e, item) => {
    if (selectedLastLocIndex === e?.target?.options?.index) {
      setSelectedLastLocIndex(-1);
      handleLastLocVesselClick(null);
      // setIsChartClosed(true);
      // handleChartOpen(false);
      return
    }
    setSelectedLastLocIndex(e?.target?.options?.index);
    // handleChartOpen(true);
    handleLastLocVesselClick(item);
  }

  const handleHexClickInner = (e, resolution) => {
    handleHexClick(e, resolution);
    if(map) {
      let mapCurrentZoom = map.getZoom();
      let variableZoom = getVariableMovement(mapCurrentZoom);
      // SetView([e?.latlng?.lat, e?.latlng?.lng + variableZoom], mapCurrentZoom);
    }
  }
// vesselData Voyage correct here
  // useEffect(() => {
  //   if (vesselData !== undefined && vesselData.length > 0) {
  //     let vesselDataLastCord = vesselData[0];
  //     if (mapPosition[0] !== vesselDataLastCord[2]) {
  //       if(vesselDataLastCord[2] && vesselDataLastCord[3]) {
  //         SetView([vesselDataLastCord[2], vesselDataLastCord[3]], 10);
  //       } else {
  //         console.log("CORDINATES EMPTY", vesselDataLastCord[2], vesselDataLastCord[3]);
  //       }
        
  //     }
  //   }
  // }, [vesselData])

  // useEffect(() => {
  //   if (vesselCurrentLocation !== undefined && vesselCurrentLocation.length > 0) {
  //     let len = vesselCurrentLocation.length - 1
  //     let vesselDataLastCord = vesselCurrentLocation[0];
  //     if (isMultiIcons) {
  //       SetView([vesselDataLastCord?.lat, vesselDataLastCord?.long], 8);
  //     }
  //   }
  // }, [vesselCurrentLocation])

  const vesselPopupDetails = (item, alertingList) => {
    return <><Popup className="vesselLocPopUp" closeButton={false}>
      {isMultiIcons ?
        <>
          <div className="onHoverName">{item.name.toUpperCase()}</div>
          <div className="modemNames">{modemList.map((item) => {
            // <div className="modemContents"><div className="dot" style={{ background: "red" }}></div><span className="modName">{item}</span></div></> : <><div className="modemContents"><div className="dot" style={{ background: "green" }}>
            return alertingList !== undefined && alertingList.length > 0 && alertingList.includes(item) ? <><div className="modemContents"><div className="dot" style={{ background: "red", width: "10px", height: "10px" }}></div><span className="modName">{item}</span></div></> : <></>;
          })}</div> </> : <div className="modName">{item.name}</div>}
    </Popup>
      {!isOuterHexagonEnabled && <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{item.name}</Tooltip>}
    </>;
  }

  const vesselLocationPopup = (item, alertingList, isAlerting) => {
    if (locationFilter?.nonAlerting && locationFilter?.alerting) {
      return vesselPopupDetails(item, alertingList)
    }
    if (locationFilter?.nonAlerting) {
      return locationFilter?.nonAlerting && (alertingList !== undefined && alertingList.length > 0) && vesselPopupDetails(item, alertingList)
    }
    if (locationFilter?.alerting) {
      return locationFilter?.alerting && !alertingList && vesselPopupDetails(item, alertingList);
    }
    return null;

  }

  const alertMap = {
    50: "Ethernet Slow 10mbps",
    51: "Ethernet Slow 100mbps",
    52: "Power Supply Thermal Throttling",
    53: "Actuator Motor Stuck",
    54: "Mast Not Vertical",
    55: "Disabled No Active Account",
    56: "Disabled Too Far From Service Address",
    57: "Disabled No Service In Ocean",
    58: "Disabled Invalid Country",
    59: "Moving While Not Mobile",
    60: "Disabled Moving Too Fast",
    61: "Power Disconnect Detected",
    62: "Pop Change",
    79: "Software Update Reboot Pending",
    63: "Unable To Align",
    64: "High Sky Obstruction",
    65: "High Sky Obstruction Mobile",
    66: "High Time Obstruction",
    67: "High Time Obstruction Mobile",
    80: "Thermal Shutdown",
    81: "Thermal Throttling",
    82: "Software Update Reboot Pending",
    "over_threshold": "Over Quota Threshold",
    "over_limit": "Over Max Quota",
    "offline_for_15m": "Offline For More Than 15 Minutes",
    "offline_for_1h": "Offline For More Than 1 Hour",
    "offline_for_6h": "Offline For More Than 6 Hours",
};


  const vesselPopUp = (item) => {
    if(item?.starlinkKpi && item?.edgeKpi) {
      let activeAlerts = ""
      if(item?.starlinkKpi?.active_alerts) {
        activeAlerts = JSON.parse(item?.starlinkKpi?.active_alerts);
      }
      let alertMsgList = "";
      if(activeAlerts && activeAlerts?.length > 0) {
        alertMsgList = activeAlerts.map((item) => {
          return alertMap[item]
        } )
      }
      if(alertMsgList && alertMsgList.length > 0 ) {
        alertMsgList = alertMsgList.join(',')
      }
      // console.log("both kpis",item )
      return <><Popup className="vesselBothPopUp" closeButton={false}>
        <div className="popupColumn">
        {/* <div className="onHoverNameHEAD">SITE</div> */}
          <div className="onHoverName">{item?.name?.toUpperCase()}</div>
          {/* <div>{getSingleRecordChartLable(moment(item?.lastSeen).valueOf(), userTimezone)}</div> */}
          {/* <div>Availability - {item?.edgeKpi?.availibility ? parseFloat(item?.edgeKpi?.availibility).toFixed(2): 0 } %</div> */}
          
          {/* <div>Down - {item?.edgeKpi?.down_bytes ? parseFloat(item?.edgeKpi?.down_bytes ): "0"}</div>
          <div>Up- {item?.edgeKpi?.up_bytes ? parseFloat(item?.edgeKpi?.up_bytes) : "0"}</div> */}
          {/* <div>probe_pass - {item?.edgeKpi?.probe_pass ? parseFloat(item?.edgeKpi?.probe_pass).toFixed(2) : "NA"}</div> */}
        </div>
        <div className="popupColumn">
          <div className="onHoverNameHEAD">STARLINK</div>
          <div className="onHoverName">{item?.starlinkName}</div>
          {/* <div>{getSingleRecordChartLable(moment(item?.lastSeen).valueOf(), userTimezone)}</div> */}
          {/* <div>Downlink - {item?.starlinkKpi?.downlink_throughput ? parseFloat(item?.starlinkKpi?.downlink_throughput).toFixed(2) : "0"} Mbps</div>
          <div>Uplink - {item?.starlinkKpi?.uplink_throughput ? parseFloat(item?.starlinkKpi?.uplink_throughput).toFixed(2) : "0"} Mbps</div> */}
          {/* <div>Active Alerts - {alertMsgList ? alertMsgList : "NA"}</div> */}
        </div>
      </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    }
    if(item?.src == "STARLINK" && item?.starlinkKpi) {
      console.log("starlink123",item)
      let activeAlerts = ""
      if(item?.starlinkKpi?.active_alerts) {
        activeAlerts = JSON.parse(item?.starlinkKpi?.active_alerts);
      }
      let alertMsgList = "";
      if(activeAlerts && activeAlerts?.length > 0) {
        alertMsgList = activeAlerts.map((item) => {
          return alertMap[item]
        } )
      }
      if(alertMsgList && alertMsgList.length > 0 ) {
        console.log("alertMsgList",alertMsgList)
        alertMsgList = alertMsgList.join(',')
      }
     
      return <><Popup className="vesselLocPopUp" closeButton={false}>
      <div className="onHoverNameHEAD">STARLINK</div>
      <div className="onHoverName">{item?.name?.toUpperCase()}</div>
      {/* <div>{getSingleRecordChartLable(moment(item?.lastSeen).valueOf(), userTimezone)}</div> */}
      {/* <div>Downlink - {item?.starlinkKpi?.downlink_throughput  ? parseFloat(item?.starlinkKpi?.downlink_throughput).toFixed(2) : "0"} Mbps</div>
      <div>Uplink - {item?.starlinkKpi?.uplink_throughput  ? parseFloat(item?.starlinkKpi?.uplink_throughput).toFixed(2) : "0"} Mbps</div> */}
      {/* <div>Active Alerts - {alertMsgList ? alertMsgList : "NA"}</div> */}
    </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    
    } else {
      return <><Popup className="vesselLocPopUp" closeButton={false}>
      {/* <div className="onHoverNameHEAD">SITE</div> */}
      <div className="onHoverName">{item?.name?.toUpperCase()}</div>
      {/* <div>{getSingleRecordChartLable(moment(item?.lastSeen).valueOf(), userTimezone)}</div> */}
      {/* <div>Availability - {item?.edgeKpi?.availibility ? parseFloat(item?.edgeKpi?.availibility).toFixed(2): 0 } %</div> */}
      {/* <div>Down - {item?.edgeKpi?.down_bytes ? parseFloat(item?.edgeKpi?.down_bytes ): "0"}</div>
      <div>Up- {item?.edgeKpi?.up_bytes ? parseFloat(item?.edgeKpi?.up_bytes) : "0"}</div>
      <div>probe_pass - {item?.edgeKpi?.probe_pass ? parseFloat(item?.edgeKpi?.probe_pass).toFixed(2) : "NA"}</div> */}
    </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    }
  }
  const getVariableMovement = (zoom) => {
    if (zoom < 12) {
      return 0.6;
    } else if (zoom >= 12 && zoom < 14) {
      return 0.16;
    } else if (zoom >= 14 && zoom < 16) {
      return 0.04;
    } else if (zoom >= 16 && zoom < 18) {
      return 0.006;
    } else if (zoom >= 18 && zoom <= 19) {
      return 0.004;
    } else if (zoom > 19 && zoom <= 22) {
      return 0.0006
    }
    else {
      return 0.04;
    }
  }

  const modemList = ["VSAT1", "VSAT2", "LTE1", "LTE2", "LTE3", "LTE4"]
  if (selectedTrack && selectedTrack !== undefined && selectedTrack.length > 0) {
    let selectedPosition = selectedTrack[0];
    let mapZoom = map.getZoom();
    let variableZoom = getVariableMovement(mapZoom);
    // SetView([selectedPosition[0], selectedPosition[1] + variableZoom], map.getZoom());
  }

  // let vsatLayersData = BeamData && BeamData?.map((item) => {
  //   return item?.obj_attributes?.geojson?.features[1];
  // });

  const getTrackColor = (status) => {
    return '#000000';
  }

  const customMarkerPoints = (props) => divIcon({
    className: 'pointsTrackIcons',
    html: `<span style=paddingTop:10px>${props}Mb/s</span>`,

    iconSize: [25, 41],
});

const EdgeEventMarkerPoints = (props) => divIcon({
  className: '',
  html: `<span>&#x2742;</span>`,
});
  return (
    <div id="map" className='LeafLetMaps' style={isPopUpOpen ? style : style} >
      <MapContainer
        center={mapPosition}
        zoom={4}
        minZoom={3}
        maxZoom={22}
        style={{ height: "100%" }}
        whenCreated={setMap}
        attributionControl={false}
        zoomControl={false}
        worldCopyJump={false} // Disable world copy jumping
        maxBounds={[[-90, -180], [90, 180]]} // Constrain to world bounds
        maxBoundsViscosity={1.0} // Prevent overscrolling

        //  zoomAnimation={true}
        // wheelPxPerZoomLevel={240}
        // scrollWheelZoom={true}  // disable original zoom function
        // smoothWheelZoom={true}
        // smoothSensitivity={1}
        scrollWheelZoom={false}
        smoothSensitivity={0.90}
        smoothWheelZoom={true}
        fadeAnimation={true}
        preferCanvas={false}
        boxZoom={true}
      >
        <ScaleControl className="mapScale" position="bottomright" />
        <ZoomControl position="topright" />
        <Control position="topright" className="customButtons" >
          <ButtonGroup orientation="vertical" className="mapCustButtonsGroup" >
            <Button title='Layers' id="layersSel"
              // color={active === "adjust" ? "primary" : "inherit"}
              onClick={() => handleLayerButton()}
            >
              <LayersIcon className="layersIcon" color="black" />
             
            </Button>
           { selectedvessels && <Button title='Auto Chart' id="layersSel" onClick ={() => handleVesselClickInner(null, defaultStartDate, defaultEndDate, null,false)}>
              <AutoGraphOutlinedIcon  className="layersIcon" color="black"/>
            </Button>}
          </ButtonGroup>
          {/* <div className="layers" onClick={handleLayerButton}> <Tooltip title="Layers"><LayersIcon /></Tooltip></div> */}
        </Control>
        <MyComponent />
        {layersOpen && <div className="maps-layers-new-container">
          <LayerDetailsMaps getLayerDetails={getLayerDetails} setOpen={setLayersOpen} layerSelectionDetails={layerSelectionDetails} isLocationEnabled={(isLocationEnabled)} isSpecialPrrmEnabled={isSpecialPrrmEnabled} />
        </div>}

        <MapIncluder isDark={false} isOpeStreet={isOpenStreet} />
        {isHexogonsEnabled && !isVsatDataSource ? Object.entries(hexagons).map(([weight, polygons]) => {
          return <Polygon
            positions={polygons}
            pathOptions={{
              fillColor: polyColor,
              color: polyColor,

            }}

          />;
        }) : null}


        {/* {isTowerEnabled && !isOuterHexagonEnabled && towerData !== undefined && towerData.map((item) => {
          const cordinates = item?.geometry?.coordinates;
          if (cordinates.length === 0) {
            return null;
          }
          const long = cordinates[0];
          const lat = cordinates[1];

          const towerDetails= item?.properties
          return <Marker
            position={[lat, long]}
            icon={CustomVesselTowerIcon(props)}

          >
            <Popup className="towerPopUp">
              {handleTowerOnhover(towerDetails)}
            </Popup>
          </Marker>
        }
        )} */}



{/* <MarkerClusterGroup
          maxClusterRadius={10}
          iconCreateFunction={createClusterCustomIcon}
        >          
        {isVesselEnabled && selectedvessels && vesselData !== undefined && vesselData?.length> 0 && vesselData?.map((item, index) => {

            // const cordinates = item?.geometry?.coordinates;
            const vesselTimeStamp = moment.utc(item[0], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            const vesselEndTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            const nearestTowerDetails  = null;
            const isEdge = null;
            // const isVesselPath  = item?.isVesselPath;
        
            const long = item[3];
            const lat = item[2];
            if ( long === null || lat === null) {
              return null;
            }
            const angle = null;
            if(isEdge && !isEdgeEventsEnabled) {
              return;
            } 

            return <Marker
              key={index}
              index={index}
              position={[lat, long]}
              icon={CustomVesselIcon(angle, index,isEdge)}
              eventHandlers={{
                mouseover: (e) => {
                  // SetVesselChartDetails(handleVesselOnhover(vesselTimeStamp, selectedvessels))

                  e.target.openPopup();
                },
                mouseout: (e) => {
                  setTimeout(() => {
                    e.target.closePopup();
                    // handleVesselPopUp(vesselTimeStamp, vesselEndTime, selectedvessels)
                  }, 500);
                },
                click: (e) => {
                  // handleClick(e, true);
                  e.target.closePopup();
                  let mapZoom = map.getZoom();
                  let VariableMovement = getVariableMovement(mapZoom);
                  SetView([lat, long + VariableMovement], map.getZoom());
                  setIsTowerConnected(true);
                   handleVesselClickInner(e, vesselTimeStamp, vesselEndTime, nearestTowerDetails,true);

                },
              }}
            >
              {(vesselData?.length - 1 === index) &&
                <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{selectedvessels}</Tooltip>}
              {isEdgeEventsEnabled && isEdge && <Popup>
                {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
              </Popup>}
              { <Popup>
                {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
              </Popup>}

            </Marker>
          }
          )}
    </MarkerClusterGroup> */}


    



        {/* {isAllVesselLocation && vesselCurrentLocation !== undefined && vesselCurrentLocation.length > 0 && vesselCurrentLocation.map((item, index) => {
          if (item == undefined || item?.lat === null || item?.long === null) {
            return;
          }
          let angle = item?.heading;
          let iconClasfier = item?.iconClasfier;
          let alertingList = item?.alertingList
          return <Marker
            key={index}
            index={index}
            position={[item?.lat, item?.long]}
            icon={item?.status == 0 ? CusomVesselRedIcon(angle, index, iconClasfier, 'edge'): item?.status == 2 ?  CusomVesselGrayIcon(angle, index, iconClasfier, 'edge') : CustomVesselBlueIcon(angle, index, iconClasfier, 'edge')}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                setTimeout(() => {
                  e.target.closePopup();
                }, 5000);
              },
              click: (e) => {
                // handleClick(e, true);
                e.target.closePopup();
                if (isVesselLastLocClickEnabled) {
                  let mapZoom = map.getZoom();
                  let VariableMovement = getVariableMovement(mapZoom);
                  SetView([item?.lat, item?.long + VariableMovement], map.getZoom());
                  handleLastLocationVesselClick(e, item);
                }

              },
            }}

          >

            {isMultiIcons ? vesselPopUp(item) : vesselPopUp(item)}

          </Marker>

        })

        } */}
        {edgeVal !== "NA" && isAllStarlinkLocation && terminalLocationDetails !== undefined && terminalLocationDetails.length > 0 && terminalLocationDetails.map((item, index) => {
          // console.log("each iitem",item)
          if (item == undefined || item?.lat === null || item?.long === null) {
            return;
          }
          let angle = item?.heading ;
          let iconClasfier = item?.iconClasfier;
          let alertingList = item?.alertingList
          let source = item?.src
          return <Marker
            key={index}
            index={index}
            position={[item?.lat, item?.long]}
            icon={item?.status == 0 ? CusomVesselRedIcon(angle, index, iconClasfier, source): item?.status == 2 ?  CusomVesselGrayIcon(angle, index, iconClasfier, source) : CustomVesselBlueIcon(angle, index, iconClasfier, source)}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                setTimeout(() => {
                  e.target.closePopup();
                }, 10);
              },
              click: (e) => {
                // handleClick(e, true);
                e.target.closePopup();
                if (isVesselLastLocClickEnabled) {
                  let mapZoom = map.getZoom();
                  let VariableMovement = getVariableMovement(mapZoom);
                  let clickedLatLng = e.latlng;
                  // let VariableMovement = calculateOffset(mapZoom);
                  const mapContainer = map?.getContainer();
                  if(mapContainer) {
                    const fixedPosition = {
                      x: mapContainer.clientWidth / 4,
                      y: mapContainer.clientHeight / 4
                   };
                    let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
                    // let newLat = clickedLatLng.lat - (fixedPointLatLng.lat - map.getCenter().lat);
                    // let newLng = clickedLatLng.lng - (fixedPointLatLng.lng - map.getCenter().lng);
                    // map.setView([newLat, newLng], map.getZoom());
                  }
                  handleLastLocationVesselClick(e, item);
                }

              },
            }}

          >

            {isMultiIcons ? vesselPopUp(item) : vesselPopUp(item)}

          </Marker>

        })

        }
        {isStarlinkLatencyMacro  && starLinkData  && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkData} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={1000} selectedDataSource={selectedDataSource} resolution={3}/> }
        {isStarlinkLatencyMicro && starLinkData && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkCoverageFour} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} selectedDataSource={selectedDataSource} resolution={4}/> }
        {isLteMacro && outerHexData && <VectorGrid assets={outerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} /> }
        {isLteMicro && innerHexData && <VectorGrid assets={innerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} resolution={7}/>} 
         {/* <VectorGrid assets={outerHexData} selectedLayer={selectedLayer} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} /> */}
        {/* {isOuterHex && true &&  ((selectedDataSource === "VSAT") || (selectedDataSource === "CELL"))  && (!isVsatDataSource ? (outerHexData && outerHexData !== null && <VectorGrid assets={outerHexData} selectedLayer={selectedLayer} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} /> ): isOuterHex && vsatCoverageData && vsatCoverageData !== null && <VectorGrid assets={vsatCoverageData} selectedLayer={selectedLayer} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} isVsatDataSource={isVsatDataSource}  resolution={5} />)} */}


{/* 
        {isInnerHex && innerHexData &&  ((selectedDataSource === "VSAT") || (selectedDataSource === "CELL")) && innerHexData !== null && (!isVsatDataSource ? <VectorGrid assets={innerHexData} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} resolution={7}/> : isInnerHex && vsatCovergaeInnerLayerData && vsatCovergaeInnerLayerData !== null && <VectorGrid assets={vsatCovergaeInnerLayerData} selectedLayer={selectedLayer} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} isVsatDataSource={isVsatDataSource} resolution={7}/>)}

        {isInnerHex && starLinkData && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkCoverageFour} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} selectedDataSource={selectedDataSource} resolution={4}/> }

        {isBeamsEnabled && isVsatDataSource && BeamNewData && <VsatBeamLayer assets={BeamNewData} uniqueContoursName={uniqueContoursName} contorsOpacity={contorsOpacity} />}

        {isVesselTrackEnabled && isVesselNetworkEnabled && vesselTrack && selectedvessels && map && vesselTrack.length > 0 &&  <MultiOptionsPolyline points={vesselTrack} map={map} /> }
        {isVesselTrackEnabled && selectedvessels && selectedTrack !== undefined && selectedTrack.length > 1 &&
          <Polyline className='selectedTrack' key={'NEw'} pathOptions={{
            color: ' #00FFFF',
            weight: 6,
          }}
            positions={selectedTrack} arrowheads={{ size: '5px' }}

          >

          </Polyline>
        }
        <MarkerClusterGroup
          maxClusterRadius={20}
          iconCreateFunction={createClusterCustomIcon}
          >
        {vesselPointsEnabled && selectedvessels && vesselTrackPointsData && map && vesselTrackPointsData.length > 0 && vesselTrackPointsData.map((item,index) => {
          let lat = item[0];
          let long = item[1];
          let linkBwe = `${(item[2]/(1000 * 1000)).toFixed(0)}`;

          return <Marker
            key={index}
            index={index}
            position={[lat, long]}
            icon={customMarkerPoints(linkBwe)}
           />
        })}
        </MarkerClusterGroup>
        {isEdgeEventsEnabled && edgeEventsData && edgeEventsData.length > 0 && edgeEventsData.map((item, index) => {
          let position=item?.geo_data;
          return <Marker
          key={index}
          index={index}
          position={[position?.latitude, position?.longitude]}
          icon={EdgeEventMarkerPoints()}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              setTimeout(() => {
                e.target.closePopup();
              }, 500);
            }
          }}
         >
          <Popup closeButton={false}>
            <div>{item?.name}</div>
            <div>Time - {getSingleRecordChartLable(moment(item?.timestamp).valueOf(), userTimezone)}</div>
          </Popup>
          </Marker>
        })
          
        }
        {connectivity && connectivity !== undefined && connectivity.length > 0 && connectivity.map((item) => {
          if(item) {
            return  <Polyline className='vesselTrack' dashArray="10,4" key={'some'} pathOptions={{
              color: '#000000'
            }} positions={item} arrowheads={{ size: '5px' }} />
          }
        })
        }
        {// need further optimization
        />}
        {kml2 && isKmlEnabled && isVsatDataSource && isSpecialPrrmEnabled && kmlLayersDetails?.Viasat2 && <GeoJSON data={kml2} />} */}
        {kml && layerSelectionDetails?.Viasat1 && isSpecialPrrmEnabled && <GeoJSON  data={kml} />}
        {kml2 && layerSelectionDetails?.Viasat2 && isSpecialPrrmEnabled && <GeoJSON data={kml2} />}
      </MapContainer>
    </div> 
  );
};

export default MapLeaflet;



