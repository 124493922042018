// Alert Table for ALert Page
import React, { useState, Fragment,useEffect } from "react";
import { Grid, Button, Dialog, Typography, Input, Tooltip, FormControl, RadioGroup, FormControlLabel, Radio, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getVesselAlerts, GetAlertNames, QueryParameters } from "../../actions/Users/authenticateAlert";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";
import ConfirmDialog from "../ConfirmDialog";
import DeleteIcon from "../../asset/image/user_delete.svg";
import { deleteAlerts } from "../AlertsDashboard/slice";
import _ from "lodash";
import { MMDDYYYYHMMSS_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import arrowAlert from "../../asset/image/arrowAlert.svg";
// import CustomLoader from "../Loader/CustomLoaders";
import "./AlertTablePage.css";
import { Pagination, RowPerPageHandler } from "../Pagination/index";
import Paper from "@material-ui/core/Paper";
import JsonTooltip, { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import { ACTION_TYPES, getAlertsMain } from "../AlertsDashboard/slice";
import NormalAlert from "../../asset/image/bar_chart.svg"
import Warning from "../../asset/image/warning.svg"
import filter from "../../asset/image/filter.svg";
import SearchBar from "../../NewComponents/SearchBar/SearchBar";
import Avatar from "@material-ui/core/Avatar";
import Pending from "../../asset/image/Pending.svg";
import DropDownCard from "../../vis/react-app/src/components/DropDownCard";
import { Box, Menu } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import UsageReportFilters from "../UsageReportFilters";
import AlertsFilters from "./AlertsFilters";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, redirectToTopolgyTabs, strToList } from "../../utils/util";
import Download from '../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { CsvParameters, downloadAsCSV, escapeCommasCsvField } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD, DELETE_ALERT } from "../../actions/types";
import { toast } from "react-toastify";

const convertToCsv = (data: any[][], userTimezone) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    let metaData = row[10]
    if(typeof metaData == "object"){
      metaData = JSON.stringify(metaData)
    }
    csvRows.push([row[2], row[5], row[4], row[6], row[7], convertDateTimeIntoTimezone(row[0], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[8], metaData])
  })
  let reportedOn = 'Reported On (' + userTimezone + ')'
  return [['Site', 'Device Name', 'Alert Name', 'Interface Name', 'WAN Type', reportedOn, 'Status', 'Metadata'], ...csvRows]
}


const AlertTablePage = (props: any) => {
  const {
    location,
    getAlertsMain,
    alertMainDetails,
    alertsMainInfo,
    authReducer,
    selectedVessel,
    selectedVesselName,
    startDate,
    endDate,
    showSummaryPeriod,
    downloadAsCSV,
    tab,
    history,
    isReadOnlyUser,
    deleteAlerts,
  } = props;
  let _q: any = getDecodeURI(location.search);
  const statusList: string[] = _q.hasOwnProperty('status') ? strToList(_q.status) : [];
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchlKey, setSearchKey] = useState<any>(_q.hasOwnProperty('search') ? _q.search : "");
  const [showMoreFilterPopup, setMoreFiltersShowPopUP] = useState<boolean>(false);
  const [currentAlertStatus, setCurrentAlertStatus] = useState<string[]>(statusList.includes('isCurrentAlert') ? ['isCurrentAlert']: statusList);
  const [alertStatus, setAlertStatus] = React.useState({
    isCurrentAlert: statusList.includes('isCurrentAlert'),
    Alerting: statusList.includes('Alerting'),
    Normal: statusList.includes('Normal')
  });

  const dispatch = useDispatch();

  const [vesselIds, setVesselIds] = useState([]);
  const [selectedOu, setSelectedOu] = useState();
  const [isShown, setIsShown] = useState(false);
  const [alertUpdateInterval, setAlertUpdateInterval] = useState("OFF")
  const intervalList = ['OFF','1m','5m','15m'];
  const interValValue = "OFF";
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: {},
});

  const handleAlertInterVal = (interval) => {
   setAlertUpdateInterval(interval);
  }

  const getIntervalInMinutes = (interval) => {
    const intervalInMinutes = interval.split('m')
    return intervalInMinutes[0] * 1000 * 60;
  }

  useEffect(() => {
    if (vesselIds.length > 0) {
      if (alertUpdateInterval !== "OFF") {
        const MINUTE_MS = getIntervalInMinutes(alertUpdateInterval);
        const interval = setInterval(() => {
          getAlertsMain(pageNumber, rowsPerPage, searchlKey, currentAlertStatus, startDate, endDate, vesselIds)
        }, MINUTE_MS);
        return () => clearInterval(interval);
      }
    }
  },[alertUpdateInterval])

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if(authReducer.csvDownloadId === 'alert-csv')
      DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone), {
        formatters: {
          0: 'ESC-COMMA',
          1: 'ESC-COMMA',
          3: 'ESC-COMMA',
          5: 'ESC-COMMA',
          7: 'JSON'
        }
      });
      return () => {
        dispatch(clearCsvDownload())
      }
    }
  }, [authReducer.csvDataDownloded])

  const handleChangeRowsPerPage: RowPerPageHandler = (
    event,
  ) => {
    const value = event.target.value;
    let limit: number;
    if (typeof value == 'string') {
      limit = parseInt(value, 10)
    } else {
      limit = value;
    }
    setRowsPerPage(limit);
    setPageNumber(1);
  };

  const handleChangePage = (
    event: React.SyntheticEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageNumber(newPage);
  };

  const rowsOption = [10, 15, 25, 50, 100];
  const getAlertTextColor = (alertText: any) => {
    switch (alertText.trim()) {
      case "Alerting": return "RedColor"
      case "Normal": return "GreenColor"
      case "Pending": return "OrangeColor"
      default: return "GreenColor";
    }
  }

  const getAlertIcon = (status: string | undefined) => {
    switch (status?.trim()) {
      case "Alerting": return Warning;
      case "Normal": return NormalAlert;
      case "Pending": return Pending;
      default: return NormalAlert;
    }
  }

  // const getAlertDescription = (alertName: string | undefined) => {

  // }

  const handleKeySerach = (key) => {
    setPageNumber(1);
    setSearchKey(key);

  }

  const handleAlertStaus = (alertStatus) => {
    setAlertStatus(alertStatus);
    const alertsStatusArry: any[] = [];
    if(alertStatus?.isCurrentAlert) {
      alertsStatusArry.push('isCurrentAlert');
      _q.status = 'isCurrentAlert'
      history.push({ pathname: location.pathname, search: `?${getEncodedURI(_q)}` });
      setCurrentAlertStatus(['isCurrentAlert']);
      return
    } else if(!alertStatus.isCurrentAlert && !alertStatus.Normal && !alertStatus.Alerting ) {
      alertStatus.Normal = true;
      alertStatus.Alerting = true;
    }
    Object.keys(alertStatus).map((item) => {
      if (alertStatus[item]) {
        alertsStatusArry.push(item);
      }
    })
    _q.status = alertsStatusArry;
    setCurrentAlertStatus(alertsStatusArry);
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(_q)}` });
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels;
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVesselIds(vessels);
  }, [authReducer?.getVesselsListing, authReducer?.filteredVessels])

  useEffect(() => {
    const _selectedOu = authReducer?.selectedOu;
    if (_.isEmpty(_selectedOu) || !_selectedOu.hasOwnProperty("name"))
      return;
    if (_selectedOu.name && _selectedOu.name != selectedOu) {
      setSelectedOu(_selectedOu.name);
      setPageNumber(1);
    }
  }, [authReducer?.selectedOu])

  useEffect(() => {
    if (vesselIds.length > 0) {
      getAlertsMain(pageNumber, rowsPerPage, searchlKey, currentAlertStatus, startDate, endDate, vesselIds)
    } else {
      dispatch({ type: ACTION_TYPES.ALERTS_MAIN_INFO, payload: { total_count: 0 } })
    }
  }, [pageNumber, rowsPerPage, searchlKey, currentAlertStatus, startDate, endDate, vesselIds])

  useEffect(() => {
    if(alertStatus.isCurrentAlert) {
      showSummaryPeriod(false);
    }else {
      showSummaryPeriod(true);
    }
  }, [alertStatus])

  useEffect(() => {
    if (!_.isEmpty(authReducer?.deleteAlert)) {
        getAlertsMain(pageNumber, rowsPerPage, searchlKey, currentAlertStatus, startDate, endDate, vesselIds)
        dispatch({ type: DELETE_ALERT, payload: {} })
        toast.success("Alert deletion in progress. This may take up to a minute.", { position: toast.POSITION.BOTTOM_LEFT })
    };
}, [authReducer?.deleteAlert])

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleAlertStaus({ ...alertStatus, [name]: event.target.checked });
    
  };

  const handleDownloadReport = () => {
    if (vesselIds.length === 0) {
      toast.error("No data available to download", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      return;
    }
    const vessels = vesselIds?.map((item: any) => item['id']);
    
    const isCurrentAlert = currentAlertStatus.includes("isCurrentAlert")
    const params: CsvParameters = {
      type: "POST_API_SITE_CONTROLLER",
      endpoint: `/v1/sites/alerts/status_history`,
      id: "alert-csv",
      payload: {
        "site_id": vessels,
        "status": isCurrentAlert ? [] : currentAlertStatus,
        "created_after": startDate ? startDate : null,
        "created_before": endDate ? endDate : null,
        "search": searchlKey,
        "page": 0,
        "size": alertsMainInfo?.total_count ?? 0,
        "currently_alerting": isCurrentAlert
      }
    }
    downloadAsCSV(params);
  }

  const getAlertNameLink = function (item) {
    if (item.alert_name === "Usage Quota")
      return redirectToTopolgyTabs(location, "/quota-management", item?.site_id, item?.site_name, { "alert_id": item?.alert_id }, true, true)
    return redirectToTopolgyTabs(location, "/fleet-dashboard/sites/alertsConfiguration", item?.site_id, item?.site_name, { "alert_id": item?.alert_id }, true, true)
  }

  const handleDeleteAlert = (siteId: string, alertId: string) => {
    deleteAlerts(siteId, alertId)
  }

  return (
    <div className="Form">

      <Grid container>
      </Grid>
      <Grid className={tab ? 'alertParentPage margin-left-right topology-alerts-conatiner' : "alertParentPage margin-left-right"}>
        <Grid container item xs={12} sm={12} md={12} lg={12} className="AlertsPageFilters">
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <SearchBar handleSerach={handleKeySerach}></SearchBar>
          </Grid>
          <Grid item xs={4} sm={2} md={1} lg={1} className="alertsInterval">
              <DropDownCard handleCarrierSelection={handleAlertInterVal}  dropDownLabel="Auto Update" dropDownList={intervalList} defaultValue={interValValue}/>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className="margin-left-30">
            <FormControl>
              <Grid className="historyorCurrent" component="label" container alignItems="center">
                <Grid item>History</Grid>
                <Grid item>
                  <Switch
                    checked={alertStatus?.isCurrentAlert}
                    onChange={handleChange("isCurrentAlert")}
                    value="isCurrentAlert"
                  />
                </Grid>
                <Grid item>Current</Grid>
              </Grid>
            </FormControl>
          </Grid>
          {!alertStatus?.isCurrentAlert && <Grid item className="alertMainStatusContainer inline-checkbox">
            <Grid className="rc--form-control flex-dispaly">
              <input type="checkbox" name="Alerting" checked={alertStatus.Alerting} onChange={handleChange("Alerting")} /> 
              <span>Alerting</span>
            </Grid>
            <Grid className="rc--form-control flex-dispaly">
              <input type="checkbox" name="Normal" checked={alertStatus.Normal} onChange={handleChange("Normal")} /> 
              <span>Normal</span>
            </Grid>
          </Grid>}
          {alertsMainInfo?.total_count > 0 && <Grid item xs={4} sm={4} md={4} lg={4} className="marg-left-auto">
            <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
          </Grid>}
        </Grid>
        <Box>
          <Pagination
            rowsPerPageOptions={rowsOption}
            count={alertsMainInfo?.total_count ? alertsMainInfo?.total_count : 0}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer className="alertsTable-FleetDashboard">
          <Table aria-label="simple table">
            <TableHead className="tableHead alertsTable-tableHead">
              <TableRow className="alertReports-tableRow">
                <TableCell className="alertsTable-fleetDataTable font-wt-900">SITE</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">DEVICE NAME</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">ALERT NAME</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">INTERFACE NAME</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">WAN TYPE</TableCell>
                <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900">REPORTED ON</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">STATUS</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">DETAILS</TableCell>
                <TableCell className="alertsTable-fleetDataTable font-wt-900">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            {0 != alertMainDetails.length &&  vesselIds.length > 0 ? (
              <TableBody className="tableBody alertsTable-tableBody">
                {
                  alertMainDetails.length > 0 && alertMainDetails.map((item) =>
                    <TableRow className="alertsData-tabelRow">
                      <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.site_name ? item.site_name : 'no data'}</span>}>
                        <TableCell className="alertsTable-tableCell alerts-site-ellipse">
                          {item?.site_name ? item.site_name : '-'}
                        </TableCell>
                      </Tooltip>
                      <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.device_name ? item.device_name : 'no data'}</span>}>
                        <TableCell className="alertsTable-tableCell alerts-devicename-ellipse">
                          {item?.device_name ? item.device_name : '-'}
                        </TableCell>
                      </Tooltip>
                      {!isReadOnlyUser ?
                        <Tooltip className="topWidgetInactiveCursor" title={item?.description ? item.description : 'no data'}>
                          <TableCell className="alertsTable-tableCell alerts-site-ellipse">
                            <Link to={getAlertNameLink(item)} style={{ textDecoration: "none" }} >
                              {item?.alert_name ? item.alert_name : ''}
                            </Link>
                          </TableCell>
                        </Tooltip> :
                      <TableCell className="alertsTable-tableCell alerts-site-ellipse">
                          {item?.alert_name ? item.alert_name : '-'}
                      </TableCell> 
                      }

                      <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.interface_name ? item?.interface_name : 'no data'}</span>}>
                        <TableCell className="alerts-interface-width">
                          {item?.interface_name ? item.interface_name : '-'}
                        </TableCell>
                      </Tooltip>

                      {/* <TableCell className="alertsTable-tableCell">{item?.interface_name}</TableCell> */}
                      <TableCell className="alertsTable-tableCell">{item?.wan_type? item?.wan_type.toUpperCase(): ""}</TableCell>
                        {/* <Tooltip title={<span style={{ fontSize: "12px" }}>{moment(item.created_on).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</span>}> */}
                          <TableCell className="alertsTable-tableCell">{convertDateTimeIntoTimezone(item.created_on, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                        {/* </Tooltip> */}
                      <TableCell className={`alertsTable-tableCell ${getAlertTextColor(item?.status)} alertStatusDetails`}>
                        <div className="alertStatusWithIcon">
                          <img src={getAlertIcon(item.status)} className="alertIcon" alt={item.status} />
                          <span className={getAlertTextColor(item?.status)}>{item.status}</span>
                        </div>
                      </TableCell>
                      <TableCell className="alertsTable-tableCell labelDetails">
                        <JsonDataTooltip className="labelDEtailsJsonToolt=Tip" data={item?.metadata} />
                      </TableCell>
                      <TableCell className="alertsTable-tableCell">
                          <img className="alertsTable-action-deleteIcon" src={DeleteIcon} title="Delete Alert" 
                              onClick={() => {
                                  setConfirmDialog({
                                      isOpen: true,
                                      title: "Delete Alert",
                                      subTitle: `Do you want to delete this alert permanently? It will only be recreated if you update the alert configuration`,
                                      onConfirm: () => {
                                        handleDeleteAlert(item?.site_id, item?.alert_id)
                                      },
                                  })
                              }}
                          />
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alertMainDetails: state?.alertsDashboard?.alertMainDetails,
  alertsMainInfo: state?.alertsDashboard?.alertsMainInfo,
  authReducer: state.authReducer,
  startDate: state?.authReducer?.newSummaryStartDate,
  endDate: state?.authReducer?.newSummaryEndDate,
  isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
  connect(mapStateToProps, {
    getVesselAlerts,
    GetAlertNames,
    getAlertsMain,
    downloadAsCSV,
    deleteAlerts
  })(AlertTablePage)
);
