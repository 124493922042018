import { Fragment, useEffect, useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";

import QuestionMarkOutlinedIcon from '@mui/icons-material/Help';

import { Alert, Box, Button, Collapse, FormControl, Grid, IconButton, InputAdornment, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import { Pagination } from "../Pagination";

import './StarlinkDashboard.css';
import { getDecodeURI, getEncodedURI } from '../../utils/util';

import { getServiceLines, getStarlinkCloudWarnings, clearComponentData } from "./slice";
import _ from "lodash";
import SortArrows, { SortOrder } from "../SortArrows";

import SearchIcon from "@mui/icons-material/Search";
import CallSplitIcon from '@mui/icons-material/CallSplit';

import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload, escapeJsonString } from "../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { STARLINK_DATAUSAGE_CATEGORIES, STARLINK_DATAUSAGE_CATEGORIES_DATA } from "../../UserScreen/Starlink/types";
import { nanoid } from "nanoid";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { YYYYMMDD_HHmmss } from "../../utils/constants";
const mapToCsvRow = (row: any) => {
    const values: any[] = Object.values(row);
    const userTerminals: any[] = row.userTerminals != '' && JSON.parse(row.userTerminals) || [];
    const usageSplit: any[] = row.usageSplit != '' && JSON.parse(row.usageSplit) || [];
    return [values[10], values[5], values[3], values[22], values[11],  usageSplit,
     userTerminals.map(value => value.kitSerialNumber).join(';'),
      userTerminals.map(value => value.pingDropRate).join(';'),
       userTerminals.map(value => value.pingLatencyMs != null ?  value.pingLatencyMs : 0).join(';'),
        userTerminals.map(value => `${value.downlinkThroughput != null ? value.downlinkThroughput : 0 }/${value.uplinkThroughput != null ? value.uplinkThroughput : 0}`).join(';'),
         userTerminals.map(value => value.obstructionPercentTime  != null ? value.obstructionPercentTime : 0).join(';'),
        userTerminals.map(value => {
            if (value.activeAlerts) {
                try {
                    const alerts = JSON.parse(value.activeAlerts);
                    return alerts.length;
                } catch (error) {
                    console.error("Error parsing activeAlerts:", error);
                    return 0; 
                }
            } else {
                return 0; 
            }
        }).join(';'),
        values[6]
        ]
}

const CsvHeaders = ['Service Line', 'Organization', 'Sub Organization','Location', 'Usage (GB)', 'Usage Split', 'User Terminal', 'Ping Drop Rate (%)', 'Ping Latency (ms)', 'Downlink/Uplink Throughput (Mbps)', 'Obstruction (%)', 'Starlink/Edge Alerts', 'Site'];


interface StarlinkDashboardProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    warnings: string[];
    serviceLines: any[];
    totalServiceLines: number;
    totalUsageGB: number;
    totalOverageGB: number;
    totalOverage: number;
    totalUserTerminals: number;
    totalOnlineTerminals: number;
    totalActiveAlerts: number;
    totalAlertingTerminals: number;
    getServiceLines: (parameters: any) => void;
    getStarlinkCloudWarnings: (parameters: any) => void;
    downloadAsCSV: (parameters: any) => void;
    newSummaryStartDate: Moment;
    newSummaryEndDate: Moment;
    newSummaryServiceLine: any;
    clearComponentData: any;
}

export type DashboardSortBy = 'nickname' | 'accountDpName' | 'dpName' | 'usageGB' | 'kitSerialNumber' | 'pingDropRate' | 'pingLatencyMs' | 'downlinkThroughput' | 'uplinkThroughput' | 'obstructionPercentTime' | 'activeAlerts' | 'serviceLineEdgeAlerts' | 'k4SiteName' | 'online';

function StarlinkDashboard(props: StarlinkDashboardProps) {

    const { authReducer, errorReducer, history, location, dpIds, warnings, serviceLines, totalServiceLines, totalUsageGB, totalOverageGB, totalOverage, totalUserTerminals, totalOnlineTerminals, totalActiveAlerts, totalAlertingTerminals, getServiceLines, getStarlinkCloudWarnings, newSummaryStartDate, newSummaryEndDate, newSummaryServiceLine, downloadAsCSV, clearComponentData } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: DashboardSortBy;
        sortOrder: SortOrder;
        search: string;
        startDate: moment.Moment;
        endDate: moment.Moment;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as DashboardSortBy || 'usageGB',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate
    });
    const [showWarning, setShowWarning] = useState<boolean>(warnings?.length > 0);
    const [updatedServiceLines, setUpdatedServiceLines] = useState<any>([]);

    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: DashboardSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    useEffect(() => {
        if (tableParams.startDate.format('YYYY-MM-DD') == newSummaryStartDate.format('YYYY-MM-DD') && tableParams.endDate.isSame(newSummaryEndDate)) {
            return;
        }
        setTableParams({
            ...tableParams,
            startDate: newSummaryStartDate,
            endDate: newSummaryEndDate,
            page: 1
        });
    }, [newSummaryEndDate, newSummaryStartDate])

    // const handleChangeStartDate = (date: moment.Moment | null) => {
    //     if (date) {
    //         setTableParams({
    //             ...tableParams,
    //             startDate: date,
    //             page: 1
    //         });
    //     }
    // }

    // const handleChangeEndDate = (date: moment.Moment | null) => {
    //     if (date) {
    //         setTableParams({
    //             ...tableParams,
    //             endDate: date,
    //             page: 1
    //         });
    //     }
    // }

    const alertMap = {
        50: "ethernet_slow_10mbps",
        51: "ethernet_slow_100mbps",
        52: "power_supply_thermal_throttling",
        53: "actuator_motor_stuck",
        54: "mast_not_vertical",
        55: "disabled_no_active_account",
        56: "disabled_too_far_from_service_address",
        57: "disabled_no_service_in_ocean",
        58: "disabled_invalid_country",
        59: "moving_while_not_mobile",
        60: "disabled_moving_too_fast",
        61: "power_disconnect_detected",
        62: "pop_change",
        79: "software_update_reboot_pending",
        63: "unable_to_align",
        64: "high_sky_obstruction",
        65: "high_sky_obstruction_mobile",
        66: "high_time_obstruction",
        67: "high_time_obstruction_mobile",
        80: "thermal_shutdown",
        81: "thermal_throttling",
        82: "software_update_reboot_pending",
        "over_threshold": "over_quota_threshold",
        "over_limit": "over_max_quota",
        "offline_for_15m": "Offline for more than 15 minutes",
        "offline_for_1h": "Offline for more than 1 hour",
        "offline_for_6h": "Offline for more than 6 hours",
    }

    const getOnlineStatusColor = (online, accountType) => {
        if (accountType === 'individual') {
            return '#efefef'
        }
        if (online === null) {
            return '#474748'
        }
        return online ? '#6cce76' : '#ed524a';
    }

    const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        // queryParams.set('startDate', tableParams.startDate.valueOf().toString())
        // queryParams.set('endDate', tableParams.endDate.valueOf().toString())
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        return () => {
        }
    }, [])

    useEffect(() => {
        updateQueryParams();
        let _parameters = {
            dpIds,
            startDate: tableParams.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: tableParams.endDate.format('YYYY-MM-DD HH:mm:ss'),
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            source: getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl != '') : [],
            usageSource:getUsageSource()
        }
        clearComponentData()
        getServiceLines(_parameters); 
    }, [ tableParams, newSummaryServiceLine]);

    const getUsageSource = () =>{
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if ( hoursDifference <= 24) {
                    return 'aggregation_table';
                } else if (hoursDifference > 24 ) {
                    return 'direct_source';
                } else {
                    return ''
                }
    }

    const getSource = () =>{
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();

        if (hoursDifference < 6) {
            return '_agg_1h';
        } else if (hoursDifference >= 6 && hoursDifference < 24) {
            return '_agg_6h';
        } else if (hoursDifference >= 24 && hoursDifference < 168) {
            return '_agg_1day';
        }else if (hoursDifference >= 168 ) {
            return '_agg_1week';
        } else {
            return ''
        }
    }

    useEffect(() => {
        getStarlinkCloudWarnings({ dpIds });
    }, [dpIds]);

    useEffect(() => {
        if (warnings?.length > 0) {
            setShowWarning(true);
        }
    }, [warnings]);

    const dataBucketFormat = (buckets: any[]) => {
        return buckets?.length > 0 ? `"${buckets?.map(bucket => {
            return Object.keys(bucket).map(key => {
                if (bucket[key] > 0) {
                return `${STARLINK_DATAUSAGE_CATEGORIES_DATA[key]} - ${bucket[key]} GB`;
                }
                return null;
            }).filter(Boolean).join(', ');
        }).join(', ')}"` : '';
    };

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'starlink-dashboard') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = JSON.parse(status[0]);
                    if (rows.length) {

                        DownloadCSV([CsvHeaders, ...rows.map(row => mapToCsvRow(row))], {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                2: 'ESC-COMMA',
                                5: dataBucketFormat,
                                10: 'ESC-COMMA',
                                11: 'ESC-COMMA',
                            }
                        }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded]) 

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            queryName: 'GET_SERVICE_LINES_SPORT',
            id: "starlink-dashboard",
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                source: getSource(),
                usageSource:getUsageSource(),
                limit: totalServiceLines,
                offset: 0,
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            }
        }
        downloadAsCSV(params);
    }

    useEffect(() => {
        const resources = JSON.parse(localStorage.getItem('all_resources') || '[]');
        const starlinkOnlyUser = JSON.parse(localStorage.getItem('loggedInUser') || '[]');
        let arr = serviceLines?.map((serviceLine) => {
            if (resources.filter((resource) => serviceLine.k4SiteName == resource.name)?.length > 0 && !starlinkOnlyUser?.data?.user?.starlinkOnlyUser) {
                serviceLine.isFound = true;
            } else {
                serviceLine.isFound = false;
            }
            return serviceLine
        })
        setUpdatedServiceLines(arr);
    }, [serviceLines])

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.location_id}:${row.site}`;
        delete params.page;
        history.push({ pathname: '/fleet-dashboard/sites', search: `?${getEncodedURI(params)}` });
    }

    return (
        <Grid>
            {/* CARDS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Service Lines" leftTitle="Total" leftContent={totalServiceLines} rightTitle="Overage" rightContent={totalOverage} progress={(totalServiceLines - totalOverage) / totalServiceLines * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="User Terminals" leftTitle="Total" leftContent={totalUserTerminals}
                            rightTitle="Offline" rightContent={totalUserTerminals - totalOnlineTerminals} progress={totalOnlineTerminals / totalUserTerminals * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Usage" leftTitle="Total" leftContent={`${totalUsageGB} GB`} rightTitle="Overage (MTD)" rightContent={`${totalOverageGB} GB`} progress={(totalUsageGB - totalOverageGB) / totalUsageGB * 100} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card title="Alerts" leftTitle="Total Alerts" leftContent={totalActiveAlerts} rightTitle="Alerting Terminals" rightContent={totalAlertingTerminals} progress={(totalUserTerminals - totalAlertingTerminals) / totalUserTerminals * 100} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                <Collapse in={showWarning}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowWarning(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {
                            warnings?.map((warning: string) => <Typography component="div" className="starlink--base--font_09x">
                                {warning}
                            </Typography>)
                        }
                    </Alert>
                </Collapse>
            </Grid>

            {/* SEARCH AND PERIOD FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid display={'flex'} alignItems={'center'} classes={{ root: 'starlink--base--flex--gap--05x' }}>
                    <TextField
                        id="search"
                        fullWidth
                        variant="outlined"
                        placeholder="Type something"
                        classes={{ root: "input-box-service" }}
                        size="small"
                        value={searchText}
                        onChange={handleOnSearchChange}
                        onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                            className: "serachBar",
                            startAdornment: (
                                <InputAdornment position="start">
                                    {!searchText && <SearchIcon
                                        className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                    />}
                                    {searchText && <CloseIcon
                                        className="cursorPointer input-search-icons"
                                        onClick={handleOnSearchClear}
                                    />}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                        <Typography component={'div'} className="starlink--base--font_08x starlink--base--padding_05x">
                            Web Service Accounts do not include online/offline, Drop Rate, Latency, Throughput, Obstruction or Alert data, this only comes from Starlink Enterprise Service Account API
                        </Typography>
                    }>
                        <QuestionMarkOutlinedIcon className="marg-left-auto" />
                    </Tooltip>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} classes={{ root: 'starlink--base--flex--gap--05x' }}>
                    {totalServiceLines > 0 && <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleDownloadReport} disabled={false} startIcon={<img className="downloadImg" src={Download} alt="" />}>Download</Button>}
                </Grid>
            </Grid>

            <Paper elevation={0} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_05x' }}>
                {/* TABLE PAGINATION */}
                <Pagination count={totalServiceLines} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

                {/* SERVICE LINE TABLE */}
                <TableContainer component={Paper} className="starlink--table_container">
                    <Table sx={{ minWidth: 650, maxWidth: '100%' }} size="small" aria-label="starlink--dashboard">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="nickname" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SERVICE LINE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">ORGANIZATION</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SUB ORGANIZATION</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                        <span className="font-wt-900">LOCATION</span>
                                </TableCell>
                                {/* <TableCell>LOCATION</TableCell> */}
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="usageGB" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">USAGE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="online" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">USER TERMINALS</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="pingDropRate" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">PING DROP RATE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="pingLatencyMs" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">PING LATENCY</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <Grid classes={{ root: 'starlink--base--flex--row' }} sx={{ gap: '0.25rem' }}>
                                        <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="downlinkThroughput" onChange={handleChangeSorting}>
                                            <span className="font-wt-900">DOWNLINK</span>
                                        </SortArrows> /
                                        <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="uplinkThroughput" onChange={handleChangeSorting}>
                                            <span className="font-wt-900">UPLINK</span>
                                        </SortArrows>
                                    </Grid>
                                    <span className="font-wt-900">THROUGHPUT</span>
                                </TableCell>
                                {/* <TableCell>UPLINK THROUGHPUT</TableCell> */}
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="obstructionPercentTime" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">OBSTRUCTION</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                <div style={{display:"flex"}}> 
                                    <Tooltip
                                        classes={{ tooltip: 'starlink--tooltip' }}
                                        title={
                                            <div className="menuScroll">
                                                <div className="starlinkAlertsTableDiv">
                                                    <Table size="small" className="starlinkAlertsTableWidth" >
                                                        <TableBody >
                                                            {Object.entries(alertMap).map(([key, value]) => (
                                                                <TableRow key={key}>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{key}</TableCell>
                                                                    <TableCell>{value}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <span className="font-wt-900">ALERTS   </span>
                                    </Tooltip>

                                   
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="activeAlerts" onChange={handleChangeSorting}>
                                </SortArrows>
                                </div>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="k4SiteName" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SITE</span>
                                    </SortArrows>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                updatedServiceLines?.length > 0 ? updatedServiceLines.map((serviceLine: any) => {
                                    return <TableRow
                                        key={nanoid()}
                                    >
                                        <TableCell>
                                            <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                <Grid>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Account Number</TableCell>
                                                                <TableCell>{serviceLine.accountNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Service Line Number</TableCell>
                                                                <TableCell>{serviceLine.serviceLineNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Location</TableCell>
                                                                <TableCell>{serviceLine.location}</TableCell>
                                                            </TableRow>
                                                            {
                                                                serviceLine.userTerminals !== '' &&   JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                                    return (
                                                                        userTerminal.interface_display_name !== null ? 
                                                                        <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>Edge Interface</TableCell>
                                                                        <TableCell>{userTerminal.interface_display_name !== null ? `${userTerminal.interface_display_name}` : ""}  ( {userTerminal.kitSerialNumber !== null ? `${userTerminal.kitSerialNumber}` : "" } )</TableCell> 
                                                                    </TableRow>
                                                                    : null
                                                                    )
                                                                })
                                                            } 
                                                           
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            }>
                                                <Box>{serviceLine.nickname}</Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >{serviceLine.accountDpName}</TableCell>
                                        <TableCell >{serviceLine.dpName}</TableCell>
                                        <Tooltip title={<span style={{ fontSize: "12px" }}>{`${serviceLine?.currentGeoLocation}, ${serviceLine?.currentGeoCountry}`}</span>}>
                                        <TableCell>
                                            {serviceLine?.currentGeoLocation}, {serviceLine?.currentGeoCountry && serviceLine?.currentGeoCountry.length > 10 ? `${serviceLine?.currentGeoCountry.substring(0, 10)}...` : serviceLine?.currentGeoCountry}
                                        </TableCell> 
                                        </Tooltip>
                                        <TableCell>
                                            {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageGB) ? <Grid display={'flex'} alignItems={'center'}>
                                                {JSON.parse(serviceLine?.usageSplit)?.length === 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                    <Grid>
                                                        <Table size="small">
                                                            <TableBody>

                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usagePriorityGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageOptInPriorityGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageStandardGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageNonBillableGB : '0'}  GB</TableCell>
                                                                </TableRow>
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                }>
                                                    <Box>{`${serviceLine?.usageGB } GB`}</Box>
                                                </Tooltip> : `${serviceLine?.usageGB } GB`}
                                                {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageSplit)?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                    <Grid>
                                                        <Table size="small">
                                                            <TableBody>
                                                                {
                                                                    JSON.parse(serviceLine?.usageSplit)?.map((split: any) => <TableRow key={`tooltip-${split?.usageGB}`}>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] ? STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] : 'Unknown Bucket'}</TableCell>
                                                                        <TableCell>{split?.usageGB} GB</TableCell>
                                                                    </TableRow>)
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                }>
                                                    <IconButton>
                                                        <CallSplitIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip> : null}
                                            </Grid> : "0 GB"}
                                        </TableCell>
                                        <TableCell >
                                             {
                                              serviceLine.userTerminals !== '' &&  JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                                                        {userTerminal.kitSerialNumber ? <Fragment>
                                                            <CircleIcon className="starlink--circle_icon" sx={{ color: getOnlineStatusColor(JSON.parse(userTerminal.online), serviceLine.accountType), marginRight: '0.5rem', fontSize: '0.56rem' }} />
                                                            {userTerminal.kitSerialNumber}
                                                        </Fragment> : null}
                                                    </Grid>
                                                })
                                            } 
                                        </TableCell>
                                        <TableCell >
                                            {
                                                 serviceLine.userTerminals !== '' &&   JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Box>{userTerminal.pingDropRate !== null ? `${userTerminal.pingDropRate}%` : null}</Box>
                                                })
                                            } 
                                        </TableCell>
                                        <TableCell >
                                            {
                                                 serviceLine.userTerminals !== '' &&   JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Box>{userTerminal.pingLatencyMs !== null ? `${userTerminal.pingLatencyMs} ms` : null}</Box>
                                                })
                                            }
                                        </TableCell>
                                        <TableCell >
                                            {
                                                serviceLine.userTerminals !== '' &&    JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Box>{userTerminal.downlinkThroughput !== null && userTerminal.uplinkThroughput !== null ? `${userTerminal.downlinkThroughput} / ${userTerminal.uplinkThroughput} Mbps` : null}</Box>
                                                })
                                            } 
                                        </TableCell>
                                        <TableCell >
                                            {
                                                 serviceLine.userTerminals !== '' &&   JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                    return <Box>{userTerminal.obstructionPercentTime !== null ? `${userTerminal.obstructionPercentTime}%` : null}</Box>
                                                })
                                            } 
                                        </TableCell>
                                        <TableCell >
                                            <Grid display={'flex'} direction={'row'} alignItems={'center'}>
                                                <Grid>
                                                    {
                                                        serviceLine.userTerminals !== '' &&    JSON.parse(serviceLine?.userTerminals)?.length > 0 ?  JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                                            return <Box>
                                                                <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                                    <Grid>
                                                                        <Table size="small">
                                                                            <TableBody>
                                                                                {
                                                                                  userTerminal.activeAlerts !== "" &&  JSON.parse(userTerminal.activeAlerts)?.map((alert: any) => {
                                                                                        return <TableRow>
                                                                                            <TableCell>
                                                                                                {alertMap[alert] ? titleCase(alertMap[alert]) : titleCase(`alert_${alert}`)}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    })

                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Grid>}>
                                                                    <Box>{  userTerminal.activeAlerts !== "" && JSON.parse(userTerminal.activeAlerts) !== null ? `${JSON.parse(userTerminal?.activeAlerts)?.length}` : 0}</Box>
                                                                </Tooltip></Box>
                                                        }) : 0
                                                    } 
                                                </Grid>
                                                <HorizontalRuleIcon fontSize="small" />
                                                <Grid>
                                                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                        <Grid>
                                                            <Table size="small">
                                                                <TableBody>
                                                                     {
                                                                        JSON.parse(serviceLine.edgeAlerts)?.map((alert: any) => {
                                                                            return <TableRow>
                                                                                <TableCell>
                                                                                    {alertMap[alert] ? titleCase(alertMap[alert]) : titleCase(`alert_${alert}`)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        })
                                                                    } 
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>}>
                                                        <Box>{JSON.parse(serviceLine.edgeAlerts) !== null ? `${JSON.parse(serviceLine?.edgeAlerts)?.length}` : 0}</Box>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <span style={serviceLine?.isFound ? {color: '#4c3aff', cursor: 'pointer'} : {color:'#45464E'}} onClick={() => serviceLine.isFound && handleSiteClick({location_id: serviceLine.k4SiteId, site: serviceLine.k4SiteName})}>{serviceLine?.k4SiteName}</span>
                                        </TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan={10} align='center'>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
}

interface CardProps {
    title: string;
    leftTitle: string;
    leftContent: any;
    rightTitle: string;
    rightContent: any;
    // leftTooltip: React.ReactNode;
    // rightTooltip: React.ReactNode;
    progress: number;
}

function Card(props: CardProps) {
    const { title, leftTitle, leftContent, rightTitle, rightContent, progress } = props;
    return (
        <Paper classes={{ root: 'starlink--base--flex--column starlink--base--flex--justify--space_between starlink--base--padding_05x' }}>
            <Typography component="div" className="starlink--dashboard--card_title">
                {title}
            </Typography>
            <Grid classes={{ root: 'starlink--base--flex--row starlink--base--flex--justify--space_around starlink--base--mtb_07x' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
                        {leftTitle}
                    </Typography>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
                        {leftContent}
                    </Typography>
                </Grid>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
                        {rightTitle}
                    </Typography>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
                        {rightContent}
                    </Typography>
                </Grid>

            </Grid>
            <Box className="starlink--dashboard--card_progress">
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    warnings: state.starlinkDashboard.warnings,
    serviceLines: state.starlinkDashboard.serviceLines,
    totalServiceLines: state.starlinkDashboard.totalServiceLines,
    totalUsageGB: state.starlinkDashboard.totalUsageGB,
    totalOverageGB: state.starlinkDashboard.totalOverageGB,
    totalOverage: state.starlinkDashboard.totalOverage,
    totalUserTerminals: state.starlinkDashboard.totalUserTerminals,
    totalOnlineTerminals: state.starlinkDashboard.totalOnlineTerminals,
    totalAlertingTerminals: state.starlinkDashboard.totalAlertingTerminals,
    totalActiveAlerts: state.starlinkDashboard.totalActiveAlerts,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceLines,
        getStarlinkCloudWarnings,
        downloadAsCSV,
        clearComponentData
    })(StarlinkDashboard)
);