// Dialog Box for the Create new ticket of Ticketing Page Pop-up
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { Dialog, DialogContentText } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import deleteIcon from "../../asset/image/delete_red_icon.svg";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./CreateNewTicketDialog.css"
import { createNewTicket } from "../../actions/Users/authenticateTicket";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT } from "../../utils/constants";
import moment from "moment-timezone";
import { autoCompleteRenderTags } from "../../utils/util";

import _ from "lodash";

import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from "@mui/material";

import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import attachmentIcon from '../../asset/image/attachments.svg';
import BackArrow from "../../asset/image/arrow_back.svg";

const AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS = 1;
const vsatIssues = [{ issue: "Upgrade / Downgrade" }, { issue: "Slow / No Service" }, { issue: "Modem" }, { issue: "Antenna" }, { issue: "Others" },];
const lteIssues = [{ issue: "Upgrade / Downgrade" }, { issue: "Slow / No Service" }, { issue: "Modem" }, { issue: "Antenna" }, { issue: "Others" },];
const kaasIssues = [{ issue: "Server SW" }, { issue: "Mobile APP" }, { issue: "Outbound Calls" }, { issue: "Inbound Calls" }, { issue: "Others" },];
const billingIssues = [{ issue: "Invoicing" }, { issue: "Payment" }, { issue: "Others" },];
const otherIssues = [{ issue: "Network Issues" }, { issue: "LAN" }, { issue: "WI-FI" }, { issue: "AV" }, { issue: "Others" },];


const CreateNewTicketDialog = (props) => {
  const { onClose, selectedValue, open, vesselNames, createTicketVesselList, createNewTicket, addToAttachments, assigneeList, autoCompleteNoOptionsText, authReducer } = props;
  let [dateFormat, setDateFormat]: any = useState();
  let [subject, setSubject]: any = useState("");
  let [description, setDescription]: any = useState("");
  let [eventDate, setEeventDate]: any = useState(new Date());
  let [assignee, setAssignee]: any = useState();
  let [vessel, setVessel]: any = useState();
  let [vsatOptions, setVsatOptions]: any = useState([])
  let [lteOptions, setLteOptions]: any = useState([])
  let [kaasOptions, setKaasOptions]: any = useState([])
  let [billingOptions, setBillingOptions]: any = useState([])
  let [otherOptions, setOtherOptions]: any = useState([])
  let [showAttachments, setShowAttachments]: any = useState([]);
  let [attachmentsFile, setAttachmentsFile]: any = useState([]);
  useEffect(() => {
    // <DatePickerComponent format={dateFormat}
    setDateFormat(DEFAULT_DATE_FORMAT.toLowerCase());
  })

  const handleClose = () => {
    onClose(selectedValue);
    setDefaultSelections();
  }

  const handleClearAllClick = () => {
    setDefaultSelections();
  }

  const setDefaultSelections = () => {

    setVessel({});
    setSubject("");
    setDescription("");
    setEeventDate(new Date());
    setAssignee({});
    setAttachmentsFile([]);
    setShowAttachments([]);
  }

  useEffect(() => {


    let vsatText = "";
    let lteText = "";
    let kaasText = "";
    let billingText = "";
    let otherText = "";
    let ticketSubject = "";

    if (vsatOptions && vsatOptions.length > 0) {
      let text = ''
      vsatOptions.forEach((el, index) => {
        if (index === vsatOptions.length - 1) {
          text += el.issue
        } else {
          text += el.issue + ', '
        }
      })
      vsatText = 'VSAT:' + text
    }

    if (lteOptions && lteOptions.length > 0) {
      let text = ''
      lteOptions.forEach((el, index) => {
        if (index === lteOptions.length - 1) {
          text += el.issue
        } else {
          text += el.issue + ', '
        }
      })
      lteText = 'LTE:' + text
    }


    if (kaasOptions && kaasOptions.length > 0) {
      let text = ''
      kaasOptions.forEach((el, index) => {
        if (index === kaasOptions.length - 1) {
          text += el.issue
        } else {
          text += el.issue + ', '
        }
      })
      kaasText = 'KaaS:' + text
    }

    if (billingOptions && billingOptions.length > 0) {
      let text = ''
      billingOptions.forEach((el, index) => {
        if (index === billingOptions.length - 1) {
          text += el.issue
        } else {
          text += el.issue + ', '
        }
      })
      billingText = 'Billing:' + text
    }

    if (otherOptions && otherOptions.length > 0) {
      let text = ''
      otherOptions.forEach((el, index) => {
        if (index === otherOptions.length - 1) {
          text += el.issue
        } else {
          text += el.issue + ', '
        }
      })
      otherText = 'Others:' + text
    }

    ticketSubject = vsatText ? vsatText : ''
    ticketSubject = lteText ? ticketSubject + ' ' + lteText : ticketSubject
    ticketSubject = kaasText ? ticketSubject + ' ' + kaasText : ticketSubject
    ticketSubject = billingText ? ticketSubject + ' ' + billingText : ticketSubject
    ticketSubject = otherText ? ticketSubject + ' ' + otherText : ticketSubject

    console.log("FinalSubject", ticketSubject)

    setSubject(ticketSubject)

  }, [vsatOptions, lteOptions, kaasOptions, billingOptions, otherOptions])


  const handleSendTicketClick = () => {
    if (vessel && subject && description && eventDate && assignee && assignee.k4EmpId) {

      let payload = {
        "k4Id": vessel ? vessel['id'] : null,
        "ticketStatus": "Open",
        "ticketSubject": subject.trim(),
        "ticketDesc": description.trim(),
        "eventDate": eventDate,
        "assignedTo": assignee.k4EmpId,
        "channel": "Web",
      }
      createNewTicket(payload);
      attachmentsFile.length > 0 && addToAttachments(attachmentsFile);
      handleClose();
    }
  }

  // attachment: box style
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#bdbdbd',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
    fontFamily: 'Roboto',
  };

  // drag and drop styles based on drag status
  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    backgroundColor: '#00e676',
    borderColor: '#00e676'
  };

  const rejectStyle = {
    backgroundColor: '#ff1744',
    borderColor: '#ff1744'
  };

  // once user dropped file is accepted
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      saveAttachment(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: ["image/png", "image/jpg", "image/jpeg", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-outlook", "application/pdf"]
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const attachmentsKeyDown = (event) => {
    event && event.preventDefault();
  }

  // handle image paste
  const attachmentsHandleOnPaste = (event) => {
    // Get the data of clipboard
    let clipboardItems = event.clipboardData.items;
    let items = [] as any;
    for (let key in clipboardItems) {
      if (clipboardItems[key]["type"] && clipboardItems[key]["type"].includes('image')) {
        items.push(clipboardItems[key]);
      }
    }
    // dont paste the content
    event && event.preventDefault();
    if (items && items.length === 0) {
      return;
    }
    const item = items[0];
    // Get the blob of image
    let blob = item && item.getAsFile();
    saveAttachment(blob);
  }

  const saveAttachment = (file) => {
    // save attachment as file
    setAttachmentsFile(prevState => [...prevState, file]);
    let name = file.name;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let base64data = reader.result;
      // save attachment image as base64 for preview
      setShowAttachments(prevState => [...prevState, { "name": name, "base64String": base64data }]); // data url
    }
  }

  const deleteAttachment = (index) => {
    if (index < showAttachments.length) {
      let filteredArray = showAttachments.filter((item, i) => i !== index)
      setShowAttachments(filteredArray);
    }
    if (index < attachmentsFile.length) {
      let filteredArray = attachmentsFile.filter((item, i) => i !== index)
      setAttachmentsFile(filteredArray);
    }
  }

  const openAttachmentInNewTab = (data) => {
    let w = window.open('about:blank');
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w && w.document.write(image.outerHTML);
    }, 0);
  }

  const handleSetDescription = (input) => {
      setDescription(input)
  }

  return (
    <div>
      <div >
        {/* <DialogTitle className="dialog_title">
          <Grid>
            New Ticket
            <Grid>
              <img src={close} onClick={() => handleClose()} className="dialog_closeIcon" />
            </Grid>
          </Grid>
        </DialogTitle> */}
        <DialogContent>
          <div className="cur-point" onClick={() => onClose()}>
            <img src={BackArrow} className="downloadImg" title="Back"/>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="h4" >
              <span style={{ fontStyle: 'italic', }}>
                Select all the boxes that describe the issue that you are having (at least one must be selected)*
              </span>
            </Typography>
          </div>
          <Grid container justifyContent={"space-between"} spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                style={{ width: "100%", padding: "5px" }}
                noOptionsText={autoCompleteNoOptionsText}
                options={vsatIssues}
                getOptionLabel={(option: any) => option['issue']}
                PaperComponent={({ children }) => (
                  <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                )}
                onChange={(e, newValue: any) => {
                  setVsatOptions(newValue)
                }}
                renderTags={(value, getTagProps) => {
                  return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "issue");
                }}
                renderInput={(params) => <TextField {...params} label="VSAT"  size="small" InputLabelProps={{ className: "filterStateName" }}/>}
              />

            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                style={{ width: "100%", padding: "5px" }}
                noOptionsText={autoCompleteNoOptionsText}
                options={lteIssues}
                getOptionLabel={(option: any) => option['issue']}
                PaperComponent={({ children }) => (
                  <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                )}
                onChange={(e, newValue: any) => {
                  setLteOptions(newValue);
                }}
                renderTags={(value, getTagProps) => {
                  return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "issue");
                }}
                renderInput={(params) => <TextField {...params} label="CELLULAR" size="small" InputLabelProps={{ className: "filterStateName" }}/>}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                style={{ width: "100%", padding: "5px" }}
                noOptionsText={autoCompleteNoOptionsText}
                options={kaasIssues}
                getOptionLabel={(option: any) => option['issue']}
                PaperComponent={({ children }) => (
                  <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                )}
                onChange={(e, newValue: any) => {
                  setKaasOptions(newValue);
                }}
                renderTags={(value, getTagProps) => {
                  return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "issue");
                }}
                renderInput={(params) => <TextField {...params} label="KaaS" size="small" InputLabelProps={{ className: "filterStateName" }}/>}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                style={{ width: "100%", padding: "5px" }}
                noOptionsText={autoCompleteNoOptionsText}
                options={billingIssues}
                getOptionLabel={(option: any) => option['issue']}
                PaperComponent={({ children }) => (
                  <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                )}
                onChange={(e, newValue: any) => {
                  setBillingOptions(newValue);
                }}
                renderTags={(value, getTagProps) => {
                  return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "issue");
                }}
                renderInput={(params) => <TextField {...params} label="Billing" size="small" InputLabelProps={{ className: "filterStateName" }}/>}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                style={{ width: "100%", padding: "5px" }}
                noOptionsText={autoCompleteNoOptionsText}
                options={otherIssues}
                getOptionLabel={(option: any) => option['issue']}
                PaperComponent={({ children }) => (
                  <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                )}
                onChange={(e, newValue: any) => {
                  setOtherOptions(newValue);
                }}
                renderTags={(value, getTagProps) => {
                  return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "issue");
                }}
                renderInput={(params) => <TextField {...params} label="Others" size="small" InputLabelProps={{ className: "filterStateName" }}/>}
              />
            </Grid>

          </Grid>
          <div className="tkt_new_ticket_dialogContent">
            {/* <div className="tkt_new_ticket_dialogContent_part1"> */}
            <Grid container spacing={2}>
              {/* <div className="tkt_new_ticket_dialog_assignee"> */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="tkt_new_ticket_dialog_assignee_title">Vessel</div>
                {

                  <div className="tkt_new_ticket_dialog_assigneeAutocomplete">
                    <Autocomplete
                      size="small"
                      noOptionsText={autoCompleteNoOptionsText}
                      options={createTicketVesselList}
                      getOptionLabel={(option: any) => option['name']}
                      PaperComponent={({ children }) => (
                        <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                      )}
                      onChange={(e, newValue: any) => {
                        setVessel(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search Vessel" />
                      )}
                    />
                  </div>
                }
                {/* </div> */}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="tkt_new_ticket_dialog_assignee_title">Assignee</div>
                {
                  assignee && assignee.fullName
                    ?
                    <OutlinedInput className="tkt_new_ticket_dialog_assignee_name" type="text" placeholder="Type something"
                      value={assignee.fullName}
                      endAdornment={
                        <InputAdornment position="end">
                          <img onClick={() => setAssignee({})} className="tkt_new_ticket_dialog_assignee_clearImage cursorPointer" src={close} />
                        </InputAdornment>
                      }
                    />
                    : <div className="tkt_new_ticket_dialog_assigneeAutocomplete">
                      <Autocomplete
                        size="small"
                        noOptionsText={autoCompleteNoOptionsText}
                        options={assigneeList}
                        renderOption={(props, option: any) => {
                          return (
                            <li {...props} key={option.k4EmpId}>
                              {option.fullName}
                            </li>
                          );
                        }}
                        getOptionLabel={(option) => option.fullName}
                        PaperComponent={({ children }) => (
                          <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                        )}
                        onChange={(e, newValue: any) => {
                          setAssignee(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Search assignee" />
                        )}
                      />
                    </div>
                }
              </Grid>
              {/* <div className="tkt_new_ticket_dialog_subject">
                <div className="tkt_new_ticket_dialog_subject_title">Subject</div>
                <TextField multiline value={subject} onChange={(event) => setSubject(event.currentTarget.value)} className="tkt_new_ticket_dialog_subject_textfield"></TextField>
              </div> */}
              {/* <div className="tkt_new_ticket_dialog_description">
                <div className="tkt_new_ticket_dialog_description_title">Description</div>
                <TextField multiline value={description} onChange={(event) => setDescription(event.currentTarget.value)} className="tkt_new_ticket_dialog_description_textfield"></TextField>
              </div> */}

           
            </Grid>
            </div>
            <div className="tkt_new_ticket_dialogContent">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="tkt_new_ticket_dialog_subject_title">Subject</div>
                <TextField multiline value={subject} onChange={(event) => setSubject(event.currentTarget.value)} className="tkt_new_ticket_dialog_subject_textfield"></TextField>
             
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="tkt_new_ticket_dialog_event_title">Event Date</div>
                <div className="tkt_new_ticket_dialog_eventDatePicker">
                  <DatePickerComponent max={new Date()} allowEdit={false} change={(event) => setEeventDate(event.value)} value={eventDate} ></DatePickerComponent>
               </div>
              </Grid>
              </Grid>

             </div>
             <div className="tkt_new_ticket_dialogContent">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
 
                <div className="tkt_new_ticket_dialog_description_title">Description</div>
                <TextField multiline value={description} onChange={(event) => handleSetDescription(event.currentTarget.value)} className="tkt_new_ticket_dialog_description_textfield"></TextField>
                {description.length > 5120 && <span className="ticket-description-alert">The input exceeds the 5120 characters limit.</span>}
            
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="tkt_new_ticket_dialog_attachments_title">Upload</div>
                <div className="tkt_new_ticket_dialog_attachments_drop_area" {...getRootProps({ style })} onPaste={(event) => attachmentsHandleOnPaste(event)} contentEditable={true} suppressContentEditableWarning={true} onKeyDown={(event) => attachmentsKeyDown(event)} >
                  <input {...getInputProps()} />
                  <div><img src={attachmentIcon} /><div><div>Browse or Drop or Paste your image here.</div><div className="support">Supports PNG, JPG, JPEG, PDF ,DOCS, XLS, XLSX, MSG</div></div></div>
                </div>
                </Grid>  
                </Grid>
                </div>
                <div className="tkt_new_ticket_dialogContent">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
              {
                showAttachments.length > 0
                  ?
                  <div className="tkt_new_ticket_dialog_attachments_preview">
                    <div className="tkt_new_ticket_dialog_attachments_preview_title">
                      Preview ({showAttachments.length})
                      <span className="tkt_new_ticket_dialog_attachments_preview_remove_all" onClick={() => setShowAttachments([])}>Remove all</span>
                    </div>
                    <div className="tkt_new_ticket_dialog_attachments_preview_image">
                      {
                        showAttachments.slice(0).reverse().map((file, i) => (
                          <div><img className="tkt_new_ticket_dialog_attachments_preview_base64_image" src={file.base64String} onClick={() => openAttachmentInNewTab(file.base64String)}></img>
                            <img className="tkt_new_ticket_dialog_attachments_preview_deleteIcon" src={deleteIcon} onClick={() => deleteAttachment(i)} />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  : ""
              }
                </Grid>
               
                </Grid>
                </div>
            {/* </div> */}
            {/* <div className="tkt_new_ticket_dialogContent_part2">
              <div className="tkt_new_ticket_dialog_assignee">
                <div className="tkt_new_ticket_dialog_assignee_title">Assignee</div>
                {
                  assignee && assignee.fullName
                    ?
                    <OutlinedInput className="tkt_new_ticket_dialog_assignee_name" type="text" placeholder="Type something"
                      value={assignee.fullName}
                      endAdornment={
                        <InputAdornment position="end">
                          <img onClick={() => setAssignee({})} className="tkt_new_ticket_dialog_assignee_clearImage cursorPointer" src={close} />
                        </InputAdornment>
                      }
                    />
                    : <div className="tkt_new_ticket_dialog_assigneeAutocomplete">
                      <Autocomplete
                        size="small"
                        noOptionsText={autoCompleteNoOptionsText}
                        options={assigneeList}
                        getOptionLabel={(option: any) => option.fullName}
                        PaperComponent={({ children }) => (
                          <Paper className="tkt_new_ticket_dialog_assigneeAutocompleteListItems">{children}</Paper>
                        )}
                        onChange={(e, newValue: any) => {
                          setAssignee(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Search assignee" />
                        )}
                      />
                    </div>
                }
              </div>

              <div className="tkt_new_ticket_dialog_event">
                <div className="tkt_new_ticket_dialog_event_title">Event Date</div>
                <div className="tkt_new_ticket_dialog_eventDatePicker">
                  <DatePickerComponent max={new Date()} allowEdit={false} change={(event) => setEeventDate(event.value)} value={eventDate} ></DatePickerComponent>
                </div>
              </div>
              <div className="tkt_new_ticket_dialog_attachments">
                <div className="tkt_new_ticket_dialog_attachments_title">Upload</div>
                <div className="tkt_new_ticket_dialog_attachments_drop_area" {...getRootProps({ style })} onPaste={(event) => attachmentsHandleOnPaste(event)} contentEditable={true} suppressContentEditableWarning={true} onKeyDown={(event) => attachmentsKeyDown(event)} >
                  <input {...getInputProps()} />
                  <div><img src={attachmentIcon} /><div><div>Browse or Drop or Paste your image here.</div><div className="support">Supports PNG, JPG, JPEG, PDF ,DOCS, XLS, XLSX, MSG</div></div></div>
                </div>
              </div>

            </div> */}
          {/* </div> */}
        </ DialogContent>
        <DialogActions className="ticket-dialogue-actions">
          <Button onClick={() => handleClose()} className="dialog_clearAllButton fontCSS" >Cancel</Button>
          <Button onClick={() => handleClearAllClick()} className="dialog_clearAllButton fontCSS" >Clear All</Button>
          <Button disabled={description.length > 5120 ? true : false} className={description.length > 5120 ? "send-ticket-disable" : 'send-ticket-enable'} onClick={() => handleSendTicketClick()}>Send Ticket</Button>
        </DialogActions>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});
export default withRouter(
  connect(mapStateToProps, {
    createNewTicket
  })(CreateNewTicketDialog)
);