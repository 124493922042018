import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {Pagination} from "../../Pagination";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart } from "../../../actions/Users/authenticate";
import _ from "lodash";
import './loginHistory.css';
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([convertDateTimeIntoTimezone(row[0], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[3], row[10], row[1], row[2], row[4]])
    })
    let date = 'Date (' + userTimezone + ')'
    return [[date, 'Organization', 'User name', 'Service', 'Event Type', 'IP Address'], ...csvRows]
}

const LoginHistoryTable = (props) => {
    const { authReducer, getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart, location, history, setValues, values, applyFilterClick, downloadAsCSV } = props;
    const { setPage, setLimit, setSearchValue, setSort, setSortOrder, setSelectedUsers, setSelectedApp, setSelectedDesc, setTrackFilter, setWidgetFilt } = setValues;
    const { startDate, endDate, page, limit, searchValue, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, interval, widgetFilt } = values;
    const ENTER_KEY_CODE = "Enter";
    const [loginHistorytable, setLoginHistoryTable] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const USER_NAME = 'user_name';
    const DP_NAME = 'dp_name';
    const APPLICATION_NAME = 'application_name';
    const CREATION_TIME = 'create_time';
    const DESCRIPTION = 'description';
    const IP_ADDRESS = 'ip_address';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();


    useEffect(() => {
        if(!_.isEmpty(authReducer.getIamLoginHistoryTable)) {
            const data = authReducer?.getIamLoginHistoryTable?.data;
            if(data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const loginHistorytable: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    loginHistorytable.push(row);
                }
                setLoginHistoryTable(loginHistorytable);
            }
        }
        if(!_.isEmpty(authReducer.getIamLoginHistortTableCount)) {
            const count = authReducer?.getIamLoginHistortTableCount?.data?.rows?.[0]?.[0];
            setTotalCount(count);
        }
    }, [authReducer.getIamLoginHistoryTable, authReducer.getIamLoginHistortTableCount])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'login-history-csv') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                , {
                    formatters: {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA'
                    }
                }
            );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        setPage(1);
        doNavigate(params);
        getIamLoginHistoryTopWidget(startDate, endDate, value, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistoryTimeseriesChart(startDate, endDate, value, interval, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistoryTable(startDate, endDate, value, 1, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistortTableCount(startDate, endDate, value, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
    };

    const Sorting = (startDate, endDate, searchValue, limit, field, order, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, field, order, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(startDate, endDate, searchValue, limit, field, order, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(startDate, endDate, searchValue, limit, field, order, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt)
    };

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        doNavigate(params);
        getIamLoginHistoryTable(startDate, endDate, searchValue, value, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getIamLoginHistoryTable(startDate, endDate, searchValue, Page, Limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
    }

    const handleClickUser = (row) => {
       const User = row.user_name
       setSelectedUsers(() => [{user_name: User}])
       setTrackFilter(true);
       applyFilterClick([{user_name: User}], selectedApp, selectedDesc, widgetFilt)
    }

    const handleClickApp =(row) => {
        const App = row.application_name;
        setSelectedApp([{application_name: App}])
        setTrackFilter(true);
        applyFilterClick(selectedUsers, [{application_name: App}], selectedDesc, widgetFilt)
    }

    const handleClickDesc =(row) => {
        const Desc = row.description;
        setSelectedDesc([{description: Desc}])
        setTrackFilter(true);
        setWidgetFilt({type:'', check:'', kpi:''});
        const cardFilt = [{type:'', check:'', kpi:''}];
        let params: any = getDecodeURI(location.search);
        doNavigate(params);
        applyFilterClick(selectedUsers, selectedApp, [{description: Desc}], cardFilt)
    }

    const handleClickOu =(row) => {
        let params: any = getDecodeURI(location.search);
        params.ouName = row.dp_name;
        params.ouId = row.dp_id;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        if(row.dp_name == 'K4Mobility') {
            getIamLoginHistoryTopWidget(startDate, endDate, searchValue, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
            getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
            getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
            getIamLoginHistortTableCount(startDate, endDate, searchValue, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt);
        }
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleDownloadReport = () => {
        const apps = selectedApp?.map((el, i) => `'${el.application_name}'`)
        const users = selectedUsers?.map((el, i) => `'${el.user_name}'`)

        const desc = selectedDesc?.map((el, i) => `'${el.description}'`)

        let SelectedOu: string[] = [];
        selectedOu?.forEach((el, i) => {
            if (el) {
                SelectedOu.push(`'${el}'`);
            }
        })

        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'login-history-csv',
            queryName: 'IAM_LOGIN_HISTORY_TABLE',
            payload: {
                startDate: startDate,
                endDate: endDate,
                search: searchValue ? searchValue : '',
                sort: sort,
                sortOrder: sortOrder,
                pageStart: 0,
                pageLimit: totalCount,
                user_filt: selectedUsers[0]?.user_name ? `and user_name in (${users})` : '',
                app_filt: selectedApp[0]?.application_name ? `and application_name in (${apps})` : '',
                group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
                description: selectedDesc[0]?.description ? `and description in (${desc})` : '',
                topwidg_filt: widgetFilt?.type && widgetFilt?.check ? `and request_type = '${widgetFilt.type}' and logged_in = '${widgetFilt.check}'` : widgetFilt?.type ? `and request_type = '${widgetFilt.type}'` : ''
            }
        }
        downloadAsCSV(params);
    }

    return (
        <div className="config-login-container">
        <Grid container spacing={0.5} className="login-history alertsFilterContainer">
            <Grid item xs={12} sm={12} md={12} lg={12} className="alertsFilterContainerBox">
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Box>
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            classes={{root: "input-box-login-hist"}}
                            size="small"
                            value={searchValue}
                            onChange={handleOnSearchChange}
                            onKeyDown={(event) => searchBoxKeyDown(event)}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchValue && <SearchIcon
                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                        />}
                                        {searchValue && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                    {totalCount > 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
            <Paper className="pagination-position config-pagination">
                    <Pagination
                        rowsPerPageOptions={rowsOption}
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer" style={{marginTop: "0px"}}>
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead">
                            <TableRow className="UsageReports-tableRow login-tableRow">
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATION_TIME)}>Date</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === CREATION_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATION_TIME, ASC_ORDER)} />
                                            <img src={sort === CREATION_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, CREATION_TIME, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DP_NAME)}>Organization</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === DP_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DP_NAME, ASC_ORDER)} />
                                            <img src={sort === DP_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, DP_NAME, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USER_NAME)}>User Name</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === USER_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USER_NAME, ASC_ORDER)} />
                                            <img src={sort === USER_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, USER_NAME, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, APPLICATION_NAME)}>Service</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === APPLICATION_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, APPLICATION_NAME, ASC_ORDER)} />
                                            <img src={sort === APPLICATION_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, APPLICATION_NAME, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DESCRIPTION)}>Event Type</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === DESCRIPTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, ASC_ORDER)} />
                                            <img src={sort === DESCRIPTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, DESCRIPTION, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, IP_ADDRESS)}>Ip Address</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sort === IP_ADDRESS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, IP_ADDRESS, ASC_ORDER)} />
                                            <img src={sort === IP_ADDRESS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, IP_ADDRESS, DESC_ORDER)} />
                                        </Grid>
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {0 != loginHistorytable.length ? (
                            <TableBody className="tableBody usage-tableBody">
                                {
                                    loginHistorytable && loginHistorytable.map((row, i) =>
                                        <TableRow key={i} className="loginHistory-tableRow">
                                            <TableCell className="login-left-align">{convertDateTimeIntoTimezone(row.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className="login-left-align selectedClick" onClick={() => {handleClickOu(row)}}>{row.dp_name}</TableCell>
                                            <TableCell className="login-left-align selectedClick" onClick={() => handleClickUser(row) }>{row.user_name}</TableCell>
                                            <TableCell className="login-left-align selectedClick" onClick={()=> handleClickApp(row) }>{row.application_name}</TableCell>
                                            <TableCell className='login-left-align selectedClick' onClick={() => handleClickDesc(row) }>{row.description}</TableCell>
                                            <TableCell className="login-left-align">{row.ip_address}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                
            </Grid>
        </Grid>
    </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { 
        getIamLoginHistoryTable,
        getIamLoginHistortTableCount,
        getIamLoginHistoryTopWidget,
        getIamLoginHistoryTimeseriesChart,
        downloadAsCSV
     })(LoginHistoryTable)
  )