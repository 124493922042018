import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { User } from "./types";

const ACTION_TYPES = {
    SET_LOADING: "starlinkReports/setLoading",
    GET_ASSETS: "starlinkReports/getAssets",
    GET_ASSETS_CSV: "starlinkReports/getAssetsCSV",
    GET_ASSET_USAGE: "starlinkReports/getAssetUsage",
    GET_ASSET_CURRENT_BILLING_CYCLE_USAGE: "starlinkReports/getAssetCurrentBillingCycleUsage",
    GET_ALL_SERVICE_LINES: "starlinkReports/getAllServiceLines",
    GET_QUOTA_AVAILABLITY_ALERT: "starlinkReports/getQuotaAvailabilityAlert"
}

const initialState = {
    assets: [],
    allServiceLines: [],
    assetsCSV: [],
    assetUsage: {},
    totalAssets: 0,
    gettingAssets: false,
    gettingAssetsCSV: false,
    gettingAssetUsage: false,
    gettingAssetCurrentBillingCycleUsage: false,
    settingQuotaAlert: false,
    settingAvailabilityAlert: false,
    quotaAvailablityAlerts:[],
}

export function StarlinkReportsReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_ASSETS: {
            const assets = action?.payload?.data?.rows?.[0]?.[0] ? action.payload.data.rows[0][0] : [];
            return {
                ...state,
                assets: assets && assets.length > 0 ? JSON.parse(assets) :  [],
                totalAssets:  assets && assets.length > 0 ? JSON.parse(assets)[0].total_count :  0,
            }
        }
        case ACTION_TYPES.GET_ASSETS_CSV: {
            const [csv] = action.payload?.data?.rows?.[0];
            return {
                ...state,
                assetsCSV: csv || [],
            }
        }
        case ACTION_TYPES.GET_ASSET_USAGE: {
            return {
                ...state,
                assetUsage: action.payload?.data?.total_gb?.series || {},
            }
        }
        case ACTION_TYPES.GET_ALL_SERVICE_LINES: {
            const allServiceLines = action.payload?.data;
            return {
                ...state,
                allServiceLines: allServiceLines || [],
            }
        }
        case ACTION_TYPES.GET_QUOTA_AVAILABLITY_ALERT: {
            const alert = action.payload?.data?.rows[0][0];
            return {
                ...state,
                quotaAvailablityAlerts: alert || [],
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

export interface getAssetParameters {
    dpIds: string[];
    searchText: string;
    chartStartDate: string;
    chartEndDate: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    usageSource:String;
    serviceLines: string[];
} 

export const getAssets = (parameters: getAssetParameters) => (dispatch) => {
    clearComponentData()
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssets: true } });
    dispatch({ type: ACTION_TYPES.GET_ASSETS, payload: null});
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        usageSource: parameters.usageSource,
        offset: (parameters.page - 1) * parameters.limit,
        serviceLines: parameters.serviceLines.map(sl => `'${sl}'`).join(',')
    };
    const data = {
        query: `GET_STARLINK_ASSETS_CH`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ASSETS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssets: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssets: false } });
        });
}

export interface getAssetsCSVParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
}

export const getAssetsCSV = (parameters: getAssetsCSVParameters) => async (dispatch) => {
    return new Promise<void>((resolve, reject) => {
        if (_.isEmpty(parameters?.dpIds)) return;
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetsCSV: true } });
        let _parameters: any = {
            dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
            searchText: parameters.searchText,
            sortBy: parameters.sortBy,
            sortOrder: parameters.sortOrder
        };
        const data = {
            query: `GET_STARLINK_ASSETS_CSV`,
            named: true,
            format: 'csv',
            parameters: _parameters
        }
        api
            .postRequestOut(`/store/ss/query`, data)
            .then((res) => {
                handleResponse(res, dispatch, ACTION_TYPES.GET_ASSETS_CSV)
                dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetsCSV: false } });
                resolve();
            })
            .catch((err) => {
                handleError(err, dispatch);
                dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetsCSV: false } });
                reject(err);
            });
    })
}

export interface getAssetUsageParameters {
    dpIds: string[];
    startDate: string;
    endDate: string;
    source:string;
    serviceLines:string[];
}

export const getAssetUsage = (parameters: getAssetUsageParameters) => (dispatch) => {
    clearComponentData()
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: true } });
    let _parameters = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        startDate: parameters.startDate,
        endDate: parameters.endDate,
        source: parameters.source,
        serviceLines: parameters.serviceLines.map(sl => `'${sl}'`).join(',')
    };
    const data = {
        query: `GET_STARLINK_ASSET_USAGE`,
        named: true,
        format: 'chart',
        groupBy: "asset_number",
        tsColumn: "date",
        series: "total_gb",
        annotation: "asset_name",
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ASSET_USAGE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: false } });
        });
}

export const getQuotaAvailablityAlert = (parameters) => (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: true } });
    let _parameters = { serviceLineNumber: parameters.serviceLineNumber.map(sl => `'${sl}'`).join(',') };
    const data = {
        query: `GET_QUOTA_AND_AVAILABLITY_ALERTS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_QUOTA_AVAILABLITY_ALERT);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetUsage: false } });
        });
}

export interface getAssetCurrentBillingCycleUsageParameters {
    assetNumbers: string[];
}

export const getAssetCurrentBillingCycleUsage = (parameters: getAssetCurrentBillingCycleUsageParameters) => (dispatch) => {
    if (_.isEmpty(parameters?.assetNumbers)) return;

    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetCurrentBillingCycleUsage: true } });
    let _parameters: any = { asset_numbers: parameters.assetNumbers.map(assetNumber => `'${assetNumber}'`).join(',') };
    const data = {
        query: "GET_STARLINK_ASSET_CURRENT_BILLING_CYCLE_USAGE",
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ss/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ASSET_CURRENT_BILLING_CYCLE_USAGE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetCurrentBillingCycleUsage: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAssetCurrentBillingCycleUsage: false } });
        });
}

export interface getAllServiceLinesParameters {
    dpIds: string[];
    serviceLines: string[];
}

export const getAllServiceLines = (parameter: getAllServiceLinesParameters) => (dispatch) => {
    // if (_.isEmpty(parameter?.dpIds)) return;
    if (_.isEmpty(parameter?.serviceLines)) return;
    if (parameter?.serviceLines.includes('All') ) return;
    let _parameters = { 
        // dpIds: parameter.dpIds.map(dpId => `'${dpId}'`).join(','),
        serviceLines: parameter?.serviceLines?.map(sl => `'${sl}'`).join(',') };
    const data = {
        query: `GET_ALL_STARTLINK_SERVICE_LINE`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ALL_SERVICE_LINES)
        })
        .catch((err) => {
            handleError(err, dispatch);
        });
}

export interface setQuotaAlertParameters {
    serviceLineNumbers: string[];
    alertingThresholds: number[];
    notificationMethods: string[];
    usersToNotify: User[];
}

export const setQuotaAlert = (parameters: setQuotaAlertParameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumbers)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingQuotaAlert: true } });
    try {
        let res = await api.starlink.post('/alerts/quotas/bulk', parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingQuotaAlert: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingQuotaAlert: false } });
        return null;
    }
}

export interface setAvailabilityAlertParameters {
    serviceLineNumbers: string[];
    alertingThreshold: string;
    notificationMethods: string[];
    usersToNotify: User[];
}

export const setAvailabilityAlert = (parameters: setAvailabilityAlertParameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumbers)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingAvailabilityAlert: true } });
    try {
        let res = await api.starlink.post('/alerts/availability/bulk', parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingAvailabilityAlert: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { settingAvailabilityAlert: false } });
        return null;
    }
}

export const clearCSV = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_ASSETS_CSV, payload: { data: { rows: [[]] } } })
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_ASSETS, payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_ASSET_USAGE, payload: { data: { total_gb: { series: {} } } } })
    dispatch({ type: ACTION_TYPES.GET_ASSETS_CSV, payload: { data: { rows: [[]] } } })
}