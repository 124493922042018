import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, FormControl, Switch } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../Pagination";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import { getAlertsMain } from '../../components/AlertsDashboard/slice';
import _ from "lodash";
import { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import Warning from "../../asset/image/warning.svg"
import Pending from "../../asset/image/Pending.svg";
import NormalAlert from "../../asset/image/bar_chart.svg"
import moment from "moment-timezone";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './AlertCharts/alertCharts.css';

const AlertsTable = (props) => {
    const { authReducer, vessels, handleSiteClick, alertStatus, setAlertStatus, alertsPage, setAlertsPage, getAlertsMain, newSummaryStartDate, newSummaryEndDate, alertMainDetails, alertsMainInfo, location, history } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const ENTER_KEY_CODE = 'Enter';
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [tableData, setTableData] = useState<any>([]);
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const [count, setCount] = useState(0);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    const getAlertStatus = (alertStatus) => {
        if(alertStatus?.isCurrentAlert) {
            return ['isCurrentAlert']
        }
        else if(alertStatus?.Alerting && !alertStatus?.Normal) {
            return ['Alerting'];
        } else if(!alertStatus?.Alerting && alertStatus?.Normal) {
            return ['Normal'];
        } else {
            return ['Alerting', 'Normal'];
        }
    }

    useEffect(() => {
        if(vessels?.length > 0) {
            getAlertsMain(1, limit, searchValue, getAlertStatus(alertStatus), newSummaryStartDate, newSummaryEndDate, vessels);
            setAlertsPage(1);
            setTableData([]);
            setCount(0);
        } else {
            setTableData([]);
            setCount(0);
            setAlertsPage(1);
            setLimit(DEFAULT_PAGE_SIZE);
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, alertStatus])

    useEffect(() => {
        if(!_.isEmpty(alertMainDetails) && !_.isEmpty(alertsMainInfo)) {
            const data = alertMainDetails;
            const count = alertsMainInfo?.total_count;
            setTableData(data);
            setCount(count)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [alertMainDetails, alertsMainInfo])

    const handleChangePage = (e, value) => {
        setAlertsPage(value);
        if(vessels?.length > 0) {
            getAlertsMain(value, limit, searchValue, getAlertStatus(alertStatus), newSummaryStartDate, newSummaryEndDate, vessels);
        }
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setAlertsPage(1);
        setLimit(Limit);
        doNavigate(params);
        if(vessels?.length > 0) {
            getAlertsMain(Page, Limit, searchValue, getAlertStatus(alertStatus), newSummaryStartDate, newSummaryEndDate, vessels);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        searchValue ? params.search = searchValue : delete params.search;
        delete params.page;
        const Page = 1;
        setAlertsPage(1);
        doNavigate(params);
        if(vessels?.length > 0) {
            getAlertsMain(Page, limit, value, getAlertStatus(alertStatus), newSummaryStartDate, newSummaryEndDate, vessels);
        }
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }; 
    
    const getAlertTextColor = (alertText: any) => {
        switch (alertText.trim()) {
          case "Alerting": return "RedColor"
          case "Normal": return "GreenColor"
          case "Pending": return "OrangeColor"
          default: return "GreenColor";
        }
    }
    
    const getAlertIcon = (status: string | undefined) => {
        switch (status?.trim()) {
            case "Alerting": return Warning;
            case "Normal": return NormalAlert;
            case "Pending": return Pending;
            default: return NormalAlert;
        }
    }
    
    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const handleChangeSwitch = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAlertStatus({ ...alertStatus, [name]: event.target.checked })
    };

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }
    
    const rowsOption = [10, 15, 25, 50, 100];

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history  trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div  trends-usage-search-container">
                    <Grid className="site-details-header usage-site-details-header">{`Alerts`}</Grid>
                    <Grid className="alerts-margin-left-20">
                        <FormControl>
                            <Grid className="historyorCurrent" component="label" container alignItems="center">
                                <Grid item>History</Grid>
                                <Grid item>
                                    <Switch
                                        checked={alertStatus?.isCurrentAlert}
                                        onChange={handleChangeSwitch("isCurrentAlert")}
                                        value="isCurrentAlert"
                                    />
                                </Grid>
                                <Grid item>Current</Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    {alertStatus?.isCurrentAlert && <Grid className="desclaimer-text">
                        <Tooltip title='Time Period not applicable for "Current Alerting".'>
                            <HelpOutlineIcon />
                        </Tooltip>
                    </Grid>}
                    <Grid className="margin-left-auto">
                        <Box>
                            {<TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-uptime-tbl input-box-alerts-tbl" }}
                                size="small"
                                value={searchValue}
                                onChange={handleOnSearchChange}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue && <SearchIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchValue && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? count : 0}
                            rowsPerPage={limit}
                            page={alertsPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead alerts-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">SITE</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">DEVICE NAME</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell alert-width">ALERT NAME</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">INTERFACE NAME</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell wan-width">WAN TYPE</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell alert-width">REPORTED ON</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell details-width">STATUS</TableCell>
                                    <TableCell className="usage-fleetDataTable alert-left-align login-tableCell details-width">DETAILS</TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData?.length && !authReducer.loading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity remove-padding">
                                                <TableCell className="alert-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick({k4Id: item?.site_id, x: item?.site_name})}>{item?.site_name}</TableCell>
                                                <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{item?.device_name}</TableCell>
                                                <Tooltip title={item?.description}><TableCell className="alert-left-align inter-font Table-Cell-Opacity">{item?.alert_name}</TableCell></Tooltip>
                                                <Tooltip title={item?.interface_name} placement="right">
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity interface-ellipse">{item?.interface_name}</TableCell>
                                                </Tooltip>
                                                <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{item?.wan_type ? item?.wan_type.toUpperCase() : ""}</TableCell>
                                                <TableCell className='alert-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(item.created_on, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <TableCell className='alert-left-align inter-font Table-Cell-Opacity'>
                                                    <div className="alertStatusWithIcon">
                                                        <img src={getAlertIcon(item.status)} className="alertIcon" alt={item.status} />
                                                        <span className={getAlertTextColor(item?.status)}>{item.status == 'Normal' ? 'Cleared' : item.status}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell className='alert-left-align inter-font Table-Cell-Opacity'><JsonDataTooltip className="labelDEtailsJsonToolt=Tip" data={item?.metadata} /></TableCell>  
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    alertMainDetails: state?.alertsDashboard?.alertMainDetails,
    alertsMainInfo: state?.alertsDashboard?.alertsMainInfo,
  });
  
  export default withRouter(
    connect(mapStateToProps, { getAlertsMain })(AlertsTable)
  )