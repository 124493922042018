import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Button, Chip, Grid, Menu, Stack, TextField, Tooltip } from "@mui/material";
import LoginHistoryTable from "../../components/LoginHistory/Table";
import LoginHistoryWidget from "../../components/LoginHistory/TopWidget";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { Fragment, useEffect, useState } from "react";
import LoginHistoryFilters from "../../components/LoginHistory/Filters";
import LoginHistoryLineChart from "../../components/LoginHistory/Chart";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment-timezone";
import { getDecodeURI, getEncodedURI, populateForParent } from "../../utils/util";
import "./loginHistoryPage.css";
import { getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart, getIamLoginHistoryTable, getIamLoginHistortTableCount, parentDpAndSubDpList } from "../../actions/Users/authenticate";
import _ from "lodash";
import LoginCardFilters from "./LoginCardFilters";
import CustomLoader from "../../components/Loader/CustomLoader";

const LoginHistoryPage = (props) => {
    const { authReducer, history, location, getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart, getIamLoginHistoryTable, getIamLoginHistortTableCount, parentDpAndSubDpList, startDate, endDate } = props;
    const [filterPopup, setFilterPopup] = useState(false);
    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
    const CREATION_TIME = 'create_time';
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : CREATION_TIME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [interval, setInterval] = useState('');
    const [selectedOu, setSelectedOu] = useState<any>([]);
    const getFilters = (type) => {
        const arr:any=[];
        let filter;
        if (type == 'user_name') {
            filter =  _q?.users?.split(',');
        } else if(type == 'application_name') {
            filter =  _q?.apps?.split(',');
        } else if(type == 'description') {
            filter =  _q?.desc?.split(',');
        }
        for(let i=0; i < filter?.length; i++) {
            arr.push({[type]: filter[i]})
        }
        return arr;
    }
    const [selectedUsers, setSelectedUsers] = useState<any>(_q.hasOwnProperty('users') && _q.users!='' ? getFilters('user_name') : []);
    const [selectedApp, setSelectedApp] = useState<any>(_q.hasOwnProperty('apps') && _q.apps!='' ? getFilters('application_name') : []);
    const [selectedDesc, setSelectedDesc] = useState<any>(_q.hasOwnProperty('desc') && _q.desc!='' ? getFilters('description') : []);
    const [trackFilter, setTrackFilter] = useState((_q.hasOwnProperty('users') || _q.hasOwnProperty('apps') || _q.hasOwnProperty('desc')) ? true :false);
    const [widgetFilt, setWidgetFilt] = useState<any>({type: _q.hasOwnProperty('type') ? _q.type : '', check: _q.hasOwnProperty('check') ? _q.check : '', kpi: _q.hasOwnProperty('kpi') ? _q.kpi : ''});
    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();

    const EmptyUsers = [];
    const EmptyApps = [];
    const EmptyDesc = [];

    const getChildren = (dpIds, dps) => {
        for(let i=0; i<dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if(dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    const getInterval = (minutes) => {
        if (minutes <= 1440) {
          return "10m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }

    useEffect(() => {
        if(!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    const getDpList = (dpList) => {
        const tempArr:any = {};
        for(let i = 0; i < dpList.length; i++){
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }
    
    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i<ous?.length; i++) {
                const item = ous[i];
                if( arr.hasOwnProperty(item.id) ) {
                    const temp:any = arr[item.id];
                    arr[item.id] = {...temp, ...item._childern};
                } else{
                    arr[item.id] = item._childern; 
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu) && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id;
            const dpIds = authReducer?.parentDpAndSubDpList;
            if(dpIds.hasOwnProperty(parentDpId)) {
                const _dpIds:any = [];
                _dpIds.push(parentDpId);
                getChildren(_dpIds, dpIds[parentDpId]);
                setSelectedOu(_dpIds);
            
                const interval = getInterval(Math.floor(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));
                setInterval(interval);
                const Page = 1;
                setPage(1);
                getIamLoginHistoryTopWidget(startDate, endDate, searchValue, _dpIds, selectedUsers, selectedApp, selectedDesc, widgetFilt);
                getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, _dpIds, selectedUsers, selectedApp, selectedDesc, widgetFilt);
                getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, sort, sortOrder, _dpIds, selectedUsers, selectedApp, selectedDesc, widgetFilt);
                getIamLoginHistortTableCount(startDate, endDate, searchValue, _dpIds, selectedUsers, selectedApp, selectedDesc, widgetFilt);
            }
        }
    }, [authReducer.selectedOu, authReducer.parentDpAndSubDpList, startDate, endDate])

    const setValues = {
        setFilterPopup,
        setPage,
        setLimit,
        setSearchValue,
        setSort,
        setSortOrder,
        setInterval,
        setSelectedUsers,
        setSelectedApp,
        setSelectedDesc,
        setSelectedOu,
        setTrackFilter,
        setWidgetFilt
    }

    const values = {
        startDate,
        endDate,
        page,
        limit,
        searchValue,
        sort,
        sortOrder,
        interval,
        selectedOu,
        selectedUsers,
        selectedApp,
        selectedDesc,
        trackFilter,
        widgetFilt
    }

    const handleStartDatePicker = (date: any) => {
        if(date){
            let params: any = getDecodeURI(location.search);
            params.startDate = date.valueOf();
            doNavigate(params);
            const interval = getInterval(Math.floor(((new Date(endDate)).getTime() - (new Date(date)).getTime()) / 60e3));
            setInterval(interval);
        }
    }
    
    const handleEndDatePicker = (date: any) => {
        if(date) {
            let params: any = getDecodeURI(location.search);
            params.endDate = date.valueOf();
            doNavigate(params);
            const interval = getInterval(Math.floor(((new Date(date)).getTime() - (new Date(startDate)).getTime()) / 60e3));
            setInterval(interval);
        }
    }

    const ApplyClearFilters = (Users, Apps, Desc, cardFilt) => {
        setPage(1);
        getIamLoginHistoryTopWidget(startDate, endDate, searchValue, selectedOu, Users, Apps, Desc, cardFilt);
        getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, selectedOu, Users, Apps, Desc, cardFilt);
        getIamLoginHistoryTable(startDate, endDate, searchValue, 1, limit, sort, sortOrder, selectedOu, Users, Apps, Desc, cardFilt);
        getIamLoginHistortTableCount(startDate, endDate, searchValue, selectedOu, Users, Apps, Desc, cardFilt);
        setFilterPopup(false);
    }

    const applyFilterClick = (Users, Apps, Desc, cardFilt) => {
        setTrackFilter(true);
        let users:any = [];
        Users?.user_name!=='' && Users?.forEach((el, i) => {
            users.push(`${el.user_name}`);
        })
        let apps:any = [];
        Apps?.application_name!=='' && Apps?.forEach((el, i) => {
            apps.push(`${el.application_name}`);
        })
        let desc:any = [];
        Desc?.forEach((el, i) => {
            desc.push(`${el.description}`);
        })

        let params: any = getDecodeURI(location.search);
        users.length!==0 ? params.users = users : delete params.users;
        apps.length!==0 ? params.apps = apps : delete params.apps;
        desc.length!==0 ? params.desc = desc : delete params.desc;
        delete params.page;
        if(Desc?.length > 0) {
            delete params.type;
            delete params.check;
            delete params.kpi;
        }
        doNavigate(params);
        ApplyClearFilters(Users, Apps, Desc, cardFilt);
    }

    const clearFilters = () => {
        setSelectedUsers([]);
        setSelectedApp([]);
        setSelectedDesc([]);
        let params: any = getDecodeURI(location.search);
        delete params.users;
        delete params.apps;
        delete params.desc;
        delete params.page;
        doNavigate(params);
        ApplyClearFilters(EmptyUsers, EmptyApps, EmptyDesc, widgetFilt);
    }

    const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElRef(e.currentTarget)
        setFilterPopup(true)
    }
    
    return (
        <div>
            <div className='LoginHistoryPage-Container'>
                <Box className='accountsActivity-Filter loginHistory-filters-comp'>
                    <Fragment>
                        <Button variant="outlined" endIcon={<TuneIcon />} onClick={filterPopClick} className='activityReports-Filter'>
                            Filter
                        </Button>
                        <LoginCardFilters setValues={setValues} values={values} applyFilterClick={applyFilterClick} />
                        <Menu disableEnforceFocus
                            onClose={() => setFilterPopup(false)}
                            open={filterPopup}
                            className="rc--filter_menu_container_reports rc--filter_menu_container_login"
                            anchorEl={anchorElRef}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            
                            <Grid className="usageFilterComponent">
                                <LoginHistoryFilters applyFilterClick={applyFilterClick} clearFilters={clearFilters} setFilterPopup={setFilterPopup} setValues={setValues} values={values} />
                            </Grid>     
                        </Menu>
                    </Fragment>
                </Box>
                <Grid className="LoginHistoryPage-Data">
                    <LoginHistoryWidget setValues={setValues} values={values} />
                    <LoginHistoryLineChart setValues={setValues} values={values} getInterval={getInterval} />
                    <LoginHistoryTable applyFilterClick={applyFilterClick} setValues={setValues} values={values} />
                </Grid>
            </div>
            {   authReducer.setIamLoginHistoryTable || authReducer.setIamLoginHistoryCountLoading || authReducer.setIamLoginHistoryTopWidget || authReducer.setIamLoginHistoryFilterUser ||
                authReducer.setIamLoginHistoryFilterApp || authReducer.setIamLoginHistoryFilterDescList || authReducer.setIamLoginHistoryTimeseriesChart ? (
                    <CustomLoader
                    showLoader={true}
                    loadingText={ "Fetching data please wait..." }
                    />
                ) : null
            }
        </div>
    )
}

const OuterDateRange = (props) => {
    const { values, handleStartDatePicker, handleEndDatePicker, applyFilterClick } = props;
    const { startDate, endDate, selectedUsers, selectedApp, selectedDesc, widgetFilt } = values;
    
    return(
        <Stack direction='row' spacing={1}>
            <div className="siteCharts__filters-date-time-picker">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label='Start Date'
                        value={startDate}
                        onChange={handleStartDatePicker}
                        onAccept={() => applyFilterClick (selectedUsers, selectedApp, selectedDesc, widgetFilt)}
                        maxDateTime={endDate}
                        className="audit-filter-inputs"
                    />
                </LocalizationProvider>
            </div>
            <div className="siteCharts__filters-date-time-picker">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label='End Date'
                        value={endDate}
                        onAccept={() => applyFilterClick (selectedUsers, selectedApp, selectedDesc, widgetFilt)}
                        onChange={handleEndDatePicker}
                        minDateTime={startDate}
                        className="audit-filter-inputs"
                        maxDateTime={moment()}
                    />
                </LocalizationProvider>
            </div>
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    startDate: state?.authReducer?.newSummaryStartDate,
    endDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getIamLoginHistoryTopWidget,
        getIamLoginHistoryTimeseriesChart,
        getIamLoginHistoryTable,
        getIamLoginHistortTableCount,
        parentDpAndSubDpList
    })(LoginHistoryPage)
);