import { Fragment, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";

import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity, readableBytes, readableBytesAsGB, readablePeriod } from '../../utils/util';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";

import DownloadCSV, { clearCsvDownload, escapeJsonString } from "../DownloadCSV";
import { paramGetServiceLineQuotas, getServiceLineQuotas, ACTION_TYPES, createQuota, updateQuota, deleteQuota, clearComponentData } from "./slice";

import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Button, Chip, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import { Actions, StarlinkQuota, StarlinkQuotaTableSortBy } from "./types";
import _ from "lodash";
import SortArrows, { SortOrder } from "../SortArrows";
import { Pagination } from "../Pagination";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../asset/image/DownloadUsage.svg';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FiberManualRecord, Edit, Delete, Lens } from '@mui/icons-material';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import AddEditQuotaDialog from "./AddEditQuotaDialog";
import { getAllServiceLines } from "../StarlinkReports/slice";
import { toast } from "react-toastify";
import { DeleteQuotaConfirmationDialog } from "../../UserScreen/QuotaManagement/DeleteQuotaConfirmDialog";
import { nanoid } from "nanoid";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";

const STARLINK_ADMIN_SF = "starlink-admin";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];

interface StarlinkQuotaTableProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    quotas: StarlinkQuota[];
    totalQuotas: number;
    gettingServiceLineQuotas: boolean;
    getServiceLineQuotas: (parameters: paramGetServiceLineQuotas) => void;
    getAllServiceLines: (parameters: any) => void;
    createQuota: (parameters: any) => any;
    updateQuota: (parameters: any) => any;
    deleteQuota: (parameters: any) => any;
    downloadAsCSV: (parameters: any) => void;
    newSummaryServiceLine: any;
    clearComponentData: () => void;
}

function StarlinkQuotaTable(props: StarlinkQuotaTableProps) {
    const { authReducer, errorReducer, history, location, dpIds, quotas, totalQuotas, gettingServiceLineQuotas, newSummaryServiceLine, getServiceLineQuotas, getAllServiceLines, createQuota, updateQuota, deleteQuota, downloadAsCSV, clearComponentData } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: StarlinkQuotaTableSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy') as StarlinkQuotaTableSortBy : 'service_line_name',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [toUpdateQuota, setToUpdateQuota] = useState<StarlinkQuota | null>(null);
    const [openQuotaConfigDialog, setOpenQuotaConfigDialog] = useState<boolean>(false);
    const [quotaDialogHeading, setQuotaDialogHeading] = useState<string>("Add Quota");
    const [toDeleteQuota, setToDeleteQuota] = useState<StarlinkQuota | null>(null);

    const dispatch = useDispatch();

    const isAdmin = useMemo(() => {
        return checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)
    }, [])

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: StarlinkQuotaTableSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const refreshQuotas = (_searchText: string | undefined = undefined) => {
        if (_.isEmpty(dpIds)) {
            // empty the table
            dispatch({
                type: ACTION_TYPES.GET_SERVICE_LINE_QUOTAS, payload: {
                    data: {
                        rows: [
                            [[], 0]
                        ]
                    }
                }
            })
            return;
        }
        setTimeout(() => {
            getServiceLineQuotas({
                dpIds,
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                page: tableParams.page,
                limit: tableParams.size,
                serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
            })
        }, 1000);
    }

    const handleEdit = (quota: StarlinkQuota) => {
        return () => {
            setQuotaDialogHeading("Edit Quota")
            setToUpdateQuota(quota);
        }
    }

    const handleCancelQuotaConfig = () => {
        setToUpdateQuota(null);
        setOpenQuotaConfigDialog(false);
    }

    const handleSaveQuotaConfig = (serviceLineNumber: string, quota: StarlinkQuota, isNew: boolean) => {
        if (isNew) {
            handleCreateQuota(quota);
        } else {
            let _quotaId = quota.id;
            if (_quotaId) {
                handleUpdateQuota(quota);
            }
        }
        setToUpdateQuota(null);
        setOpenQuotaConfigDialog(false);
    }

    const handleCreateQuota = async (quota: StarlinkQuota) => {
        quota.dp_id = dpIds[0];
        let res = await createQuota({
            quota: quota
        });
        if (res?.success) {
            refreshQuotas();
        } else {
            toast.error("Failed to create quota.", {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const handleUpdateQuota = async (quota: StarlinkQuota) => {
        quota.dp_id = dpIds[0];
        let res = await updateQuota({
            quota: quota
        });
        if (res?.success) {
            refreshQuotas();
        } else {
            toast.error("Failed to update quota.", {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const handleDelete = (quota: StarlinkQuota) => {
        return () => {
            setToDeleteQuota(quota);
        }
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteQuota) {
            let _toDeleteQuota = toDeleteQuota
            setToDeleteQuota(null);
            let res = await deleteQuota({
                quota: _toDeleteQuota
            });
            if (res?.success) {
                refreshQuotas();
            } else {
                toast.error("Failed to delete quota", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }
        setToDeleteQuota(null);
    }

    const handleDeleteCancel = () => {
        setToDeleteQuota(null);
    }

    useEffect(() => {
        updateQueryParams();
        getServiceLineQuotas({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [ tableParams]);

    const convertToCsv = (data: any[][], userTimezone: string) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            csvRows.push([row[4], row[13], row[7], convertDateTimeIntoTimezone(row[1], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[2], row[8], row[17]]);
        })
        return [['Organization', 'Service Line', 'Kit Number', `Quota Start(${userTimezone})`, 'Usage (GB)', 'Limit (GB)', 'Status'], ...csvRows];
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-line-quota') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))], authReducer.userTimezone), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                3: 'ESC-COMMA',
                                4: 'GB',
                                5: 'GB'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-line-quota',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalQuotas,
                offset: 0,
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_STARLINK_QUTOAS'
        }
        downloadAsCSV(params);
    }

    useEffect(() => {
        updateQueryParams();
        clearComponentData();
        getServiceLineQuotas({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });

        if (!_.isEmpty(dpIds)) {
            getAllServiceLines({ dpIds: dpIds,  serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],});
        }
    }, [ newSummaryServiceLine]);

    return (
        <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
            <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x' }}>
                {/* FILTERS */}
                <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                    <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} classes={{ root: 'starlink--base--flex--row starlink--base--flex--gap--05x starlink--base--flex--align--center' }}>
                            <Box>
                                <TextField
                                    id="search"
                                    variant="outlined"
                                    placeholder="Type something"
                                    classes={{ root: "input-box-user-ter" }}
                                    size="small"
                                    value={searchText}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "serachBar",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchText && <SearchIcon
                                                    className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                                />}
                                                {searchText && <CloseIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    className="starlink--button_contained--primary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => { setOpenQuotaConfigDialog(true); setQuotaDialogHeading("Add Quota") }}
                                >
                                    ADD QUOTA
                                </Button>
                                <AddEditQuotaDialog heading={quotaDialogHeading} open={toUpdateQuota != null || openQuotaConfigDialog} quota={toUpdateQuota} onSave={handleSaveQuotaConfig} onClose={handleCancelQuotaConfig} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid classes={{ root: 'starlink--base--flex--row starlink--base--flex--gap--05x starlink--base--flex--justify--end starlink--base--flex--align--center' }}>
                        <Box>
                            <div className='quota_management--base--font_07x quota_management--base--flex--align--center'>
                                <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--remaining' }} /> Remaining &nbsp;&nbsp;
                                <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--normal' }} /> Normal &nbsp;&nbsp;
                                <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--alerting' }} /> Over Quota Threshold &nbsp;&nbsp;
                                <FiberManualRecord fontSize='small' classes={{ root: 'quota_management--dashboard--usage_metric_color--exceeded' }} /> Over Max Quota &nbsp;&nbsp;

                            </div>
                        </Box>
                        {totalQuotas > 0 && <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className="starlink--button_contained--primary"
                            onClick={handleDownloadReport}
                            startIcon={<img src={Download} alt="download" className="download-icon" />}
                        >
                            DOWNLOAD
                        </Button>}
                    </Grid>
                </Grid>

                <DeleteQuotaConfirmationDialog open={toDeleteQuota != null} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />

                {/* TABLE PAGINATION */}
                <Pagination count={totalQuotas} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

                {/* SERVICE ACCOUNT TABLE */}
                <TableContainer component={Paper} className="starlink--table_container">
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dp_name" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">ORGANIZATION</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="service_line_name" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">SERVICE LINE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="kit_numbers" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">KIT NUMBER</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="start_time" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">QUOTA START</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="period_display" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">QUOTA PERIOD</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell classes={{ root: 'quota_management--dashboard--usage' }}>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="current_usage" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">USAGE</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="status" onChange={handleChangeSorting}>
                                        <span className="font-wt-900">STATUS</span>
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left' classes={{ root: 'quota_management--dashboard--actions' }}>
                                    <Grid classes={{ root: 'quota_management--base--flex--align--center' }}>
                                        ACTIONS
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                quotas?.length > 0 ? quotas.map((quota: StarlinkQuota) => {
                                    return <TableRow
                                        key={quota.service_line_number}
                                    >
                                        <TableCell>
                                            {quota.dp_name}
                                        </TableCell>
                                        <TableCell>
                                            {quota.service_line_name}
                                        </TableCell>
                                        <TableCell>
                                            {quota.kit_numbers}
                                        </TableCell>
                                        <TableCell>
                                            {convertDateTimeIntoTimezone(quota.current_quota_start_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                        </TableCell>
                                        <TableCell>
                                            {readablePeriod(quota.period, quota.period_unit)}
                                        </TableCell>
                                        <TableCell>
                                            <UsageLineRep quota={quota} userTimezone={authReducer.userTimezone} />
                                        </TableCell>
                                        <TableCell>
                                            {quota?.status === "over_limit" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }}><Lens style={{ color: '#cc3300', fontSize: 'unset', marginRight: '0.25rem', borderRadius: '7px', boxShadow: '0px 0px 3px #cc3300' }} />Over Max Quota</Grid>
                                                : quota?.status === "alerting" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }} ><Lens style={{ color: '#ffcc00', fontSize: 'unset', marginRight: '0.25rem', borderRadius: '7px', boxShadow: '0px 0px 3px #ffcc00' }} />Over Quota Threshold </Grid>
                                                    : quota?.status === "active" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }} ><Lens style={{ color: '#1B51A7', fontSize: 'unset', marginRight: '0.25rem', borderRadius: '7px', boxShadow: '0px 0px 3px #1B51A7' }} />Normal </Grid> : null}
                                        </TableCell>
                                        <TableCell>
                                            <Paper elevation={0} className="quota_management--device_summary--actions">
                                                <Tooltip title="Edit"><IconButton size='small' onClick={handleEdit(quota)}
                                                >
                                                    <Edit />
                                                </IconButton></Tooltip>
                                                {/* <Tooltip title="Copy"><IconButton size='small'
                                                onClick={handleCopy}
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton></Tooltip> */}
                                                <Tooltip title="Delete"><IconButton disabled={!isAdmin && (quota !== undefined && quota.actions !== undefined && quota.actions?.filter?.(a => a.type === Actions.ActionPauseServiceLine || a.type === Actions.ActionResumeServiceLine)?.length > 0)} size='small' onClick={handleDelete(quota)}
                                                >
                                                    <Delete />
                                                </IconButton></Tooltip>
                                                {/* <Tooltip title="Enable / Disable">
                                                    <Switch
                                                        size="small"
                                                        checked={!quota.disabled}
                                                        color="primary"
                                                        // onChange={handleToggleDisable}
                                                    />
                                                </Tooltip> */}
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan={8} align='center'>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </Grid>
    );
}

interface UsageLineRepProps {
    quota: StarlinkQuota;
    userTimezone: string;
}

// component draws usage line representation using divs and colors
// with total, used and limit
export function UsageLineRep(props: UsageLineRepProps) {
    const { quota, userTimezone } = props;

    const used = quota?.current_usage ? Number(quota?.current_usage) : quota?.current_usage ? Number(quota?.current_usage) : 0
    const total = Number(quota.limit) > 0 ? Number(quota.limit) : 1000

    let thresholds = [100]
    const thresholdPercent = Math.min(...thresholds)
    const usedPercent = Math.round(used / total * 100)

    const status = used > total ? "exceeded" : usedPercent > thresholdPercent ? "alerting" : "normal"

    return (
        <Fragment>
            <Tooltip title={
                <Paper elevation={3}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    USAGE
                                </TableCell>
                                {
                                    quota.actions?.sort((a, b) => a.usage_percentage - b.usage_percentage)?.filter(action => !action.revert)?.map((action, index) => {
                                        return (
                                            <TableCell key={index}>
                                                QUOTA ({action.usage_percentage}%)
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell>
                                    MAX QUOTA
                                </TableCell>
                                <TableCell>
                                    PERIOD
                                </TableCell>
                                <TableCell>
                                    EST. MAX OVER DATE
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {readableBytesAsGB(used)}
                                </TableCell>
                                {
                                    quota.actions?.filter(action => !action.revert)?.map((action, index) => {
                                        return (
                                            <TableCell key={nanoid()} sx={{ color: action.usage_percentage < usedPercent ? status === 'exceeded' ? '#cc3300' : status === 'alerting' ? '#ffcc00' : 'inherit' : 'inherit' }}>
                                                {readableBytesAsGB(action.usage_percentage * total / 100)}
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell>
                                    {readableBytesAsGB(total)}
                                </TableCell>
                                <TableCell>
                                    {quota?.span !== "" ? `${quota.span}/${quota.period}${quota.period_unit}` : ""}
                                </TableCell>
                                <TableCell>
                                    {moment(quota.estimated_max_over_date).isValid() ? convertDateTimeIntoTimezone(quota.estimated_max_over_date, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "None"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            } classes={{ tooltip: "quota_management--usage_tooltip" }} placement="top">
                <Box sx={{ width: '100%', height: 10, bgcolor: status === "exceeded" ? '#cc3300' : '#c2c7e3', borderRadius: 5, position: 'relative' }} >
                    <Box sx={{ width: `${status === "exceeded" ? total / used * 100 : 100}%`, position: 'relative' }}>
                        <Box sx={{ width: `${status === "exceeded" ? 100 : usedPercent > thresholdPercent ? usedPercent : thresholdPercent}%`, height: 10, bgcolor: status === "alerting" || status === "exceeded" ? '#ffcc00' : '#c2c7e3', borderRadius: 5, position: 'absolute' }} />
                        <Box sx={{ width: `${status === "exceeded" ? thresholdPercent : usedPercent < thresholdPercent ? usedPercent : thresholdPercent}%`, height: 10, bgcolor: '#1B51A7', borderRadius: 5, position: 'absolute' }} />
                    </Box>
                </Box>
            </Tooltip>
            <Box>
                {readableBytesAsGB(used)} / {readableBytesAsGB(total)} ({usedPercent}%)
            </Box>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    quotas: state.starlinkQuota.quotas,
    totalQuotas: state.starlinkQuota.totalQuotas,
    gettingServiceLineQuotas: state.starlinkQuota.gettingServiceLineQuotas,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceLineQuotas,
        getAllServiceLines,
        createQuota,
        updateQuota,
        deleteQuota,
        downloadAsCSV,
        clearComponentData
    })(StarlinkQuotaTable)
);