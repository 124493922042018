import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { StarlinkAdminNavTabs } from '../types';
import { Grid, Paper } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import Loader from "react-loader";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: StarlinkAdminNavTabs;
    value: StarlinkAdminNavTabs;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`starlink-tabpanel-${index}`}
            aria-labelledby={`${index}`}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </div>
    );
}

function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

interface NavigationProps {
    authReducer: any;
    value: StarlinkAdminNavTabs;
    onChange: (event: React.SyntheticEvent, newValue: StarlinkAdminNavTabs) => void;
}

function Navigation(props: NavigationProps) {
    const { authReducer, value, onChange } = props;

    const handleChange = (event: React.SyntheticEvent, newValue: StarlinkAdminNavTabs) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            onChange(event, newValue);
        }
    };

    return (
        <Paper elevation={1} square={true} className='starlink--admin_navigation--container' classes={{ root: 'starlink--base--flex--align--center starlink--base--flex--justify--space_between starlink--base--padding_1x' }}>
            <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="starlink-navigation-tabs">
                    <Tab label="Service Account" value="service_account" />
                    <Tab label="Service Line" value="service_line" />
                    <Tab label="User Terminal" value="user_terminal" />
                    <Tab label="Audit Logs" value="audit_logs" />
                </Tabs>
            </Grid>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default connect(mapStateToProps, {})(Navigation)