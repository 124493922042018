import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Box, Button, FormControl, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import { Search } from '@mui/icons-material';
import { Edit, Delete } from "@material-ui/icons";
import CircleIcon from '@mui/icons-material/Circle';

import { Pagination } from "../../Pagination";
import ServiceAccountDialog from "../ServiceAccountDialog";

import './ServiceAccounts.css';
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI } from '../../../utils/util';

import { createServiceAccount, deleteServiceAccount, getServiceAccounts, updateServiceAccount } from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { ServiceAccount } from "../types";
import { toast } from "react-toastify";
import { DeleteConfirmationDialog } from "../DeleteConfirm";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

import ServiceAccountWorkflowDialog from "../ServiceAccountWorkflowDialog";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";

import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";

const STARLINK_ADMIN_SF = "starlink-admin";
const STARLINK_FULL_SF = "starlink-full";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
const starlinkFullService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["service"];
const starlinkFullFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["function"];

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[3], row[5], row[7], row[0], row[8] ? 'Yes' : 'No'])
    })
    return [['Organization', 'Account Type', 'Username', 'Client ID', 'Valid'], ...csvRows]
}
interface ServiceAccountsProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    serviceAccounts: any[];
    totalServiceAccounts: number;
    getServiceAccounts: (parameters: any) => void;
    createServiceAccount: (parameters: ServiceAccount, abortSignal: AbortSignal | undefined) => Promise<any>;
    updateServiceAccount: (parameters: ServiceAccount) => Promise<any>;
    deleteServiceAccount: (id: string) => Promise<any>;
    downloadAsCSV: (parameters: any) => void;
    newSummaryServiceLine: any;
}

export type AccountSortBy = 'user_name' | 'client_id' | 'valid' | 'dpName' | 'type';

function ServiceAccounts(props: ServiceAccountsProps) {

    const { authReducer, errorReducer, history, location, dpIds, serviceAccounts, totalServiceAccounts, getServiceAccounts, createServiceAccount, updateServiceAccount, deleteServiceAccount, downloadAsCSV, newSummaryServiceLine } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = useState<{
        page: number;
        size: number;
        sortBy: AccountSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AccountSortBy || 'client_id',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
    });

    const [openServiceAccountDialog, setOpenServiceAccountDialog] = useState<boolean>(false);
    const [serviceAccountToUpdate, setServiceAccountToUpdate] = useState<any>(null);
    const [toDeleteServiceAcount, setToDeleteServiceAcount] = useState<ServiceAccount | null>(null);
    const [requesAbortController, setRequesAbortController] = useState<AbortController | null>(null);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();


    const refreshServiceAccountTable = (samePage: boolean = false) => {
        let _page = paginationParams.page;
        setPaginationParams({
            ...paginationParams,
            page: samePage ? _page : 1
        });
    }

    const handleOpenServiceAccountDialog = () => {
        setRequesAbortController(new AbortController());
        setOpenServiceAccountDialog(true);
    }

    const handleCloseServiceAccountDialog = (refresh: boolean = false) => {
        setOpenServiceAccountDialog(false);
        setServiceAccountToUpdate(null);
        if (refresh) {
            refreshServiceAccountTable();
        }
    }

    const handleSaveServiceAccount = async (serviceAccount: ServiceAccount) => {
        // do api call here to create or update
        if (serviceAccountToUpdate) {
            // update
            await updateServiceAccount(serviceAccount);
            refreshServiceAccountTable()
        } else {
            // create
            await createServiceAccount(serviceAccount, requesAbortController?.signal);
            refreshServiceAccountTable()
        }
        setOpenServiceAccountDialog(false);
        setServiceAccountToUpdate(null);
    }

    const handleDeleteServiceAcount = (serviceAccount: ServiceAccount) => {
        setToDeleteServiceAcount(serviceAccount);
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteServiceAcount) {
            let _toDeleteServiceAcount = toDeleteServiceAcount
            setToDeleteServiceAcount(null);
            let res = await deleteServiceAccount(_toDeleteServiceAcount.id);
            if (res?.success) {
                refreshServiceAccountTable();
            } else {
                toast.error("Failed to delete serviceAccount", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }
        setToDeleteServiceAcount(null);
    }

    const handleDeleteCancel = () => {
        setToDeleteServiceAcount(null);
    }

    
    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }


    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPaginationParams({ ...paginationParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setPaginationParams({ ...paginationParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: AccountSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        getServiceAccounts({
            dpIds,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            page: paginationParams.page,
            limit: paginationParams.size
        });
    }, [dpIds, paginationParams, newSummaryServiceLine]);

    const handleEdit = (serviceAccount: ServiceAccount) => {
        setServiceAccountToUpdate(serviceAccount);
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-account') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status[0] === null) {
                    return;
                }
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))])
                            , {
                                formatters: {
                                    0: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-account',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: paginationParams.search,
                sortBy: paginationParams.sortBy,
                sortOrder: paginationParams.sortOrder,
                limit: totalServiceAccounts,
                offset: 0
            },
            queryName: 'GET_SERVICE_ACCOUNTS',
        }
        downloadAsCSV(params);
    }

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x' }}>
            {/* FILTERS */}
            <Grid item xs={12} sm={12} md={12} lg={12} classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SERVICE ACCOUNTS
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{root: "input-box-service"}}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {/* link service account button */}
                    <Button variant="contained" disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} color="primary" className="starlink--button_contained--primary" onClick={handleOpenServiceAccountDialog}>
                        Link Service Account
                    </Button>
                    {totalServiceAccounts > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalServiceAccounts} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                {/* <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="type" onChange={handleChangeSorting}> */}
                                <span className="font-wt-900">ACCOUNT TYPE</span>
                                {/* </SortArrows> */}
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="user_name" onChange={handleChangeSorting}>
                                <span className="font-wt-900">USERNAME</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="client_id" onChange={handleChangeSorting}>
                                <span className="font-wt-900">CLIENT ID</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="valid" onChange={handleChangeSorting}>
                                <span className="font-wt-900">VALID</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                 <span className="font-wt-900">ACTIONS</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            serviceAccounts?.length > 0 ? serviceAccounts.map((serviceAccount: ServiceAccount) => {
                                return <TableRow
                                    key={serviceAccount.id}
                                >
                                    <TableCell>
                                        {serviceAccount.dpName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceAccount.type === 'service_account_client' ? 'Service Account Client' : 'Web Portal'}
                                    </TableCell>
                                    <TableCell>
                                        {serviceAccount.userName ? serviceAccount.userName : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {serviceAccount.clientId ? serviceAccount.clientId : '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={serviceAccount.valid ? 'Valid' : 'Invalid'}>
                                            <Grid classes={{ root: 'starlink--base--flex--column starlink--base--flex--align--center' }}>
                                                <CircleIcon className="starlink--circle_icon starlink--service_accounts--valid_status" fontSize="small" sx={{ color: serviceAccount.valid ? 'green' : 'red' }} /> {serviceAccount.valid ? 'Yes' : 'No'}
                                            </Grid>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {/* edit */}
                                        <Tooltip title="Edit"><IconButton disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} size='small' onClick={() => { handleEdit(serviceAccount) }}>
                                            <Edit fontSize="small" />
                                        </IconButton></Tooltip>
                                        {/* delete */}
                                        <Tooltip title="Delete"><IconButton disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} size='small' onClick={() => { handleDeleteServiceAcount(serviceAccount) }}>
                                            <Delete fontSize="small" />
                                        </IconButton></Tooltip>
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={6} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* DELETE SERVICE ACCOUNT CONFIRMATION */}
            <DeleteConfirmationDialog open={toDeleteServiceAcount != null} itemName="Service Account" itemCaption={toDeleteServiceAcount ? `${toDeleteServiceAcount.dpName} : ${toDeleteServiceAcount.type === 'service_account_client' ? toDeleteServiceAcount.clientId : toDeleteServiceAcount.userName}` : ''} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />

            {/* SERVICE ACCOUNT EDIT DIALOG */}
            <ServiceAccountDialog open={serviceAccountToUpdate !== null} serviceAccount={serviceAccountToUpdate} onClose={handleCloseServiceAccountDialog} onSave={handleSaveServiceAccount} />

            {/* SERVICE ACCOUNT WORKFLOW DIALOG */}
            <ServiceAccountWorkflowDialog open={openServiceAccountDialog} onClose={handleCloseServiceAccountDialog} onSave={handleSaveServiceAccount} />
            

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    serviceAccounts: state.starlinkAdmin.serviceAccounts,
    totalServiceAccounts: state.starlinkAdmin.totalServiceAccounts,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceAccounts,
        createServiceAccount,
        updateServiceAccount,
        deleteServiceAccount,
        downloadAsCSV
    })(ServiceAccounts)
);