import { Box, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'; import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getGeneratedReports, deleteGeneratedReport, getGeneretedReportDownloadURL } from '../../actions/Users/authenticateUsageReports';
import './generatedReports.css';
import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { JsonDataTooltip } from '../JSONTooltip/JsonTooltip';
import { toast } from 'react-toastify';
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from '../../utils/util';
import { Delete } from '@material-ui/icons';
import { DeleteReportConfirmationDialog } from './ConfirmDeleteReports';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';
import { GeneratedReport, GeneratedReportsProps } from './types';
import SortArrows, { SortOrder } from "../SortArrows";
import { Pagination } from '../Pagination';

const ENTER_KEY_CODE = 'Enter';

const GeneratedReports = (props: GeneratedReportsProps) => {
    const { authReducer, reports, total_count, history, location, getGeneratedReports, deleteGeneratedReport, getGeneretedReportDownloadURL } = props;

    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [toDeleteReport, setToDeleteReport] = useState<GeneratedReport | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: string;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10,
        sortBy: _q.has('sortBy') ? _q.get('sortBy') as string : 'created_at',
        sortOrder: _q.has('sortOrder') ? _q.get('sortOrder') as SortOrder : 'desc',
        search: _q.has('search') ? _q.get('search') as string : '',
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();


    useEffect(() => {
        let params: any = getDecodeURI(location?.search);
        delete params.reportsTab;
        history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    }, [])

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id) {
            const dpId = authReducer?.selectedOu?.id;
            getGeneratedReports({
                dpId: dpId,
                page: tableParams.page,
                size: tableParams.size,
                search: tableParams.search,
                sort_by: tableParams.sortBy,
                sort_order: tableParams.sortOrder
            })
        }
    }, [authReducer.selectedOu, tableParams])


    const handleDownloadReport = async (report: GeneratedReport, detailed: boolean) => {
        const resp = await getGeneretedReportDownloadURL({
            dpId: report.org_id,
            id: report.id,
            detailed_report: report.filters['detailed_report'] ? detailed : false
        });
        if (resp && resp.result) {
            window.open(resp.result);
        } else {
            toast.error('Something went wrong, unable to download the file..', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    }

    const rowsOption = [10, 15, 25, 50, 100];

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleDelete = (row: GeneratedReport) => {
        setToDeleteReport(row)
    }

    const handleDeleteCancel = () => {
        setToDeleteReport(null);
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteReport) {
            let _toDeleteReport = toDeleteReport;
            setToDeleteReport(null);
            await deleteGeneratedReport(_toDeleteReport.org_id, _toDeleteReport.id);
            setTableParams({
                ...tableParams,
                page: tableParams.page
            });
        }
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            handleSearch(searchValue);
        }
    }

    const handleOnSearchClick = () => {
        handleSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const getReportType = (type: string) => {
        switch (type) {
            case 'activity':
                return 'Activity';
            case 'content_filter':
                return 'Content Filter';
            case 'firewall':
                return 'Firewall';
            default:
                return '';
        }
    }

    return (
        <div className="generated-reports-container">
            <Grid item xs={12} sm={12} md={12} lg={12} className='search-report-count'>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box>
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            classes={{ root: "input-box-login-hist input-box-generated-reports" }}
                            size="small"
                            value={searchValue}
                            onChange={handleOnSearchChange}
                            onKeyDown={(event) => searchBoxKeyDown(event)}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchValue && <SearchIcon
                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                        />}
                                        {searchValue && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                    Generated Reports : {total_count}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner generated-reports-table">
            <Pagination count={total_count} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

                <TableContainer className="usageReports-FleetDashboard reports-gene-container">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead">
                            <TableRow className="UsageReports-tableRow config-tableRow">
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Requested At</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Generated At</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900 metadata">Metadata</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Status</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Report Type </TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Summary </TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Flow Report</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head actions">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {reports?.length > 0 ? (
                            <TableBody className="tableBody usage-tableBody">
                                {
                                    reports && reports.map((row: GeneratedReport) =>
                                        <TableRow key={row.id} className="usageData-tabelRow config-backup-tabe-row gene-repo">
                                            <TableCell className="usagedata-tableCell config-left-align">{row.created_at ? convertDateTimeIntoTimezone(row['created_at'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : null}</TableCell>
                                            <TableCell className="usagedata-tableCell config-left-align">{row.generated_at ? convertDateTimeIntoTimezone(row['generated_at'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : null}</TableCell>
                                            <TableCell className="usagedata-tableCell config-left-align">
                                                <JsonDataTooltip data={row.filters} />
                                            </TableCell>
                                            <TableCell className="usagedata-tableCell config-left-align">
                                                <div className='generated-report-status'>
                                                    <div className={row.status === 'completed' ? 'status-completed' : row.status === 'processing' || row.status === 'queued' ? 'status-inprogress' : ''}></div>
                                                    <span>{row.status}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="usagedata-tableCell config-left-align">{getReportType(row.type)}</TableCell>
                                            <Tooltip title={row.summary_report_storage_path}>
                                                <TableCell className={row.status === 'completed' ? "usagedata-tableCell config-left-align download-report" : "usagedata-tableCell config-left-align download-report-inProg"}
                                                    onClick={() => { row.status === 'completed' && handleDownloadReport(row, false) }}
                                                >{row.status === 'completed' ? 'Download' : 'NA'}</TableCell>
                                            </Tooltip>
                                            <Tooltip title={row.detailed_report_storage_path}>
                                                <TableCell className={row.status === 'completed' && row.detailed_report_storage_path ? "usagedata-tableCell config-left-align download-report" : "usagedata-tableCell config-left-align download-report-inProg"}
                                                    onClick={() => { row.status === 'completed' && row.detailed_report_storage_path && handleDownloadReport(row, true) }}
                                                >{row.status === 'completed' && row.detailed_report_storage_path ? 'Download' : 'NA'}</TableCell>
                                            </Tooltip>
                                            <TableCell className="usagedata-tableCell config-left-align text-align-actions">
                                                <Tooltip title="Delete">
                                                    <IconButton size='small' onClick={() => handleDelete(row)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
            <DeleteReportConfirmationDialog open={toDeleteReport != null} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    reports: state.authReducer.getGeneratedReportsTable,
    total_count: state.authReducer.getGeneratedReportsTableCount
});

export default withRouter(
    connect(mapStateToProps, {
        getGeneratedReports,
        deleteGeneratedReport,
        getGeneretedReportDownloadURL
    })(GeneratedReports)
);