import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, Collapse, Grid, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import _ from "lodash";
import close from "../../asset/image/close.svg";

const FilterPopup = (props) => {
    const { authReducer, values, setValues, applyFilterClick, setFilterPopup, newSummaryStartDate, newSummaryEndDate } = props;
    const { vessels, category, app, user, vlan, device, subCategory } = values;
    const { setCategory, setApp, setUser, setVlan, setDevice, setSubCategory, setTrackApplyFilter } = setValues;
    const [categoryList, setCategoryList] = useState([]);
    const [appList, setAppList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [vlanList, setVlanList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    useEffect(() => {
        if(!_.isEmpty(authReducer.getContentFilterCategoryList)) {
            const categories = authReducer.getContentFilterCategoryList.hasOwnProperty('data') ? authReducer.getContentFilterCategoryList.data : {};
            const categoryList = categories?.rows?.map((item) => {
                return categories?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setCategoryList(Array.from(new Set(categoryList?.map(value => value.category))))
        }
        if(!_.isEmpty(authReducer.getContentFilterAppList)) {
            const apps = authReducer.getContentFilterAppList.hasOwnProperty('data') ? authReducer.getContentFilterAppList.data : {};
            const appList = apps?.rows?.map((item) => {
                return apps?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setAppList(Array.from(new Set(appList?.map(value => value.domain))))
        }
        if(!_.isEmpty(authReducer.getContentFilterUserList)) {
            const users = authReducer.getContentFilterUserList.hasOwnProperty('data') ? authReducer.getContentFilterUserList.data : {};
            const userList = users?.rows?.map((item) => {
                return users?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setUserList(Array.from(new Set(userList?.map(value => value.username))))
        }
        if(!_.isEmpty(authReducer.getContentFilterVlanList)) {
            const vlans = authReducer.getContentFilterVlanList.hasOwnProperty('data') ? authReducer.getContentFilterVlanList.data : {};
            const vlanList = vlans?.rows?.map((item) => {
                return vlans?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setVlanList(Array.from(new Set(vlanList?.map(value => value.name))))
        }
        if(!_.isEmpty(authReducer.getContentFilterDeviceList)) {
            const devices = authReducer.getContentFilterDeviceList.hasOwnProperty('data') ? authReducer.getContentFilterDeviceList.data : {};
            const deviceList = devices?.rows?.map((item) => {
                return devices?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setDeviceList(Array.from(new Set(deviceList?.map(value => value.Device_Name))))
        }
        if(!_.isEmpty(authReducer.getContentFilterSubCategoryList)) {
            const devices = authReducer.getContentFilterSubCategoryList.hasOwnProperty('data') ? authReducer.getContentFilterSubCategoryList.data : {};
            const deviceList = devices?.rows?.map((item) => {
                return devices?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setSubCategoryList(Array.from(new Set(deviceList?.map(value => value.subcategory))))
        }
    }, [authReducer.getContentFilterCategoryList,
        authReducer.getContentFilterAppList,
        authReducer.getContentFilterUserList,
        authReducer.getContentFilterVlanList,
        authReducer.getContentFilterDeviceList,
        authReducer.getContentFilterSubCategoryList])

    const handleChange = (value, key) => {
        setTrackApplyFilter(false);
        switch (key) {
            case 'Category': {
                setCategory(value);
                break;
            }
            case 'Domain': {
                setApp(value);
                break;
            }
            case 'User Name': {
                setUser(value);
                break;
            }
            case 'VLAN': {
                setVlan(value);
                break;
            }
            case 'Device Name': {
                setDevice(value);
                break;
            }
            case 'Sub Category': {
                setSubCategory(value);
                break;
            }
        }
    }

    const applyFilters = (key) => {
        switch (key) {
            case 'apply': {
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory);
                setFilterPopup(false);
                break;
            }
            case 'clear': {
                const value = '';
                (category || app || user || vlan || device || subCategory) && applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, value, value, value, value, value, value);
                setFilterPopup(false);
                setCategory(value);
                setApp(value);
                setUser(value);
                setVlan(value);
                setDevice(value);
                setSubCategory(value);
            }
        }
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="dash-filter-pop">
                    <Grid>
                        <Grid className="filter-clearFilter">
                            <Grid className="activityReportsFilter-close">
                                <img src={close} onClick={() => setFilterPopup(false)} alt="Close" />
                            </Grid> 
                            <div className="Filters_Title">Filters</div>
                            <div className="clearFiletrBtnDiv">
                                <Button className="clearFiletrBtnUsage margin-left-80" onClick={() => applyFilters('clear')}>Clear Filter</Button>
                            </div>
                        </Grid>
                       <Grid className="menuScroll">
                       <Grid className="content-filter-body-content">
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'Category'} options={categoryList} handleChange={handleChange} value={category} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'Domain'} options={appList} handleChange={handleChange} value={app} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'User Name'} options={userList} handleChange={handleChange} value={user} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'VLAN'} options={vlanList} handleChange={handleChange} value={vlan} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'Device Name'} options={deviceList} handleChange={handleChange} value={device} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'Sub Category'} options={subCategoryList} handleChange={handleChange} value={subCategory} />
                            </div>
                        </Grid>
                        </Grid>
                       </Grid>
                    </Grid>
                        <Button className="usage-applyFilterBtn" onClick={() => applyFilters('apply')}>Apply Filter</Button>
                </Grid>
            </Grid>
        </div>
    )
    
    interface Props {
        options: string[];
        name: string;
        handleChange: any;
        value: string;
    }

    function SearchUsageFilter({ name, options, handleChange, value }: Props) {
        const [expanded, isExpanded] = useState(true);

        const onKeyDown = (e: React.KeyboardEvent) => {
            e.stopPropagation();
        }
     
        return (
            <Fragment>
                <div className="rc--filter_header">
                    <div className="rc--content_sub_headers-select">{name}</div>
                </div>
                    <div className="rc--filter_search_container">
                        <div className="rc--filter_search userName-usageData">
                            <Autocomplete
                                disableClearable={value ? false : true}
                                ListboxProps={{className: "list-box-props" }}
                                size="small"
                                className="audit-filter-inputs-usage"
                                value={value}
                                onChange={(e, newValue) => { handleChange(newValue, name)}}
                                options={options}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" onKeyDown={onKeyDown} placeholder="search" />
                                )}
                            />
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { })(FilterPopup)
);