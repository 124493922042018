import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Menu, Button, Collapse, Chip, Autocomplete, createFilterOptions, TextField, InputAdornment, Dialog, Box, Grid, Checkbox } from "@mui/material";
import _ from "lodash";

import ExpandMoreIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLessIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from "@material-ui/icons/Clear";
import TuneIcon from '@mui/icons-material/Tune';
import { FilterOptions } from "..";
import close from "../../../asset/image/close.svg";


import "./index.css";

interface FilterMenuOptions {
    defaultFilter: FilterOptions
    handleChange({ address, port, type, alias, isRevoked }: FilterOptions): void
    generatedLinks: any
}

const mapStateToProps = (state) => ({
    generatedLinks: state.remoteConnection.generatedLinks
});

export const FilterMenu = connect(mapStateToProps, {})(({ defaultFilter, handleChange, generatedLinks }: FilterMenuOptions) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filter, setFilter] = useState<FilterOptions>(defaultFilter)
    const [clearFilter, setClearFilter] = useState<Number>(Math.random());
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (anchorEl == null) {
            setFilter(defaultFilter)
        }
    }, [anchorEl])

    useEffect(() => {
        setFilter(defaultFilter)
    }, [defaultFilter])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleChangeAddress = (selectedOptions) => {
        setFilter({
            ...filter,
            address: selectedOptions
        })
    }

    const handleChangePort = (selectedOptions) => {
        setFilter({
            ...filter,
            port: selectedOptions.map(op => Number(op)).filter(op => !Number.isNaN(op))
        })
    }

    const handleChangeAlias = (selectedOptions) => {
        setFilter({
            ...filter,
            alias: selectedOptions
        })
    }

    const handleChangeType = (selectedOptions) => {
        setFilter({
            ...filter,
            type: selectedOptions.map((op: string) => op.toLowerCase())
        })
    }

    const handleClearFilter = () => {
        let _filter = {
            address: [],
            port: [],
            type: [],
            alias: [],
            isRevoked: []
        }
        setFilter(_filter)
        setClearFilter(Math.random())
        handleChange(_filter)
    }

    const handleClickApplyFilter = () => {
        handleChange(filter)
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <Button size="small" variant="outlined" endIcon={<TuneIcon />} onClick={handleClick}>
                Filter
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: "menuScroll"
                }}
            >
                <div className="rc--filter_menu_container">
                    <div className="rc--filter_header rc--filter_section rc-filter-header-padding">
                        <div className="pad-top-3">
                            <img src={close} onClick={() => setAnchorEl(null)} className="dialog_closeIcon" alt="Close" />
                        </div>
                        <div className="rc--content_headers">Filters</div>
                        <div className="rc--links pad-top-3 margin-l-115" onClick={handleClearFilter}>Clear Filter</div>
                    </div>
                    <div className="rc--filter_section">
                        <SearchSelectChip name={"IP Address"} defaultSelection={filter.address} handleChange={handleChangeAddress} onClear={clearFilter} valueOptions={Array.from(new Set(generatedLinks.map(l => l.address)))} />
                    </div>
                    <div className="rc--filter_section">
                        <SearchSelectChip name={"Port"} defaultSelection={filter.port} handleChange={handleChangePort} onClear={clearFilter} valueOptions={Array.from(new Set(generatedLinks.map(l => Number(l.port))))} />
                    </div>
                    <div className="rc--filter_section">
                        <SearchSelectChip name={"Alias"} defaultSelection={filter.alias} handleChange={handleChangeAlias} onClear={clearFilter} valueOptions={Array.from(new Set(generatedLinks.map(l => l.alias)))} />
                    </div>
                    <div className="rc--filter_footer">
                        <Button fullWidth className="rc--apply_filter" onClick={handleClickApplyFilter} variant="contained" >Apply Filter</Button>
                    </div>
                </div>
            </Menu>
        </Fragment>
    )
})

function SearchSelectChip({ name, handleChange, onClear, defaultSelection, valueOptions }) {
    const [expanded, isExpanded] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelection)

    useEffect(() => {
        setSelectedOptions([])
    }, [onClear])

    useEffect(() => {
        setSelectedOptions(defaultSelection)
    }, [defaultSelection])

    return (
        <Fragment>
            <div className="rc--filter_header">
                <div className="rc--content_sub_headers_filters">{name}</div>
            </div>
                <div className="rc--filter_search_container">
                    <div className="rc--filter_search">
                        {/* <input className="rc--filter_search_input" type="text" name={name} id={`rc--filter_id_${name}`} placeholder={`Search for ${name}`} value={searchValue} onChange={handleSearchChange} onKeyDown={handleSelect} /> */}
                        {/* <SearchIcon sx={{ color: "#8B8D97" }} onClick={handleSelect} className="rc--filter_search_input_icon" /> */}

                        <Autocomplete
                            fullWidth
                            multiple
                            freeSolo
                            options={valueOptions}
                            value={selectedOptions}
                            onChange={(e, val) => {
                                setSelectedOptions(val)
                                handleChange(val)
                            }}
                            ChipProps={{
                                className: "rc--filter_search_chip"
                            }}
                            renderInput={(params) => <TextField {...params} size="small" variant="outlined" placeholder={`Search ${name}`} 
                            />}
                        />
                    </div>
                    {/* <div className="rc--filter_search_chip_container">
                        {
                            selectedOptions.map(option => <Chip key={option} className="rc--filter_search_chip" label={option} onDelete={() => handleUnselect(option)} />)
                        }
                    </div> */}
                </div>
        </Fragment>
    )
}

function SearchSelectCheckbox({ name, options, handleChange, onClear, defaultSelection }) {
    const [expanded, isExpanded] = useState(true);
    const initialSelection = useCallback(() => {
        let _initial = {};
        for (let key of options) {
            _initial[key] = defaultSelection.includes(key);
        }
        return _initial;
    }, [options, defaultSelection])

    const [selected, setSelected] = useState(initialSelection());

    useEffect(() => {
        setSelected(initialSelection())
    }, [defaultSelection])

    useEffect(() => {
        setSelected(initialSelection());
    }, [onClear]);

    const handleChangeCheckbox = (e) => {
        selected[e.target.name] = e.target.checked;
        handleChange(Object.keys(selected).filter(option => {
            return selected[option]
        }))
    }

    return (
        <Fragment>
            <div>
                <div className="rc--filter_header" onClick={(e) => { isExpanded(!expanded) }}>
                    <div className="rc--content_sub_headers_filter">{name}</div>
                </div>
                <div className="rc--filter_search_container">
                    {
                        options.map(option => {
                            return (
                                <Grid key={option} className="rc--content_sub_headers_type">
                                    <Grid className="rc--form-control" style={{ textTransform: "uppercase" }}>
                                        <Checkbox className="no-padding" name={option} id={`${option}_filter`} checked={selected[option]} onChange={handleChangeCheckbox} /> {option}
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
}