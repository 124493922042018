import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, FormControl, Select, MenuItem, Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../Pagination";
import { useEffect, useRef, useState } from "react";
import { getDecodeURI, getEncodedURI, readableBytes } from "../../utils/util";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { getUptimeTableData } from "../../actions/Users/authenticateTrends";
import _ from "lodash";
import Download from '../../asset/image/DownloadUsage.svg';
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[2], row[6], row[3], row[4], row[5]])
    })
    return [['Site', 'Overall (%)', 'Starlink (%)', 'Cellular (%)', 'VSAT (%)', 'LBAND (%)'], ...csvRows]
}

const UpTimeTable = (props) => {
    const { vessels, timePeriod, handleSiteClick, getUptimeTableData, newSummaryUptimeTable, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, setDashboardUptimeSiteStatisticsTableLoading, location, history, authReducer, downloadAsCSV } = props;

    const _q = getDecodeURI(location?.search);
    const SITE_NAME = 'name';
    const TOTAL = 'availibility';
    const VSAT = 'VSAT';
    const LTE = 'LTE';
    const STARLINK = 'starlink';
    const LBAND = 'FBB';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const DEFAULT_PAGE_SIZE = 10;
    const ENTER_KEY_CODE = 'Enter';
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : SITE_NAME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    useEffect(() => {
        if(vessels?.length > 0) {
            const window_value = (timePeriod?.minutes / 5);
            getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, 0, limit, sort, sortOrder, searchValue, window_value);
            setPage(0);
        } else {
            setTableData([]);
            setCount(0);
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType])

    useEffect(() => {
        if(!_.isEmpty(newSummaryUptimeTable)) {
            const data = newSummaryUptimeTable?.hasOwnProperty('data') ? newSummaryUptimeTable?.data : {};
            const dataArr:any = [];
            setCount(data?.rows?.[0]?.[7]);
            data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [newSummaryUptimeTable])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'uptime-table-newsum') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows])
                    , {
                        formatters: {
                            0: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 0;
        setPage(0);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, limit, field, order, searchValue, window_value)
    }

    const setSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };
    
    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value-1);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value-1, limit, sort, sortOrder, searchValue, window_value);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 0;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(0);
        setLimit(Limit);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, Limit, sort, sortOrder, searchValue, window_value);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 0;
        setPage(0);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, limit, sort, sortOrder, value, window_value);
    };

    const handleChangeTableLimit = (e) => {
        const value = e.target.value;
        setLimit(value);
        const Page = 0;
        setPage(0);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.limit : params.limit = value;
        delete params.page;
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUptimeTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, value, sort, sortOrder, searchValue, window_value);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleOnSearchClick = () => {
        doSearch(searchValue);
      }
    
      const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
      }
    
      const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
          searchTimeoutRef.current = undefined;
          doSearch(e.target.value);
        }, 1000)
      }

    const handleDownloadReport = () => {
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
  
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id : "uptime-table-newsum",
            queryName: 'DASHBOARD_UPTIME_SITESTATISTICS_TABLE',
            payload: {
                k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                startTime: startDate,
                endTime: endDate,
                pageStart: 0,
                pageLimit: count ? Number(count) : 0,
                sortColumn: sort,
                sortOrder: sortOrder,
                avail_window: avail_window ? avail_window : '12',
                wanType: newSummaryWanType.value ? `and wan_type ilike '${newSummaryWanType.value}'` : '',
                search: searchValue
            }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]
    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">{`Sites`}</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid item xs={4} sm={4} md={4} lg={4} className="margin-left-auto">
                            <Box>
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    classes={{ root: "input-box-uptime-tbl" }}
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "serachBar",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="cursorPointer"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid className="downloadBtnContainer usage-downloadBtnContainer">
                            <div className="download-summary-btn marg-top-2" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page + 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOTAL)}>OVERALL</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOTAL && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL, ASC_ORDER)} />
                                                <img src={sort === TOTAL && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTAL, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, STARLINK)}>STARLINK</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === STARLINK && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, STARLINK, ASC_ORDER)} />
                                                <img src={sort === STARLINK && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, STARLINK, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LTE)}>CELLULAR</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LTE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LTE, ASC_ORDER)} />
                                            <img src={sort === LTE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LTE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, VSAT)}>VSAT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === VSAT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VSAT, ASC_ORDER)} />
                                            <img src={sort === VSAT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, VSAT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LBAND)}>LBAND</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LBAND && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LBAND, ASC_ORDER)} />
                                            <img src={sort === LBAND && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LBAND, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !setDashboardUptimeSiteStatisticsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick({k4Id: item?.k4Id, x: item?.name})}>{item?.name}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.availability ? `${Math.round(item?.availability)} %` : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.starlink ? `${Math.round(item?.starlink)} %` : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.LTE ? `${Math.round(item?.LTE)} %` : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.VSAT ? `${Math.round(item?.VSAT)} %` : '-'}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.FBB ? `${Math.round(item?.FBB)} %` : '-'}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryUptimeTable: state.authReducer?.newSummaryUptimeTable,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryWanType:state?.authReducer?.newSummaryWanType,
    setDashboardUptimeSiteStatisticsTableLoading: state?.authReducer?.setDashboardUptimeSiteStatisticsTableLoading
  });
  
  export default withRouter(
    connect(mapStateToProps, { getUptimeTableData, downloadAsCSV })(UpTimeTable)
  )