import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import {  Button, Box, FormControl, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Switch, Checkbox, Dialog, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import { Search } from '@mui/icons-material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { Pagination } from "../../Pagination";
import ServiceLineDialog from "../ChangeSubOrgDialog";

import './ServiceLines.css';
import { checkLoggedInUserAuthorizedToViewPage, getDataFromLocalStorageParsed, getDecodeURI, getEncodedURI } from '../../../utils/util';

import { bulkUpdateServiceLineOrganization, getServiceLines, subscribeToServiceLines, optInForServiceLine,pauseResumeServiceLine, getAvailableProductsForAccount, clearComponentData } from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { ServiceLine } from "../types";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import moment from "moment-timezone";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import changeGroupIcon from "../../../asset/image/changeGroup.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import EditActionsPopup from "./EditActionsPopup";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[5], row[8]?.replaceAll(",", ""), row[11], row[10] ? 'Active' : 'Inactive', row[15]])
    })
    return [['Organization', 'Sub Organization', 'Service Line', 'Service Plan', 'Status', 'User Terminals'], ...csvRows]
}

interface ServiceLinesProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    serviceLines: any[];
    totalServiceLines: number;
    getServiceLines: (parameters: any) => void;
    downloadAsCSV: (parameters: any) => void;
    subscribeToServiceLines: (parameters: any) => void;
    optInForServiceLine: (parameters: any) => void;
    pauseResumeServiceLine: (parameters: any) => void;
    clearComponentData: () => void;
    getAvailableProductsForAccount: (parameters: any) => void;
    bulkUpdateServiceLineOrganization: (parameters: any) => Promise<any>;
    allServiceLines: any;
    availableProductsForAccount:any[];
    newSummaryServiceLine: any;
}

interface OptInProps {
    serviceLineNumber:String,
    accountNumber:String,
    accountDpId:String,
    action:String,
    serviceLineName:String
}

interface PauseProps {
    serviceLineNumber:String,
    accountNumber:String,
    accountDpId:String,
    action:String,
    endNow:Boolean,
    serviceLineName:String
}

const STARLINK_ADMIN_SF = "starlink-admin";
const STARLINK_FULL_SF = "starlink-full";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
const starlinkFullService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["service"];
const starlinkFullFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["function"];

export type ServiceLineSortBy = 'serviceLineName' | 'servicePlan' | 'userTerminals' | 'accountDpName' | 'dpName';

function ServiceLines(props: ServiceLinesProps) {

    const { authReducer, errorReducer, history, location, dpIds, serviceLines, allServiceLines, availableProductsForAccount, totalServiceLines, getServiceLines, bulkUpdateServiceLineOrganization, clearComponentData, downloadAsCSV, subscribeToServiceLines, optInForServiceLine,pauseResumeServiceLine, getAvailableProductsForAccount, newSummaryServiceLine} = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
    const [searchText, setSearchText] = useState<string>('');
    const [optInDialog, setOptInDialog] = useState<boolean>(false);
    const [pauseDialog, setPauseDialog] = useState<boolean>(false);
    const [product, setProduct] = useState('');
    const [optInData, setOptInData] = useState<OptInProps>({
        serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action:'',
        serviceLineName:''
    });
    const [pauseData, setPauseData] = useState<PauseProps>({
        serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action:'',
        endNow:false,
        serviceLineName:''
    });

    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: ServiceLineSortBy;
        sortOrder: SortOrder;
        search: string;
        serviceLineStatus:String[];
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy') as ServiceLineSortBy : 'serviceLineName',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
        serviceLineStatus: queryParams.has('serviceLineStatus') ? queryParams.get('serviceLineStatus')?.split(',') as string[] : ['true', 'false']
    });

    const [endNow, setEndNow] = useState(false)
    const [rejectReason, setRejectReason] = useState('')
    const [availableProducts, setAvailableProducts] = useState<{
        plan: string;
        product_reference_id: string;
    }[]>([])

    const [openServiceLineDialog, setOpenServiceLineDialog] = useState<boolean>(false);
    const [serviceLineToUpdate, setServiceLineToUpdate] = useState<any>(null);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const [editActionsPopup, setEditActionsPopup] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [selectedServiceLine, setSelectedServiceLine] = useState<any>({});
    const [mobilePriority, setMobilePriority] = useState(false);

    const refreshServiceLineTable = (samePage: boolean = false) => {
        let _page = 1;
        if (samePage) {
            _page = tableParams.page;
        }
        setTableParams({
            ...tableParams,
            page: _page
        });
    }

    const handleOpenServiceLineDialog = () => {
        setOpenServiceLineDialog(true);
    }

    const handleCloseServiceLineDialog = () => {
        setOpenServiceLineDialog(false);
        setServiceLineToUpdate(null);
    }

    const handleChangeSubOrg = async (dpId: string, serviceLineNumbers: string[]) => {
        await bulkUpdateServiceLineOrganization({
            serviceLineNumbers: serviceLineNumbers,
            dpId: dpId,
        });
        refreshServiceLineTable(true);
        setOpenServiceLineDialog(false);
        setServiceLineToUpdate(null);
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: ServiceLineSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        queryParams.set('serviceLineStatus', tableParams.serviceLineStatus.join(','))
                history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        clearComponentData()
        getServiceLines({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLineStatus: tableParams.serviceLineStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [ tableParams, newSummaryServiceLine]);

    const handleEdit = (serviceLine: any) => {
        setServiceLineToUpdate(serviceLine);
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-lines-csv') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status[0] === null) {
                    return;
                }
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))]), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                4: 'ESC-COMMA'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-lines-csv',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalServiceLines,
                offset: 0,
                serviceLineStatus: tableParams.serviceLineStatus.map(status => `'${status}'`).join(','),
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_SERVICE_LINES_ADMIN',
        }
        downloadAsCSV(params);
    }

    const handlePauseResume = async ( ) => {
        const requestData = {
            dpId:pauseData.accountDpId,
            serviceLineNumber:pauseData.serviceLineNumber,
            accountNumber:pauseData.accountNumber,
            action:pauseData.action,
            productReferenceId:product,
            endNow:endNow,
            rejectReason:rejectReason
        }
        await pauseResumeServiceLine(requestData)

        getServiceLines({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLineStatus: tableParams.serviceLineStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }

    
    const handleChangeOptIn = async ( sl, accountDpId, accountNumber, action) =>{
        const requestData = {
            dpId:accountDpId,
            serviceLineNumber:sl,
            accountNumber:accountNumber,
            action:action
        }
        await optInForServiceLine(requestData)

        getServiceLines({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLineStatus: tableParams.serviceLineStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }

    const handleChangeProduct = (event) =>{
        setProduct(event.target.value)
    }

    const handleChangeEndNow = (event) =>{
        setEndNow(event.target.value === "Yes" ? true : false)
    }

    const handleChangeSwitch = async (e, sl, dpId) =>{
        const action = e?.target?.checked ? "subscribe" : "unsubscribe";
        const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        
        const requestData: { serviceLineNumber: any; dpId: string; user: any; action: string; startDate?: string | undefined; endDate?: string | undefined; }  =
        {
            serviceLineNumber: [sl],
            dpId: dpId,
            user: loggedInUser?.data?.user?.userName,
            action: action
        };
        
        if (e?.target?.checked) {
            requestData.startDate = date;
        } else {
            requestData.endDate = date;
        }
        await subscribeToServiceLines(requestData);

            getServiceLines({
                dpIds,
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                page: tableParams.page,
                limit: tableParams.size,
                serviceLineStatus: tableParams.serviceLineStatus,
                serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
            });
        
    }

    const handleCloseOptInDialog = () =>{
        setOptInDialog(false)
        setPauseDialog(false)
        setOptInData({serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action:'',
        serviceLineName:''})
        setPauseData({serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action:'',serviceLineName:'',
    endNow:false})
    }

    const handleOptIn = () =>{
        if(optInData?.serviceLineNumber != '' && optInData?.accountDpId != '' && optInData?.accountNumber != ''){
            handleChangeOptIn(optInData?.serviceLineNumber, optInData?.accountDpId, optInData?.accountNumber, optInData.action)
        } 
    }


    const handleChangeServiceLineStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _serviceLineStatus = ['true', 'false'];
        if (event.target.value === 'true') {
            _serviceLineStatus = ['true'];
        } else if (event.target.value === 'false') {
            _serviceLineStatus = ['false'];
        }

        setTableParams({
            ...tableParams,
            serviceLineStatus: _serviceLineStatus,
            page: 1
        });
    };

        useEffect(()=>{
            if(availableProductsForAccount && availableProductsForAccount.length > 0){
                let productSubscriptions : any[] = availableProductsForAccount.map(item => {
                    return {
                        plan: item[0],
                        product_reference_id: item[1]
                    };
                });
                setAvailableProducts(productSubscriptions)
            }
           
        },[availableProductsForAccount])
    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x' }}>
            {/* FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SERVICE LINES
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <RadioGroup
                        row
                        aria-labelledby="starlink--service_account_type--radio_group"
                        name="controlled-radio-buttons-group"
                        value={tableParams.serviceLineStatus.length === 2 ? 'all' : tableParams.serviceLineStatus[0]}
                        onChange={handleChangeServiceLineStatus}
                    >
                        <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                        <FormControlLabel value="true" control={<Radio size="small" />} label="Active" />
                        <FormControlLabel value="false" control={<Radio size="small" />} label="InActive" />
                    </RadioGroup>
                    <Button size="small" variant="contained" disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} className='starlink--button_contained--primary' onClick={handleOpenServiceLineDialog} startIcon={<CorporateFareIcon />}>Assign</Button>
                    <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{root: "input-box-serviceline"}}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    {openServiceLineDialog && <ServiceLineDialog open={serviceLineToUpdate !== null || openServiceLineDialog} serviceLine={serviceLineToUpdate} onClose={handleCloseServiceLineDialog} onSave={handleChangeSubOrg} />}
                    {totalServiceLines > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalServiceLines} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SUB ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="serviceLineName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SERVICE LINE</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="servicePlan" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SERVICE PLAN</span>
                                </SortArrows>
                            </TableCell>

                            <TableCell>
                                <span className="font-wt-900">STATUS</span>
                            </TableCell>

                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="userTerminals" onChange={handleChangeSorting}>
                                <span className="font-wt-900">USER TERMINALS</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                            <span className="font-wt-900">OPERATIONS</span>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            serviceLines?.length > 0 ? serviceLines.map((serviceLine: ServiceLine) => {
                                return <TableRow
                                    key={serviceLine.serviceLineNumber}
                                >
                                    <TableCell>
                                        {serviceLine.accountDpName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.dpName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.serviceLineName ? serviceLine.serviceLineName : serviceLine.serviceLineNumber}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.servicePlan ? serviceLine.servicePlan : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.serviceLineStatus ? "Active" : 'Inactive'}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.userTerminals ? serviceLine.userTerminals : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {/* edit */}
                                        <Grid container className="starlink-admin-service-lines-actions-td">
                                        {/* <Tooltip title="Change Sub Organization">
                                            <div>
                                                <IconButton
                                                    className="starlink-change-sub-org"
                                                    disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)}
                                                    size='small' onClick={() => {
                                                        handleEdit({
                                                            serviceLineNumber: serviceLine.serviceLineNumber,
                                                            nickname: serviceLine.serviceLineName
                                                        })
                                                    }}>
                                                    <img src={changeGroupIcon} alt='changeGroup' />
                                                </IconButton>
                                            </div>
                                        </Tooltip> */}

                                        {/* <Tooltip title={serviceLine?.optedIn ? "Opt-out" : "Opt-in"}>
                                            <div style={{ display: 'inline-block' }}>
                                                <IconButton
                                                    //  disabled={serviceLine?.accountType === "credential" || !serviceLine.serviceLineStatus} 
                                                    //checkLoggedInUserAuthorizedToViewPage(starlinkAdminFunction, starlinkAdminFunction)
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: serviceLine?.optedIn ? "#1976d2" : "white",
                                                        // color: serviceLine?.optedIn ? "white" : undefined,
                                                        borderRadius: '50%',
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <CallSplitIcon
                                                        onClick={(e) => {
                                                            setOptInDialog(true);
                                                            setOptInData({
                                                                serviceLineNumber: serviceLine.serviceLineNumber,
                                                                serviceLineName: serviceLine.serviceLineName,
                                                                accountDpId: serviceLine.accountDpId,
                                                                accountNumber: serviceLine.accountNumber,
                                                                action: serviceLine?.optedIn ? "optOut" : "optIn"
                                                            });
                                                        }}
                                                    />
                                                </IconButton>
                                            </div>
                                        </Tooltip> */}

                                        {/* {serviceLine.subscriptionActiveStatus === "active" ?
                                            <Tooltip title="Pause Service Line">
                                                <div style={{ display: 'inline-block' }}>
                                                    <IconButton
                                                        disabled={true}
                                                    // disabled={serviceLine?.accountType === "credential"}
                                                    //checkLoggedInUserAuthorizedToViewPage(starlinkAdminFunction, starlinkAdminFunction)
                                                    >
                                                        <PauseCircleOutlineIcon
                                                            onClick={(e) => {
                                                                setPauseDialog(true)
                                                                setPauseData({ serviceLineNumber: serviceLine.serviceLineNumber, serviceLineName: serviceLine.serviceLineName, accountDpId: serviceLine.accountDpId, accountNumber: serviceLine.accountNumber, action: "Pause", endNow: false })
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Resume Service Line">
                                                <div style={{ display: 'inline-block' }}>
                                                    <IconButton
                                                        disabled={true}
                                                    // disabled={serviceLine?.accountType === "credential"}
                                                    //checkLoggedInUserAuthorizedToViewPage(starlinkAdminFunction, starlinkAdminFunction)
                                                    >
                                                        <PlayCircleRoundedIcon
                                                            onClick={(e) => {
                                                                setPauseDialog(true)
                                                                getAvailableProductsForAccount({ accountNumber: serviceLine.accountNumber })
                                                                setProduct(serviceLine.productReferenceId.toString())
                                                                setPauseData({ serviceLineNumber: serviceLine.serviceLineNumber, serviceLineName: serviceLine.serviceLineName, accountDpId: serviceLine.accountDpId, accountNumber: serviceLine.accountNumber, action: "Resume", endNow: false })
                                                            }}

                                                        />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>} */}

                                        <Tooltip title={serviceLine.servicelineSubscription ? "Starlink Cloud : Subscribed" : "Starlink Cloud : UnSubscribed"}>
                                            <div>
                                                <Switch
                                                    checked={serviceLine.servicelineSubscription}
                                                    disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)}
                                                    onChange={(e) => { handleChangeSwitch(e, serviceLine.serviceLineNumber, serviceLine.dpId) }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </div>
                                        </Tooltip>
                                        <span onClick={() => {setEditActionsPopup(true); setSelectedServiceLine(serviceLine)}}><SettingsIcon className="inventory-edit-icon starlink-admin-service-lines-actions" /></span>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={optInDialog} onClose={handleCloseOptInDialog} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_2x">
                       {optInData && optInData?.action != '' && optInData?.action == "optOut" ? "Disable" : "Enable"}  Mobile Priority for {optInData?.serviceLineName}.
                    </Typography>
                    <Typography component="div" className="starlink--base--font_1x">
                    {optInData && optInData?.action != '' && optInData?.action == "optOut" ?
                    "Data will continue to be charged for up to 15 minutes." :
                    "Charges will be incurred until Mobie Priority is disabled. Mobile Priority will begin within 15 minutes "
                    
                    }
                    </Typography>
                </Grid>
               
                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                    <Button variant="outlined" className='starlink--button--cancel' onClick={handleCloseOptInDialog}>Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handleOptIn} >{optInData && optInData?.action != '' && optInData?.action == "optOut" ? "Disable" : "Enable"}</Button>
                </Grid>
            </Grid>
        </Dialog>

        <Dialog open={pauseDialog} onClose={handleCloseOptInDialog} aria-labelledby="service_account--dialog" maxWidth="sm" fullWidth>
            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_2x">
                       {pauseData && pauseData?.action != '' && pauseData?.action} Service Line - {pauseData?.serviceLineName}
                        
                    </Typography>

                   
                        {pauseData && pauseData?.action != '' && pauseData?.action === "Pause" &&
                        <>
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="div" className="starlink--base--font_1x">
                                    End on
                                </Typography>

                                <FormControl sx={{ m: 2, minWidth: 400 }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={endNow === true ? "Yes" : "No"}
                                        label=""
                                        onChange={handleChangeEndNow}
                                    >
                                        <MenuItem value="Yes">Now</MenuItem>
                                        <MenuItem value="No">Billing Date</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>

                             <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="div" className="starlink--base--font_1x">
                                    Reason
                                </Typography>

                                <FormControl sx={{ m: 2, minWidth: 400 }}>
                                <TextField sx={{ padding: 0 }}
                                        className="audit-filter-inputs"
                                        size="small"
                                        variant="outlined"
                                        placeholder=""
                                        value={rejectReason}
                                        onChange={(e)=>{setRejectReason(e.target.value)}}
                                       
                                    />
                                </FormControl>
                            </div>
                            </>
                        }


                    {pauseData && pauseData?.action != '' && pauseData?.action === "Resume" && 
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                     <Typography component="div" className="starlink--base--font_1x">
                     Select Products
                     </Typography>
                    <FormControl sx={{ m: 2, minWidth: 400 }}>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product}
                        label="Age"
                        onChange={(e)=>{handleChangeProduct(e)}}
                        >
                            {availableProducts.map((product)=>{
                                return <MenuItem value={product.product_reference_id}>{product.plan}</MenuItem>
                            })}
                        
                        </Select>
                    </FormControl>
                    </div>
                    }
                  
                </Grid>
               
                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                    <Button variant="outlined" className='starlink--button--cancel' onClick={handleCloseOptInDialog}>Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handlePauseResume} disabled={pauseData && pauseData?.action != '' && pauseData?.action === "Resume" && product==''}>{pauseData && pauseData?.action != '' && pauseData?.action}</Button>
                </Grid>
            </Grid>
        </Dialog>

        {editActionsPopup ? <EditActionsPopup 
            open={editActionsPopup} setOpen={setEditActionsPopup}
            confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} 
            row={selectedServiceLine} setRow={setSelectedServiceLine}
            handleEdit={handleEdit} serviceLine={serviceLineToUpdate}
            onSave={handleChangeSubOrg} starlinkService={starlinkAdminService} starlinkFunction={starlinkAdminFunction}
            pauseData={pauseData} setPauseData={setPauseData} endNow={endNow} handleChangeEndNow={handleChangeEndNow} rejectReason={rejectReason} setRejectReason={setRejectReason}
            product={product} setProduct={setProduct} handleChangeProduct={handleChangeProduct} availableProducts={availableProducts} handlePauseResume={handlePauseResume}
            optInData={optInData} setOptInData={setOptInData} mobilePriority={mobilePriority} setMobilePriority={setMobilePriority} handleOptIn={handleOptIn}
        /> : null}
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    serviceLines: state.starlinkAdmin.serviceLines,
    totalServiceLines: state.starlinkAdmin.totalServiceLines,
    allServiceLines: state.starlinkReports.allServiceLines,
    availableProductsForAccount: state.starlinkAdmin.availableProductsForAccount,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceLines,
        bulkUpdateServiceLineOrganization,
        downloadAsCSV,
        subscribeToServiceLines,
        optInForServiceLine,
        pauseResumeServiceLine,
        getAvailableProductsForAccount,
        clearComponentData
    })(ServiceLines)
);