import { withRouter, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Pagination } from "../Pagination";
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import { getDecodeURI, getEncodedURI, populateForParent } from "../../utils/util";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from "../../asset/image/DownloadUsage.svg";
import _ from "lodash";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg"
import DescSort from "../../asset/image/DescSort.svg"
import "./SiteInfo.css";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { parentDpAndSubDpList } from "../../actions/Users/authenticate";
import { getSiteInfo, getSiteInfoCount } from "../../actions/Users/authenticateInventory";
import { GET_SITE_INFO_LIST, GET_SITE_INFO_LIST_COUNT } from "../../actions/types";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";

const SiteInfo = (props: any) => {
    const { authReducer, parentDpAndSubDpList, getSiteInfo, getSiteInfoCount, downloadAsCSV } = props;

    const location = useLocation()
    const history = useHistory()

    const dispatch = useDispatch()
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    let _q = getDecodeURI(location?.search);

    const Id = "id"
    const Name = "name"
    const SortOrderAsc = "asc"
    const SortOrderDesc = "desc"

    const [tableData, setTableData] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0)

    const [searchText, setSearchText] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "")
    const [sortBy, setSortBy] = useState<any>(_q.hasOwnProperty("sort") && !_.isEmpty(_q.sort) && _q.sort == Name || _q.sort == Id ? _q.sort : Name)
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : SortOrderAsc)
    const [page, setPage] = useState<number>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 0)
    const [size, setSize] = useState<number>(_q.hasOwnProperty("limit") ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE)

    const TABLE_DATA_QUERY_NAME = "GET_SITEID_SITENAME_INFO"
    const TOTAL_COUNT_QUERY_NAME = "GET_SITEID_SITENAME_INFO_COUNT"
    const DOWNLOAD_CSV_ID = "siteId_vs_siteName_list"
    let payload = {
        "query": TABLE_DATA_QUERY_NAME,
        "named": true,
        "parameters": {
            "dpIds": "",
            "search": searchText,
            "page_start": page,
            "page_size": size,
            "sort_by": sortBy,
            "sort_order": sortOrder
        },
        "format": "csv"
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    useEffect(() => {
        if (!_.isEmpty(authReducer) && authReducer.hasOwnProperty("selectedOu") && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            let dpIds = getParentAndSubDpIds()
            payload["parameters"]["dpIds"] = dpIds.map(x => "'" + x + "'").toString()
            getSiteInfo(payload)

            payload["query"] = TOTAL_COUNT_QUERY_NAME
            getSiteInfoCount(payload)
        }
    }, [authReducer.selectedOu, authReducer.parentDpAndSubDpList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSiteInfoList)) {
            const data = authReducer.getSiteInfoList.hasOwnProperty("data") ? authReducer.getSiteInfoList.data : {}
            if (data.hasOwnProperty("rows") && Array.isArray(data["rows"])) {
                const rows: any[] = data["rows"]
                let list: any[] = []
                rows.forEach(row => {
                    list.push({
                        "id": row[0],
                        "name": row[1]
                    })
                })
                setTableData(list)
            } else {
                setTableData([])
            }
            dispatch({ type: GET_SITE_INFO_LIST, payload: {} })
        }
    }, [authReducer.getSiteInfoList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSiteInfoListCount)) {
            const data = authReducer.getSiteInfoListCount.hasOwnProperty("data") ? authReducer.getSiteInfoListCount.data : {}

            if (!_.isEmpty(data) && data.hasOwnProperty("rows") && Array.isArray(data.rows)) {
                let count = data.rows.length > 0 && data.rows[0][0] ? parseInt(data.rows[0][0]) : 0
                setTotalCount(count)
            } else {
                setTotalCount(0)
            }
            dispatch({ type: GET_SITE_INFO_LIST_COUNT, payload: {} })
        }
    }, [authReducer.getSiteInfoListCount])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === DOWNLOAD_CSV_ID) {
                let sitesInfo = authReducer.csvDataDownloded;
                if (!_.isEmpty(sitesInfo) && sitesInfo.length>0) {
                    DownloadCSV([['Site Id', 'Site Name'], ...sitesInfo.slice(1,sitesInfo.length)])
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const getDpList = (dpList) => {
        const tempArr: any = {}
        dpList.forEach(dp => {
            const _organisations = []
            populateForParent(dp, null, _organisations)
            getDPTree(_organisations, tempArr)
        })
        parentDpAndSubDpList(tempArr)
    }

    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            ous.forEach(ou => {
                if (arr.hasOwnProperty(ou.id)) {
                    const temp: any = arr[ou.id]
                    arr[ou.id] = { ...temp, ...ou._childern }
                } else {
                    arr[ou.id] = ou._childern
                }
                getDPTree(ou._childern, arr)
            })
        }
    }

    const getParentAndSubDpIds = () => {
        const _dpIds: any = []
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        dps.forEach(dp => {
            let id = dp?.id
            dpIds.push(id)
            if (dp.hasOwnProperty('_childern') && dp['_childern']?.length > 0) {
                getChildren(dpIds, dp['_childern'])
            }
        })
    }

    const handleSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search)
        value ? params.search = value : delete params.search
        delete params.page
        setPage(0)

        doNavigate(location.url, params)

        let dpIds = getParentAndSubDpIds()
        payload["parameters"]["dpIds"] = dpIds.map(x => "'" + x + "'").toString()
        payload["parameters"]["search"] = value
        getSiteInfo(payload)

        payload["query"] = TOTAL_COUNT_QUERY_NAME
        getSiteInfoCount(payload)
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value)
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined
            handleSearch(e.target.value)
        }, 1000)
    }

    const handleDownloadReport = () => {
        let dpIds = getParentAndSubDpIds()
        const params: CsvParameters = {
            type: 'QUERY',
            id: DOWNLOAD_CSV_ID,
            payload: { dpIds: dpIds.map(x => "'" + x + "'").toString(), search: searchText, sort_by: sortBy, sort_order: sortOrder, page_start:0,  page_size: totalCount },
            queryName: TABLE_DATA_QUERY_NAME,
        }
        downloadAsCSV(params);
    }

    const handlePagination = (e, value) => {
        let params: any = getDecodeURI(location?.search)
        params.page = value - 1
        setPage(value - 1)
        doNavigate(location.pathname, params)

        let dpIds = getParentAndSubDpIds()
        payload["parameters"]["dpIds"] = dpIds.map(x => "'" + x + "'").toString()
        payload["parameters"]["page_start"] = (value - 1) * size

        getSiteInfo(payload)
    }

    const handlePerPageChange = (e: any) => {
        let _perPage = parseInt(e.target.value)
        if (Number.isInteger(_perPage)) {
            let params: any = getDecodeURI(location?.search)
            params.page = 0
            params.limit = _perPage
            setPage(0)
            setSize(_perPage)
            doNavigate(location.url, params)

            let dpIds = getParentAndSubDpIds()
            payload["parameters"]["dpIds"] = dpIds.map(x => "'" + x + "'").toString()
            payload["parameters"]["page_start"] = 0
            payload["parameters"]["page_size"] = _perPage
            getSiteInfo(payload)
        }
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search)
            params.page = 0
            params.limit = size
            params.sort = field
            params.sortOrder = order

            setPage(0)
            setSortBy(field)
            setSortOrder(order)

            payload["parameters"]["page_start"] = 0
            payload["parameters"]["sort_by"] = field
            payload["parameters"]["sort_order"] = order

            doNavigate(location.pathname, params)
            let dpIds = getParentAndSubDpIds()
            payload["parameters"]["dpIds"] = dpIds.map(x => "'" + x + "'").toString()
            getSiteInfo(payload)
        }
    }

    const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` })
    }

    return (
        <div className="inventory-group-actions-tab-content site-info-tab">
            <div className="Form">
                <Grid className="am-table">
                    <Grid className="">
                        <Grid className="deployed-inputRow">
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Type something"
                                        classes={{ root: "input-box-def-conf" }}
                                        value={searchText}
                                        onChange={handleOnSearchChange}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                        size="small"
                                        InputLabelProps={{ className: "serachLabel" }}
                                        InputProps={{
                                            className: "serachBar",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {!searchText && <SearchIcon
                                                        className="cursorPointer"
                                                        onClick={handleOnSearchClick}
                                                    />}
                                                    {searchText && <CloseIcon
                                                        className="cursorPointer"
                                                        onClick={handleOnSearchClear}
                                                    />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            {
                                totalCount > 0 ?
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                                    </Grid>
                                    : null
                            }
                        </Grid>
                        {
                            <div className="menuScroll">
                                <Box>
                                    <Pagination
                                        rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                        count={totalCount ? totalCount : 0}
                                        rowsPerPage={size}
                                        page={page + 1}
                                        onPageChange={handlePagination}
                                        onRowsPerPageChange={handlePerPageChange}
                                    />
                                </Box>
                                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer deployed-container white-bg" component={Paper}>
                                    <Table aria-label="simple sticky table" className="inventory-group-table site-info-table" stickyHeader>
                                        <TableHead className="usageReports-tableHead">
                                            <TableRow className="UsageReports-tableRow login-tableRow">
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, Id, SortOrderAsc)}>Site Id</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sortBy === Id && sortOrder === SortOrderAsc ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, Id, SortOrderAsc)} />
                                                            <img src={sortBy === Id && sortOrder === SortOrderDesc ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, Id, SortOrderDesc)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                                    <span className="align-items-center">
                                                        <span className="login-history-dp-name font-wt-900" onClick={(event) => setSorting(event, Name, SortOrderAsc)}>Site Name</span>
                                                        <Grid className="sort-icon-margin">
                                                            <img src={sortBy === Name && sortOrder === SortOrderAsc ? ascSortingSelected : AscSort}
                                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, Name, SortOrderAsc)} />
                                                            <img src={sortBy === Name && sortOrder === SortOrderDesc ? descSortingSelected : DescSort}
                                                                className="sortingImages" onClick={(event) => setSorting(event, Name, SortOrderDesc)} />
                                                        </Grid>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {tableData && 0 != tableData.length ? (
                                            <TableBody className="tableBody usage-tableBody">
                                                {
                                                    tableData.map((row, i) =>
                                                        <TableRow key={i} className="loginHistory-tableRow new-deployed-usage-tr">
                                                            <TableCell className="login-left-align inter-font text-center CF-cell">
                                                                {row["id"]}
                                                            </TableCell>
                                                            <TableCell className="login-left-align inter-font text-center CF-cell">
                                                                {row["name"]}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={2} className="noDataAvailableCss" align="center">No data available</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
})

export default withRouter(
    connect(mapStateToProps, {
        parentDpAndSubDpList, getSiteInfo, getSiteInfoCount, downloadAsCSV
    })(SiteInfo)
)
