// Dialog Box for the ticket View of Ticketing Page Pop-up
import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography,
} from "@mui/material";
import { withRouter, useHistory,useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import Plus from "../../asset/image/Plus.svg";
import Word from "../../asset/image/Word.svg";
import Excel from "../../asset/image/Excel.svg";
import OK from "../../asset/image/Complete.svg";
import Discard from "../../asset/image/Discard.svg";
import ImagePlaceHolder from "../../asset/image/ImagePlaceholder.svg";
import pdf from "../../asset/image/Placeholder.svg";
import Autocomplete from "@mui/material/Autocomplete";
import TicketEmail from "../../asset/image/TicketEmail.svg";
import { autoCompleteRenderTags, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import Tooltip from '@material-ui/core/Tooltip';
import {
    getTicketHistoryByTicketId, getTicketDetailsByTicketId,
    getTicketCommentsByTicketId, getTicketAllAgents, getTicketFields,
    getTicketAttachmentsByTicketId, getSingleAttachmentsByTicketId, sentTicketEmail,
    sendComment, searchTicketAssigneeByName, updateTicket, addAttachments
} from "../../actions/Users/authenticateTicket";
import { DEFAULT_TIMEZONE, MMDDYYYYHMMSS_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import moment from "moment-timezone";
import _ from "lodash";
import "./TicketView.css"
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { BC_TICKETS_VIEW } from "../../constants/Constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { getDataFromLocalStorage } from "../../utils/util";
import { updatePermission } from "../../actions/Users/authenticate";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { SIDE_MENU_ITEM_TICKETS } from "../../utils/constants";
import CommonFilters from "../BreadCrumbs/CommonFilters";
import BackArrow from "../../asset/image/arrow_back.svg";
import close from "../../asset/image/cross.svg";

const TicketView = (props) => {
    const { match, getTicketHistoryByTicketId, getTicketCommentsByTicketId,
        sentTicketEmail, getTicketAllAgents, getTicketDetailsByTicketId,
        addAttachments, getTicketAttachmentsByTicketId, getTicketFields,
        getSingleAttachmentsByTicketId, authReducer, sendComment, updateTicket,
        onClose, updatePermission, location } = props;
        const history = useHistory()
    const setBreadcrumbInfo: any = React.useRef();
    const [breadcrumb, setBreadcrumb] = useState<any[]>(BC_TICKETS_VIEW);

    const TABs = ["Comments", "History",]
    const loadingTextOnUI = "Fetching data please wait...";

    const [ticketId, setTicketId]: any = useState(match?.params?.ticketId ? match.params.ticketId : "");
    const [k4id, setk4id]: any = useState(match?.params?.k4id ? match.params.k4id : "");

    // const {ticketInfo,assigneeList}=props.history.location.state;
    let [allAgentList, setAllAgentList]: any = useState([]);
    const [loaders, setLoaders] = useState({ showLoader: false, loadingText: loadingTextOnUI });
    const AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS = 1;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    let [commentArray, setTicketComments]: any = useState([]);
    let [ticketHistory, setTicketHistory]: any = useState();
    let [showAttachments, setShowAttachments]: any = useState([]);
    let [ticketDetails, setTicketDetails]: any = useState([])
    let [attachment, setAttachments]: any = useState({});
    let [ticketFields, setTicketFields]: any = useState([]);
    let [selectedstatus, setSelectedStatus]: any = useState('');
    let [selectedIssue, setSelectedIssue]: any = useState("");
    let [selectedSystemType, setSelectedSystemType]: any = useState("");
    let [selectedPriority, setSelectedPriority]: any = useState("");
    let [selectedClassification, setSelectedClassification]: any = useState("");
    let [selectedComponent, setSelectedComponent]: any = useState("");
    let [selectedChannel, setSelectedChannel]: any = useState("")
    let [customerReported, setCustomerReported]: any = useState("")
    let [selectedProvider, setSelectedProvider]: any = useState("")
    let [selectedAssignee, setSelectedAssignee]: any = useState([])
    let [editSubject, setEditSubject] = useState(false)
    let [editDescription, setEditDescription] = useState(false)
    let [editedSubject, setEditedSubject] = useState("")
    let [editedDescription, setEditedDescription] = useState("")
    let [issueTypeSet, setissueTypeSet]: any = useState([]);
    let [systemTypeSet, setsystemTypeSet]: any = useState([]);
    let [statusSet, setstatusSet]: any = useState([]);
    let [prioritySet, setprioritySet]: any = useState([]);
    let [classificationSet, setclassificationSet]: any = useState([]);
    let [componentSet, setcomponentSet]: any = useState([]);
    let [channelSet, setchannelSet]: any = useState([]);
    let [newComment, setComment]: any = useState("")
    let [attachmentIdx, setAttachmentIdx]: any = useState(-1);
    let [dialogOpen, setDialogOpen] = useState(false)
    let [hover, setHover] = useState(false)
    let [subjectHover, setSubjectHover] = useState(false)
    let [descHover, setDescHover] = useState(false)
    let [attachmentsFile, setAttachmentsFile]: any = useState([]);
    let [sendEmailPopup, setSendEmailPoprup] = useState(false)
    let [ticketEmail, setTicketEMail]: any = useState([]);
    let [editorState, setEditorState]: any = useState(EditorState.createEmpty())
    let [editorHTML, setEditorHTML]: any = useState('')

    let providersSet = ["AT&T", "Aliv", "BTC", "Flow", "Orange", "TMobile", "Verizon", "Vodafone"]
    const statusArray = ["Open", "Closed", "ReOpened", "Escalated"]
    const AUTO_COMPLETE_NO_OPTIONS_TEXT = "No options";
    const K4ID_KEY = "k4Id";
    const TICKET_ID_KEY = "ticketId";
    let loggedInUser = getDataFromLocalStorage("loggedInEmail");
    let breadcrumbInfo = BC_TICKETS_VIEW;

    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_TICKETS !== authReducer.sideMenu.menuItem)) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_TICKETS };
      }

    const servicePortal = SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        : ""
    const viewTickets = SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
        ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
        : "";

    function entityMapper(entity) {
        if (entity.type === 'DIV') {
            return `<div>${entity.data.innerHTML}</div>`;
        }
        if (entity.type === 'TABLE') {
            return `<table>${entity.data.innerHTML}</table>`;
        }
        if (entity.type === 'TBODY') {
            return `<tbody>${entity.data.innerHTML}</tbody>`;
        }
        if (entity.type === 'TR') {
            return `<tr>${entity.data.innerHTML}</tr>`;
        }
        if (entity.type === 'TH') {
            return `<th>${entity.data.innerHTML}</th>`;
        }
        if (entity.type === 'TD') {
            return `<td>${entity.data.innerHTML}</td>`;
        }
        if (entity.type === 'STYLE') {
            return `<style>${entity.data.innerHTML}</style>`;
        }
        return '';
    }

    function entityMapperToComponent(entity) {
        if (entity.type === 'DIV') {
            return () => (
                <div dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'TABLE') {
            return () => (
                <table dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'TBODY') {
            return (
                <tbody dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'TR') {
            return () => (
                <tr dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'TH') {
            return () => (
                <th dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'TD') {
            return () => (
                <td dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
            );
        }
        if (entity.type === 'STYLE') {
            return () => <style>{entity.data.innerHTML}</style>;
        }

        return '';
    }

    function customChunkRenderer(nodeName, node) {
        const allowedNodes = [
            'div',
            'table',
            'tbody',
            'tr',
            'th',
            'td',
            'thead',
            'style',
        ];

        if (allowedNodes.includes(nodeName)) {
            return {
                type: nodeName.toString().toUpperCase(),
                mutability: 'MUTABLE',
                data: {
                    innerText: node.innerText,
                    innerHTML: node.innerHTML,
                },
            };
        }
        return null;
    }

    useEffect(() => {
        const info = {
            permission: {
                service: servicePortal,
                serviceFunction: viewTickets,
            },
            isMultiVessel: false,
            isSingleVessel: false,
            showOu: false,
            ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
        };
        updatePermission(info);
        if (ticketId) {
            // breadcrumb[3] = { title: ticketId };
            // setBreadcrumbInfo.current(breadcrumb);
            getTicketDetails(ticketId)
        }
    }, [ticketId])

    useEffect(() => {
        if (ticketDetails instanceof Array) return;
        setEditedSubject(ticketDetails.ticketSubject)
        setEditedDescription(ticketDetails.ticketDesc)
        getTicketDetailsFields()
        getTicketAttachments(ticketId)
        getTicketComments(ticketId);
        getTicketHistoryByTicketId(ticketId,k4id);

        let assignee = { "fullName": ticketDetails ? ticketDetails.assignee ? ticketDetails.assignee : "" : "", "k4EmpId": ticketDetails.assignedTo }
        setSelectedAssignee(assignee)
        setSelectedStatus(ticketDetails.ticketStatus)
        setSelectedIssue(ticketDetails.issueType)
        setSelectedSystemType(ticketDetails.systemType)
        setSelectedPriority(ticketDetails.priority)
        setSelectedClassification(ticketDetails.classification)
        setSelectedComponent(ticketDetails.component)
        setSelectedChannel(ticketDetails.channel)
        setCustomerReported(ticketDetails.customerReported ? "Yes" : "No")
        setSelectedProvider(ticketDetails.provider)

    }, [ticketDetails])



    const getTicketDetailsFields = () => {
        getTicketFields()
    }

    const openAttachmentInNewTab = (idx) => {
        setAttachmentIdx(idx)
        getSingleAttachmentsByTicketId(ticketDetails.k4Id, showAttachments[idx].attachmentId)
        addHtmlToEditor();

    }

    useEffect(() => {
        if (!_.isEmpty(attachment)) {
            if (attachment.type.startsWith("image/")) {
                setDialogOpen(true)
                // let w = window.open('about:blank');
                // let image = new Image();
                // image.src = attachment.url;
                // setTimeout(function () {
                //     w && w.document.write(image.outerHTML);
                // }, 0);
            } else {
                const link = document.createElement('a');
                link.href = attachment.url;
                link.setAttribute('download', showAttachments[attachmentIdx].fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                setAttachmentIdx(-1)
                setAttachments({})
            }

        }
    }, [attachment])

    const getTicketDetails = (ticketId: any) => {
        getTicketDetailsByTicketId(ticketId,k4id)
    }


    const getTicketAttachments = (ticketId: any) => {
        getTicketAttachmentsByTicketId(ticketId,k4id)
    }

    useEffect(() => {

        let data = ticketFields,
            issueTypeSet = [],
            systemTypeSet = [],
            statusSet = [],
            prioritySet = [],
            classificationSet = [],
            component = [],
            channel = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].type === 'issueType') {
                issueTypeSet = data[i].values;
            }
            if (data[i].type === 'systemType') {
                systemTypeSet = data[i].values.map((e) => {
                    return e.toUpperCase();
                });
            }
            if (data[i].type === 'priority') {
                prioritySet = data[i].values;
            }
            if (data[i].type === 'channel') {
                channel = data[i].values.sort();
            }
            if (data[i].type === 'ticketStatus') {
                statusSet = data[i].values;
            }
            if (data[i].type === 'classification') {
                classificationSet = data[i].values;
            }

            if (data[i].type === 'component') {
                component = data[i].values;
            }
        }


        setissueTypeSet(issueTypeSet)
        setsystemTypeSet(systemTypeSet)
        setstatusSet(statusSet)
        setprioritySet(prioritySet)
        setclassificationSet(classificationSet)
        setcomponentSet(component)
        setchannelSet(channel)

    }, [ticketFields])

    useEffect(() => {
        setAllAgentList([]);
        getTicketAllAgents();

    }, []);


    useEffect(() => {
        if (!_.isEmpty(authReducer.getTicketComments) && authReducer.getTicketComments.data) {
            setTicketComments(authReducer.getTicketComments.data);
            authReducer.getTicketComments = {};
        }

        if (!_.isEmpty(authReducer.getTicketDetails) && authReducer.getTicketDetails.data) {
            setTicketDetails(authReducer.getTicketDetails.data);
            authReducer.getTicketDetails = {};
        }

        if (!_.isEmpty(authReducer.getTicketHistory) && authReducer.getTicketHistory.data) {
            setTicketHistory(authReducer.getTicketHistory.data);
            authReducer.getTicketHistory = {};
        }

        if (!_.isEmpty(authReducer.getTicketAttachments) && authReducer.getTicketAttachments.data) {
            setShowAttachments(authReducer.getTicketAttachments.data);
            authReducer.getTicketAttachments = {};
        }

        if (!_.isEmpty(authReducer.getTicketFields) && authReducer.getTicketFields.data) {
            setTicketFields(authReducer.getTicketFields.data);
            authReducer.getTicketFields = {};
        }

        if (!_.isEmpty(authReducer.getTicketAllAgents) && authReducer.getTicketAllAgents.data
            && Array.isArray(authReducer.getTicketAllAgents.data) && authReducer.getTicketAllAgents.data.length > 0) {

            setAllAgentList([]);
            setAllAgentList(authReducer.getTicketAllAgents.data);
            authReducer.getTicketAllAgents = {};
        }

        if (!_.isEmpty(authReducer.getSingleTicketAttachments) && authReducer.getSingleTicketAttachments.data) {
            if (authReducer.getSingleTicketAttachments) {

                let url = window.URL.createObjectURL(
                    new Blob([authReducer.getSingleTicketAttachments.data], {
                        // fileName: "downloadedFile.png",
                        type: authReducer.getSingleTicketAttachments.headers['content-type'],
                    }));

                let attachment = {}
                attachment["type"] = authReducer.getSingleTicketAttachments.headers['content-type']
                attachment["url"] = url
                setAttachments(attachment);
            }
            authReducer.getSingleTicketAttachments = {};
        }
    }, [authReducer.getTicketComments, authReducer.getTicketHistory, authReducer.getTicketAllAgents, authReducer.getTicketAttachments, authReducer.getSingleTicketAttachments, authReducer.getTicketFields, authReducer.getTicketDetails])

    useEffect(()=>{
        if (!_.isEmpty(authReducer.updateTicket) && authReducer.updateTicket.data) {
            getTicketDetails(ticketId)
        }

    },[authReducer?.updateTicket])


    const updateDetails = () => {
        let payload = {}
        let isEdited = false
        payload[K4ID_KEY] = k4id;
        payload[TICKET_ID_KEY] = ticketId;

        if (editSubject) {
            if(editedSubject.trim() !== ticketDetails.ticketSubject){
                isEdited = true;
                setEditSubject(false)
                payload["ticketSubject"] = editedSubject;
            }
        }

        if (editDescription) {
            if(editedDescription.trim() !== ticketDetails.ticketDesc){
                isEdited = true;
                setEditDescription(false)
                payload["ticketDesc"] = editedDescription;
            }
        }

        if (selectedstatus != "" && selectedstatus != ticketDetails.ticketStatus) {
            isEdited = true;
            payload["ticketStatus"] = selectedstatus;
        }

        if (selectedChannel != "" && selectedChannel != ticketDetails.channel) {
            isEdited = true;
            payload["channel"] = selectedChannel;
        }

        if (selectedIssue != "" && selectedIssue != ticketDetails.issueType) {
            isEdited = true;
            payload["issueType"] = selectedIssue;
        }

        if ( customerReported != "" && !(customerReported === "No" && false === ticketDetails.customerReported) && !(customerReported === "Yes" && true === ticketDetails.customerReported)) {
            isEdited = true;
            payload["customerReported"] = customerReported;
        }

        if (selectedComponent != "" && selectedComponent != ticketDetails.component) {
            isEdited = true;
            payload["component"] = selectedComponent;
        }

        if (selectedProvider != "" && selectedProvider != ticketDetails.provider) {
            isEdited = true;
            payload["provider"] = selectedProvider;
        }

        if (selectedPriority != "" && selectedPriority != ticketDetails.priority) {
            isEdited = true;
            payload["priority"] = selectedPriority;
        }

        if (selectedSystemType != "" && selectedSystemType != ticketDetails.systemType) {
            isEdited = true;
            payload["systemType"] = selectedSystemType;
        }

        if (selectedAssignee?.k4EmpId && selectedAssignee?.k4EmpId !== ticketDetails?.assignedTo) {
            isEdited = true;
            payload["assignedTo"] = selectedAssignee.k4EmpId;

        }

        if (selectedClassification != "" && selectedClassification != ticketDetails.classification) {
            isEdited = true;
            payload["classification"] = selectedClassification;
        }



        isEdited && updateTicket(payload);
    }


    const handleDiscard = () => {
        let assignee = { "fullName": ticketDetails ? ticketDetails.assignee ? ticketDetails.assignee : "" : "", "k4EmpId": ticketDetails.assignedTo }
        setSelectedAssignee(assignee)
        setSelectedStatus(ticketDetails.ticketStatus)
        setSelectedIssue(ticketDetails.issueType)
        setSelectedSystemType(ticketDetails.systemType)
        setSelectedPriority(ticketDetails.priority)
        setSelectedClassification(ticketDetails.classification)
        setSelectedComponent(ticketDetails.component)
        setSelectedChannel(ticketDetails.channel)
        setCustomerReported(ticketDetails?.customerReported ? "Yes" : "No")
        setSelectedProvider(ticketDetails?.provider)
        let _q = getDecodeURI(location?.search);
        _q.status = "Open";
        history.push({ pathname: '/tickets', search: `?${getEncodedURI(_q)}` });
          
    }

    const handleChange = (event) => {
        if (event.target.value !== undefined) {
            setSelectedStatus(event.target.value);
        }
    };

    const doComment = () => {
        if (!_.isEmpty(editorHTML) && !_.isEmpty( editorState.getCurrentContent().getPlainText()) && editorState.getCurrentContent().getPlainText().trim() !== "") {
            let payload = {
                "k4Id":k4id,
                "ticketId": ticketId,
                "comment": editorState.getCurrentContent().getPlainText(),
                "htmlComment": editorHTML
            }
            sendComment(payload);
            // setComment("")
            setEditorHTML('')
            setEditorState('')
            setTimeout(() => {
                getTicketComments(ticketId);
            }, 500)
        }
    }

    const getTicketComments = (ticketId: any) => {
        getTicketCommentsByTicketId(ticketId,k4id);
    }

    const getCommentorShortName = (fullname: any) => {
        let shortname = "";
        let nameArray = fullname.split(" ");
        if (nameArray.length > 0) {
            for (let i = 0; i < nameArray.length; i++) {
                // show only two letters ex: venkat sai desai show it as VS
                if (i < 2 && nameArray[i].length > 0) {
                    shortname += nameArray[i][0];
                }
            }
        } else {
            shortname = fullname.length > 0 ? fullname[0] : "";
        }
        return shortname;
    }

    const sectionStyle = (ind) => {
        return ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2px",
            background: selectedTab === ind ? "linear-gradient(1deg, #a7b7ec, transparent)" : selectedTab != ind && hover ? "#f1f1f1" : "white",
            height: "30px", width: "70%",
            borderRadius: "4px",
            cursor: "pointer"
        })
        // background: hover ? "" : "",


    };

    const setSubjectStyle = () => {
        return ({
            background: subjectHover && !editSubject ? "#f1f1f1" : "white",
            padding: !editSubject ? "10px 20px" : "0px", borderBottom: editSubject ? "white" : "2px solid #DCE8F3",
            margin: "5px 10px",
            borderRadius: "5px"
        })
    };

    const setDescriptionStyle = () => {
        return ({
            background: descHover && !editDescription ? "#f1f1f1" : "white",
            padding: !editDescription ? "10px 20px" : "0px",
            // height: "80px",
            margin: "5px 10px",
            borderRadius: "5px"

        })
    };

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            saveAttachment(file);
        });

    }, []);


    useEffect(() => {
        if(attachmentsFile.length === 0) return;
        uploadAttachments(ticketId, ticketDetails.k4Id);
        setTimeout(() => {
            getTicketAttachments(ticketId)
        }, 2000);
    }, [attachmentsFile])



    const uploadAttachments = (ticketId, k4Id) => {
        if (attachmentsFile.length > 0 && k4id) {
            //  formdata object
            let formData = new FormData();
            //  append the values with key, value pair
            formData.append(K4ID_KEY, k4id);
            formData.append(TICKET_ID_KEY, ticketId);
            for (let i = 0; i < attachmentsFile.length; i++) {
                let lastModified = new Date().getTime();
                let fileNameWithExtension = attachmentsFile[i]["name"] && attachmentsFile[i]["name"].lastIndexOf(".") > 0 ? attachmentsFile[i]["name"] : "image.png";
                let lastIndexOfDot = fileNameWithExtension.lastIndexOf(".");
                let fileExtension = fileNameWithExtension.slice(lastIndexOfDot + 1);
                let fileName = fileNameWithExtension.slice(0, lastIndexOfDot);
                let fileNameWithTimeStamp = fileName + "_" + lastModified + "." + fileExtension;

                // append each file to formdata with file name
                formData.append(
                    "file",
                    attachmentsFile[i],
                    fileNameWithTimeStamp
                );
            }
            // send fordata to attachment API
            addAttachments(formData);
        }
        setAttachmentsFile([])
    };

    const saveAttachment = (file) => {
        // save attachment as file
        setAttachmentsFile(prevState => [...prevState, file]);
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        multiple: false,
        accept: ["image/png", "image/jpg", "image/jpeg", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-outlook", "application/pdf"]
    });


    const fnSendEmail = async () => {

        if (ticketEmail.length == 0) {
            return
        }

        let payload = {
            ticketId: ticketId,
            k4Id: k4id,
            emails:
                ticketEmail.length > 0
                    ? ticketEmail.split(',')
                    : [],
        };

        sentTicketEmail(payload)

    };

    useEffect(() => {

        setTicketEMail([])
        setSendEmailPoprup(false)
        authReducer.sendTicketEmail = {};

    }, [authReducer.sendTicketEmail])


    // const onEditorStateChange = (editorState) => {
    //     // console.log(editorState)
    //         setEditorState(editorState)
    //   };

    const onEditorStateChange = (editor) => {
        const editorHTML = draftToHtml(
            convertToRaw(editor.getCurrentContent()),
            null,
            false,
            entityMapper
        );
        // this.setState({ editorState: editor, editorHTML }, () => {});
        setEditorState(editor)
        setEditorHTML(editorHTML)
    };

    const addHtmlToEditor = () => {
        const contentBlock = htmlToDraft(
            editorHTML,
            customChunkRenderer
        );
        let editor;
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            editor = EditorState.createWithContent(contentState);
        } else {
            editor = EditorState.createEmpty();
        }
        setEditorState(editor)

    };
    
    const doNavigateToTickets = (path) => {
        let params: any = getDecodeURI(location?.search);
        if(path.endsWith("alerts") && params?.alert_id){
          delete params.alert_id;
        }
        history.push({ pathname: path, search: `?${getEncodedURI(params)}` });
      }
    
      const gotoTickets = () => {
        doNavigateToTickets(`/tickets`);
      }

    return (
        <div className="Form">
            <CommonFilters loading={authReducer?.loading || authReducer?.userPreferencesLoading} showSummaryPeriod={false} showWantType={false} />
            <Grid container style={{ width: "100%", padding: "110px 0px 0px 20px" }} >
                <div className="margin-left15 align-back" onClick={gotoTickets}>
                    <img src={BackArrow} className="downloadImg" title="Back"/>
                </div>
                <Grid container className="ticket-view__contianer">
                <Grid item xs={12} sm={12} md={7} lg={7} >
                    <Grid className="t_border subject">
                        <Grid item style={{ padding: "20px 10px 0px 20px" }}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item >
                                    <Typography variant="h5" className="font12 transformUpper">
                                        Ticket - {ticketId ? ticketId : ""} ({ticketDetails ? ticketDetails.vessel : ""})
                                    </Typography>
                                </Grid>
                                <Grid item style={{ color: "#676984", display: "flex", alignItems: "center" }}>
                                    <Typography variant="h5" className="font12 transformUpper">
                                        Created By: {ticketDetails ? ticketDetails.fullName : ""}
                                    </Typography>
                                    <img className="icon-email" title="Send Email" src={TicketEmail} onClick={() => setSendEmailPoprup(true)} />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={setSubjectStyle()} onMouseEnter={() => setSubjectHover(true)} onMouseLeave={() => setSubjectHover(false)} >
                            {editSubject === true ?

                                <TextField style={{ width: "100%" }} inputProps={{ style: { height: "0px" } }} value={editedSubject} onBlur={(e) => { updateDetails() }} onKeyDown={(e) => { if (e.key === "Escape") { setEditSubject(false) } }} onKeyPress={(e) => { if (e.key === 'Enter') { updateDetails() } }} onChange={(event) => setEditedSubject(event.currentTarget.value)}></TextField>
                                :
                                <Typography variant="h3" className="font15 bold" onClick={(e) => setEditSubject(true)}>
                                    {editedSubject}
                                </Typography>


                            }
                        </Grid>
                        <Grid item style={setDescriptionStyle()} onMouseEnter={() => setDescHover(true)} onMouseLeave={() => setDescHover(false)} className="ticket-view__description-container">
                            {editDescription === true ?
                                <TextField multiline style={{ width: "100%" }} onKeyDown={(e) => { if (e.key === "Escape") { setEditDescription(false) } }} value={editedDescription} onBlur={(e) => { updateDetails() }} onKeyPress={(e) => { if (e.key === 'Enter') { updateDetails() } }} onChange={(event) => setEditedDescription(event.currentTarget.value)}></TextField>
                                :

                                <Typography variant="h3" onClick={(e) => setEditDescription(!editDescription)} style={{ fontSize: "15px", wordWrap: 'break-word' }}>
                                    {editedDescription}
                                </Typography>

                            }
                        </Grid>
                        <Grid item style={{ padding: "10px 20px 20px 20px", color: "#273050" }}>
                            <Grid container justifyContent={"space-between"}>

                                <Typography variant="h3" className="font15 bold" >
                                    Attachments ({showAttachments.length})
                                </Typography>
                                {/* <img className="icon-cursor" title="Add Attachment" src={Plus} onClick={() => handleAddAttachment()} /> */}
                                <div  {...getRootProps({})} suppressContentEditableWarning={true}  >
                                    <input {...getInputProps()} />
                                    <div><img src={Plus} className="icon-cursor" /></div>
                                </div>
                            </Grid>
                            <Grid item>
                                {showAttachments.length > 0
                                    ?
                                    <div style={{ display: "flex", paddingBottom: "5px", overflowX: "auto" }}>
                                        {
                                            showAttachments && showAttachments.slice(0).map((file: any, idx) => (
                                                file.mimeType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.mimeType == "application/vnd.ms-excel" ?
                                                    <div className="image_conatiner">
                                                        <img className="image-placeholder" title={file.fileName} src={Excel} onClick={() => openAttachmentInNewTab(idx)}></img>
                                                    </div>
                                                    :
                                                    file.mimeType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                        <div className="image_conatiner">
                                                            <img className="image-placeholder" title={file.fileName} src={Word} onClick={() => openAttachmentInNewTab(idx)}></img>
                                                        </div>
                                                        :
                                                        file.mimeType == "application/pdf" ?
                                                            <div className="image_conatiner">
                                                                <img className="image-placeholder" title={file.fileName} src={pdf} onClick={() => openAttachmentInNewTab(idx)}></img>

                                                            </div>
                                                            :
                                                            <div className="image_conatiner">
                                                                <img className="image-placeholder" title={file.fileName} src={ImagePlaceHolder} onClick={() => openAttachmentInNewTab(idx)}></img>
                                                            </div>

                                            ))
                                        }
                                    </div>

                                    : ""
                                }

                            </Grid>

                            {/* <div style={{height:"310px",overflowY:"scroll"}}> */}
                            <Grid item style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px" }}>
                                <div style={{ display: 'flex', justifyContent: "space-around", padding: '5px', borderRadius: '10px', width: "30%" }}>
                                    {TABs.map((tab, ind) => {
                                        return (
                                            <div key={ind}
                                                onClick={() => { setSelectedTab(ind) }} className="font10" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={sectionStyle(ind)}>
                                                <Typography style={{ textTransform: "uppercase", fontWeight: "bold", color: selectedTab === ind ? "#2860B4" : "#676984", fontSize: "12px" }}>
                                                    {tab}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </Grid>
                            {selectedTab === 1 ?
                                <Grid item>
                                    <div className="ticket-view__history-container">
                                    {ticketHistory && ticketHistory.length > 0 ?
                                        ticketHistory.map((history, idx) => {

                                            return (
                                                <div>
                                                    <Grid container justifyContent={"space-between"} style={{ padding: "10px 20px 0px 20px" }}>
                                                        <Grid item className="flex">
                                                            <div className="t_border bullet" ></div>
                                                            <Typography variant="h3" className="font12 bold">
                                                                {convertDateTimeIntoTimezone(history.updateDate, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item style={{ color: "#676984" }}>
                                                            <Typography variant="h5" className="font12 transformUpper">
                                                                {history.fullName}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ padding: "5px 50px" }}>
                                                        <Typography variant="h4" style={{ fontSize: "12px", wordWrap: 'break-word' }}>
                                                            {history.updateMsg}
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                            )

                                        })
                                        :
                                        <div className="flex justyfy-center bold" style={{ height: "100px", alignItems: "center", }}>
                                            No Ticket History
                                        </div>
                                    }
                                    </div>
                                </Grid>
                                :
                                <Grid item >
                                    <div style={{ display: "flex", padding: "10px 0px", alignItems: "center" }}>
                                        <Tooltip title={loggedInUser ? loggedInUser : ""}>
                                            <div className="commentor">
                                                <div>{loggedInUser ? getCommentorShortName(loggedInUser) : ""}</div>
                                            </div>
                                        </Tooltip>
                                        {/* <TextField multiline fullWidth value={newComment} placeholder="Enter new comment" onKeyPress={(e) => { if (e.key === 'Enter') { doComment() } }} onKeyDown={(e) => { if (e.key === "Escape") { setComment("") } }} onChange={(event) => setComment(event.currentTarget.value)}>
                                        </TextField> */}

                                        <div className='editor' style={{width:"-webkit-fill-available"}}>
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'embedded', 'emoji'],
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                    image: { inDropdown: true },
                                                    // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: false } },
                                                }}
                                                editorStyle={{
                                                    border: '1px solid #dcdcdc',
                                                    borderRadius: '4px',
                                                    padding: '10px',
                                                    backgroundColor: 'white',
                                                }}
                                            />
                                        </div>

                                    </div>
                                    {!_.isEmpty(editorHTML) || editorHTML !== '' ?
                                        <div style={{ paddingLeft: "60px" }}>
                                            <img className="icon-cursor2" title="Save Comment" src={OK} onClick={() => doComment()} />
                                            <img className="icon-cursor2" title="Discard Comment" src={Discard} onClick={() => setComment("")} />
                                        </div>
                                        : null
                                    }

                                    <div className="ticket-view__comments-container">
                                    {commentArray.length > 0 ?
                                        commentArray.map((comment, idx) => {
                                            return (
                                                <div>
                                                    <Grid container justifyContent={"space-between"} style={{ padding: "10px 20px 0px 20px" }}>
                                                        <Grid item style={{ display: "flex" }}>
                                                            <div className="t_border bullet" ></div>
                                                            <Typography variant="h3" className="font12 bold">
                                                                {convertDateTimeIntoTimezone(comment.commentDate, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item style={{ color: "#676984" }}>
                                                            <Typography variant="h5" className="font12 transformUpper">
                                                                {comment.commenter}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ padding: "0px 80px", wordWrap: 'break-word' }}>
                                                        {/* <Typography variant="h4">
                                                            {comment.comment}
                                                        </Typography> */}
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: comment.htmlComment
                                                                    ? comment.htmlComment
                                                                    : comment.comment,
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                            )

                                        })
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center", fontWeight: "bold" }}>
                                            No Ticket Comments
                                        </div>
                                    }
                                    </div>
                                </Grid>}


                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid className="t_border subject ticket-view__details-container">
                        <Grid item style={{ marginBottom: "30px" }}>
                            <Typography variant="h3" className="font15 bold">
                                Details
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent={"space-around"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedstatus}
                                            label="Status"
                                            onChange={(e) => {
                                                setSelectedStatus(e.target.value)

                                            }}
                                        >
                                            {statusSet && statusSet.length > 0 ?
                                                statusSet.map((status, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={status}>{status}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Channel</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedChannel}
                                            label="Channel"
                                            onChange={(e) => { setSelectedChannel(e.target.value) }}
                                        >
                                            {channelSet && channelSet.length > 0 ?
                                                channelSet.map((channel, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={channel}>{channel}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"space-around"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Issue Type</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedIssue}
                                            label="Issue Type"
                                            onChange={(e) => { setSelectedIssue(e.target.value) }}
                                        >
                                            {issueTypeSet && issueTypeSet.length > 0 ?
                                                issueTypeSet.map((issue, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={issue}>{issue}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Customer Reported</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={customerReported}
                                            label="Customer Reported"
                                            onChange={(e) => { setCustomerReported(e.target.value) }}
                                        >
                                            {statusArray && statusArray.length > 0 ?
                                                ["Yes", "No"].map((customer, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={customer}>{customer}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"space-around"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Component</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedComponent}
                                            label="Component"
                                            onChange={(e) => { setSelectedComponent(e.target.value) }}
                                        >
                                            {componentSet && componentSet.length > 0 ?
                                                componentSet.map((component, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={component}>{component}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Providers</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedProvider}
                                            label="Provider"
                                            onChange={(e) => { setSelectedProvider(e.target.value) }}
                                        >
                                            {providersSet && providersSet.length > 0 ?
                                                providersSet.map((provider, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={provider}>{provider}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"space-around"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>System Type</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedSystemType}
                                            label="Status"
                                            onChange={(e) => { setSelectedSystemType(e.target.value) }}
                                        >
                                            {systemTypeSet && systemTypeSet.length > 0 ?
                                                systemTypeSet.map((systemType, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={systemType}>{systemType}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Priority</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedPriority}
                                            label="Priority"
                                            onChange={(e) => { setSelectedPriority(e.target.value) }}
                                        >
                                            {prioritySet && prioritySet.length > 0 ?
                                                prioritySet.map((priority, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={priority} >{priority}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"space-around"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <Autocomplete
                                            size="small"
                                            style={{ width: "210px" }}
                                            noOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT}
                                            options={allAgentList}
                                            value={selectedAssignee}
                                            defaultValue={selectedAssignee}
                                            getOptionLabel={(option: any) => option.hasOwnProperty("fullName") ? option["fullName"] : "Unassigned"}
                                            PaperComponent={({ children }) => (
                                                <Paper className="fontCSS fontSize14px fontWeightNormal" >{children}</Paper>
                                            )}
                                            onChange={(e, newValue: any) => {
                                                setSelectedAssignee(newValue);
                                            }}
                                            renderTags={(value, getTagProps) => {
                                                return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "fullName");
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Search assignee" label="Assignee" />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item style={{ margin: "10px" }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Classification</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "210px" }}
                                            value={selectedClassification}
                                            label="classification"
                                            onChange={(e) => { setSelectedClassification(e.target.value) }}
                                        >
                                            {classificationSet && classificationSet.length > 0 ?
                                                classificationSet.map((classification, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={classification}>{classification}</MenuItem>
                                                    )
                                                }) :
                                                null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"}>
                                <Grid item style={{ margin: "10px" }}>
                                    <Button className="dialog_okButton" onClick={() => handleDiscard()} >Discard</Button>

                                </Grid>
                                <Grid item style={{ margin: "10px" }}>
                                    <Button className="dialog_okButton" onClick={() => updateDetails()} >Update</Button>

                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid></Grid>
            {sendEmailPopup ? (
                <Dialog
                    open={true}
                    // TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                >
                    <DialogTitle style={{ padding: '10px', display: "flex", justifyContent: "space-between", background: "#264C86", alignItems: "center" }}>
                        <Typography variant="h2" style={{ color: "#ffffff", fontWeight: "500", fontSize: "16px" }}>
                            Send Ticket Details E-Mail
                        </Typography>
                        <IconButton
                            edge='start'
                            color='inherit'
                            style={{ float: 'right', padding: '3px', background: "#ffffff" }}
                            onClick={() => {
                                setSendEmailPoprup(false)
                            }}
                            aria-label='close'
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            width: '500px',
                        }}
                    >
                        <div >
                            <Grid
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    padding: "20px 0px 10px 0px"
                                }}
                            >
                                <Typography className="typos">
                                    Send Ticket to
                                </Typography>
                                <div>
                                    <FormControl style={{ margin: '1px', width: '100%' }}>
                                        <TextField
                                            id='outlined-multiline-static'
                                            multiline
                                            rows='3'
                                            variant='outlined'
                                            value={ticketEmail}
                                            InputProps={{
                                            }}
                                            onChange={(event) => {
                                                setTicketEMail(event.target.value);
                                            }}
                                            style={{ minWidth: '82%' }}
                                        />
                                    </FormControl>
                                    <Typography className="typo2">
                                        Please enter multiple email addresses separated by a comma.
                                    </Typography>
                                </div>
                            </Grid>

                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <button
                                    type='button'
                                    className="send-email-button"
                                    onClick={() => {
                                        setSendEmailPoprup(false)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    className="send-email-button"
                                    onClick={() => {
                                        fnSendEmail();
                                    }}
                                >
                                    Send
                                </button>
                            </div>


                        </div>
                    </DialogContent>
                </Dialog>
            ) : null}
            <Dialog className="menuScroll ticket-attachment-popup" open={dialogOpen} fullWidth maxWidth="md" style={{ alignItems: "stretch" }} >
                <DialogTitle className="dialog_title image-filename">
                    <span>{attachmentIdx > 0 && showAttachments[attachmentIdx] ? showAttachments[attachmentIdx].fileName : ""}</span>
                    <img src={close} className="close" title="Close" onClick={() => {
                            setAttachmentIdx(-1)
                            setAttachments({})
                            setDialogOpen(false)
                        }}
                    />
                </DialogTitle>
                <DialogContent className="image-content">
                    <img src={attachment.url}/>
                </DialogContent>
            </Dialog>

            {/* {
                authReducer.loading ? (
                    <CustomLoader
                        showLoader={true}
                        loadingText={
                            loaders["loadingText"]
                                ? loaders["loadingText"]
                                : loadingTextOnUI
                        }
                    />
                ) : null
            } */}
        </div>
    )

}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
        sendComment,
        sentTicketEmail,
        addAttachments,
        getTicketAllAgents,
        getTicketFields,
        updateTicket,
        getTicketHistoryByTicketId,
        getTicketDetailsByTicketId,
        getTicketCommentsByTicketId,
        getSingleAttachmentsByTicketId,
        getTicketAttachmentsByTicketId,
        searchTicketAssigneeByName,
        updatePermission
    })(TicketView)
);