import { useEffect, useRef, useState } from "react";
import { Box, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Pagination } from "../../Pagination";
import './backupConfig.css';
import _ from "lodash";
import { backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, copyConfigToManualBackup } from "../../../actions/Users/authenticateInventory";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { toast } from "react-toastify";
import OK from "../../../asset/image/Complete.svg";
import Discard from "../../../asset/image/Discard.svg";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const BackupConfig = (props) => {
    const { authReducer, backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, history, location, copyConfigToManualBackup } = props;
    let _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 15;
    const [k4Ids, setK4ids] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [backupTableData, setBackupTableData] = useState<any>([]);
    const ENTER_KEY_CODE = "Enter";
    const [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
    const NAME = 'config_name';
    const SITE_NAME = 'site_name';
    const DEVICE_ID = 'device_id';
    const SOFTWARE_VERSION = 'software_version';
    const CREATE_TIME = 'create_time';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : CREATE_TIME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [totalCount, setTotalCount] = useState(0);
    const [downloadConfig, setDownloadConfig] = useState(false);
    // show only Auto backups
    const IS_MANUAL_BACKUP = false
    const [configNameUpdateRowNo, setConfigNameUpdateRowNo] = useState<Number>(-1)
    const [updatedConfigName, setUpdatedConfigName] = useState<String>("")
    const searchTimeoutRef = useRef<NodeJS.Timeout>();


    useEffect(() => {
        if(!_.isEmpty(authReducer.copyConfigToManualBackup)){
            k4Ids && backupConfigData(k4Ids, page, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
            authReducer.copyConfigToManualBackup = {}
        }
    },[authReducer.copyConfigToManualBackup])

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        if(vessels?.length == 0) {
            setBackupTableData([]);
            setPage(0);
            setLimit(0);
            setTotalCount(0);
            return;
        }
        const k4Ids = vessels && vessels.length > 0 ? `${vessels.map((item:any) => item.id).join(",")}` : '';
        setK4ids(k4Ids);
        const Page = 1;
        setPage(1);
        setLimit(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
        k4Ids && backupConfigData(k4Ids, Page, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if(!_.isEmpty(authReducer.backupConfigData)) {
            const data = authReducer?.backupConfigData?.result;
            setBackupTableData(data);
            setTotalCount(authReducer?.backupConfigData?.result_info?.total_count);
            authReducer.backupConfigData = {}
        }
    }, [authReducer.backupConfigData])

    const rowsOption = [10, 15, 25, 50, 100];

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, value, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, Limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
            let params: any = getDecodeURI(location?.search);
            params.search = value;
            delete params.page;
            const Page = 1;
            setPage(1);
            doNavigate(params);
            k4Ids && backupConfigData(k4Ids, Page, limit, value, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    };

    const closeSearch = () => {
        setSearchValue("");
        let params: any = getDecodeURI(location?.search);
        searchValue ? params.search = searchValue: delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, limit, '', sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, limit, searchValue, field, order, '', '', '', IS_MANUAL_BACKUP);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order);
        }
      };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order);
      };

    useEffect(() => {
        if(!_.isEmpty(authReducer.inventoryDownloadConfig) && downloadConfig) {
          const url = authReducer?.inventoryDownloadConfig?.result;
     
           if(authReducer?.inventoryDownloadConfig?.success == true) {
            window.open(url);
            clearInventoryConfigDownload();
           } else if (!authReducer?.inventoryDownloadConfig?.success || authReducer?.inventoryDownloadConfig?.data?.success == false) {
           toast.error('Something went wrong, unable to download the file..', {
             position: toast.POSITION.BOTTOM_LEFT,
           });
          }
          authReducer.inventoryDownloadConfig = {};
         }
     },[authReducer?.inventoryDownloadConfig])
     
    const handleDownloadConfig = (row) => {
       setDownloadConfig(true);
       const siteId = row?.site_id;
       const deviceId = row?.device_id;
       const configId = row?.id
       inventoryDownloadConfig(siteId, deviceId, configId)
     }

    const saveConfigName = (row) => {
        copyConfigToManualBackup(row.site_id, row.device_id, row.id, updatedConfigName)
        closeConfigNameUpdateOption()
    }

    const closeConfigNameUpdateOption = () => {
        setConfigNameUpdateRowNo(-1)
        setUpdatedConfigName("")
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
      }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }
    
      const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
          searchTimeoutRef.current = undefined;
          doSearch(e.target.value);
        }, 1000)
      }

    return (
        <div className="backup-config-container">
            <Grid container spacing={0.5} className="golden-config-default-container">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={2} sm={2} md={2} lg={2}> 
                        <Box>
                            {<TextField
                                id="outlined-basic"
                                classes={{ root: "input-box-def-conf" }}                                
                                variant="outlined"
                                placeholder="Type something"
                                size="small"
                                value={searchValue}
                                onChange={handleOnSearchChange}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue && <SearchIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchValue && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={totalCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard backup-config-table-container">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow config-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, NAME)}>Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, NAME, ASC_ORDER)} />
                                                <img src={sort === NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>Site Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_ID)}>DID</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, ASC_ORDER)} />
                                                <img src={sort === DEVICE_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, SOFTWARE_VERSION)}>Software Version</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SOFTWARE_VERSION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SOFTWARE_VERSION, ASC_ORDER)} />
                                                <img src={sort === SOFTWARE_VERSION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SOFTWARE_VERSION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATE_TIME)}>Creation Time</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CREATE_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATE_TIME, ASC_ORDER)} />
                                                <img src={sort === CREATE_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CREATE_TIME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != backupTableData.length ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        backupTableData && backupTableData.map((row, i) =>
                                            <TableRow key={i} className="usageData-tabelRow config-backup-tabe-row">
                                                <TableCell className="usagedata-tableCell config-left-align selected-config">
                                                    {
                                                        i !== configNameUpdateRowNo ?
                                                            <div className="config-name">
                                                                <span onClick={() => handleDownloadConfig(row)}>{row.config_name}</span>
                                                                <span title="Copy to backup configuration" onClick={() => { setConfigNameUpdateRowNo(i); setUpdatedConfigName(row.config_name) }}>
                                                                    <ContentCopyOutlinedIcon className="copy-icon" />
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="update-config-name">
                                                                <TextField inputProps={{ style: { height: "0px" } }} label="Manual config name" value={updatedConfigName} onChange={(e) => setUpdatedConfigName(e.target.value)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            saveConfigName(row)
                                                                        }
                                                                    }}
                                                                ></TextField>
                                                                <img className="icon-cursor2" title="Copy" src={OK} onClick={() => saveConfigName(row)} />
                                                                <img className="icon-cursor2" title="Cancel" src={Discard} onClick={() => { closeConfigNameUpdateOption() }} />
                                                            </div>
                                                    }
                                                </TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.site_name}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.device_id}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.software_version}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{convertDateTimeIntoTimezone(row.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
        backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, copyConfigToManualBackup
    })(BackupConfig)
);