import { api } from '../../../../../src/server/request';
import { fetchDataRequest, fetchDataSuccess, fetchTowerDetailsSuccess, fetchDataError, fetchVesselNameSuccess, fetchVesselVoyageSuccess, fetchVesselKpiDataSuccess, fetchCarrierDataSuccess, fetchCarrierDataInnerLayerSuccess, fetchVesselCurrentLocationSuccess, fetchVesselMetricsInfoSuccess, fetchUniqueCarrierSuccess, fetchVesselVoyageRequest, fetchVesselKpiDataRequest, fetchSelctedVesselVoyageSuccess, fetchHexSinrHistoSuccess, fetchHexRssiHistoSuccess, fetchHexSpeedHistoSuccess, fetchVesselCurrentLocationRequest, setApiErrors, deleteApiError, fetchVsatCoverageSuccess, fetchVsatCoverageInnerLayerDataSuccess, fetchVSATHistoSuccess, fetchVSATTxPowerHistoSuccess, fethcVSATAllHexMetricsSuccess, clearMapErros, fetchGetEdgeEventsSucesss, fetchVesselDataRateSuccess, fetchVesselDataRTTSuccess, fetchUniqueVsatCarrierSuccess, fetchStarLinkHistoSucesss, fetchStarLinkLatencyHistoSucesss, fetchStarLinkSpeedHistoSucesss, fetchStarLinkDropPercentageHistoSucesss,
  fetchUniqueCarrierRequest, fetchHexSpeedHistoRequest, fetchHexRssiHistoRequest, fetchHexSinrHistoRequest, fetchUniqueVsatCarrierRequest, fetchStarLinkLatencyHistoRequest, fetchStarLinkSpeedHistoRequest, fetchStarLinkDropPercentageHistoRequest, fetchSelctedVesselVoyageRequest, fetchTerminalCurrentLocationSuccess, fetchTerminalCurrentLocationRequest, fetchSelctedSiteServiceLineData, fetchSelctedSiteServiceLineDataSuccess, 
  getAllStarlinkServiceLinesRequest, getAllStarlinkServiceLinesSeccess, getStarlinkServiceLineAndSitesCount, getStarlinkServiceLineAndSitesLoader} from "./action";
  import { API_URL } from '../../../../config.js';

export const getVesselRSSIandSINR = (selctedVessel: any, vesselStartTime: any, vesselEndTime: any, chartInterval: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `RSSI_SINR_2`;
  return (dispatch: any) => {
    dispatch(fetchVesselKpiDataRequest())
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "chart",
      "parameters": {
        "selctedVessel": selctedVessel,
        "vesselStartTime":vesselStartTime,
        "vesselEndTime":vesselEndTime,
        "chartInterval": chartInterval
      },
      "named":true,
      "groupBy": "interfaceDeviceAlias",
      "tsColumn": "tb",
      "series": "sinrAvg,rssiAvg"
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchVesselKpiDataSuccess(res));
        } else {

        }
      })
      .catch((err) => {
        console.log(err)
        console.log("error");
      });
  }
}

export const getVesselRATE = (selctedVessel: any, vesselStartTime: any, vesselEndTime: any, chartInterval: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `VESSEL_SPEED_2`;
  return (dispatch: any) => {
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "chart",
      "named": true,
      "parameters": {
        "selctedVessel": selctedVessel,
        "vesselStartTime": vesselStartTime,
        "vesselEndTime": vesselEndTime,
        "chartInterval": chartInterval
      },
      "groupBy": "interfaceDeviceAlias", "tsColumn": "tb", "series": "rateAvg"
    })
    )
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchVesselDataRateSuccess(res));

        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getVesselRTT = (selctedVessel: any, vesselStartTime: any, vesselEndTime: any, chartInterval: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `VESSEL_RTT_2`;
  return (dispatch: any) => {
    api.postRequestOut(`${apiUrl}`, JSON.stringify({ query: query, "format": "chart",
    "named": true,
    "parameters": {
      "selctedVessel": selctedVessel,
      "vesselStartTime":vesselStartTime,
      "vesselEndTime":vesselEndTime,
      "chartInterval": chartInterval
    },
     "groupBy": "interfaceDeviceAlias", "tsColumn": "tb", "series": "rttAvg" 
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchVesselDataRTTSuccess(res));

        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

const getIntervalInFormat:any = (interval:any) => {
  switch (interval) {
    case "5m": return "5 MINUTE"
    case "15m": return "15 MINUTE";
    case "20m": return "20 MINUTE"
    case "1h": return "1 HOUR";
    case "2h": return "2 HOUR";
    case "6h": return "6 HOUR";
    case "12h": return "12 HOUR";
    case "1d": return "1 DAY";
    case "7d": return "7 DAY";
    case "30d": return "30 DAY";
    default: return "All";
  }
}

export const getVesselVoyage = (selctedVessel: any, vesselStartTime: any, vesselEndTime: any, chartInterval: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `${API_URL}/edge/voyage_path` : `/store/ss/query`
  const query = `VESSEL_VOYAGE`;
  return (dispatch: any) => {
    dispatch(fetchVesselVoyageRequest())
    api.postRequestForExternalApi(`${apiUrl}`, JSON.stringify(
      {
        "startTime": vesselStartTime,
        "endTime": vesselEndTime,
        "locationId": selctedVessel,
        "interval": `${chartInterval}`,
        "intervalNum": getIntervalInFormat(chartInterval)
      }
    ))
      .then((res) => {
        console.log("res",res)
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          console.log("Res",res);
          if(res?.data?.data?.rows && res?.data?.data?.rows?.length > 0) {
            dispatch(fetchVesselVoyageSuccess(res));
          } else {
            dispatch(fetchVesselVoyageSuccess([]));
          }
          

        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getVesselLastLocation = (k4Ids: any, ouName: any, vesslNamesList: any, authorizedVesselList: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const vesselMap = new Map();
  Object.keys(authorizedVesselList)?.map((item: any) => {
    let vesselObj = authorizedVesselList[item];
    let vesselName = vesselObj["vessel-name"];
    vesselMap.set(authorizedVesselList[item]?.id, vesselName)
  })
  let k4idhere = `${"'" + k4Ids.join("','") + "'"}`;

  const query = `VESSEL_LAST_LOCATON_WITH_STATUS`;
  console.log("k4idhere", k4idhere);
  if(k4Ids.length == 0) {
    // console.log("k4idhere getting in")

    return (dispatch: any) => {
      dispatch(fetchVesselCurrentLocationSuccess([]));
    }
  } else {
  return (dispatch: any) => {
    dispatch(fetchVesselCurrentLocationRequest())
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "csv",
      "named":true,
      "parameters": {
        "k4idhere":k4idhere
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data?.rows;
          let lastLocTemp = lastLocDetails.map((item: any, i: any) => {
            let vName = vesselMap.get(item[0]);
            lastLocDetails[i].push(vName);
          });
          dispatch(fetchVesselCurrentLocationSuccess(lastLocDetails));

        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}
}

export const getTerminalLastLocation = (k4Ids: any, ouName: any, vesslNamesList: any, authorizedVesselList: any, dpId:any, edgeVal:any, selectedTerminal: any, starlinkVal: any , isEdgeStarlink:any) => {
  // console.log("calling terminal")  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const vesselMap = new Map();
  Object.keys(authorizedVesselList)?.map((item: any) => {
    let vesselObj = authorizedVesselList[item];
    let vesselName = vesselObj["vessel-name"];
    vesselMap.set(authorizedVesselList[item]?.id, vesselName)
  })
  let k4idhere = `${ k4Ids.join(",") }`;

  const query = `SITES_STARLINK_LOCATION_WITH_STATUS`;
  let authorizedList = authorizedVesselList?.map((item:any) => {
    console.log("item",item)
    return item?.id;
  })

  let listOfAuthIds = `${authorizedList.join(",")}`

  if(edgeVal == "NA") {

    return (dispatch: any) => {
      dispatch(fetchVesselCurrentLocationSuccess([]));
    }
  }
  let selectedTerm = `${selectedTerminal?.join(",")}`;
  let parameters = {
    query: query, "format": "csv",
    "named":true,
    "parameters": {
      "dp_id": dpId,
      "site_ids": k4Ids?.length == 0 ? listOfAuthIds:k4idhere,
      "service_line_numbers": selectedTerm ? selectedTerm : "",
      "is_edge": edgeVal,
      "is_starlink": starlinkVal == null ? false : starlinkVal,
      "is_edge_starlink":isEdgeStarlink
    }
  }

  return (dispatch: any) => {
    dispatch(fetchTerminalCurrentLocationRequest())
    api.postRequestOut(`${apiUrl}`, JSON.stringify(parameters))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data?.rows;
          let lastLocTemp = lastLocDetails.map((item: any, i: any) => {
            let vName = vesselMap.get(item[0]);
            lastLocDetails[i].push(vName);
          });
          dispatch(fetchTerminalCurrentLocationSuccess(lastLocDetails));

        } else {

        }
      })
      .catch((err) => {
        dispatch(fetchVesselCurrentLocationSuccess([]));
        console.log("error");
      });
  }
}

export const getVesselLastLocationForSepcific = (k4Ids: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  let k4idhere = `${"'" + k4Ids.join("','") + "'"}`;
  const query = `VESSEL_LAST_LOCATON`;

  return (dispatch: any) => {
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "csv",
      "named":true,
      "parameters": {
        "k4idhere":k4idhere
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data?.rows;
          dispatch(fetchVesselCurrentLocationSuccess(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getSelectedSiteServiceLineData = (selctedVessel: any, serviceLine: any,) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `SITES_STARLINK_LAST_ALERT_AND_AVAILABILITY`;
  return (dispatch: any) => {
    dispatch(fetchSelctedSiteServiceLineData());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "json", "named": true, 
      "parameters": {
        "site_id": selctedVessel ? selctedVessel : "",
        "service_line_number": serviceLine? serviceLine : ""
    }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // console.log("res",res);
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          // dispatch(fetchSelctedVesselVoyageSuccess(res));
          dispatch(fetchSelctedSiteServiceLineDataSuccess(res?.data?.data))
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}



export const getSelectedPathVesselVoyage = (selctedVessel: any, vesselStartTime: any, vesselEndTime: any, chartInterval: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `VESSEL_VOYAGE`;
  return (dispatch: any) => {
    dispatch(fetchSelctedVesselVoyageRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "csv", "named": true,
      "parameters": {
        "startTime": vesselStartTime,
        "endTime": vesselEndTime,
        "locationId": selctedVessel,
        "interval": `${chartInterval}`,
        "intervalNum": getIntervalInFormat(chartInterval)
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchSelctedVesselVoyageSuccess(res));

        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getLTEOperators = () => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `LTE_OPERATORS`;
  return (dispatch: any) => {
    dispatch(fetchUniqueCarrierRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "named": true
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchUniqueCarrierSuccess(res));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getVSATOperators = () => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `VSAT_OPERATORS`;
  return (dispatch: any) => {
    dispatch(fetchUniqueVsatCarrierRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "named": true
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          // dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchUniqueVsatCarrierSuccess(res)); 
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

const getNamedQueryOnSource = (selectedDataSource:any) => {
  switch (selectedDataSource) {
    case "Starlink": return "MAP_STARLINK_LATENCY_HISTOGRAM"
    case "Drop Percentage": return "MAP_STARLINK_DROP_PERCENTAGE_HISTOGRAM";
    case "Speed": return "MAP_STARLINK_SPEED_HISTOGRAM";
    default: return "MAP_STARLINK_LATENCY_HISTOGRAM";
  }
}

export const getStarLinkHistoDetails = (h3Index: any, hexResolution:any, selectedLayer: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = getNamedQueryOnSource(selectedLayer);

  return (dispatch: any) => {
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3Index,
         "resolution":hexResolution
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchStarLinkHistoSucesss(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getStarLinkLatencyHistoDetails = (h3Index: any, hexResolution:any, selectedLayer: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = getNamedQueryOnSource(selectedLayer);

  return (dispatch: any) => {
    dispatch(fetchStarLinkLatencyHistoRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_STARLINK_LATENCY_HISTOGRAM", "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3Index,
         "resolution":hexResolution
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchStarLinkLatencyHistoSucesss(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getStarLinkSpeedHistoDetails = (h3Index: any, hexResolution:any, selectedLayer: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = getNamedQueryOnSource(selectedLayer);

  return (dispatch: any) => {
    dispatch(fetchStarLinkSpeedHistoRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_STARLINK_SPEED_HISTOGRAM", "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3Index,
         "resolution":hexResolution
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchStarLinkSpeedHistoSucesss(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getStarLinkDropPercentageHistoDetails = (h3Index: any, hexResolution:any, selectedLayer: any) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = getNamedQueryOnSource(selectedLayer);

  return (dispatch: any) => {
    dispatch(fetchStarLinkDropPercentageHistoRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_STARLINK_DROP_PERCENTAGE_HISTOGRAM", "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3Index,
         "resolution":hexResolution
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchStarLinkDropPercentageHistoSucesss(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getRSSIHistoDetails = (h3index: string, carrierName: string ,operator: string, mcc: number, mnc: number, histroType: string, interval: number, hexResolution: number) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`

  return (dispatch: any) => {
    dispatch(fetchHexRssiHistoRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_CELLULAR_RSSI_HISTOGRAM", 
      "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3index,
         "resolution": hexResolution,
        "operator":operator,
        "carrier":carrierName,
        "mcc":mcc,
        "mnc":mnc
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchHexRssiHistoSuccess(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getSINRHistoDetails = (h3index: string, carrierName: string ,operator: string, mcc: number, mnc: number, histroType: string, interval: number, hexResolution: number) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`

  return (dispatch: any) => {
    dispatch(fetchHexSinrHistoRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_CELLULAR_SINR_HISTOGRAM", 
      "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3index,
         "resolution": hexResolution,
        "operator":operator,
        "carrier":carrierName,
        "mcc":mcc,
        "mnc":mnc
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchHexSinrHistoSuccess(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getSPEEDHistoDetails = (h3index: string, carrierName: string ,operator: string, mcc: number, mnc: number, histroType: string, interval: number, hexResolution: number) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`

  return (dispatch: any) => {
    dispatch(fetchHexSpeedHistoRequest())
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: "MAP_CELLULAR_FASTEST_SPEED_HISTOGRAM", 
      "format": "csv",
      "named":true,
      "parameters": {
        "h3index":h3index,
         "resolution":hexResolution,
        "operator":operator,
        "carrier":carrierName,
        "mcc":mcc,
        "mnc":mnc
      }
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          let lastLocDetails = res?.data?.data;
          dispatch(fetchHexSpeedHistoSuccess(lastLocDetails));
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getStarlinkServiceLines = (ouId: string) => {
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `GET_ALL_STARLINK_SERVICE_LINES_BY_DP`;
  return (dispatch: any) => {
    dispatch(getAllStarlinkServiceLinesRequest());
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "named": true, format: 'json', parameters: { dp_id: ouId}
    }))
      .then((res) => {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          dispatch(getAllStarlinkServiceLinesSeccess(res)); 
        } else {

        }
      })
      .catch((err) => {
        console.log("error");
      });
  }
}

export const getSiteStarlinkCount = (ouId: string) => {
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const query = `MAP_SITES_STARLINK_COUNT`;
  return (dispatch: any) => {
    dispatch(getStarlinkServiceLineAndSitesLoader())
    api.postRequestOut(`${apiUrl}`, JSON.stringify({
      query: query, "named": true, format: 'json', parameters: { dp_id: ouId}
    }))
      .then((res) => {
        console.log("RES",res)
        if (res) {
          dispatch(getStarlinkServiceLineAndSitesCount(res?.data)); 
        } 
      })
      .catch((err) => {
        dispatch(getStarlinkServiceLineAndSitesLoader())
        console.log("error");
      });
  }
}