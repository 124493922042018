import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { StarlinkNavTabs } from '../types';
import { Grid, Paper } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: StarlinkNavTabs;
    value: StarlinkNavTabs;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`starlink-tabpanel-${index}`}
            aria-labelledby={`${index}`}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </div>
    );
}

function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

interface NavigationProps {
    value: StarlinkNavTabs;
    disableAdmin: boolean;
    onChange: (event: React.SyntheticEvent, newValue: StarlinkNavTabs) => void;
}

export default function Navigation(props: NavigationProps) {
    const { value, disableAdmin, onChange } = props;

    const handleChange = (event: React.SyntheticEvent, newValue: StarlinkNavTabs) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            onChange(event, newValue);
        }
    };

    return (
        <Paper elevation={1} square={true} className='starlink--navigation--container' classes={{ root: 'starlink--base--flex--align--center starlink--base--flex--justify--space_between starlink--base--padding_1x' }}>
            <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="starlink-navigation-tabs">
                    <Tab label="Dashboard" value="dashboard" />
                    <Tab label="Usage" value="usage" />
                    <Tab label="Quota" value="quota" />
                    <Tab label="Analytics" value="analytics" />
                    <Tab label="Admin" value="admin" disabled={disableAdmin} />
                </Tabs>
            </Grid>
        </Paper>
    );
}