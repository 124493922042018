import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, Divider, Grid, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from "react";
import { getLicenseTypes, upgradeInventoryLicense } from '../../actions/Users/authenticateInventory';
import _ from "lodash";

const UpgradeLicense = (props) => {
    const { getLicenseTypes, licenseTypes, row, existingLicense, existingTerm, handleClose, upgradeInventoryLicense, setPayload } = props;
    const [licenseOptions, setLicenseOptions] = useState<any>([]);
    const [termOptions, setTermOptions] = useState<any>([]);
    const [selectedLicense, setSelectedLicense] = useState<any>(!_.isEmpty('existingLicense') ? existingLicense : {});
    const [selectedTerm, setSelectedTerm] = useState<any>(!_.isEmpty('existingTerm') ? existingTerm : {});

    useEffect(() => {
        getLicenseTypes(row['dpId'])
    }, [row])

    useEffect(() => {
        if (!_.isEmpty(licenseTypes) && licenseTypes?.data) {
            let licenseData = licenseTypes.data;
            if (licenseData && Array.isArray(licenseData) && licenseData.length > 0) {
                let types: any[] = [];
                let termTypes:any = {};

                licenseData = _.orderBy(licenseData, ['tierOrder'], ['asc']);

                licenseData.forEach(tierType => {
                    let type = {
                        "label": tierType?.tierAlias,
                        "value": tierType?.tierType
                    }

                    if (tierType?.term && Array.isArray(tierType.term) && tierType.term.length > 0) {
                        let termOptions: any[] = []

                        tierType.term = _.orderBy(tierType.term, ['termOrder'], ['asc']);
                        tierType.term.forEach(durationtype => {
                            let option = {
                                "label": durationtype?.termDisplay,
                                "value": durationtype?.termValue
                            }
                            termOptions.push(option)
                        });
                        termTypes[tierType?.tierType] = termOptions
                    }

                    types.push(type)
                });
                setTermOptions(termTypes?.[selectedLicense?.['value']])
                setLicenseOptions(types)
            }
        }
    }, [licenseTypes])

    const handleUpgradeLicense = () => {
        const payload = {
            did : row["deviceId"],
            licenseFrom: existingLicense.value,
            licenseTo: selectedLicense?.value,
            termFrom: existingTerm.value,
            termTo: selectedTerm?.value
        }
        setPayload(payload);
        upgradeInventoryLicense(payload);
    }

    return (
        <div className="Form">
            <Grid container className="deploy-inventory-content">
                <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check upgrade-license-container" >
                    <div>
                        <span id="content-left-text">Select License</span>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            forcePopupIcon={true}
                            popupIcon={<KeyboardArrowDownIcon />}
                            ListboxProps={{ className: 'warehouse-apply-config-props' }}
                            className="audit-filter-inputs-usage config-textField"
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            size='small'
                            options={licenseOptions}
                            value={selectedLicense}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => { setSelectedLicense(newValue) }}
                            renderInput={({ inputProps, ...params }) => (
                                <TextField {...params} placeholder="select"
                                    inputProps={{ ...inputProps, readOnly: true }}
                                />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                    <div>
                        <span id="content-left-text">Select Term</span>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            forcePopupIcon={true}
                            popupIcon={<KeyboardArrowDownIcon />}
                            ListboxProps={{ className: 'warehouse-apply-config-props' }}
                            className="audit-filter-inputs-usage config-textField"
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            size='small'
                            options={termOptions}
                            value={selectedTerm}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => { setSelectedTerm(newValue) }}
                            renderInput={({ inputProps, ...params }) => (
                                <TextField {...params} placeholder="select"
                                    inputProps={{ ...inputProps, readOnly: true }}
                                />
                            )}
                        />
                    </div>
                </Grid>
                <span className="change-license-info-msg">Change License can be used to move from the Current License Tier / Term to a Different license Tier / Term (Subject to business rules specified under MSA). Either Or both of the License Tier, Term can be updated during the Change License.</span>
            </Grid>
            <div className="edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={handleClose}>Cancel</Button>
                    <Button className={(!_.isEmpty(selectedLicense) && !_.isEmpty(selectedTerm)) ? "confirm-dialogue-proceed" : "confirm-dialogue-proceed-notAllow"} 
                        disabled={(!_.isEmpty(selectedLicense) && !_.isEmpty(selectedTerm)) ? false : true} onClick={handleUpgradeLicense}
                    >Upgrade</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    licenseTypes: state.authReducer?.getLicenseTypes,
});

export default withRouter(
    connect(mapStateToProps, { getLicenseTypes, upgradeInventoryLicense })(UpgradeLicense)
);

