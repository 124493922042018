import { ftruncate } from "fs";
import {
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_ERROR,
    FETCH_TOWER_DETAILS,
    FETCH_TOWER_DETAILS_SUCCESS,
    FETCH_VESSEL_NAME_SUCCESS,
    FETCH_VESSEL_VOYAGE,
    FETCH_VESSEL_VOYAGE_SUCCESS,
    FETCH_VESSEL_KPI_DATA,
    FETCH_VESSEL_KPI_DATA_SUCCESS,
    FETCH_CARRIER_DATA_SUCCESS,
    FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS,
    FETCH_VESSEL_CURRENT_LOCATION_SUCCESS,
    FETCH_VESSEL_METRICS_INFO_SUCCESS,
    FETCH_UNIQUE_CARRIER_SUCCESS,
    FETCH_VESSEL_VOAYGE_REQUEST,
    FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS,
    FETCH_HEX_SINR_HISTOGRAM_SUCCESS,
    FETCH_HEX_RSSI_HISTOGRAM_SUCCESS,
    FETCH_HEX_SPEED_HISTOGRAM_SUCCESS,
    FETCH_VESSEL_CURRENT_LOCATION,
    SET_API_ERRORS,
    DELETE_API_ERRORS,
    FETCH_VSAT_COVERAGE_DATA_SUCCESS,
    FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS,
    FETCH_VSAT_HISTO_SUCCESS,
    FETCH_VSAT_TX_POWER_HISTO_SUCCESS,
    GET_VSAT_ALL_HEX_METRICS,
    CLEAR_MAP_ERRORS,
    GET_EDGE_EVENTS_SUCCESS,
    FETCH_VESSEL_KPI_DATA_RATE_SUCCESS,
    FETCH_VESSEL_KPI_DATA_RTT_SUCCESS,
    FETCH_UNIQUE_VSAT_CARRIER_SUCCESS,
    SET_STARTLINK_DATA,
    GET_STARLINK_SPEED_DATA,
    GET_STARLINK_HISTO_SUCCESS,
    GET_STARLINK_LATENCY_HISTO_SUCCESS,
    GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS,
    GET_STARLINK_SPEED_HISTO_SUCCESS,
    FETCH_UNIQUE_CARRIER_REQUEST,
    FETCH_CARRIER_DATA_REQUEST,
    FETCH_CARRIER_DATA_INNER_LAYER_REQUEST,
    FETCH_VSAT_COVERAGE_DATA_REQUEST,
    SET_STARTLINK_DATA_LOADING,
    GET_STARLINK_SPEED_DATA_LOADING,
    FETCH_HEX_SPEED_HISTOGRAM_REQUEST,
    FETCH_HEX_RSSI_HISTOGRAM_REQUEST,
    FETCH_HEX_SINR_HISTOGRAM_REQUEST,
    FETCH_UNIQUE_VSAT_CARRIER_REQUEST,
    GET_STARLINK_LATENCY_HISTO_REQUEST,
    GET_STARLINK_SPEED_HISTO_REQUEST,
    GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST,
    FETCH_SELCTED_VESSEL_VOYAGE_REQUEST,
    FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS,
    FETCH_TERMINAL_CURRENT_LOCATION,
    FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS,
    FETCH_SELECTED_SITE_SERVICE_LINE_DATA,
    GET_ALL_STARLINK_SERVICE_LINES_REQUEST,
    GET_ALL_STARLINK_SERVICE_LINES,
    GET_MAP_SITES_STARLINK_COUNT,
    GET_MAP_SITES_STARLINK_LOADER,
    SET_EMPTY_VESSEL_VOYAGE,
  } from "./actionType";
  
  export function fetchDataRequest() {
    return {
      type: FETCH_DATA_REQUEST
    };
  }
  
  export function fetchDataSuccess(item:any) {
    return {
      type: FETCH_DATA_SUCCESS,
      item
    };
  }

  export function fetchTowerDetails(item:any) {
    return {
      type: FETCH_TOWER_DETAILS,
      item
    };
  }

  export function fetchTowerDetailsSuccess(item:any) {
    return {
      type: FETCH_TOWER_DETAILS_SUCCESS,
      item
    };
  }
  

  export function fetchVesselNameSuccess(item:any) {
    return {
      type: FETCH_VESSEL_NAME_SUCCESS,
      item
    };
  }

  export function fetchVesselVoage(item:any) {
    return {
      type: FETCH_VESSEL_VOYAGE,
      item
    };
  }

  export function fetchVesselVoyageRequest() {
    return {
      type: FETCH_VESSEL_VOAYGE_REQUEST,
    };
  }
  

  export function fetchVesselVoyageSuccess(item:any) {
    return {
      type: FETCH_VESSEL_VOYAGE_SUCCESS,
      item
    };
  }

  export function fetchSelctedVesselVoyageSuccess(item:any) {
    return {
      type: FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS,
      item
    };
  }

  export function fetchSelctedVesselVoyageRequest() {
    return {
      type: FETCH_SELCTED_VESSEL_VOYAGE_REQUEST
    }
  }

  export function fetchSelctedSiteServiceLineData() {
    return {
      type: FETCH_SELECTED_SITE_SERVICE_LINE_DATA
    }
  }

  export function fetchSelctedSiteServiceLineDataSuccess(item:any) {
    return {
      type: FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS,
      item
    }
  }
  export function fetchVesselKpiDataRequest() {
    return {
      type: FETCH_VESSEL_KPI_DATA,
    };
  }
  
  export function fetchVesselKpiDataSuccess(item:any) {
    return {
      type: FETCH_VESSEL_KPI_DATA_SUCCESS,
      item
    };
  }

  export function fetchVesselDataRateSuccess(item:any) {
    return {
      type: FETCH_VESSEL_KPI_DATA_RATE_SUCCESS,
      item
    };
  }

  export function fetchVesselDataRTTSuccess(item:any) {
    return {
      type: FETCH_VESSEL_KPI_DATA_RTT_SUCCESS,
      item
    };
  }

  export function fetchCarrierDataSuccess(item:any) {
    return {
      type: FETCH_CARRIER_DATA_SUCCESS,
      item
    };
  }

  export function fetchCarrierDataRequest() {
    return {
      type: FETCH_CARRIER_DATA_REQUEST
    };
  }

  export function fetchVsatCoverageSuccess(item:any) {
    return {
      type: FETCH_VSAT_COVERAGE_DATA_SUCCESS,
      item
    };
  }

  export function fetchVsatCoverageRequest() {
    return {
      type: FETCH_VSAT_COVERAGE_DATA_REQUEST
    }
  }

  export function fetchVsatCoverageInnerLayerDataSuccess(item:any) {
    return {
      type: FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS,
      item
    };
  }

  export function fetchCarrierDataInnerLayerSuccess(item:any) {
    return {
      type: FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS,
      item
    };
  }

  export function fetchCarrierDataInnerLayerRequest() {
    return {
      type: FETCH_CARRIER_DATA_INNER_LAYER_REQUEST
    }
  }

  export function fetchVesselCurrentLocationSuccess(item:any) {
    return {
      type: FETCH_VESSEL_CURRENT_LOCATION_SUCCESS,
      item
    };
  }

  export function fetchTerminalCurrentLocationRequest() {
    return {
      type: FETCH_TERMINAL_CURRENT_LOCATION,
    };
  }

  export function fetchTerminalCurrentLocationSuccess(item:any) {
    return {
      type: FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS,
      item
    };
  }

  export function fetchVesselCurrentLocationRequest() {
    return {
      type: FETCH_VESSEL_CURRENT_LOCATION,
    };
  }

  export function fetchVesselMetricsInfoSuccess(item:any) {
    return {
      type: FETCH_VESSEL_METRICS_INFO_SUCCESS,
      item
    };
  }

  export function fetchUniqueCarrierSuccess(item:any) {
    return {
      type: FETCH_UNIQUE_CARRIER_SUCCESS,
      item
    };
  }

  export function fetchUniqueCarrierRequest() {
    return {
      type: FETCH_UNIQUE_CARRIER_REQUEST,
    };
  }

  export function fetchUniqueVsatCarrierSuccess(item:any) {
    return {
      type: FETCH_UNIQUE_VSAT_CARRIER_SUCCESS,
      item
    };
  }

  export function fetchUniqueVsatCarrierRequest() {
    return {
      type: FETCH_UNIQUE_VSAT_CARRIER_REQUEST
    };
  }

  export function fetchHexSinrHistoSuccess(item:any) {
    return {
      type: FETCH_HEX_SINR_HISTOGRAM_SUCCESS,
      item
    };
  }

  export function fetchHexSinrHistoRequest() {
    return {
      type: FETCH_HEX_SINR_HISTOGRAM_REQUEST
    }
  }

  export function fetchVSATHistoSuccess(item:any) {
    return {
      type: FETCH_VSAT_HISTO_SUCCESS,
      item
    };
  }

  export function fethcVSATAllHexMetricsSuccess(item:any) {
    return {
      type: GET_VSAT_ALL_HEX_METRICS,
      item
    };
  }

  export function fetchVSATTxPowerHistoSuccess(item:any) {
    return {
      type: FETCH_VSAT_TX_POWER_HISTO_SUCCESS,
      item
    };
  }

  export function fetchHexRssiHistoSuccess(item:any) {
    return {
      type: FETCH_HEX_RSSI_HISTOGRAM_SUCCESS,
      item
    };
  }

  export function fetchHexRssiHistoRequest() {
    return {
      type: FETCH_HEX_RSSI_HISTOGRAM_REQUEST,
    };
  }

  export function fetchHexSpeedHistoSuccess(item:any) {
    return {
      type: FETCH_HEX_SPEED_HISTOGRAM_SUCCESS,
      item
    };
  }

  export function fetchHexSpeedHistoRequest() {
    return {
      type: FETCH_HEX_SPEED_HISTOGRAM_REQUEST
    }
  }

  export function setApiErrors(item:any) {
    return {
      type: SET_API_ERRORS,
      item
    };
  }

  export function clearMapErros(item:any) {
    return {
      type: CLEAR_MAP_ERRORS,
      item
    }
  }


  export function deleteApiError(item:any) {
    return {
      type: DELETE_API_ERRORS,
      item
    };
  }

  export function fetchGetEdgeEventsSucesss(item:any) {
    return {
      type: GET_EDGE_EVENTS_SUCCESS,
      item
    }
  }

  export function fetchStarLinkSuccess(item:any) {
    return {
      type: SET_STARTLINK_DATA,
      item
    }
  }

  export function fetchStarLinkRequest() {
    return {
      type: SET_STARTLINK_DATA_LOADING
    }
  }

  export function fetchStarLinkSpeedSuccess(item:any) {
    return {
      type: GET_STARLINK_SPEED_DATA,
      item
    }
  }

  export function fetchStarLinkSpeedRequest() {
    return {
      type: GET_STARLINK_SPEED_DATA_LOADING
    }
  }

  export function fetchDataError(error:any) {
    return {
      type: FETCH_DATA_ERROR,
      payload: { error }
    };
  }
  
  export function fetchStarLinkHistoSucesss(item:any) {
    return {
      type: GET_STARLINK_HISTO_SUCCESS,
      item
    }
  }

  export function fetchStarLinkLatencyHistoSucesss(item:any) {
    return {
      type: GET_STARLINK_LATENCY_HISTO_SUCCESS,
      item
    }
  }

  export function fetchStarLinkLatencyHistoRequest() {
    return {
      type: GET_STARLINK_LATENCY_HISTO_REQUEST
    }
  }

  export function fetchStarLinkDropPercentageHistoSucesss(item:any) {
    return {
      type: GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS,
      item
    }
  }

  export function fetchStarLinkDropPercentageHistoRequest() {
    return {
      type: GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST
    }
  }

  export function fetchStarLinkSpeedHistoSucesss(item:any) {
    return {
      type: GET_STARLINK_SPEED_HISTO_SUCCESS,
      item
    }
  }

  export function fetchStarLinkSpeedHistoRequest() {
    return {
      type: GET_STARLINK_SPEED_HISTO_REQUEST,
    }
  }

  export function getAllStarlinkServiceLinesRequest() {
    return {
      type: GET_ALL_STARLINK_SERVICE_LINES_REQUEST
    }
  }

  export function getAllStarlinkServiceLinesSeccess(item:any) {
    return {
      type: GET_ALL_STARLINK_SERVICE_LINES,
      item
    }
  }

  export function getStarlinkServiceLineAndSitesCount(item:any) {
    return {
      type : GET_MAP_SITES_STARLINK_COUNT,
      item
    }
  }

  export function getStarlinkServiceLineAndSitesLoader() {
    return {
      type : GET_MAP_SITES_STARLINK_LOADER,
    }
  }

  export function setEmptyVesselVoyage() {
    return {
      type : SET_EMPTY_VESSEL_VOYAGE
    }
  }

  