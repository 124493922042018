import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import { Search } from '@mui/icons-material';
import { Edit } from "@material-ui/icons";

import { Pagination } from "../../Pagination";

import './UserTerminals.css';
import { getDecodeURI, getEncodedURI } from '../../../utils/util';

import { getUserTerminals, clearComponentData} from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { UserTerminal } from "../types";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import Download from '../../../asset/image/DownloadUsage.svg';
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";

const convertToCsv = (data: any[][]) => {
    const csvRows: any[] = [];
    data.forEach((row) => {
        csvRows.push([row[5], row[3], row[8], row[10], row[9],  row[7], row[2]])
    })
    return [['Organization', 'Sub Organization', 'Starlink ID', 'Serial Number', 'KIT Number', 'Service Line', 'K4 Site'], ...csvRows]
}

interface UserTerminalsProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    userTerminals: any[];
    totalUserTerminals: number;
    getUserTerminals: (parameters: any) => void;
    clearComponentData : () => void;
    downloadAsCSV: (parameters: any) => void;
    updateUserTerminalOrganization: (parameters: any) => void;
    newSummaryServiceLine:any;
}

export type UserTerminalSortBy = 'serviceLineName' | 'userTerminalId' | 'kitSerialNumber' | 'dishSerialNumber' | 'k4SiteName' | 'accountDpName' | 'dpName';

function UserTerminals(props: UserTerminalsProps) {

    const { authReducer, errorReducer, history, location, dpIds, userTerminals, totalUserTerminals, getUserTerminals, clearComponentData, downloadAsCSV, newSummaryServiceLine } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>('');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: UserTerminalSortBy;
        sortOrder: SortOrder;
        search: string;
        terminalStatus: string[];
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy')  as UserTerminalSortBy: 'userTerminalId',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
        terminalStatus: queryParams.has('terminalStatus') ? queryParams.get('terminalStatus')?.split(',') as string[] : ['deployed', 'in-warehouse']
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();


    const refreshUserTerminalTable = (samePage: boolean = false) => {
        let _page = 1;
        if (samePage) {
            _page = tableParams.page;
        }
        setTableParams({
            ...tableParams,
            page: _page
        });
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: UserTerminalSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const handleChangeTerminalStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _terminalStatus = ['deployed', 'in-warehouse'];
        if (event.target.value === 'deployed') {
            _terminalStatus = ['deployed'];
        } else if (event.target.value === 'in-warehouse') {
            _terminalStatus = ['in-warehouse'];
        }
        setTableParams({
            ...tableParams,
            terminalStatus: _terminalStatus,
            page: 1
        });
    };

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        queryParams.set('terminalStatus', tableParams.terminalStatus.join(','))
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        clearComponentData();
        getUserTerminals({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            terminalStatus: tableParams.terminalStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [ tableParams, newSummaryServiceLine]);

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'user-terminal') {
                const data = authReducer.csvDataDownloded;
                if (data === null) {
                    return;
                }  
                if (data && data.length > 0) {
                    const keys = ['accountNumber', 'dpId', 'k4SiteName', 'dpName', 'accountDpId', 'accountDpName', 'serviceLineNumber', 'serviceLineName', 'userTerminalId', 'kitSerialNumber', 'dishSerialNumber', 'terminalStatus', 'k4DeviceId', 'k4SiteId', 'total_count'];

                    const arrayOfObjects = data.map((data,rowIndex) => {
                        if (rowIndex === 0) return null;
                      const obj = {};
                      keys.forEach((key, index) => {
                        obj[key] = data[index];
                      });
                      return obj;
                    }).filter(obj => obj !== null);
                    if (arrayOfObjects.length) {
                        DownloadCSV(convertToCsv([ ...arrayOfObjects.map(row => Object.values(row))]), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                5: 'ESC-COMMA',
                                6: 'ESC-COMMA'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'user-terminal',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalUserTerminals,
                offset: 0,
                terminalStatus: tableParams.terminalStatus.map(status => `'${status}'`).join(','),
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_USER_TERMINALS_ADMIN',
        }
        downloadAsCSV(params);
    }

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x' }}>
            {/* FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        USER TERMINALS
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <RadioGroup
                        row
                        aria-labelledby="starlink--service_account_type--radio_group"
                        name="controlled-radio-buttons-group"
                        value={tableParams.terminalStatus.length === 2 ? 'all' : tableParams.terminalStatus[0]}
                        onChange={handleChangeTerminalStatus}
                    >
                        <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                        <FormControlLabel value="deployed" control={<Radio size="small" />} label="Deployed" />
                        <FormControlLabel value="in-warehouse" control={<Radio size="small" />} label="In Warehouse" />
                    </RadioGroup>
                    {/* <FormControl size="small">
                        <TextField
                            label={'Search'}
                            value={searchText}
                            onChange={handleChangeSearchText}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch() } }}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: '0.5rem' }}
                            InputProps={{
                                endAdornment: <Search onClick={handleSearch} style={{ cursor: 'pointer' }} />
                            }}
                        />
                    </FormControl> */}
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{root: "input-box-user-ter"}}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {totalUserTerminals > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalUserTerminals} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SUB ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="userTerminalId" onChange={handleChangeSorting}>
                                <span className="font-wt-900">STARLINK ID</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dishSerialNumber" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SERIAL NUMBER</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="kitSerialNumber" onChange={handleChangeSorting}>
                                <span className="font-wt-900">KIT NUMBER</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="serviceLineName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SERVICE LINE</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="k4SiteName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">K4 SITE</span>
                                </SortArrows>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            userTerminals?.length > 0 ? userTerminals.map((userTerminal: UserTerminal) => {
                                return <TableRow
                                    key={userTerminal.userTerminalId}
                                >
                                    <TableCell>
                                        {userTerminal.accountDpName}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.dpName}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.userTerminalId}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.dishSerialNumber}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.kitSerialNumber}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.serviceLineName}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.k4SiteName}
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    userTerminals: state.starlinkAdmin.userTerminals,
    totalUserTerminals: state.starlinkAdmin.totalUserTerminals,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine
});

export default withRouter(
    connect(mapStateToProps, {
        getUserTerminals,
        downloadAsCSV,
        clearComponentData
    })(UserTerminals)
);