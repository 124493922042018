
import React, { useEffect, useRef } from "react";
import { Polyline } from "react-leaflet";
import "leaflet-arrowheads";
const PolyLineArrow = (props) => {
    const polylineRef = useRef();

    useEffect(() => {
        if (polylineRef) {
            const polyline = polylineRef.current;
            if (props.arrowheads) {
                polyline.arrowheads(props.arrowheads)
                polyline._update()
            }
        }
        }, [polylineRef]);

return (
    <Polyline {...props} ref={polylineRef}>
        {props.children}
    </Polyline>
)

}

export default PolyLineArrow;