import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";

import { 
    SET_CF_TOP_USERS_TABLE_LOADING,
    SET_CF_TOP_USERS_TABLE_COUNT_LOADING,
    SET_CF_TOP_SITES_TABLE_LOADING,
    SET_CF_TOP_SITES_TABLE_COUNT_LOADING,
    SET_CF_CATEGORY_PIE_LOADING,
    SET_CF_APPLICATION_PIE_LOADING,
    SET_CF_SUBCAT_PIE_LOADING,
    SET_CF_LINECHART_LOADING,
    SET_CF_TW_TOP_SITE_LOADING,
    SET_CF_TW_TOP_USER_LOADING,
    SET_CF_TW_TOP_CATEGORY_LOADING,
    SET_CF_TW_TOP_DOMAIN_LOADING,
    SET_CF_CATEGORY_LIST_LOADING,
    SET_CF_APP_LIST_LOADING,
    SET_CF_USER_LIST_LOADING,
    SET_CF_VLAN_LIST_LOADING,
    SET_CF_DEVICE_LIST_LOADING,
    SET_CF_SUb_CATEGORY_LIST_LOADING,
    GET_CF_TOP_USERS_TABLE,
    GET_CF_TOP_USERS_TABLE_COUNT,
    GET_CF_TOP_SITES_TABLE,
    GET_CF_TOP_SITES_TABLE_COUNT,
    GET_CF_CATEGORY_PIE,
    GET_CF_APPLICATION_PIE,
    GET_CF_SUBCAT_PIE,
    GET_CF_LINECHART,
    GET_CF_TW_TOP_SITE,
    GET_CF_TW_TOP_USER,
    GET_CF_TW_TOP_CATEGORY,
    GET_CF_TW_TOP_DOMAIN,
    GET_CF_CATEGORY_LIST,
    GET_CF_APP_LIST,
    GET_CF_USER_LIST,
    GET_CF_VLAN_LIST,
    GET_CF_DEVICE_LIST,
    GET_CF_SUB_CATEGORY_LIST
} from '../types';

export const getContentFilterTopUsersTable = (start, end, vessels, category, app, user, vlan, device, subCat,  page, limit, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TOP_USERS_TABLE_LOADING, payload: true });
    const table : any = {
        query: 'CONTENT_FILTER_TOP_USERS_TABLE',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            sortcolumn: sort,
            sortorder: sortOrder,
            pageoffset: ((page-1)*limit),
            pagesize: limit
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, table)
    .then((res) => handleResponse(res, dispatch, GET_CF_TOP_USERS_TABLE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopUsersTableCount = (start, end, vessels, category, app, user, vlan, device, subCat, page, limit, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TOP_USERS_TABLE_COUNT_LOADING, payload: true });
    const tableCount : any = {
        query: 'CONTENT_FILTER_TOP_USERS_TABLE_COUNT',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, tableCount)
    .then((res) => handleResponse(res, dispatch, GET_CF_TOP_USERS_TABLE_COUNT))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopSitesTable = (start, end, vessels, category, app, user, vlan, device, subCat,  page, limit, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TOP_SITES_TABLE_LOADING, payload: true });
    const sieTable : any = {
        query: 'CONTENT_FILTER_TOP_SITES_TABLE',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            sortcolumn: sort,
            sortorder: sortOrder,
            pageoffset: ((page-1)*limit),
            pagesize: limit
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, sieTable)
    .then((res) => handleResponse(res, dispatch, GET_CF_TOP_SITES_TABLE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopSitesTableCount = (start, end, vessels, category, app, user, vlan, device, subCat, page, limit, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TOP_SITES_TABLE_COUNT_LOADING, payload: true });
    const siteTableCount : any = {
        query: 'CONTENT_FILTER_TOP_SITES_TABLE_COUNT',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, siteTableCount)
    .then((res) => handleResponse(res, dispatch, GET_CF_TOP_SITES_TABLE_COUNT))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterCategoryPie = (start, end, vessels, category, app, user, vlan, device, subCat, limit) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_CATEGORY_PIE_LOADING, payload: true });
    const categoryPie : any = {
        query: 'CONTENT_FILTER_CATEGORY_PIE_CHART',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            limit : limit!='All' ? `limit ${limit}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, categoryPie)
    .then((res) => handleResponse(res, dispatch, GET_CF_CATEGORY_PIE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterApplicationPie = (start, end, vessels, category, app, user, vlan, device, subCat, limit) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_APPLICATION_PIE_LOADING, payload: true });
    const applicationPie : any = {
        query: 'CONTENT_FILTER_APPLICATION_PIE_CHART',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            limit : limit!='All' ? `limit ${limit}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, applicationPie)
    .then((res) => handleResponse(res, dispatch, GET_CF_APPLICATION_PIE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterSubCategoryPie = (start, end, vessels, category, app, user, vlan, device, subCat, limit) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_SUBCAT_PIE_LOADING, payload: true });
    const subCategoryPie : any = {
        query: 'CONTENT_FILTER_SUBCATEGORY_PIE_CHART',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            limit : limit!='All' ? `limit ${limit}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, subCategoryPie)
    .then((res) => handleResponse(res, dispatch, GET_CF_SUBCAT_PIE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterLinechart = (start, end, vessels, category, app, user, vlan, device, subCat, interval) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let newInterval = interval;
    if(isClickHouse) {
        if(newInterval.includes('m')) {
            newInterval = newInterval.replace("m", " minute")
        } else if(newInterval.includes('h')) {
            newInterval = newInterval.replace("h"," hour")
        } else {
            newInterval = newInterval.replace("d", " day")
        }
    }
    dispatch({ type: SET_CF_LINECHART_LOADING, payload: true });
    const lineChart : any = {
        query: 'CONTENT_FILTER_LINE_CHART',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : '',
            interval: newInterval
        },
        format: 'chart',
        groupBy: 'category',
        series: 'hits',
        tsColumn: 'tb',
    }
    api
    .postRequestOut(`${apiUrl}`, lineChart)
    .then((res) => handleResponse(res, dispatch, GET_CF_LINECHART))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopWidgetTopSite = (start, end, vessels, category, app, user, vlan, device, subCat) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TW_TOP_SITE_LOADING, payload: true });
    const topSite : any = {
        query: 'CONTENT_FILTER_TOP_WIDGET_TOP_SITE',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, topSite)
    .then((res) => handleResponse(res, dispatch, GET_CF_TW_TOP_SITE))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopWidgetTopUser = (start, end, vessels, category, app, user, vlan, device, subCat) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TW_TOP_USER_LOADING, payload: true });
    const topSite : any = {
        query: 'CONTENT_FILTER_TOP_WIDGET_TOP_USER',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, topSite)
    .then((res) => handleResponse(res, dispatch, GET_CF_TW_TOP_USER))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopWidgetTopCategory = (start, end, vessels, category, app, user, vlan, device, subCat) => (dispatch) => {
    dispatch({ type: SET_CF_TW_TOP_CATEGORY_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const topSite : any = {
        query: 'CONTENT_FILTER_TOP_WIDGET_TOP_CATEGORY',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, topSite)
    .then((res) => handleResponse(res, dispatch, GET_CF_TW_TOP_CATEGORY))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterTopWidgetTopDomain = (start, end, vessels, category, app, user, vlan, device, subCat) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_TW_TOP_DOMAIN_LOADING, payload: true });
    const topSite : any = {
        query: 'CONTENT_FILTER_TOP_WIDGET_TOP_DOMAIN',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            category : category ? `and category='${category}'` : '',
            application : app ? `and fqdn='${app}'` : '',
            hostname : user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
            vlan : vlan ? `and Access_Network='${vlan}'` : '',
            devicename: device ? `and Device_Name='${device}'` : '',
            subcategory: subCat ? `and subcategory='${subCat}'` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, topSite)
    .then((res) => handleResponse(res, dispatch, GET_CF_TW_TOP_DOMAIN))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterCategoryList = (start, end, vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_CATEGORY_LIST_LOADING, payload: true });
    const categoryList : any = {
        query: 'CONTENT_FILTER_FILTERS_CATEGORY',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, categoryList)
    .then((res) => handleResponse(res, dispatch, GET_CF_CATEGORY_LIST))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterAppList = (start, end, vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_APP_LIST_LOADING, payload: true });
    const appList : any = {
        query: 'CONTENT_FILTER_FILTERS_DOMAIN',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, appList)
    .then((res) => handleResponse(res, dispatch, GET_CF_APP_LIST))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterUserList = (start, end, vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_USER_LIST_LOADING, payload: true });
    const userList : any = {
        query: 'CONTENT_FILTER_FILTERS_USERS',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, userList)
    .then((res) => handleResponse(res, dispatch, GET_CF_USER_LIST))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterVlanList = (vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_VLAN_LIST_LOADING, payload: true });
    const vlanList : any = {
        query: 'CONTENT_FILTER_FILTERS_ACCESS_NETWORK',
        named: true,
        parameters: {
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, vlanList)
    .then((res) => handleResponse(res, dispatch, GET_CF_VLAN_LIST))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterDeviceList = (vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_DEVICE_LIST_LOADING, payload: true });
    const deviceList : any = {
        query: 'CONTENT_FILTER_FILTERS_DEVICE_NAME',
        named: true,
        parameters: {
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, deviceList)
    .then((res) => handleResponse(res, dispatch, GET_CF_DEVICE_LIST))
    .catch((err) => handleError(err, dispatch));
};

export const getContentFilterSubCategoryList = (start, end, vessels) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_CF_SUb_CATEGORY_LIST_LOADING, payload: true });
    const subCategoryList : any = {
        query: 'CONTENT_FILTER_FILTERS_SUBCATEGORY',
        named: true,
        parameters: {
            start_time : start,
            end_time : end,
            location_ids : vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, subCategoryList)
    .then((res) => handleResponse(res, dispatch, GET_CF_SUB_CATEGORY_LIST))
    .catch((err) => handleError(err, dispatch));
};