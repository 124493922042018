import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';

import "leaflet.vectorgrid";
import { SET_SELECTED_HEX } from "../../actions/actionType";


const VsatGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, selectedLayer, contorsOpacity, isEventsEnabled, handleHexogonClick, selectedCarrier, h3Index, setHexId, selectedHexId, zindex, isVsatDataSource, uniqueContoursName } = props;
  const data = assets;

  const vectorGrid = useMemo(() => {

    return L.vectorGrid.slicer(data, {
      // rendererFactory: L.canvas.tile,
      // idField: 'vectorLayer',
      zIndex: zindex,
      vectorTileLayerStyles: {
        sliced: function (e) {
          if (uniqueContoursName[e?.obj_name]) {
            let style = { fill: true, fillColor: "#555555", fillOpacity: (contorsOpacity * 0.1), color: 'white', weight: 0.3 };
            return style;
          } else {
            return {
              fillOpacity: 0,
              stroke: false,
              fill: false,
              opacity: 0,
              weight: 0,
            }
          }
        },
      },
      getFeatureId: function (e) {
        return e?.properties?.obj_id
      },
      interactive: true,
    });

  }, [data, selectedCarrier, contorsOpacity, uniqueContoursName]);


  vectorGrid.on("mouseover", function (e) {
    L.popup()
      .setContent(e?.layer?.properties?.obj_name)
      .setLatLng(e.latlng)
      .openOn(map)
    vectorGrid.setFeatureStyle(e?.layer?.properties?.obj_id, { fill: true, fillColor: "964B00", fillOpacity: 0.6, color: "white", weight: 3 });
  });

  const resetSelectedHex = (hexId) => {
    vectorGrid.resetFeatureStyle(hexId);
  }

  // vectorGrid.on("click", function (e) {
  //   console.log("Beam Clicked");
  //   console.log(e);
  //   isEventsEnabled && h3Index(e?.layer?.properties?.h3index);
  //   resetSelectedHex(selectdHexLocal)
  //   handleHexogonClick(e);
  //   setHexId(e?.layer?.properties?.obj_id);
  //   setSelectedHeIdLocal(e?.layer?.properties?.obj_id)

  //   vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: "#FF0000", fillOpacity: 0.3, color: "#FF0000", weight: 3 });


  // });

  vectorGrid.on("mouseout", function (e) {
    vectorGrid.resetFeatureStyle(e?.layer?.properties?.obj_id);
  });

  const container = layerContainer || map;

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);

    };
  }, [data, selectedCarrier, contorsOpacity, uniqueContoursName]);

  return null;
}

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VsatGrid);
