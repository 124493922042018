import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TicketTable from "../../components/TicketTable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI } from "../../utils/util";
import NotAuthorized from "../NotAuthorized"
import { SIDE_MENU_ITEM_TICKETS } from "../../utils/constants";
import { BC_TICKETS } from "../../constants/Constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { updatePermission } from "../../actions/Users/authenticate";
import SubHeaderSummary from "../../components/SubHeaderSummary";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";

const TicketPage = (props) => {
  const { authReducer, updatePermission, location } = props;
  const setBreadcrumbInfo: any = React.useRef();
  let _q = getDecodeURI(location?.search);
  let [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const not_authorized_page_title = "Tickets";
  const not_authorized_page_message = "You are not authorised to view tickets";

  let SVF = SERVICE_FUNCTION_TO_FEATURE;
  const servicePortal = SVF && SVF["view-tickets"] && SVF["view-tickets"]["service"]
    ? SVF["view-tickets"]["service"]
    : (SVF && SVF["create-ticket"] && SVF["create-ticket"]["service"] ? SVF["create-ticket"]["service"] : "");

  const createTicket = SVF && SVF["create-ticket"] && SVF["create-ticket"]["function"]
    ? SVF["create-ticket"]["function"] : "";

  const viewTickets = SVF && SVF["view-tickets"] && SVF["view-tickets"]["function"]
    ? SVF["view-tickets"]["function"] : "";
  let breadcrumbInfo = BC_TICKETS;

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(servicePortal, viewTickets);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: servicePortal,
        serviceFunction: viewTickets,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);
  }, []);

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_TICKETS !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_TICKETS };
  }

  const handleSelctedSubDetails = () => { }

  return (
    <div className="Form">
      <div>
        <CommonFilters loading={authReducer?.loading || authReducer?.getTicketsLoading || authReducer?.userPreferencesLoading} showSummaryPeriod={_q.hasOwnProperty('status') && _q.status == 'Open' ? false : true} showWantType={false} userGuide={[true, 'tickets']} />
        {/* <SubHeaderSummary newSummary={true} subHeaderSelectedDropdown={handleSelctedSubDetails} showSummaryPeriod={showSummaryPeriod} showWantType={false}/> */}
      </div>
      <Grid item container style={{ backgroundColor: "#ffffff" }}>
        <Grid className="tickets-main-container">
          {
            isAuthorizedToViewPage
              ? [
                <TicketTable />
              ]
              :
              <Grid item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10 }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(connect(mapStateToProps, { updatePermission })(TicketPage));