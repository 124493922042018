import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI } from "../../utils/util";
import { SIDE_MENU_ITEM_DASHBOARD } from "../../utils/constants";
import { BC_DASHBOARD_VESSELS, BC_DASHBOARD_ALERTS, BC_DASHBOARD_LOCATIONS } from "../../constants/Constants";
import { INTERVALS } from "../../constants/Constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import FleetSelection from "../../components/FleetSelection";
import NotAuthorized from "../NotAuthorized"
import CardDetail from "../../components/CardDetails";
import { updatePermission } from "../../actions/Users/authenticate";
import "./FleetDashboard.css";
import _ from "lodash";
import { clearSiteDashboard } from "../../actions/Users/authenticateDashboard";
import SubHeaderSummary from "../../components/SubHeaderSummary";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";


var Loader = require("react-loader");
// const FILTER_BY_LIST = [
//   { label: "Vessel Name", value: "vesselName" },
//   { label: "System Type", value: "systemType" },
// ];

const FleetSummary = (props) => {
  const { authReducer, match, location, updatePermission, clearSiteDashboard } = props;
  let _q = getDecodeURI(location?.search);
  const setBreadcrumbInfo: any = React.useRef();
  const [search, setSearch] = useState<string>("");
  // const [filterBy, setFilterBy] = useState<string>("");
  const [interval, setInterval] = useState(_q.hasOwnProperty('interval') ? {label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval} : INTERVALS[1]);
  const [loading, setLoading] = useState(false);

  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";
  const [vessels, setVessels] = useState([]);

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];
  let breadcrumbInfo = BC_DASHBOARD_VESSELS;

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_DASHBOARD !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_DASHBOARD };
  }

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    return () => {
      clearSiteDashboard();
    }
  }, []);

  useEffect(() => {
    setLoading(authReducer.vesselDashboardLoading ||
      authReducer.vesselSummaryLoading ||
      authReducer.searchAlertsLoading ||
      authReducer.vesselLocWithAlertsLoading || authReducer.loading || authReducer.edgeServerStatusLoading || authReducer?.vesselWanLinkStatusLoading || authReducer?.sportSiteSummaryCountLoading ||
      authReducer.setWanProfilePerDeviceLoading || authReducer.setAdvancedBondingLoading || authReducer.setLANStatusLoading || authReducer.setConnectedHubLoading
    );
  }, [authReducer.vesselDashboardLoading,
  authReducer.vesselSummaryLoading,
  authReducer.searchAlertsLoading,
  authReducer.vesselLocWithAlertsLoading, authReducer.loading, authReducer.edgeServerStatusLoading,authReducer?.vesselWanLinkStatusLoading, authReducer?.sportSiteSummaryCountLoading,
  authReducer.setWanProfilePerDeviceLoading , authReducer.setAdvancedBondingLoading, authReducer.setLANStatusLoading, authReducer.setConnectedHubLoading
]);

  const handleSelctedSubDetails = () => {}

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
        return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
        vessels = filteredVessels;
    } else if(!_q.hasOwnProperty('k4Ids')) {
        vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
}, [authReducer?.getVesselsListing, authReducer?.filteredVessels]);

  return (
    <div className="Form">
      <div>
        {/* <SubHeaderSummary newSummary={true} subHeaderSelectedDropdown={handleSelctedSubDetails} setTimePeriod={setInterval} showWantType={false} /> */}
        <CommonFilters loading={loading || authReducer?.userPreferencesLoading} showWantType={false} periodOptions={['1h','15mins','2h','6h','12h','1d', '7d', '30d']} isCustomPeriodSelectionRequired={false} userGuide={[true, 'sites']} />      
      
      </div>
      <Grid key="dashboard" item container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="site-dashboard--container">
          {
            isAuthorizedToViewPage ? [
              <Grid key="SubHeader" item container className="SubHeader">
                <CardDetail search={search} interval={interval} vesselList={vessels} selectedOu={authReducer.selectedOu} />
              </Grid>,
              <Grid key="summary" className="summary">
                <FleetSelection  search={search} setSearch={setSearch} interval={interval} setInterval={setInterval}  loader={loading} isMapEnabled={true} vesselList={vessels}/>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission, clearSiteDashboard })(FleetSummary)
);
