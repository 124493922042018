import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { ServiceAccount } from "./types";
import { KAPTUREV2_URL_INV } from "../../config";
import axios from "axios";
import ServiceLines from "./ServiceLines";
import { toast } from "react-toastify";

const ACTION_TYPES = {
    SET_LOADING: "starlinkAdmin/setLoading",
    GET_SERVICE_ACCOUNTS: "starlinkAdmin/getServiceAccounts",
    CREATE_SERVICE_ACCOUNT: "starlinkAdmin/createServiceAccount",
    GET_SERVICE_LINES: "starlinkAdmin/getServiceLines",
    GET_USER_TERMINALS: "starlinkAdmin/getUserTerminals",
    GET_STARLINK_AUDIT_LOGS: "starlinkAdmin/getStarlinkAuditLogs",
    GET_ALL_SERVICE_LINES_BY_CREDENTIAL: "starlinkAdmin/getAllServiceLinesByCredential",
    GET_SERVICE_LINES_SUBSCRIPTIONS: "starlinkAdmin/getServiceLineSubscription",
    GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT: "starlinkAdmin/getAvailableProductsForAccount",
    GET_OPTIN_SERVICE_LINE: 'starlinkAdmin/getOptinServiceLine'
}

const initialState = {
    serviceAccounts: [],
    totalServiceAccounts: 0,
    gettingServiceAccounts: false,
    creatingServiceAccount: false,
    updatingServiceAccount: false,
    deletingServiceAccount: false,
    serviceLines: [],
    allServiceLines: [],
    totalServiceLines: 0,
    gettingServiceLines: false,
    updatingServiceLine: false,
    userTerminals: [],
    totalUserTerminals: 0,
    auditLogs:[],
    totalAuditLogs:0,
    gettingUserTerminals: false,
    subcribingServiceLine:false,
    allServiceLinesSubscription:[],
    availableProductsForAccount:[],
    optInServiceLineRes: {}
}

export function starlinkAdminReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_SERVICE_ACCOUNTS: {
            const [serviceAccounts, totalServiceAccounts] = action.payload?.data?.rows.length > 0 ?  action.payload?.data?.rows[0] : [];
            return {
                ...state,
                serviceAccounts: serviceAccounts || [],
                totalServiceAccounts: totalServiceAccounts ? totalServiceAccounts : 0,
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINES: {
            const [serviceLines, totalServiceLines] = action.payload?.data?.rows.length > 0 ? action.payload?.data?.rows[0] : [];
            return {
                ...state,
                serviceLines: serviceLines || [],
                totalServiceLines: totalServiceLines ? totalServiceLines : 0,
            }
        }
        case ACTION_TYPES.GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT: {
            return {
                ...state,
                availableProductsForAccount: action.payload?.data?.rows,
            }
        }
        case ACTION_TYPES.GET_USER_TERMINALS: {
            const userTerminals = action.payload?.data;
            const totalUserTerminals = action.payload?.data[0]?.total_count;
            return {
                ...state,
                userTerminals: userTerminals || [],
                totalUserTerminals: totalUserTerminals ? totalUserTerminals : 0,
            }
        }
        case ACTION_TYPES.GET_STARLINK_AUDIT_LOGS: {
            const auditLogs = action.payload?.data[0]?.result;
            const totalAuditLogs = action.payload?.data[0]?.total_count;
            return {
                ...state,
                auditLogs: auditLogs || [],
                totalAuditLogs: totalAuditLogs ? totalAuditLogs : 0,
            }
        }
        case ACTION_TYPES.GET_ALL_SERVICE_LINES_BY_CREDENTIAL: {
            const allServiceLines = JSON.parse(action.payload?.data?.rows[0][0]);
            return {
                ...state,
                allServiceLines: allServiceLines || [],
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINES_SUBSCRIPTIONS: {
            const allServiceLinesSubscription = action.payload || [];
            return {
                ...state,
                allServiceLinesSubscription: allServiceLinesSubscription
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case ACTION_TYPES.GET_OPTIN_SERVICE_LINE: {
            return {
                ...state,
                optInServiceLineRes: action.payload
            }
        }
        default:
            return state
    }
}

export interface getServiceAccountsParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
}

export const getServiceAccounts = (parameters: getServiceAccountsParameters) => (dispatch) => {
    if (_.isEmpty(parameters?.dpIds)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit
    };
    const data = {
        query: `GET_SERVICE_ACCOUNTS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_ACCOUNTS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: false } });
        });
}

export const createServiceAccount = (parameters: ServiceAccount, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        dpId: parameters.dpId,
        type: parameters.type,
        username: parameters.userName,
        password: parameters.password,
        clientId: parameters.clientId,
        clientSecret: parameters.clientSecret,
        validate: parameters.validate,
    }
    
    try {
        let res = await api.starlink.post('/credentials', data, {}, abortSignal)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const updateServiceAccount = (parameters: ServiceAccount) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: true } });
    const data = {
        dpId: parameters.dpId,
        type: parameters.type,
        username: parameters.userName,
        password: parameters.password,
        clientId: parameters.clientId,
        clientSecret: parameters.clientSecret,
    }
    
    try {
        let res = await api.starlink.put(`/credentials/${parameters.id}`, data)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return null;
    }
}

export const deleteServiceAccount = (id: string) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: true } });
    try {
        let res = await api.starlink.delete(`/credentials/${id}`)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: false } });
        return null;
    }
}

export interface getServiceLinesParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    serviceLineStatus:('true' | 'false')[];
    serviceLines: string[];
}

export const getServiceLines = (parameters: getServiceLinesParameters) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit,
        serviceLineStatus:  parameters.serviceLineStatus.map(status => `'${status}'`).join(','),
        serviceLines:  parameters.serviceLines.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_SERVICE_LINES_ADMIN`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export const getAvailableProductsForAccount = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
       accountNumber:parameters?.accountNumber
    };
    const data = {
        query: `GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export interface parametersUpdateServiceLineOrganization {
    serviceLineNumber: string;
    dpId: string;
}

export const updateServiceLineOrganization = (parameters: parametersUpdateServiceLineOrganization) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: true } });
    const data = {
        dpId: parameters.dpId,
    }
    try {
        let res = await api.starlink.put(`/service-lines/${parameters.serviceLineNumber}/organization`, data)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return null;
    }
}

export interface parametersBulkUpdateServiceLineOrganization {
    serviceLineNumbers: string[];
    dpId: string;
}

export const bulkUpdateServiceLineOrganization = (parameters: parametersBulkUpdateServiceLineOrganization) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: true } });
    try {
        let res = await api.starlink.put(`/service-lines/organization`, parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        showToastMessage(res, 'Change Sub Org');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return null;
    }
}

export interface getUserTerminalsParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    terminalStatus: ('deployed' | 'in-warehouse')[];
    serviceLines: string[];
}

export const getUserTerminals = (parameters: getUserTerminalsParameters) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit,
        terminalStatus: parameters.terminalStatus.map(status => `'${status}'`).join(','),
        serviceLines: parameters.serviceLines.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_USER_TERMINALS_ADMIN`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_USER_TERMINALS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export interface extractThroughCredentialParameters {
    id?: string;
    username?: string;
    clientId?: string;
    skipUsage?: boolean;
}

export const extractThroughCredential = (parameters: extractThroughCredentialParameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: true } });
    const data = {}
    for (let param of Object.keys(parameters)) {
        if (parameters[param]) {
            data[param] = parameters[param];
        }
    }
    try {
        let res = await api.starlink.post(`/credentials/extract`, data, {}, abortSignal)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return null;
    }
}

export interface getServiceLineByCredentialParameters extends extractThroughCredentialParameters {}

export const getAllServiceLinesByCredential = (parameters: getServiceLineByCredentialParameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    if (_.isEmpty(parameters?.clientId) && _.isEmpty(parameters?.username)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters = {
        clientId: parameters.clientId,
        username: parameters.username,
    };
    const data = {
        query: `GET_SERVICE_LINES_WORKFLOW`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    try {
        let res = await api.postRequestOut(`/store/ch/query`, data, abortSignal)
        handleResponse(res, dispatch, ACTION_TYPES.GET_ALL_SERVICE_LINES_BY_CREDENTIAL)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    } catch (err) {
        handleError(err, dispatch);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    }
}

export interface createSubOrgParameters {
    DPName: string;
    DPFullName: string;
    parentName: string;
}

export const createSubOrg = (parameters: createSubOrgParameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });    
    try {
        let res = await api.postRequestForExternalApi(`${KAPTUREV2_URL_INV}vessels/addDP`, parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res.data;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_SERVICE_ACCOUNTS, payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_STARLINK_AUDIT_LOGS,  payload: { data: [{result:[]}]} })
    dispatch({ type: ACTION_TYPES.GET_USER_TERMINALS,  payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_SERVICE_LINES,  payload: { data: { rows: [[[], 0]] } } })
    // dispatch({
}

export const subscribeToServiceLines = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/subscriptions', parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        showToastMessage(res, 'Subscribe');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

export const getServiceLineSubscription = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.dpId)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/getSubscriptionData', parameters)
       
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINES_SUBSCRIPTIONS)
        
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

const showToastMessage = (res, handler) => {
   if(res.success == false) {
    toast.error(`${handler} : ${res.message}, Please Try Later.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   } else {
    toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   }
}

export const optInForServiceLine = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/optInOptOut', parameters)

        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        dispatch({ type: ACTION_TYPES.GET_OPTIN_SERVICE_LINE, payload: res });
        showToastMessage(res, 'Mobile Priority');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

export const pauseResumeServiceLine = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/pauseResume', parameters)

        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        showToastMessage(res, 'Pause/Resume SL');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}


export const getStarlinkAuditLogs = (parameters) => (dispatch) => {
    clearComponentData()
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit,
        page: parameters.page,
        serviceLines: parameters.serviceLines.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_STARLINK_AUDIT_LOGS`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_AUDIT_LOGS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}
