import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment-timezone";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Autocomplete, TextField, InputAdornment, Button, Stack, Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import TuneIcon from '@mui/icons-material/Tune';
import Download from '../../asset/image/DownloadUsage.svg';
import { getEdgeEvents, getEdgeEventsFilter, downloadEdgeEventsTable, clearEdgeEventsDownload } from "../../actions/Users/authenticateEdgeEvents";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS, YYYYMMDDHHMM_UTC_OFFSET } from "../../utils/constants";
import CustomLoader from "../Loader/CustomLoaders";
import "./EdgeEvents.css"
import filter from "../../asset/image/filter.svg";
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import FilterEdgeEventsDialog from "./../FilterEdgeEventsDialog";
import DateRangeTimePicker from "../../vis/react-app/src/components/DateRangeTimePicker";
import { displayToastError } from "../../server/request";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_EDGE_EVENTS_FILTER } from "../../actions/error_types";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import JsonTooltip, { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { StyledEngineProvider } from '@mui/material/styles';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import GetApp from "@material-ui/icons/GetApp";
import { Pagination } from "../Pagination";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([convertDateTimeIntoTimezone(row[0], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[8], row[2], row[6], row[3], row[4],  row[5], row[7]])
    })
    let timestamp = 'Timestamp (' + userTimezone + ')'
    return [[timestamp, 'Site Name', 'DID', 'SOURCE', 'Level', 'Module', 'Description', 'Metadata'], ...csvRows]
}

const DEFAULT_PAGE = 0;

const EdgeEvents = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const MAX_OPEN_DATE = new Date();
    const MORE_FILTERS_TEXT = "Filters";
    const searchParams = getDecodeURI(location?.search);

    const { authReducer, errorReducer, getEdgeEvents, getEdgeEventsFilter, selectedVessel, selectedVesselName, downloadEdgeEventsTable, clearEdgeEventsDownload, downloadAsCSV, newSummaryStartDate, newSummaryEndDate } = props;
    const [edgeEventsData, setEdgeEventsData] = useState<any[]>([]);
    const [edgeEventsFitler, setEdgeEventsFilter] = useState({});
    const [showLoader, setShowLoader] = useState<Boolean>(false);
    const [loadingText, setLoadingText] = useState<String>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const [search, setSearch] = useState<String>("");
    const [showMoreFilterPopup, setMoreFiltersShowPopUP] = useState<Boolean>(false);
    const [moreFiltersButtonText, setMoreFiltersButtonText] = useState<String>(MORE_FILTERS_TEXT);
    const [pages, setPages] = useState<number>(0);
    const [page, setPage] = useState<number>(DEFAULT_PAGE);
    const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
    const [total, setTotal] = useState<number>(0);
    const [fromItem, setFromItem] = useState<number>(0);
    const [toItem, setToItem] = useState<number>(0);
    const [selectedEventFilters, setSelectedEventFilters] = useState({});
    // const [startDate, setStartDate] = useState(moment(Date.now()).subtract(3, 'days'));
    // const [endDate, setEndDate] = useState(moment(Date.now()));
    const [parameters, setParameters] = useState<any>({});
    const [isDownload, setIsDownload] = useState(false);
    const [trackSearch, setTrackSearch] = useState(false);
    const ENTER_KEY_CODE = "Enter";
    const searchTimeoutRef = useRef<NodeJS.Timeout>();


    useEffect(() => {
        !trackSearch && processQueryUrl();
        setTrackSearch(false);
    }, [location]);

    useEffect(() => {
        setEdgeEventsData([]);
        let deviceid = (selectedEventFilters['devices']?.length > 0) ? selectedEventFilters['devices'][0] : null;
        let level = (selectedEventFilters['levels']?.length > 0) ? selectedEventFilters['levels'][0] : null;
        let module = (selectedEventFilters['modules']?.length > 0) ? selectedEventFilters['modules'][0] : null;
        // let searchText = !_.isEmpty(search) ? search : null;
        // getEdgeEventsFilter(selectedVessel, startDate, endDate);

    }, [selectedVessel])



    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEvents)) {
            const edgeEvents = authReducer.getEdgeEvents;
            if (edgeEvents) {

                const rows: any[] = edgeEvents?.data?.rows;
                const columns: string[] = edgeEvents?.data?.columns;
                const edgeEventsData: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    edgeEventsData.push(row);
                }
                setEdgeEventsData(edgeEventsData);
            }
        }
    }, [authReducer.getEdgeEvents]);


    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEventsFilter)) {
            const edgeEvents = authReducer.getEdgeEventsFilter?.data;
            if (edgeEvents?.hasOwnProperty('filters')) {
                const data = edgeEvents.filters.data;
                if (data.length >= 3) {
                    let filters = {};
                    filters["sources"] = data[3] ? data[3].split(',') : ['No Records'];
                    filters["devices"] = data[0] ? data[0].split(',') : ['No Records'];
                    filters["modules"] = data[1] ? data[1].split(',') : ['No Records'];
                    filters["levels"] = data[2].split(',').filter((el) => el == "SEVERITY_CRITICAL").length > 0 ? data[2].split(',') : data[2] == "No Records" ? ["SEVERITY_CRITICAL"] : [...data[2].split(','), "SEVERITY_CRITICAL"];
                    const totalRecords = Number.parseInt(data[4]);
                    let totalPages = Math.ceil(totalRecords / limit);
                    let fromIndex = (totalRecords > 0) ? (page * limit + 1) : 0;
                    let toIndex = (fromIndex + limit - 1) > totalRecords ? totalRecords : (fromIndex + limit - 1);
                    setTotal(totalRecords);
                    setPages(totalPages);
                    setFromItem(fromIndex);
                    setToItem(toIndex);
                    setEdgeEventsFilter(filters);
                } else {
                    setTotal(0);
                    setPages(0);
                    setFromItem(0);
                    setToItem(0);
                    setEdgeEventsFilter({});
                }
            }
        }
    }, [authReducer.getEdgeEventsFilter])

    useEffect(() => {
       if(!_.isEmpty(authReducer.downloadEdgeEventsTable) && isDownload) {
        const data = authReducer?.downloadEdgeEventsTable;
        if (data) {
            const rows: any[] = data?.data?.rows;
            const columns: string[] = data?.data?.columns;
            const downloadData:any = [];
            for (let i = 0; i < rows?.length; i++) {
                const row = {};
                const currentRow = rows[i];
                columns.forEach((column, index) => {
                    row[column] = currentRow[index];
                })
                downloadData.push(row);
            }
            const pdfData = downloadData?.map((row, i) => {
                return [
                 row.ts ? moment(row.ts).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "",
                 row.device_id ? row.device_id : '',
                 row.source ? row.source : '',
                 row.level ? row.level : '',
                 row.module ? row.module : '',
                 row.description ? row.description : ''
                ]
             })
             const doc = new jsPDF('l', 'pt', 'a4');
             let now = moment(moment.now());
             doc.text(`Edge Events - ${now.toLocaleString()}`, 10, 30)
             autoTable(doc, {
             head: [["TIMESTAMP", "DID", "SOURCE", "LEVEL", "MODULE", "DESCRIPTION"]],
             body: pdfData,
             theme: "striped",
             startY: 50,
             margin: 10,
             headStyles: {
                 fillColor: "#264C86"
             }
             });
             if(pdfData?.length > 0) {
                doc.save(`edge-events-${now.toLocaleString()}.pdf`);
                setIsDownload(false);
                clearEdgeEventsDownload();
            }
        }
       }
    },[authReducer.downloadEdgeEventsTable, isDownload])

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEvents)) {
            displayToastError(errorReducer.errorGetEdgeEvents.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEvents]);

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEventsFitler)) {
            displayToastError(errorReducer.errorGetEdgeEventsFitler.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS_FILTER, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEventsFitler]);

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'topology-edge-events') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA',
                            1: 'ESC-COMMA',
                            2: 'ESC-COMMA',
                            5: 'ESC-COMMA',
                            6: "JSON"
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const processQueryUrl = () => {
 
        let parameters = {
            startTime: newSummaryStartDate?.toISOString(),
            endTime: newSummaryEndDate?.toISOString(), 
            locationId: selectedVessel, 
            pageSize: limit, 
            offset: page,
            deviceIdFilter: "",
            moduleFilter: "",
            levelFilter: "",
            sourceFilter: "",
            search: ""
        }
        let _q = getDecodeURI(location?.search);
        setSearch(_q.search ? _q.search : '');

        let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
        if (_limit != limit) {
            parameters.pageSize = _limit;
            setLimit(_limit);
        }

        let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0;
        if (_page != page) {
            parameters.offset = _page * _limit;
            setPage(_page);
        }        
        let filters : any = {}
        let SEVERITY = 'SEVERITY_CRITICAL';
        if(_q.filter && '' !== _q.filter.trim()){
            filters = JSON.parse(_q.filter);
            parameters.moduleFilter = filters.modules?.length > 0 ? `and module IN (${filters.modules.map(m => `'${m}'`).join()})`: '';
            parameters.deviceIdFilter = filters.devices?.length > 0 ? `and device_id IN (${filters.devices.map(d => `'${d}'`).join()})`: '';
            parameters.levelFilter = filters.levels?.length > 0 ? `and level IN (${filters.levels.map(l => `'${l}'`).join()})`: '';
            parameters.sourceFilter = filters?.sources?.length > 0 ? `and source IN (${filters.sources.map(s => `'${s}'`).join()})`: '';
        } else {
            parameters.levelFilter = `and level IN ('${SEVERITY}')`;
            filters = {
                "levels": [
                    "SEVERITY_CRITICAL"
                ],
                "modules": [],
                "devices": []
            };
        }
        setSelectedEventFilters(filters);

        let _startDate = newSummaryStartDate;
        if(_q.startDate && '' !== _q.startDate && _q.startDate > 1) {
            let incominStarDate =  moment(new Date(parseInt(_q.startDate)));
            // setStartDate(incominStarDate);
            _startDate = incominStarDate;
            parameters.startTime = incominStarDate.toISOString();
        }

        let _endDate = newSummaryEndDate;
        if(_q.endDate && '' !== _q.endDate && _q.endDate > 1) {
            let incominEndDate = moment(new Date(parseInt(_q.endDate)));
            // setEndDate(incominEndDate);
            _endDate = incominEndDate;
            parameters.endTime = incominEndDate.toISOString();
        }

        if(_q.search && _q.search.trim() !== '') {
            parameters.search = `and (concat_ws(',',if(device_id IS NULL,'',device_id),if(deviceAlias IS NULL,'',deviceAlias),if(level IS NULL,'',level),if(deviceAlias IS NULL,'',deviceAlias),
            if(description IS NULL,'',description),if(source IS NULL,'',source)) ILIKE '%${_q.search}%' OR  b_metadata ILIKE '%${_q.search}%')`
            setSearchValue(_q.search);
        }

        if(!_startDate.isBefore(_endDate)){
            return;
        }
        setParameters(parameters);
        getEdgeEventsFilter(parameters);
        getEdgeEvents(parameters);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue );
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        setPage(0);
        doNavigate(params);
    }

    const clearSearch = () => {
        let params: any = getDecodeURI(location.search);
        delete params.search;
        doNavigate(params);
        setSearchValue("");
    }

    const handlePerPageChange = (e: any) => {
        let params: any = getDecodeURI(location.search);
        const value = parseInt(e.target.value);
        params.page = 0;
        params.limit = value;
        doNavigate(params);
    }

    const handlePagination = (e: any, value: number) => {
        let params: any = getDecodeURI(location.search);
        params.page = value - 1;
        doNavigate(params);
    }

    // const handleStartDatePicker = (_startDate: moment.Moment | null) => {
    //     if (_startDate == null || !_startDate.isValid() || startDate.isSame(_startDate))
    //         return;
    //     setPage(0)
    //     let params: any = getDecodeURI(location.search);
    //     params.startDate = _startDate.valueOf();
    //     params.page = 0;
    //     doNavigate(params);
    // }

    // const handleEndDatePicker = (_endDate: moment.Moment | null) => {
    //     if (_endDate == null || !_endDate.isValid() || endDate.isSame(_endDate))
    //         return;
    //     setPage(0)
    //     let params: any = getDecodeURI(location.search);
    //     params.page = 0;
    //     params.endDate = _endDate.valueOf();
    //     doNavigate(params);
    // }

    const onApplyFilter = (selecedEventFilters) => {
        let params: any = getDecodeURI(location.search);
        params.filter = JSON.stringify(selecedEventFilters);
        delete params.page;
        doNavigate(params);
    }

    const handleDownlodReport = () => {
        if (total <= 0) return;
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'topology-edge-events',
            payload: { ...parameters, offset: 0, pageSize: total },
            queryName: 'EDGE_EVENTS',
        }
        downloadAsCSV(params);
    }


    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }
    
    let inputDateFormat = "YYYY-MM-DD HH:mm:ss.SSSSSS Z UTC";

    return (
        <Fragment><StyledEngineProvider injectFirst>
            <Grid container className="container-edgeEvent padding-edge-events-container" padding={2}>
                <Grid container item xs={12} sm={12} md={12} lg={12} className='margin-events align-filters'>
                    
                    <Grid item xs={12} sm={6} md={3} lg={2} className='edgeEvents-search'>
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            classes={{root: "input-box-sw-vt"}}
                            size="small"
                            value={searchValue}
                            onChange={handleOnSearchChange}
                            onKeyDown={(event) => searchBoxKeyDown(event)}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar events-searchBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchValue && <SearchIcon
                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                        />}
                                        {searchValue && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={9} className="FiltersButtonContainer">
                        <Button variant="outlined" endIcon={<TuneIcon />}  className="EdgeEvent_moreFilters_button cursorPointer position-relative" style={{ border: "1px solid #1976d2", background:"rgba(25, 118, 210, 0.04)", display: "flex", justifyContent: "center" }} onClick={() => setMoreFiltersShowPopUP(true)}>
                            Filter
                        </Button>
                    </Grid>
                 
                    {/* <Grid item className="edgeEvents-download">
                        <Tooltip title='Download'>
                            <div
                                className={`d-flex align-center `}
                                onClick={() => DownloadEventsTable()}
                            >
                                <GetApp className="exportUsage"></GetApp>
                            </div>
                        </Tooltip>
                    </Grid> */}
                    {total > 0 && <Grid item xs={1} sm={1} md={1} lg={1} >
                        <div className="download-summary-btn" onClick={handleDownlodReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}>
                    <Box>
                        <Pagination
                            rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                            count={total ? total : 0}
                            rowsPerPage={limit}
                            page={page + 1}
                            onPageChange={handlePagination}
                            onRowsPerPageChange={handlePerPageChange}
                        />
                    </Box>
                    <TableContainer className="alertsTable-FleetDashboard">
                        <Table aria-label="simple table">
                            <TableHead className="tableHead alertsTable-tableHead">
                            <TableRow className="alertReports-tableRow">
                                <TableCell className="alertsTable-fleetDataTable font-wt-900">TIMESTAMP</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900">DID</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900">SOURCE</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900">LEVEL</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900">MODULE</TableCell>
                                <TableCell align="left" className="alertsTable-fleetDataTable  font-wt-900 width-20">DESCRIPTION</TableCell>
                                <TableCell className="alertsTable-fleetDataTable  font-wt-900">METADATA</TableCell>
                            </TableRow>
                            </TableHead>
                            { edgeEventsData.length > 0 &&
                            <TableBody className="tableBody alertsTable-tableBody">
                                {
                                edgeEventsData.length > 0 && edgeEventsData.map((row) =>
                                    <TableRow className="alertsData-tabelRow">
                                    <TableCell className="alertsTable-tableCell">
                                    {convertDateTimeIntoTimezone(row['ts'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                    </TableCell>
                                    <TableCell className="alertsTable-tableCell">
                                    {row['device_id']}
                                    </TableCell>
                                    <TableCell className="alertsTable-tableCell alertsTable-tableCell-source">{row['source']}</TableCell>
                                    <TableCell className="alertsTable-tableCell">{row['level']}</TableCell>
                                    <Tooltip title={<span style={{ fontSize: "12px" }}>{row['module']}</span>}>
                                        <TableCell className="alertsTable-tableCell">{row['module']}</TableCell>
                                    </Tooltip>
                                    <Tooltip title={<span style={{ fontSize: "12px" }}>{row['description']}</span>}>
                                        <TableCell className="events-description-width" >{row['description']}</TableCell>
                                    </Tooltip>
                                    <TableCell className="alertsTable-tableCell labelDetails">
                                    {row.hasOwnProperty("metadata") && (_.isEmpty(row["metadata"]) || null == row["metadata"] || "null" === row["metadata"]) ? null :<JsonDataTooltip data={row['metadata']} />}
                                    </TableCell>
                                    </TableRow>
                                )
                                }
                            </TableBody> } 
                            
                            {edgeEventsData.length === 0 && <TableBody>
                                <TableRow>
                                <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>}
                            
                        </Table>
                     </TableContainer>
                </Grid>
            </Grid>
            <FilterEdgeEventsDialog open={showMoreFilterPopup} edgeEventsFitler={edgeEventsFitler} onClose={() => { setMoreFiltersShowPopUP(false) }} onApplyFilter={onApplyFilter} selectedEventFilters={selectedEventFilters}></FilterEdgeEventsDialog>
        </StyledEngineProvider></Fragment>);
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getEdgeEvents,
        getEdgeEventsFilter,
        downloadEdgeEventsTable,
        clearEdgeEventsDownload,
        downloadAsCSV
    })(EdgeEvents)
);
