import React, { Fragment, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SIDE_MENU_ITEM_SIM_REPORTS, SIM_ANALYSIS, GRAPHICAL_DATA_USAGE, DATA_USAGE, USER_DATA, FIREWALL_REPORT, GENERATED_REPORTS, CONTENT_FILTER } from "../../utils/constants";
import { BC_REPORTS } from "../../constants/Constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import SimAnalysis from "../SimAnalysis";
import DataUsage from "../DataUsage";
import GraphicalDataUsage from "../GraphicalDataUsage";
import FirewallReport from "../FirewallReport";
import _ from "lodash";
import { updatePermission } from "../../actions/Users/authenticate";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, getServiceFunction } from "../../utils/util";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import UserData from "../UserData";
import "./simReports.css";
import CellularChart from "../SimAnalysis/CellularChart";
import GeneratedReports from '../../components/GeneratedReports';
import ContentFilter from "../../components/ContentFilter";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import moment from "moment-timezone";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";
import InfoIcon from '@mui/icons-material/Info';
import close from "../../asset/image/close.svg";
var Loader = require("react-loader");

const VIEW_VESSEL_SUMMARY = "view-vessel-summary";

const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

const SimReports = (props) => {

    const { authReducer, match, location, history, updatePermission, newSummaryStartDate, newSummaryEndDate } = props;
    const setBreadcrumbInfo: any = React.useRef();
    const updateData: any = React.useRef();
    let [breadcrumbs, __] = useState(BC_REPORTS);
    const [activeTab, setActiveTab] = useState<string>(match?.params?.tab ? match.params.tab : USER_DATA);
    const [loading, setLoading] = useState<Boolean>(false);
    const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
    const TABLE = 'table';
    const CHART = 'chart';
    let _q = getDecodeURI(location?.search);
    const [cellularTab, setCellularTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : TABLE);
    const dispatch = useDispatch();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: ""
    });

    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_SIM_REPORTS !== authReducer.sideMenu.menuItem)) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_SIM_REPORTS };
    }

    useEffect(() => {
        const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
        const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
        const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
        setIsAuthorizedToViewBetaFeature(authorizedBetaUser)
    }, []);

    const permissionChanges = (showOu, showSite) => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
        if (authorized) {
            const info = {
                permission: {
                    service: viewVesselSummaryService,
                    serviceFunction: viewVesselSummaryFunction,
                },
                isMultiVessel: showSite,
                isSingleVessel: false,
                showOu: showOu,
                ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);
        }
    }

    useEffect(() => {
        applyBreadcrumb(BC_REPORTS, activeTab);
        permissionChanges(true, activeTab !== GENERATED_REPORTS ? true : false);
    }, [activeTab])

    useEffect(() => {
        setLoading(authReducer.graphicalDataUsageLoading ||
            authReducer.getSimBoxesLoading ||
            authReducer.activityReportsLoading ||
            authReducer.activityReportsAppsLoading ||
            authReducer.activityReportsVlansLoading ||
            authReducer.activityReportsWidgetLoading ||
            authReducer.activityReportsCategoryPieLoading ||
            authReducer.activityReportsApplicationPieLoading ||
            authReducer.activityReportsUsageLineChartLoading ||
            authReducer.activityReportsTopAppLineChartUsage ||
            authReducer.activityReportsTopUsersLoading ||
            authReducer.activityReportsTopSitesLoading ||
            authReducer.setActivityReportsBottomTableLoading ||
            authReducer.setActivityReportsTopBlockedAppBottomTableLoading ||
            authReducer.setFirewallWidgetsLoading ||
            authReducer.setFirewallActiveTodayLoading ||
            authReducer.setFirewallRuleNameLoading ||
            authReducer.setFirewallRuleNameFilterLoading ||
            authReducer.setFirewallTableDataCountLoading ||
            authReducer.setFirewallTableDataLoading ||
            authReducer.setFirewallLineChartLoading ||
            authReducer.setCellularDataUsageLoading ||
            authReducer.setGeneratedReportsTableLoading ||
            authReducer.setGeneratedReportsTableCountLoading ||
            authReducer.setContentFilterTopUsersTableLoading ||
            authReducer.setContentFilterTopUsersTableCountLoading ||
            authReducer.setContentFilterTopSitesTableLoading ||
            authReducer.setContentFilterTopSitesTableCountLoading ||
            authReducer.setContentFilterCategoryPieLoading ||
            authReducer.setContentFilterApplicationPieLoading ||
            authReducer.setContentFilterSubCategoryPieLoading ||
            authReducer.setContentFilterTopWidgetTopSiteLoading ||
            authReducer.setContentFilterTopWidgetTopUserLoading ||
            authReducer.setContentFilterTopWidgetTopCategoryLoading ||
            authReducer.setContentFilterTopWidgetTopDomainLoading ||
            authReducer.setContentFilterLinechartLoading ||
            authReducer.setContentFilterCategoryListLoading ||
            authReducer.setContentFilterAppListLoading ||
            authReducer.setContentFilterUserListLoading ||
            authReducer.setContentFilterVlanListLoading ||
            authReducer.setContentFilterDeviceListLoading ||
            authReducer.steContentFilterSubCategoryListLoading ||
            authReducer.setActivityReportsBottomTableDomainLoading ||
            authReducer.setActivityReportsBottomTableIPLoading ||
            authReducer.setTopWidgetRiskScore ||
            authReducer.setPieChartAllSiteRisk ||
            authReducer.loading 
        );
    }, [authReducer.graphicalDataUsageLoading,
        authReducer.getSimBoxesLoading,
        authReducer.activityReportsLoading,
        authReducer.activityReportsAppsLoading,
        authReducer.activityReportsVlansLoading,
        authReducer.activityReportsWidgetLoading,
        authReducer.activityReportsCategoryPieLoading,
        authReducer.activityReportsApplicationPieLoading,
        authReducer.activityReportsUsageLineChartLoading,
        authReducer.activityReportsTopAppLineChartUsage,
        authReducer.activityReportsTopUsersLoading,
        authReducer.activityReportsTopSitesLoading,
        authReducer.setActivityReportsBottomTableLoading,
        authReducer.setActivityReportsTopBlockedAppBottomTableLoading,
        authReducer.loading,
        authReducer.setFirewallWidgetsLoading,
        authReducer.setFirewallActiveTodayLoading,
        authReducer.setFirewallRuleNameLoading,
        authReducer.setFirewallRuleNameFilterLoading,
        authReducer.setFirewallTableDataCountLoading,
        authReducer.setFirewallTableDataLoading,
        authReducer.setFirewallLineChartLoading,
        authReducer.setCellularDataUsageLoading,
        authReducer.setGeneratedReportsTableLoading,
        authReducer.setGeneratedReportsTableCountLoading,
        authReducer.setContentFilterTopUsersTableLoading,
        authReducer.setContentFilterTopUsersTableCountLoading,
        authReducer.setContentFilterTopSitesTableLoading,
        authReducer.setContentFilterTopSitesTableCountLoading,
        authReducer.setContentFilterCategoryPieLoading,
        authReducer.setContentFilterApplicationPieLoading,
        authReducer.setContentFilterSubCategoryPieLoading,
        authReducer.setContentFilterTopWidgetTopSiteLoading,
        authReducer.setContentFilterTopWidgetTopUserLoading,
        authReducer.setContentFilterTopWidgetTopCategoryLoading,
        authReducer.setContentFilterTopWidgetTopDomainLoading,
        authReducer.setContentFilterLinechartLoading,
        authReducer.setContentFilterCategoryListLoading,
        authReducer.setContentFilterAppListLoading,
        authReducer.setContentFilterUserListLoading,
        authReducer.setContentFilterVlanListLoading,
        authReducer.setContentFilterDeviceListLoading,
        authReducer.steContentFilterSubCategoryListLoading,
        authReducer.setActivityReportsBottomTableDomainLoading,
        authReducer.setActivityReportsBottomTableIPLoading,
        authReducer.setTopWidgetRiskScore,
        authReducer.setPieChartAllSiteRisk,
    ]);

    useEffect(()=>{
        let _q: any = getDecodeURI(location?.search);
       
        if(!_q?.interval && !_q.isFixedIntervalDisabled){
          const start = moment(Date.now()).utc().subtract(1, 'hour');
        const end = moment(Date.now()).utc();
        dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end})
        dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start})   
        }
        
    },[])

    const applyBreadcrumb = (bc: any, tab) => {
        let bcObj = bc;
        if (tab) {
            if ('cellular' === tab) {
                bcObj[3] = { title: 'SIM Analysis' };
            }
            else if (DATA_USAGE === tab) {
                bcObj[3] = { title: 'Data Usage' };
            }
            else if(USER_DATA === tab) {
                bcObj[3] = {title: 'User Data'};
            }
            else if(GENERATED_REPORTS === tab) {
                bcObj[3] = {title: 'Generated Reports'};
            }
            else if(CONTENT_FILTER === tab) {
                bcObj[3] = {title: 'Content Filter'};
            }
            else {
                bcObj[3] = { title: 'Wan Usage' };
            }
            return bcObj;
        } else {
            return bcObj;
        }
    };


    const doNavigateSIM = (pathname, tabName) => {
        const currentParams = getDecodeURI(location?.search);
        const params = {};
        if(currentParams.ouName) {
            params['ouName'] = currentParams.ouName;
        }
        if(currentParams.ouId) {
            params['ouId'] = currentParams.ouId;
        }
        if(currentParams.k4Ids) {
            params['k4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
            params['sideNav'] = currentParams.sideNav
          }
        if(currentParams.interval) {
        params['interval'] = currentParams.interval
        }
        if(currentParams.startDate && currentParams.startDate > 1) {
        params['startDate'] = currentParams.startDate
        }
        if(currentParams.endDate && currentParams.endDate > 1) {
        params['endDate'] = currentParams.endDate
        }
        if(tabName == GENERATED_REPORTS) {
            params['reportsTab'] = true;
        }
        params['timezone'] = authReducer.userTimezone;
        history.push({ pathname, search: getEncodedURI(params) })
        setActiveTab(tabName)
    }

    const doNavigateToCellularTable = () => {
        const params:any = getDecodeURI(location?.search);
        params.tab = TABLE;
        delete params.page;
        delete params.date;
        delete params.month;
        delete params.year;
        delete params.providers;
        history.push({ pathname: '/reports/cellular', search: getEncodedURI(params) })
        setCellularTab(TABLE);
    }

    const doNavigateToCellularChart = () => {
        const params:any = getDecodeURI(location?.search);
        params.tab = CHART;
        delete params.page;
        delete params.limit;
        delete params.filterBy;
        delete params.search;
        history.push({ pathname: '/reports/cellular', search: getEncodedURI(params) })
        setCellularTab(CHART);
    }

    return (
        <div className="Form">
            {/* <BreadCrumbs breadcrumbinfo={breadcrumbs} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading}></BreadCrumbs> */}
            <CommonFilters loading={loading || authReducer?.userPreferencesLoading} showWantType={false} showSummaryPeriod={ GENERATED_REPORTS === activeTab || SIM_ANALYSIS === activeTab ? false : true} periodOptions={activeTab == GRAPHICAL_DATA_USAGE && ['1h', '6h', '12h', '1d', '7d', '30d', 'MTD', 'customDates']} userGuide={[activeTab == USER_DATA ? true : false, 'activity-reports-user-guide']} />
            <Grid className="Reports-NavigationBtns">
                <Grid className={activeTab === GENERATED_REPORTS ? "Reports-navbarBtns navigation-buttons-reports-div hide-overlap" : "Reports-navbarBtns navigation-buttons-reports-div"}>
                    <Grid>
                        <Button
                            className={activeTab === GRAPHICAL_DATA_USAGE ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/wanUsage", GRAPHICAL_DATA_USAGE)
                            }}
                            title="WAN Usage"
                        >
                            WAN Usage
                        </Button>
                        {<Button
                            className={activeTab === USER_DATA ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/activity", USER_DATA)
                            }}
                        >
                            Activity
                        </Button>}
                        <Button
                            className={activeTab === FIREWALL_REPORT ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/firewall-report", FIREWALL_REPORT)
                            }}
                            title="Firewall Reports"
                        >
                            Firewall
                        </Button>
                        <Button
                            className={activeTab === CONTENT_FILTER ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/contentFilter", CONTENT_FILTER)
                            }}
                            title="Content Filter"
                        >
                            Content Filter
                        </Button>
                        <Button
                            className={activeTab === SIM_ANALYSIS ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/cellular", SIM_ANALYSIS)
                            }}
                        >
                            Cellular
                        </Button>
                        {<Button
                            className={activeTab === GENERATED_REPORTS ? "usage-buttonCssFleet" : "inActiveUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/generatedReports", GENERATED_REPORTS)
                            }}
                        >
                            Generated Reports
                        </Button>}
                        {/* <Button
                            className={activeTab === DATA_USAGE ? "dataUsage-buttonCssFleet" : "inActiveDataUsage-buttonCssFleet"}
                            onClick={() => {
                                doNavigateSIM("/reports/dataUsage", DATA_USAGE)
                            }}
                            title="Data Usage"
                        >
                            Data Usage
                        </Button> */}
                    </Grid>
                    {activeTab === GRAPHICAL_DATA_USAGE && <div onClick={() => setConfirmDialog({
                        isOpen: true,
                        title: 'Information',
                        subTitle: `"WAN usage is measured on the Edge system for each WAN interface. The Edge usage may differ from Carrier usage (e.g. Starlink or T Mobile) due additional radio network and operational overheads from the carrier.
                    Starlink provided usage is on average 8% larger than Edge Starlink WAN usage."`,
                    })} className="help-icon-create wan-usage-help helpIcon-reports"><InfoIcon />
                    </div>}
                    {activeTab === USER_DATA && <div className="help-icon-create wan-usage-help helpIcon-reports">
                        <Tooltip placement="left" title='Operational usage is not included in the Activity Reports.'><InfoIcon /></Tooltip>
                    </div>}
                </Grid>
            </Grid>
            <Grid key="content" item container className="ReportPagesContainer">
                <Grid key="content1" className="firewall-scroll" item>
                    <Grid className={GENERATED_REPORTS === activeTab ? "firewall-scroll gen-rep-height" : "firewall-scroll"}>
                        <div>
                            <div className={FIREWALL_REPORT === activeTab ? "fw-reports-tabsContainer" : "Reports-TabsContainer"}>
                                {
                                  USER_DATA === activeTab ? (
                                    <Fragment key={USER_DATA}>
                                        <UserData isAuthorizedToViewBetaFeature={localStorage.getItem("isBetaUser") === "true" } />
                                    </Fragment>
                                ) : 
                                FIREWALL_REPORT === activeTab ? (
                                    <Fragment key={FIREWALL_REPORT}>
                                        <FirewallReport isAuthorizedToViewBetaFeature={localStorage.getItem("isBetaUser") === "true" } />
                                    </Fragment>
                                ) :
                                SIM_ANALYSIS === activeTab ? (
                                    <Fragment key={SIM_ANALYSIS}>
                                        <Grid>
                                            <Grid container className="inventory-config-container">
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Grid container className="cellular-sub-tabs">
                                                        <Grid item xs={1} sm={1} md={1} lg={1} onClick={doNavigateToCellularTable}>
                                                            <Button className={cellularTab == TABLE ? 'table-active-tab' : 'chart-active-tab'}>Table</Button>
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1} lg={1} onClick={doNavigateToCellularChart}>
                                                            <Button className={cellularTab == CHART ? 'table-active-tab' : 'chart-active-tab'}>Graph</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                { cellularTab == TABLE ? <SimAnalysis /> : <CellularChart/> }
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                ) : DATA_USAGE === activeTab ? (
                                    <Fragment key={DATA_USAGE}>
                                        <DataUsage />
                                    </Fragment>
                                ) : GRAPHICAL_DATA_USAGE === activeTab ? (
                                    <Fragment key={GRAPHICAL_DATA_USAGE}>
                                        <GraphicalDataUsage />
                                    </Fragment>
                                ) : FIREWALL_REPORT === activeTab ? (
                                    <Fragment key={FIREWALL_REPORT}>
                                        <FirewallReport />
                                    </Fragment>
                                ) : GENERATED_REPORTS === activeTab ? (
                                    <Fragment key={GENERATED_REPORTS}>
                                        <GeneratedReports />
                                    </Fragment>
                                ) : CONTENT_FILTER === activeTab ? (
                                    <Fragment key={CONTENT_FILTER}>
                                        <ContentFilter />
                                    </Fragment>
                                ) :
                                    null}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {confirmDialog.isOpen ? <ShowText confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} /> : null}
        </div>
    );
}

function ShowText({confirmDialog, setConfirmDialog}) {

    return (
      <div className="text-msg-dialogue">
        <Dialog open={confirmDialog.isOpen} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} className="custom_confirm_dialog custom-text-dialogue">
          <DialogTitle className="text-msg-dialogue-title">
            <div>
              {confirmDialog.title}
            </div>
            <img src={close} className="text-close" title="Close" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
          </DialogTitle>
          <DialogContent className="text-msg-dialogue-content" style={{paddingTop: "45px", paddingBottom: '50px' }}>
            <div className="custom_confirm_dialog_sub_title">
              {confirmDialog.subTitle}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        updatePermission
    })(SimReports)
);