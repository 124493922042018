import { useState, useEffect } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getVesselEdgeTopologyProducts } from "../../actions/Users/authenticateDashboard";
import _ from "lodash";
import "./VesselSummary.css";
import onlineIcon from "../../asset/image/online.svg";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import SettingsIcon from '@mui/icons-material/Settings';

import {
    LOCATION_ID_KEY, ID_KEY, NAME_KEY, TYPE_KEY,
    HARDWARE_VESION_KEY, SOFTWARE_VERSION_KEY, STATUS
} from "../../utils/constants";
import EdgeLinkIcon from "../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../asset/image/linkDisabled.svg"
import { getEdgeServerStatus } from "../../actions/Users/graphQlServices";
import { USER_OU } from "../../constants/Constants";
import { checkLoggedInUserAuthorizedToViewPage, checkSelectedOuIsInvitedOu, convertDateTimeIntoTimezone, getDataFromLocalStorage, getDataFromLocalStorageParsed, getDecodeURI, getInvitedOuList } from "../../utils/util";
import NameUpdate from "./NameUpdate";
import userEditImage from "../../asset/image/user_edit.svg";
import { vesselAddressMMSI } from "../../actions/Users/authenticateService"
import { clearData, getGeneratedLinks } from "../RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../server/request";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { getOrganisationUnitList, updateUserOuHasSubOus } from "../../actions/Users/authenticate";
import { backupConfigNow, getDevicewiseLatestEOSversion, rebootDevice } from "../../actions/Users/authenticateInventory";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { compare } from "compare-versions";
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import SoftwareUpgrade from "../InventoryDeployedTable/SoftwareUpgrade";
import { DEVICEWISE_LATEST_EOS_VERSION, GET_HIGH_AVAILABILITY_DEVICE} from "../../actions/types";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { getHighAvailabilityDevice } from "../../actions/Users/authenticateHighAvailability";
import UpdatesGoingOn from "../../asset/image/updates_going_on.svg";
import ManageConfigLogs from "./ManageConfigLogs";
import EditActionsPopup from "../InventoryDeployedTable/EditActionsPopup";

const VesselSummary: any = (props) => {
    const { authReducer, selectedVessel, selectedVesselName, getEdgeServerStatus, updateVesselName, vesselAddressMMSI, getGeneratedLinks, backupConfigNow, getDevicewiseLatestEOSversion, rebootDevice , isReadOnlyUser } = props;
    let [products, setProducts]: any = useState([]);
    let [productsArray, setProductsArray]: any = useState([]);
    const [selectedProductId, setSelectedProductId] = useState<string>("");
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
    const [openUpdateSiteName, setOpenUpdateSiteName] = useState(false);
    const [openUpdateProductName, setOpenUpdateProductName] = useState(false);
    const [clickedProductName, setClickedProductName] = useState("");
    const [clickedProductId, setClickedProductId] = useState("");
    const [clickedProductDevices, setClickedProductDevices] = useState<any[]>([])
    const [k4Id, setK4Id] = useState("")
    const [ouName, setOuName] = useState("")
    const [editActionsPopup, setEditActionsPopup] = useState(false);
    const [editActionRow, setEditActionRow] = useState<any>({});
    const dispatch = useDispatch();

    const isAuthorizedToBeta= checkLoggedInUserAuthorizedToViewPage(SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"], SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"]);
    const [isSelectedOuIsUserOu, setIsSelectedOuIsUserOu] = useState(false)
    const [invitedOus, setInvitedOus] = useState<any[]>([])

    const [devicewiseLatestStableOsVersion, setDevicewiseLatestStableOsVersion] = useState<object>({})


    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    useEffect(()=>{
        if (!_.isEmpty(selectedVessel)) {
            getDevicewiseLatestEOSversion()
        }
    },[selectedVessel])

    useEffect(() => {
        if (!_.isEmpty(authReducer.devicewiseLatestEOSversion)) {
          const data = authReducer.devicewiseLatestEOSversion.hasOwnProperty("data") ? authReducer.devicewiseLatestEOSversion.data : {}
          if(!_.isEmpty(data)){
            setDevicewiseLatestStableOsVersion(data)
          } else {
            setDevicewiseLatestStableOsVersion({})
          }
        dispatch({ type: DEVICEWISE_LATEST_EOS_VERSION, payload: true });
        }
      },[authReducer.devicewiseLatestEOSversion])

    useEffect(() => {
        updateUserOuHasSubOus(authReducer)
        let invitedOuList = getInvitedOuList(authReducer.getOrganisationUnitList)
        setInvitedOus(invitedOuList)
    }, [authReducer.getOrganisationUnitList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
            && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {

            let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
            setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
        }
    }, [authReducer.selectedOu]);

    useEffect(() => {
        if (invitedOus.length > 0) {
            let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
            setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
        }else {
            setIsSelectedOuIsUserOu(true)
        }
    }, [invitedOus])


    const createProductData = (k4Id, id, name, type = "-", hardwareVersion = "-", softwareVersion = "-", status = "-", devices = [], dp_name, ou_user) => {
        return { k4Id, id, name, type, hardwareVersion, softwareVersion, status, devices, dp_name, ou_user };
    }


    // useEffect(() => {
    //     if (products && products.length > 0) {
    //         let devicesArray: string[] = [];
    //         products.map((product) => {
    //             product.devices.map((pro) => {
    //                 devicesArray.push(pro.id)
    //                 //    return 
    //             })
    //         })
    //         getEdgeServerStatus(devicesArray)

    //     }
    // }, [products])

    // useEffect(() => {

    //     if (!_.isEmpty(authReducer.edgeServerStatus) && authReducer.edgeServerStatus.data) {
    //         let endpoint_status: any[] = authReducer?.edgeServerStatus?.data?.getVesselsEndPointStatusResponse?.endpoint_status;

    //         let _products = JSON.parse(JSON.stringify(products));
    //         for (let i = 0; i < _products.length; i++) {
    //             for (let j = 0; j < _products[i].devices.length; j++) {
    //                 let deviceId = _products[i].devices[j].id;
    //                 let status = "-"
    //                 let last_seen = "-";
    //                 for (let k = 0; k < endpoint_status.length; k++) {
    //                     if (deviceId === endpoint_status[k].name && endpoint_status[k].last_seen != null) {
    //                         {
    //                             status = endpoint_status[k].status;
    //                             last_seen = endpoint_status[k].last_seen.seconds;
    //                         }
    //                     }
    //                 }
    //                 _products[i].devices[j].status = status;
    //                 _products[i].devices[j].last_seen = last_seen;

    //             }
    //         }
    //         setK4Id(_products[0]?.k4Id)
    //         setProductsArray(_products)

    //         authReducer.vesselEdgeInfo = {};
    //     }
    // }, [authReducer.edgeServerStatus]);

    useEffect(() => {
        if(k4Id){
            vesselAddressMMSI(k4Id)
        }
    }, [k4Id])


    useEffect(() => {
        if (!_.isEmpty(authReducer.getHighAvailabilityDevice) && !_.isEmpty(devicewiseLatestStableOsVersion)) {
            if (authReducer.getHighAvailabilityDevice.hasOwnProperty("status") && authReducer.getHighAvailabilityDevice.status === "ok") {
                if (authReducer.getHighAvailabilityDevice.hasOwnProperty("data") && Array.isArray(authReducer.getHighAvailabilityDevice.data.manage_location)  &&  authReducer.getHighAvailabilityDevice.data.manage_location.length > 0 ) {
                    let data = authReducer.getHighAvailabilityDevice.data;
                    let dp_name = data.name;
                    setOuName(dp_name);
                    const _userOu: any = getDataFromLocalStorage(USER_OU);
                    let userOu: any = _userOu ? JSON.parse(_userOu) : {};
                    if (data && data.hasOwnProperty("manage_location") && Array.isArray(data.manage_location) && data.manage_location.length > 0) {
                        let _products: Array<Object> = data.manage_location[0].products && data.manage_location[0].products.map((product) => {
                            let k4Id = product.hasOwnProperty(LOCATION_ID_KEY) ? product[LOCATION_ID_KEY] : "";
                            setK4Id(k4Id)
                            let id = product.hasOwnProperty(ID_KEY) ? product[ID_KEY] : "";
                            let name = product.hasOwnProperty(NAME_KEY) ? product[NAME_KEY] : "-";
                            let type = product.hasOwnProperty(TYPE_KEY) ? product[TYPE_KEY] : "-";
                            let hardwareVersion = product.hasOwnProperty(HARDWARE_VESION_KEY) ? product[HARDWARE_VESION_KEY] : "-";
                            let softwareVersion = product.hasOwnProperty(SOFTWARE_VERSION_KEY) ? product[SOFTWARE_VERSION_KEY] : "-";
                            let status = product.hasOwnProperty(STATUS) ? product[STATUS] : "-";
                            let devices = product.hasOwnProperty("devices") ? product["devices"] : [];

                            for (let i = 0; i < devices.length; i++) {
                                let device = devices[i]
                                device["is_current_EOS_version_above_v6.5.3"] = false
                                device["last_seen"] = device?.konnectLastSeen
                                device["status"] = device?.konnectStatus
                                device["inprogressFlag"] = 0
                                if (device?.inprogress && device.inprogress =="1"){
                                    device["inprogressFlag"] = 1
                                }
                                device["action_status"] = device?.action_status
                                device["action"] = device?.action

                                if (!_.isEmpty(device["id"]) && device.hasOwnProperty("softwareVersion") && !_.isEmpty(device.softwareVersion)) {
                                    let currentEOSversion = device.softwareVersion
                                    if (currentEOSversion.indexOf("~") > -1) {
                                        currentEOSversion = currentEOSversion.substring(0, currentEOSversion.indexOf("~"))
                                    }

                                    device["current_EOS_version"] = currentEOSversion
                                    try {
                                        device["is_current_EOS_version_above_v6.5.3"] = compare(currentEOSversion, '6.5.3', '>=')
                                        device["is_current_EOS_version_above_v6.5.2"] = compare(currentEOSversion, '6.5.2', '>=')
                                        device["is_current_EOS_version_above_v6.5.0"] = compare(currentEOSversion, '6.5.0', '>=')
                                    } catch (error) {
                                        console.log("error in version compare, current_EOS_version", device["current_EOS_version"])
                                    }

                                    // upgrade
                                    let deviceIdentifier = device["id"].slice(0, 2)
                                    device["isLatestEOSavailable"] = false
                                    device["latestEOSavailable"] = ""

                                    if (!_.isEmpty(devicewiseLatestStableOsVersion) && devicewiseLatestStableOsVersion.hasOwnProperty(deviceIdentifier)) {
                                        let currentEOSversion = device.softwareVersion
                                        let latestEOSversion = devicewiseLatestStableOsVersion[deviceIdentifier]
                                        device["latestEOSavailable"] = latestEOSversion

                                        if (currentEOSversion.indexOf("~") > -1) {
                                            currentEOSversion = currentEOSversion.substring(0, currentEOSversion.indexOf("~"))
                                        }

                                        try {
                                            device["isLatestEOSavailable"] = compare(currentEOSversion, latestEOSversion, "<")
                                        } catch (error) {
                                            console.log("error in version compare, current_EOS_version", device["current_EOS_version"], "latest_EOS_version", latestEOSversion)
                                        }
                                    }
                                }
                            }

                            let dp_name = data.name;
                            let ou_user = userOu.name;
                            return createProductData(k4Id, id, name, type, hardwareVersion, softwareVersion, status, devices, dp_name, ou_user);
                        });
                        setProducts(_products);
                    }
                }
            } else {
                console.error("Error - getHighAvailabilityDevice() resposne: ", authReducer.getHighAvailabilityDevice);
            }
            dispatch({ type: GET_HIGH_AVAILABILITY_DEVICE, payload: {}})  
        }
    }, [authReducer.getHighAvailabilityDevice, devicewiseLatestStableOsVersion]);


    const updateDeviceInfo = (productName, productId, productDevices) => {
        setClickedProductName(productName);
        setClickedProductId(productId);
        setClickedProductDevices(productDevices);
        setOpenUpdateProductName(true);
    }
    
    useEffect(()=>{
        if(!openUpdateProductName){
            setClickedProductName("");
            setClickedProductId("");
            setClickedProductDevices([]);
        }
    },[openUpdateProductName])
    const EDGE_PORTAL_ADDRESS = "127.0.0.1";
    const EDGE_PORTAL_PORT = 80;

    const onConnectToEdge = async (e, deviceId: any) => {
        e.preventDefault()
        let resp = await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
        if (!_.isEmpty(resp?.result)) {
            let [edgeLink] = resp.result?.filter(rc => {
                return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
            });
            if (edgeLink && edgeLink.url) {
                let url = edgeLink.url;
                const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
                let edgeToken = loggedInUser?.data?.edge_token;
                if (!_.isEmpty(edgeToken)) {
                    let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
                    url = objUrl?.href
                }
                window.open(url)
                clearData()
            }
        } else {
            displayToastError('No remote connection link found')
        }
    }

    const handleEditAction = (device) => {
        setEditActionsPopup(true);

        const rowData = {
            action: device?.action,
            action_status: device?.action_status,
            current_EOS_version: device?.current_EOS_version,
            deviceId: device?.id,
            deviceName: device?.name,
            // dpId: "",
            // dpname: "",
            inprogressFlag: device?.inprogressFlag,
            isLatestEOSavailable: device?.isLatestEOSavailable,
            "is_current_EOS_version_above_v6.5.0": device["is_current_EOS_version_above_v6.5.0"],
            "is_current_EOS_version_above_v6.5.2": device["is_current_EOS_version_above_v6.5.2"],
            "is_current_EOS_version_above_v6.5.3": device["is_current_EOS_version_above_v6.5.3"],
            k4Id: k4Id,
            konnect_status: device?.konnectStatus,
            last_seen: device?.last_seen,
            latestEOSavailable: device?.latestEOSavailable,
            licenseType: device?.licenseType,
            name: selectedVesselName,   //siteName
            productType: device?.type,
            sn: device?.id
        };
        setEditActionRow(rowData);
    }


    return (
        <Grid className="vessel_summary_page white-bg">
            <div style={{padding: "10px 14px"}}>
                <Grid className="siteUpdate">
                    <Button className="marBtm20" onClick={() => setOpenUpdateSiteName(true)} > {selectedVesselName} </Button>
                    <img className="icon-cursor" src={userEditImage}
                        alt="Update site name" title="Update site name" onClick={() => setOpenUpdateSiteName(true)} />
                </Grid>
                <div className="topology-ou-name" >{ouName}</div>
                {
                    openUpdateSiteName ? <NameUpdate open={openUpdateSiteName}
                        k4Id={k4Id}
                        value='newLocationName'
                        type='Site'
                        nameId={selectedVessel} onNameUpdate={updateVesselName}
                        name={selectedVesselName} closeUpdatePopup={() => setOpenUpdateSiteName(false)} /> : null
                }
            </div>
            {/* <div className="mmsi-streetAdrdess">
              <Grid className="MMSINumber"><span className="VesselMMSI">MMSI : </span>{ MMSIValue ? MMSIValue : "No data" }</Grid>
              <Grid className="streetAddressName"><span className="VesselMMSI">Street Address : </span> { streetAddress ? streetAddress : "No data" }</Grid>
            </div> */}
            {/* <UserCards wanLinkWidget={wanLinkWidget} uptimeWidget={uptimeWidget} vsatSpeedWidget={vsatSpeedWidget} lteSpeedWidget={lteSpeedWidget} vsatServicePlan={vsatServicePlan} lteServicePlan={lteServicePlan} kaasServicePlan={kaasServicePlan} isVesselOnline={isVesselOnline} /> */}
            <Grid container className="topology">
                {/* <Grid item xs={12} sm={12} md={5} lg={5} className="vessel_summary_topology_diagram">
                    <ToplogyView products={products} selectedVesselName={selectedVesselName} setSelectedNode={setSelectedNode}></ToplogyView>
                </Grid>

                < Grid item xs={12} sm={12} md={7} lg={7} className="vessel_summary_product_details"> */}
                < Grid item xs={12} sm={12} md={12} lg={12} className="vessel_summary_product_details scrollVesselDetail" style={{ padding: '10px' }}>
                    {
                        products.map((product: any, i) => (
                            ("" === selectedProductId || selectedProductId === product.id) ?
                                [
                                    <Grid className="vessel_summary_product" key={i}>

                                       <div style={{ display: "inline !important" }}> 
                                       <div className="vessel_summary_product_name productName">
                                            <span>{product.name}</span>
                                            <img className="icon-cursor deviceUpdate" src={userEditImage}
                                                alt="Update device name" title="Update device name" onClick={() => updateDeviceInfo(product.name, product.id, product.devices)} />
                                        </div>
                                        </div>
                                        <TableContainer>
                                            <Table aria-label="product_table" className="product_table">
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell style={{ fontSize: "12px" }}>DID : {product.id}</TableCell>
                                                        {/* <TableCell style={{ fontSize: "12px" }}> Type : {product.type}</TableCell> */}
                                                        {/* <TableCell style={{ fontSize: "12px" }}> K4 Id : {product.k4Id}</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </TableContainer>
                                    </Grid>,

                                    product.devices && product.devices.length > 0 ?
                                        <Grid className="vessel_summary_product_device">
                                            {/*  */}

                                            <TableContainer className="alertsTable-FleetDashboard">
                                                <Table aria-label="simple table">
                                                    <TableHead className="tableHead alertsTable-tableHead">
                                                        <TableRow className="alertReports-tableRow">
                                                            <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology">Device Name</TableCell>
                                                            <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology">DID</TableCell>
                                                            <TableCell className="alertsTable-fleetDataTable font-wt-900 width-12-percent border-right-topology">EOS Version</TableCell>
                                                            <TableCell className="alertsTable-fleetDataTable font-wt-900 width-12-percent border-right-topology">Konnect Status</TableCell>
                                                            <TableCell className="alertsTable-fleetDataTable font-wt-900 width-12-percent border-right-topology">Last Seen</TableCell>
                                                            {!isReadOnlyUser && localStorage.getItem("readOnlyAceess") !== "undefined" && !(localStorage.getItem("readOnlyAceess") == "true")  && <TableCell align="left" className="alertsTable-fleetDataTable width-12-percent font-wt-900 border-right-topology">Connect to Portal</TableCell>}
                                                            {!isReadOnlyUser && <TableCell className="alertsTable-fleetDataTable font-wt-900 width-12-percent border-right-topology">Operations</TableCell>}
                                                            {localStorage.getItem("readOnlyAceess") == "true" && <TableCell align="left" className="alertsTable-fleetDataTable width-12-percent font-wt-900 border-right-topology">Connect to Portal</TableCell>}
                                                        </TableRow>
                                                    </TableHead>
                                                        <TableBody className="tableBody alertsTable-tableBody">
                                                        {  product.devices.map((device: any, i) => (
                                                                ("" === selectedDeviceId || selectedDeviceId === device.id) ?
                                                                    <TableRow className="alertsData-tabelRow">
                                                                        <TableCell className="alertsTable-tableCell width-20-percent">
                                                                            <span className="topology-device-name-with-warning-icon">
                                                                                <Tooltip title={device.name}><span>{device.name}</span></Tooltip>
                                                                                {
                                                                                    device.hasOwnProperty("inprogressFlag") && device.inprogressFlag
                                                                                    ? <span className="modify-config-icon warningIcon" title={(device?.action) + ":" + (!_.isEmpty(device.action_status) ? (device?.action_status.length > 1 ? device.action_status.charAt(0).toUpperCase() + device.action_status.slice(1) : device.action_status) : null)}>
                                                                                        <img src={UpdatesGoingOn} />
                                                                                    </span>
                                                                                    : null
                                                                                }
                                                                            </span>
                                                                        </TableCell>
                                                                        <TableCell className="alertsTable-tableCell width-20-percent">
                                                                        {device.id}
                                                                        </TableCell>
                                                                        <TableCell className="alertsTable-tableCell width-12-percent" title={device.hasOwnProperty("softwareVersion")? device.softwareVersion : null}>{device.hasOwnProperty("softwareVersion") ?(device.softwareVersion.indexOf("~") > -1 ? device.softwareVersion.substring(0, device.softwareVersion.indexOf("~")) : device.softwareVersion) : "-"}
                                                                        </TableCell>
                                                                        <TableCell className="alertsTable-tableCell width-12-percent">
                                                                        {device && device?.isAscentModem ?  "-" :
                                                                            device.status == "STATUS_CONNECTED" ?
                                                                                <img src={onlineIcon} alt="Online" title="Edge Server Online" />
                                                                                : device.status == "-" ? 
                                                                                <span className="dot" style={{ background: "gray",width:"6px",height:"6px" }}></span> 
                                                                               : <span className="dot" style={{ background: "red",width:"6px",height:"6px" }}></span> 
                                                                                
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell className="alertsTable-tableCell width-12-percent">
                                                                        {device?.isAscentModem && device?.ascentModemLastSeen ? convertDateTimeIntoTimezone(device.ascentModemLastSeen, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : device.last_seen !== '-' ? convertDateTimeIntoTimezone(device.last_seen, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : '-'}</TableCell>     
                                                                {!isReadOnlyUser && localStorage.getItem("readOnlyAceess")!== undefined && !(localStorage.getItem("readOnlyAceess") == "true") &&  <TableCell className="alertsTable-tableCell width-12-percent">
                                                                        {device.status === "STATUS_CONNECTED" ?
                                                                                <a className="vessel_table_cell--portal_link" onClick={(e) => onConnectToEdge(e, device.id)}>
                                                                                    <img src={EdgeLinkIcon} title="Portal" />
                                                                                </a>
                                                                                : device.status  === "STATUS_NOT_FOUND" ?
                                                                                <a style={{ textDecoration: "none", textAlign: 'center' }}>
                                                                                      <img src={EdgeLinkIcon} title="Portal" />
                                                                                      </a> : device?.isAscentModem ?  "-" :
                                                                                        <a style={{ textDecoration: "none", textAlign: 'center' }}>
                                                                                        <img src={EdgeLinkIconDisabled} title="Portal" />
                                                                                </a>
                                                                            }
                                                                        </TableCell>}
                                                                        {localStorage.getItem("readOnlyAceess") == "true" &&  <TableCell className="alertsTable-tableCell width-12-percent">
                                                                        {device.status === "STATUS_CONNECTED" ?
                                                                                <a className="vessel_table_cell--portal_link" onClick={(e) => onConnectToEdge(e, device.id)}>
                                                                                    <img src={EdgeLinkIcon} title="Portal" />
                                                                                </a>
                                                                                : device.status  === "STATUS_NOT_FOUND" ?
                                                                                <a style={{ textDecoration: "none", textAlign: 'center' }}>
                                                                                      <img src={EdgeLinkIcon} title="Portal" />
                                                                                      </a> : device?.isAscentModem ?  "-" :
                                                                                        <a style={{ textDecoration: "none", textAlign: 'center' }}>
                                                                                        <img src={EdgeLinkIconDisabled} title="Portal" />
                                                                                </a>
                                                                            }
                                                                        </TableCell>}
                                                                        {!isReadOnlyUser && <TableCell className="alertsTable-tableCell width-12-percent">
                                                                        <span onClick={() => handleEditAction(device) }> <SettingsIcon className="inventory-edit-icon" /> </span>
                                                                    </TableCell>}
                                                                        </TableRow> : null
                                                            ))
                                                        }
                                                                
                                                        </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        : null
                                ] : null
                        ))
                    }  
                    {
                     
                            <ManageConfigLogs selectedSite={selectedVessel}/>
                    
                    }
                </Grid>
                {
                    openUpdateProductName ? <NameUpdate value='deviceAliasName'
                        k4Id={""}
                        type='Device'
                        open={openUpdateProductName}
                        siteId={selectedVessel} nameId={clickedProductId} devices={clickedProductDevices} onNameUpdate={setClickedProductName}
                        name={clickedProductName} closeUpdatePopup={() => setOpenUpdateProductName(false)} /> : null
                }
            </Grid>
            {/* <Grid item >
                <Chart
                    chartOptions={firstChartoptions}
                    chartData={data} chartType={'Line'}
                    isRateEnabled={true}
                    ratesAndUsage={ratesAndUsage} />
            </Grid>
            <Grid>
                <Chart
                    chartOptions={secondChartOptions}
                    chartData={data} chartType={'Bar'}
                    isRateEnabled={false}
                    ratesAndUsage={{}} />
            </Grid> */}
            {
              editActionsPopup ? <EditActionsPopup open={editActionsPopup} setOpen={setEditActionsPopup} row={editActionRow} isSelectedOuIsUserOu={isSelectedOuIsUserOu} confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} setRow={setEditActionRow} />: null
            }
            
        </Grid>
    );
}

const DeviceNameCell = ({deviceName, deviceId}) => {
    const [openUpdateDeviceName, setOpenUpdateDeviceName] = useState(false);

    return <TableCell className="topology-tab-actions white-bg">
        <span className="marBtm20">{deviceName}</span>
    </TableCell>
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    generatedLinks: state.remoteConnection.generatedLinks,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        getVesselEdgeTopologyProducts,
        getEdgeServerStatus,
        vesselAddressMMSI,
        getGeneratedLinks,
        clearData,
        getOrganisationUnitList,
        backupConfigNow,
        getDevicewiseLatestEOSversion,
        rebootDevice,
        getHighAvailabilityDevice
    })(VesselSummary)
);