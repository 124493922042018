

import * as React from 'react';
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Grid, Input, InputLabel, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from "@mui/material";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { createRemoteConnectionLink } from '../../remoteConnectionSlice';
import AddLinkIcon from '@mui/icons-material/AddLink';
import close from "../../../../asset/image/close.svg";

import "./index.css"
import { getTimezoneCity } from '../../../../utils/util';

const DEFAULT_ADDRESS = '192.168.0.10';
const DEFAULT_PORT = 80;

function GenerateLinkDialog(props) {

    const { authReducer, siteId, deviceId, createRemoteConnectionLink } = props;

    const [open, setOpen] = React.useState<boolean>(false);

    const [address, setAddress] = React.useState<string>(DEFAULT_ADDRESS);
    const [port, setPort] = React.useState<number>(DEFAULT_PORT);
    const [alias, setAlias] = React.useState<string>();
    const [expireTime, setExpireTime] = React.useState<moment.Moment | null>(moment(Date.now()).add(1, 'days'));
    const [protocol, setProtocol] = React.useState<string>('http');
    const [inputsDisabled, isInputsDisabled] = React.useState(false);
    const [urlSuffix, setUrlSuffix] = React.useState<string>("");
    const [authEmail, setAuthEmail] = React.useState<string>("");
    const [validInputs, setValidInputs] = React.useState<boolean>(false);

    const handleGenerateLink = async () => {
        isInputsDisabled(true);
        let rc = await createRemoteConnectionLink(siteId, deviceId, {
            "address": address,
            "port": port,
            "alias": alias,
            "protocol": protocol,
            "type": "wan", // type of remote connection doesn't matter now
            "expireTime": expireTime?.utc().toISOString(),
            "urlSuffix": urlSuffix,
            "authEmail": authEmail
        });
        if (!_.isEmpty(rc)) {
            setOpen(false);
        }
        isInputsDisabled(false);
    };

    const handleOpen = () => {
        setOpen(true);
        setAlias('')
        setProtocol('http')
        setExpireTime(moment(Date.now()).add(1, 'days'))
        setUrlSuffix('')
        setAuthEmail('')
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeExpireTime = (date: moment.Moment | null) => {
        if (date == null || !date.isValid())
            return;
        setExpireTime(date)
    }

    const validEmail = (email: string) => {
        if (email === '')
            return true;
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    React.useEffect(() => {
        setValidInputs(validEmail(authEmail));
    }, [authEmail])

    return (
        <div>
            <Tooltip title="Generate remote connection link">
                <Button aria-label="generate-link" size="small" onClick={handleOpen} variant="outlined" endIcon={<AddLinkIcon />}>
                    Generate Link
                </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <div className='rc--generate_link_dialog--title_container'>
                    <div>
                        Generate Konnect Link
                    </div>
                    <div className='pad-top-10'>
                        <img src={close} onClick={() => handleClose()} className="dialog_closeIcon" alt="Close" />
                    </div>

                </div>

                <div className='rc--generate_link_dialog--content'>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Alias (optional)
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <TextField
                                type='text'
                                id="outlined-size-small"
                                defaultValue="Small"
                                size="small"
                                value={alias}
                                placeholder='Set Alias'
                                fullWidth
                                onChange={(e) => setAlias(e.target.value)}
                                disabled={inputsDisabled}
                            />
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Remote Address
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <TextField
                                type='text'
                                id="outlined-size-small"
                                defaultValue={DEFAULT_ADDRESS}
                                size="small"
                                value={address}
                                fullWidth
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Remote Port
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <TextField
                                type='text'
                                id="outlined-size-small"
                                defaultValue={DEFAULT_PORT}
                                size="small"
                                value={port}
                                fullWidth
                                onChange={(e) => setPort(Number(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Protocol
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={protocol}
                                style={{ height: '40px' }}
                                variant='outlined'
                                fullWidth
                                onChange={(event: SelectChangeEvent) => { setProtocol(event.target.value) }}
                                disabled={inputsDisabled}
                            >
                                <MenuItem value={'http'}>HTTP</MenuItem>
                                <MenuItem value={'https'}>HTTPS</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Set Expiry Date & Time
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    ampm={false}
                                    timezone={getTimezoneCity(authReducer.userTimezone)}
                                    value={expireTime}
                                    onChange={handleChangeExpireTime}
                                    minDateTime={moment(Date.now())}
                                    disabled={inputsDisabled}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            URL Suffix (optional)
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <TextField
                                type='text'
                                id="outlined-size-small"
                                variant='outlined'
                                defaultValue="Small"
                                size="small"
                                value={urlSuffix}
                                placeholder='Enter Suffix'
                                fullWidth
                                onChange={(e) => setUrlSuffix(e.target.value)}
                                disabled={inputsDisabled}
                            />
                        </div>
                    </div>
                    <div className='rc--generate_link_dialog--content--row'>
                        <div className='rc--generate_link_dialog--content--row--title'>
                            Auth Email (optional)
                        </div>
                        <div className='rc--generate_link_dialog--content--row--data'>
                            <TextField
                                type='email'
                                id="outlined-size-small"
                                variant='outlined'
                                defaultValue="Small"
                                size="small"
                                value={authEmail}
                                placeholder='Auth Email'
                                fullWidth
                                onChange={(e) => setAuthEmail(e.target.value)}
                                disabled={inputsDisabled}
                                error={!validEmail(authEmail)}
                            />
                        </div>
                    </div>
                </div>
                <div className='rc--generate_link_dialog--actions'>
                    <div className='rem-con-cancel-parent'>
                        <Button disabled={inputsDisabled} className='rem-con-cancel' variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                    <div className='pad-left-10'>
                        <Button disabled={inputsDisabled || !validInputs} variant="contained" className='rc--submit' onClick={handleGenerateLink}>Submit</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, {
    createRemoteConnectionLink
})(GenerateLinkDialog)