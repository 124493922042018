import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import ChangeSubOrg from '../ChangeSubOrgDialog/ChangeSubOrgPerServiceLine';
import { checkLoggedInUserAuthorizedToViewPage } from "../../../utils/util";
import PauseResumeServiceLine from './PauseResume';
import { getAvailableProductsForAccount } from "../slice";
import MobilePriority from "./MobilePriority";
import { STARLINK_ADMIN_SL_CHNAGE_SUB_ORG, STARLINK_ADMIN_SL_MOBILE_PRIORITY, STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE, STARLINK_ADMIN_SL_RESUME_SERVICE_LINE } from "../../../utils/actionConstants";
var Loader = require("react-loader");

const EditActionsPopup = (props) => {
    const { loading, getAvailableProductsForAccount, open, setOpen, confirmDialog, setConfirmDialog, row, setRow, handleEdit, serviceLine, onSave, starlinkService, starlinkFunction,
        pauseData, setPauseData, endNow, handleChangeEndNow, rejectReason, setRejectReason, product, setProduct, handleChangeProduct, availableProducts, handlePauseResume, optInData, setOptInData,
        mobilePriority, setMobilePriority, handleOptIn
    } = props;

    const deviceActionsList = [
        STARLINK_ADMIN_SL_CHNAGE_SUB_ORG,
        STARLINK_ADMIN_SL_MOBILE_PRIORITY,
        row.subscriptionActiveStatus === "active" ? STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE : STARLINK_ADMIN_SL_RESUME_SERVICE_LINE,
    ].filter(action => action);

    const getIdNameForList = (action) => {
        if (action == STARLINK_ADMIN_SL_CHNAGE_SUB_ORG) {
            if (!checkLoggedInUserAuthorizedToViewPage(starlinkService, starlinkFunction)) {
                return 'actionType-disabled';
            }
        } else if(action == STARLINK_ADMIN_SL_MOBILE_PRIORITY) {
            // if(row?.accountType === "credential" || !row.serviceLineStatus) {
                return 'actionType-disabled';
            // }
        } else if(action == STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE || action == STARLINK_ADMIN_SL_RESUME_SERVICE_LINE) {
            // if(row?.accountType === "credential") {
                return 'actionType-disabled';
            // }
        }
        return 'actionType-enabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));

    const updateActionValues = (action) => {
        switch (action) {
            case STARLINK_ADMIN_SL_CHNAGE_SUB_ORG: {
                handleEdit({
                    serviceLineNumber: row.serviceLineNumber,
                    nickname: row.serviceLineName
                });
                break;
            }
            case STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE: {
                setPauseData({ serviceLineNumber: row.serviceLineNumber, serviceLineName: row.serviceLineName, accountDpId: row.accountDpId, accountNumber: row.accountNumber, action: "Pause", endNow: false });
                break;
            }
            case STARLINK_ADMIN_SL_RESUME_SERVICE_LINE: {
                setPauseData({ serviceLineNumber: row.serviceLineNumber, serviceLineName: row.serviceLineName, accountDpId: row.accountDpId, accountNumber: row.accountNumber, action: "Resume", endNow: false });
                setProduct(row.productReferenceId.toString());
                getAvailableProductsForAccount({ accountNumber: row.accountNumber })
                break;
            }
            case STARLINK_ADMIN_SL_MOBILE_PRIORITY: {
                setOptInData({
                    serviceLineNumber: row.serviceLineNumber,
                    serviceLineName: row.serviceLineName,
                    accountDpId: row.accountDpId,
                    accountNumber: row.accountNumber,
                    action: row?.optedIn ? "optOut" : "optIn"
                });
                setMobilePriority(row?.optedIn ? true : false);
                break;
            }
            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
        setRow({});
        setPauseData({});
        setProduct('');
        setOptInData({});
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Operations</span>
                            <span className="selected-site-device">{`Service Line : ${row['serviceLineName']}`}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={4} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists' style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className="menuScroll starlinkAdmin-edit-actions-cont">
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == STARLINK_ADMIN_SL_CHNAGE_SUB_ORG && <ChangeSubOrg
                                            serviceLine={serviceLine}
                                            open={open} onSave={onSave} onClose={handleClose}
                                            currentDpName={row['dpName']}
                                        />}
                                        {selectedAction == STARLINK_ADMIN_SL_MOBILE_PRIORITY && <MobilePriority
                                            setMobilePriority={setMobilePriority} mobilePriority={mobilePriority} optInData={optInData} handleOptIn={handleOptIn} row={row}
                                        />}
                                        {(selectedAction == STARLINK_ADMIN_SL_PAUSE_SERVICE_LINE || selectedAction == STARLINK_ADMIN_SL_RESUME_SERVICE_LINE) &&
                                            <PauseResumeServiceLine
                                                pauseData={pauseData} rejectReason={rejectReason} setRejectReason={setRejectReason}
                                                endNow={endNow} handleChangeEndNow={handleChangeEndNow} product={product} handleChangeProduct={handleChangeProduct} availableProducts={availableProducts}
                                                handlePauseResume={handlePauseResume} handleClose={handleClose}
                                            />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine
});

export default withRouter(
    connect(mapStateToProps, { getAvailableProductsForAccount })(EditActionsPopup)
);