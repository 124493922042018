import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Pagination } from "../../Pagination";
import './manualBackupConfig.css';
import _ from "lodash";
import { backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, getInventoryWarehouseVesselList, deleteManualBackupConfig } from "../../../actions/Users/authenticateInventory";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { toast } from "react-toastify";
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import DeleteIcon from "../../../asset/image/user_delete.svg";
import ConfirmDialog from "../../ConfirmDialog";

import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[4], row[17], row[2], row[7], convertDateTimeIntoTimezone(row[14], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)])
    })
    let creationTime = 'Creation Time (' + userTimezone + ')'
    return [['Config Name', 'Site', 'DID', 'EOS Version', creationTime], ...csvRows]
}

const ManualBackupConfig = (props) => {
    const { authReducer, backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, getInventoryWarehouseVesselList, deleteManualBackupConfig, history, location, downloadAsCSV, isReadOnlyUser } = props;
    let _q = getDecodeURI(location?.search);
    const MANUAL_BACKUP_CONFIG = "manual-backup-config"
    // filterAppliedOn - will be available if filter is applied on manual backup config table
    // on PAGE REFRESH dont consider URL filter keys, fetch data using default filters
    if (!(_q.hasOwnProperty("filterAppliedOn") && _q.filterAppliedOn == MANUAL_BACKUP_CONFIG)) {
        _q = {}
    }
    const DEFAULT_PAGE_SIZE = 15;
    const [k4Ids, setK4ids] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [backupTableData, setBackupTableData] = useState<any>([]);
    const ENTER_KEY_CODE = "Enter";
    const [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
    const [isSearchApplied, setISearchApplied] = useState<Boolean>(searchValue ? true : false);
    const NAME = 'config_name';
    const SITE_NAME = 'site_name';
    const DEVICE_ID = 'device_id';
    const SOFTWARE_VERSION = 'software_version';
    const CREATE_TIME = 'create_time';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc"; 
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : CREATE_TIME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [totalCount, setTotalCount] = useState(0);
    const [downloadConfig, setDownloadConfig] = useState(false);
    // show only manual backups
    const IS_MANUAL_BACKUP = true;
    const VIEW_INVENTORY = "view-inventory";
    const viewDeployedService = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['service'];
    const viewDeployedFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['function'];
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'manual-config') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA',
                            1: 'ESC-COMMA',
                            4: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            const sites = authReducer?.getVesselsListing?.hasOwnProperty("locations") ? authReducer?.getVesselsListing?.locations : [];
            if (sites?.length == 0) {
                setBackupTableData([]);
                setPage(0);
                setLimit(0);
                setTotalCount(0);
                return;
            }
            const k4Ids = sites && sites.length > 0 ? `${sites.map((item: any) => item.id).join(",")}` : '';
            setK4ids(k4Ids);
            const Page = 1;
            setPage(1);
            setLimit(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
            k4Ids && backupConfigData(k4Ids, Page, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.backupConfigData)) {
            const data = authReducer?.backupConfigData?.result;
            setBackupTableData(data);
            setTotalCount(authReducer?.backupConfigData?.result_info?.total_count);
            authReducer.backupConfigData = {}
        }
    }, [authReducer.backupConfigData])

    useEffect(() => {
        if (!_.isEmpty(authReducer.deleteManualBackupConfig)) {
            console.log(authReducer.deleteManualBackupConfig)
            backupConfigData(k4Ids, page, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
            authReducer.deleteManualBackupConfig = {}
        }
    }, [authReducer.deleteManualBackupConfig])

    const rowsOption = [10, 15, 25, 50, 100]

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        params.filterAppliedOn = MANUAL_BACKUP_CONFIG
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, value, limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        params.filterAppliedOn = MANUAL_BACKUP_CONFIG
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, Limit, searchValue, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        params.search = value;
        delete params.page;
        const Page = 1;
        setPage(1);
        setISearchApplied(true);
        params.filterAppliedOn = MANUAL_BACKUP_CONFIG
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, limit, value, sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    };

    const closeSearch = () => {
        setSearchValue("");
        let params: any = getDecodeURI(location?.search);
        delete params.search;
        delete params.page;
        delete params.filterAppliedOn
        const Page = 1;
        setPage(1);
        doNavigate(params);
        setISearchApplied(false);
        k4Ids && backupConfigData(k4Ids, Page, limit, '', sort, sortOrder, '', '', '', IS_MANUAL_BACKUP);
    }

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        params.filterAppliedOn = MANUAL_BACKUP_CONFIG
        doNavigate(params);
        k4Ids && backupConfigData(k4Ids, Page, limit, searchValue, field, order, '', '', '', IS_MANUAL_BACKUP);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order);
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order);
    };

    useEffect(() => {
        if (!_.isEmpty(authReducer.inventoryDownloadConfig) && downloadConfig) {
            const url = authReducer?.inventoryDownloadConfig?.result;

            if (authReducer?.inventoryDownloadConfig?.success == true) {
                window.open(url);
                clearInventoryConfigDownload();
            } else if (!authReducer?.inventoryDownloadConfig?.success || authReducer?.inventoryDownloadConfig?.data?.success == false) {
                toast.error('Something went wrong, unable to download the file..', {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
            }
            authReducer.inventoryDownloadConfig = {};
        }
    }, [authReducer?.inventoryDownloadConfig])

    const handleDOwnloadConfig = (row) => {
        setDownloadConfig(true);
        const siteId = row?.site_id;
        const deviceId = row?.device_id;
        const configId = row?.id
        inventoryDownloadConfig(siteId, deviceId, configId)
    }

    const DeleteManualBackipConfig = (row) => {
        deleteManualBackupConfig(row.site_id, row.device_id, row.id, row.config_name)
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }
    
    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleDownloadReport = () => {
        if (k4Ids.length === 0) {
            toast.error("No data available to download", {
              position: toast.POSITION.BOTTOM_LEFT,
            })
            return;
          }
        const params: CsvParameters = {
            type: "POST_API_SITE_CONTROLLER",
            id: "manual-config",
            endpoint: `/v1/sites/devices/configuration/query`,
            payload: {
                "site_id": k4Ids,
                "device_id": '',
                "device_type": '',
                "manual": true,
                "search": searchValue,
                "page": 0,
                "size": totalCount,
                "sort_by": sort,
                "sort_order": sortOrder,
                "software_version": ''
            }
        }
        downloadAsCSV(params);
    }

    return (
        <div className="manual-backup-config-container">
            <Grid container spacing={0.5} className="golden-config-default-container alertsFilterContainer">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="title">Backup Configuration</Grid>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="alertsFilterContainerBox">
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            {<TextField style={{ marginLeft: '2px', marginTop: '20px', marginBottom: '10px' }}
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-def-conf" }}
                                size="small"
                                value={searchValue}
                                onChange={handleOnSearchChange}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue && <SearchIcon
                                                className="cursorPointer"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchValue && <CloseIcon
                                                className="cursorPointer"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                    </Grid>
                    {totalCount > 0 && <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                <Paper className="pagination-position config-pagination">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={totalCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard backup-config-table-container manual-backup-table">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow config-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, NAME)}>Config Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, NAME, ASC_ORDER)} />
                                                <img src={sort === NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>Site</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_ID)}>DID</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, ASC_ORDER)} />
                                                <img src={sort === DEVICE_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_ID, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, SOFTWARE_VERSION)}>EOS Version</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SOFTWARE_VERSION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SOFTWARE_VERSION, ASC_ORDER)} />
                                                <img src={sort === SOFTWARE_VERSION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SOFTWARE_VERSION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                        <span className="backup-config-headers align-items-center">
                                            <span className="fleetDataTableSite default-config-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATE_TIME)}>Creation Time</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CREATE_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATE_TIME, ASC_ORDER)} />
                                                <img src={sort === CREATE_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CREATE_TIME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    {!isReadOnlyUser && <TableCell className="fleetDataTable usage-fleetDataTable config-left-align">
                                    <span className="fleetDataTableSite default-config-dp-name font-wt-900">Actions</span>
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            {0 != backupTableData.length ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        backupTableData && backupTableData.map((row, i) =>
                                            <TableRow key={i} className="usageData-tabelRow config-backup-tabe-row">
                                                <TableCell className="usagedata-tableCell config-left-align selected-config" onClick={() => handleDOwnloadConfig(row)}>{row.config_name}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.site_name}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.device_id}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{row.software_version}</TableCell>
                                                <TableCell className="usagedata-tableCell config-left-align">{convertDateTimeIntoTimezone(row.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                {!isReadOnlyUser && <TableCell className="usagedata-tableCell config-left-align actions">
                                                    <img src={DeleteIcon} title="Delete configuration" 
                                                        onClick={() => {
                                                            setConfirmDialog({
                                                                isOpen: true,
                                                                title: CONFIRM_DIALOG_TITLE,
                                                                subTitle: `Are you sure to delete the configuration: ${row.config_name} ?`,
                                                                onConfirm: () => {
                                                                    DeleteManualBackipConfig(row)
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </TableCell>}
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                   
                </Grid>
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        backupConfigData, inventoryDownloadConfig, clearInventoryConfigDownload, getInventoryWarehouseVesselList, deleteManualBackupConfig, downloadAsCSV
    })(ManualBackupConfig)
);