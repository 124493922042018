
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { getConfigStatus, applyGoldenConfig, upgradeDevicetoLatestEOSversion } from '../../../actions/Users/authenticateInventory';
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from '../../../utils/util';

import { Button, Chip, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { FiberManualRecord, ArrowDownward, ArrowUpward, Search, Clear, Replay, QrCodeScannerOutlined } from '@mui/icons-material';


import { ConfigStatus, Site, SortBy, SortOrder } from './types';
import { Pagination } from '../../Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants/Constants';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CONFIG_STATUS, GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import JsonTooltip from '../../JSONTooltip/JsonTooltip';
import GroupDeviceList from '../../InventoryGroupActions/GroupDeviceList';
import "./ConfigStatus.css"
import DeviceStatuses from './DeviceStatuses';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../../utils/constants';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import JSONPretty from 'react-json-pretty';

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        let name = row[14]
        // group name
        if(!_.isEmpty(row[10])){
            name = row[10]
        }

        let status = row[15]
        if(!_.isEmpty(status)){
            if(!status.includes("|") && status.includes(":")){
                status = status.split(":")[1]
            }
        }
        csvRows.push([name, row[5], row[0] === null ? 'Config Restore' : row[0], row[0] === null ? row[2] : row[7], convertDateTimeIntoTimezone(row[3], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[18], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), status])
    })
    let startTime = 'Start Time (' + userTimezone + ')'
    let completeTime = 'Complete Time (' + userTimezone + ')'
    return [['Site/Group', 'Device', 'Action', 'Version', startTime, completeTime, 'Status'], ...csvRows]
}

const getEosStatus = (row: ConfigStatus) => {
    let status = row.status
    if (!_.isEmpty(status)) {
        if (status.includes("|")) {
            let _statuses = status.split("|")
            let eachDeviceInfo : any = {}
            for (let i=0; i<_statuses.length;i++){
                if(_statuses[i].includes(":")){
                    let deviceName = _statuses[i].split(":")[0]
                    let deviceId = !_.isEmpty(row.device_id) ? row.device_id.split(",")[i] :""
                    let key = _statuses[i].split(":")[1]
                    if(!_.isEmpty(key)){
                        if(key.toLocaleLowerCase().includes("failed")){
                            key = "failed"
                        }

                        key = key.length > 1 ? key.charAt(0).toUpperCase() + key.slice(1) : key.charAt(0)

                        let count = 1
                        let devices :object[] = []
                        if(!_.isEmpty(eachDeviceInfo) && eachDeviceInfo.hasOwnProperty(key)){
                            count = eachDeviceInfo[key].count + 1
                            devices = eachDeviceInfo[key].devices
                        }
                        devices.push({
                            "id":deviceId,
                            "name":deviceName
                        })
                        if (key.toLowerCase() == "upgraded") {
                            key = "Success"
                        }

                        eachDeviceInfo[key] = {
                            "devices": devices,
                            "count": count
                        }
                    }
                }
            }
            return JSON.stringify(eachDeviceInfo)
        } else if (status.includes(":")) {
                status = status.split(":")[1]
        }
    }

    if (!_.isEmpty(status) && status.toLowerCase() == "upgraded") {
        status = "Success"
    }
    return _.isEmpty(status) ? "" : status
}

const getStatusHistory = (history: string) => {
    if (!_.isEmpty(history)) {
        if (history.includes("|")) {
            let h_statuses = history.split("|")
            let eachDeviceInfo : any = {}
            for (let i=0; i<h_statuses.length;i++){
                if(h_statuses[i].includes(":")){
                    let key = h_statuses[i].slice(0,h_statuses[i].indexOf(":"))
                    let value = h_statuses[i].slice(h_statuses[i].indexOf(":")+1)
                    if(!_.isEmpty(key)){
                       eachDeviceInfo[key] = value; 
                     }
                }
            }
            return JSON.stringify(eachDeviceInfo)
        } else if (history.includes(":")) {
            if(!(history.startsWith("{") && history.endsWith("}"))){
                history = history.slice(history.indexOf(":")+1)
            }
        }
    }

    if (_.isEmpty(history) || "null" == history) {
        history =  ""
    }

    return history
}

const getTableData = (statuses: []) => {
    statuses?.map((row: ConfigStatus) =>{
        row.deviceCount = _.isEmpty(row.device_id) ? 0 : row.device_id.split(",").length
            let status =  getEosStatus(row)
            try {
                if(typeof(JSON.parse(status))=="object"){
                   row.status_obj = JSON.parse(status)
                }
            } catch (error) {
                row.status = status
            }

            let status_history = getStatusHistory(row.status_history)
            row.status_history = status_history
            try {
                if(typeof(JSON.parse(status_history))=="object"){
                   row.status_history_obj = JSON.parse(status_history)
                }
            } catch (error) {
            }
    })
    return statuses
}

function ConfigStatusTable(props: any): JSX.Element {

    const { authReducer, errorReducer, getVesselsListing, filteredVessels, statuses, totalStatuses, loading, getConfigStatus, applyGoldenConfig,downloadAsCSV, upgradeDevicetoLatestEOSversion } = props

    const history = useHistory();
    const location = useLocation()
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = React.useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = React.useState<{
        page: number;
        size: number;
        sortBy: SortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : DEFAULT_PAGE_SIZE,
        sortBy: queryParams.get('sortBy') as SortBy || 'created_at',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || ''
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const [sites, setSites] = useState<Site[]>([]);
    const [tableData, setTableData] = useState<ConfigStatus[]>([]);

    const [groupDeviceListPopup, setGroupDeviceListPopup] = useState<boolean>(false)
    const [deviceStatusesListPopup, setDeviceStatusesListPopup] = useState<boolean>(false)
    const [clickedRow, setClickedRow] : any = useState({})
    const [clickedStatus, setClickedStatus] : any = useState("")

    useEffect(() => {
        let data: Site[] = [];
        if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
            data = filteredVessels?.map(site => {
                return {
                    id: site.id,
                    name: site['vessel-name'],
                };
            });
        } else if (!_.isEmpty(getVesselsListing)) {
            data = getVesselsListing?.locations?.map(site => {
                return {
                    id: site.id,
                    name: site.name,
                };
            });
        }
        if (_.isEqual(data, sites)) {
            return;
        } else {
            setSites(data);
        }
    }, [getVesselsListing, filteredVessels]);

    useEffect(() => {
        if(!_.isEmpty(statuses)){
            let _statuses=getTableData(statuses)
            setTableData(_statuses)
        }
    }, [statuses])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'config-restore-status') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (!_.isEmpty(rows) && rows.length > 0) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))], authReducer.userTimezone)
                            , {
                                formatters: {
                                    0: 'ESC-COMMA',
                                    1: 'ESC-COMMA',
                                    4: 'ESC-COMMA',
                                    5: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangeSorting = (sortBy: SortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder
        });
    }

    const handleChangePage = (event: React.SyntheticEvent<HTMLButtonElement> | null, _page: number) => {
        setPaginationParams({
            ...paginationParams,
            page: _page
        });
    };

    const handleChangeSize = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
        setPaginationParams({
            ...paginationParams,
            size: event.target.value as number,
            page: 1
        });
    };

    
    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const refresh = (_searchText: string | undefined = undefined) => {
        setTableData([])
        if (_.isEmpty(sites)) {
            // empty the table
            dispatch({
                type: GET_CONFIG_STATUS, payload: {
                    data: {
                        rows: [
                            [[], 0]
                        ]
                    }
                }
            })
            return;
        }
        getConfigStatus({ sites: sites.map(site => site.id), search: paginationParams.search, sort: paginationParams.sortBy, order: paginationParams.sortOrder, limit: paginationParams.size, offset: (paginationParams.page - 1) * paginationParams.size })
    }

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        updateQueryParams();
        refresh();
    }, [paginationParams, sites]);

    const handleRetry = async (row: ConfigStatus) => {
        let ouId = ""
        if (!_.isEmpty(authReducer.selectedOu) && !_.isEmpty(authReducer.selectedOu?.id)) {
            ouId = authReducer.selectedOu.id
        }

        if (!_.isEmpty(row) && !_.isEmpty(row?.action)) {
            if (!_.isEmpty(ouId) && row.action.toLowerCase() == "eos upgrade") {
                await upgradeDevicetoLatestEOSversion(row.site_id,row.device_id)
            }
        } else {
            await applyGoldenConfig({ site_id: row.site_id, device_id: row.device_id, config_id: row.config_id })
        }
        refresh();
    }

    const rowsOption = [10, 15, 25, 50, 100];

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'config-restore-status',
            payload: { sites: sites.map(site => `'${site.id}'`).join(','), search: paginationParams.search, sort: paginationParams.sortBy, order: paginationParams.sortOrder, limit: totalStatuses, offset: 0 },
            queryName: 'GET_CONFIG_RESTORE_STATUS',
        }
        downloadAsCSV(params);
    }

    const handleMoreDeviceClick = (row: ConfigStatus) => {
        if (!_.isEmpty(row)&& !_.isEmpty(row?.device_name) && !_.isEmpty(row?.device_id)) {
            let deviceIds = row.device_id.split(",")
            let deviceNames = row.device_name.split("|")
            const devices: any[] = []
            for (let i = 0; i < deviceNames.length; i++) {
                devices.push({
                    "id": deviceIds[i],
                    "name": deviceNames[i]
                })
            }
            let _clickedRow = {
                "groupName": row.group_name,
                "devices": devices
            }
            setGroupDeviceListPopup(true);
            setClickedRow(_clickedRow);
        }
    }

    const showDevicesDetails = (row: ConfigStatus, clickedStatus) => {
        setDeviceStatusesListPopup(true)
        setClickedStatus(clickedStatus)
        setClickedRow(row)
    }
    const getReasonTitle = (row) => {
        if(row.status) {
            if(row?.status.includes("failed") || row?.status.includes("Failed")) {
                if(row?.reason) {
                    return row?.reason;
                } else {
                    // return row?.status;
                }
            } else {
                return ""
            }
        }  else {
            return ""
        }
    }

    return (
        <Paper elevation={0} style={{marginTop: "60px"}} className="quota_management--dashboard" sx={{margin: '1rem', padding: '1rem', marginTop:'0px'}}>
            <Grid classes={{ root: 'quota_management--base--flex quota_management--base--flex--justify--end quota_mangement--base--padding_05x' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} className='disp-flex'>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Type something"
                            classes={{ root: "input-box-def-conf" }}
                            value={searchText}
                            onChange={handleOnSearchChange}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}

                            size="small"
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchText && <SearchIcon
                                            className="cursorPointer"
                                            onClick={handleOnSearchClick}
                                        />}
                                        {searchText && <CloseIcon
                                            className="cursorPointer"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {totalStatuses > 0 && tableData && 0 !== tableData?.length && <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                <Paper className="pagination-position config-pagination new-usage">
                    <Pagination count={totalStatuses > 0 && tableData && 0 !== tableData?.length ? totalStatuses : 0} rowsPerPageOptions={rowsOption} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer inventory-config-status">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead alerts-tableHead">
                            <TableRow className="UsageReports-tableRow login-tableRow">
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">SITE/GROUP</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">DEVICE</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">VERSION</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">START TIME</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">COMPLETE TIME</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">ACTION</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">INITIATED BY</TableCell>
                                <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        {tableData && 0 !== tableData?.length && !authReducer.loading ? (
                            <TableBody className="tableBody usage-tableBody Table-Body-Opacity white-bg">
                                {
                                    tableData?.map((row: ConfigStatus, index) =>
                                        <TableRow key={`${row.site_id}-${row.device_id}-${index}`}>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity ellipsis" title={_.isEmpty(row.group_name)?row.site_name:row.group_name}>{_.isEmpty(row.group_name)?row.site_name:row.group_name}</TableCell>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity inventory-group-table-devices">
                                                {
                                                    [

                                                        !_.isEmpty(row?.device_name) && row.device_name.split("|").map((name, idx) => (
                                                            idx == 0 ?
                                                                <Tooltip key={`${row.site_id}-${row.device_id}-${index}-${idx}`} title={<span>{name}</span>}>
                                                                    <Chip key={`${index}-${idx}`} label={name} variant="outlined" />
                                                                </Tooltip>
                                                                : null
                                                        )),
                                                        !_.isEmpty(row?.device_name) && row.device_name.split("|").length > 1 ? (
                                                            <Chip className="inventory-group-table-more-devices" key={`pmore-${Math.random()}`} label={"+" + (row.device_name.split("|").length - 1)} variant="outlined" title={"More Devices"}
                                                                onClick={() => {
                                                                    handleMoreDeviceClick(row)
                                                                }}
                                                            />
                                                        ) : null
                                                    ]
                                                }
                                            </TableCell>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity ellipsis" title={!_.isEmpty(row.eos_version_before_upgrade) && (!_.isEmpty(row.eos_version)) ? "v" + row.eos_version_before_upgrade.split("~")[0] + " -> v" + row.eos_version.split("~")[0] : _.isEmpty(row.config_name) ? (!_.isEmpty(row.eos_version) ? "v" + row.eos_version.split("~")[0] : "") : row.config_name} >
                                                {!_.isEmpty(row.eos_version_before_upgrade) && (!_.isEmpty(row.eos_version)) ? "v" + row.eos_version_before_upgrade.split("~")[0] + " -> v" + row.eos_version.split("~")[0] : _.isEmpty(row.config_name) ? (!_.isEmpty(row.eos_version) ? "v" + row.eos_version.split("~")[0] : "") : row.config_name}
                                            </TableCell>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(row.created_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className='alert-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(row.updated_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{_.isEmpty(row.action) ? "Config restore" : row.action}</TableCell>
                                            <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{row.user_name}</TableCell>
                                            <TableCell className='alert-left-align inter-font Table-Cell-Opacity inventory-config-status-td'>
                                                {
                                                    _.isEmpty(row.status)
                                                        ? "" :
                                                        row.deviceCount > 1 && !_.isEmpty(row.group_transaction_id)
                                                            ?
                                                            [
                                                                <div>
                                                                    <span className='bluePointer' onClick={()=>showDevicesDetails(row,"Failed")}>{"Failed: "}{!_.isEmpty(row.status_history) && row.status_obj.hasOwnProperty("Failed")?row.status_obj["Failed"].count:0}</span>
                                                                </div>,
                                                                <div>
                                                                    <span className='bluePointer' onClick={()=>showDevicesDetails(row,"Total")}>{"Total: "}{row.deviceCount}</span>
                                                                </div>
                                                            ]
                                                            :  (getReasonTitle(row) ? <Tooltip title={getReasonTitle(row)}><span className="Pointer">{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span></Tooltip> : <span>{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span>)
                                                }
                                                {
                                                     row.deviceCount == 1 && !_.isEmpty(row.status) && row.status.toLowerCase().includes("failed") && !row.status.toLowerCase().includes("inprogress") ? <Tooltip title="Retry" key={`pmore-${Math.random()}`}>
                                                        <IconButton onClick={() => handleRetry(row)}>
                                                            <Replay />
                                                        </IconButton>
                                                    </Tooltip> : null
                                                }
                                                {
                                                    _.isEmpty(row.status_history_obj) || row.deviceCount > 1 ? null : <JsonTooltip className="prettyToolTip" json={JSON.stringify(row.status_history_obj)} />
                                                }
                                                {
                                                    !_.isEmpty(row.status_history_obj) && !_.isEmpty(row.status_history_obj["metadata"])
                                                    ?
                                                        row.status_history_obj["metadata"].hasOwnProperty("steps_completed") &&  row.status_history_obj["metadata"].hasOwnProperty("total_steps") && row.status_history_obj["metadata"]["steps_completed"] != row.status_history_obj["metadata"]["total_steps"]
                                                        ?
                                                            <div className='progress-bar-div'>
                                                                <Tooltip classes={{ tooltip: 'jsonTooltip' }} title={<JSONPretty className='scrollTooltip' data={JSON.stringify(row.status_history_obj["steps"])} onJSONPrettyError={console.error} />} {...props} placement="left-start" arrow>
                                                                    <LinearProgress variant="determinate" {...props} value={
                                                                        row.status_history_obj["metadata"]["steps_completed"] * 100 / row.status_history_obj["metadata"]["total_steps"]
                                                                    } />
                                                                    <Typography variant="body2" color="textSecondary">{
                                                                        row.status_history_obj["metadata"]["steps_completed"] + "/" + row.status_history_obj["metadata"]["total_steps"]
                                                                    }</Typography>
                                                                </Tooltip>
                                                            </div>
                                                        :null
                                                    : null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
            {groupDeviceListPopup ? <GroupDeviceList selectedRow={clickedRow} groupDeviceListPopup={groupDeviceListPopup} setGroupDeviceListPopup={setGroupDeviceListPopup} /> : null}
            {deviceStatusesListPopup ? <DeviceStatuses handleRetry={handleRetry} clickedRow={clickedRow} clickedStatus={clickedStatus} deviceStatusesListPopup={deviceStatusesListPopup} setDeviceStatusesListPopup={setDeviceStatusesListPopup} /> : null}
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    loading: state.authReducer?.loading,
    getVesselsListing: state.authReducer?.getVesselsListing,
    filteredVessels: state.authReducer?.filteredVessels,
    statuses: state.authReducer?.getConfigStatus,
    totalStatuses: state.authReducer?.getConfigStatusTotalCount
});

export default withRouter(connect(mapStateToProps, {
    getConfigStatus,
    applyGoldenConfig,
    downloadAsCSV,
    upgradeDevicetoLatestEOSversion,
})(ConfigStatusTable));