import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

import { AssetUsageBarChartProps } from "../types";
import { useEffect, useMemo, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { REPORTS_CHART_COLORS } from "../../../utils/Colors";
import moment from "moment-timezone";
import ChartUnSelected from "../../../asset/image/ChartUnSelected.svg"

NoDataToDisplay(Highcharts);

const AssetUsageBarChart = (props: AssetUsageBarChartProps) => {
    const { assets, selectedAssets, startDate, endDate, assetUsage } = props;
    const [usageForAllServiceLines, setUsageForAllServiceLines] :any = useState(0)

    const series = useMemo(() => {
        const series: any = [];
        const selectedAssetNumbers = new Set(selectedAssets.map((asset) => asset.asset_number));
        
        if (selectedAssetNumbers.size === 0) {
            let data: any = {};
            Object.keys(assetUsage).forEach((assetNumber) => {
                assetUsage[assetNumber].forEach((point) => {
                    if (!data[point.x]) {
                        data[point.x] = point.y;
                    }
                    else {
                        data[point.x] += point.y;
                    }
                });
            });

            let _data: any = [];
            Object.keys(data).forEach((date) => {
                _data.push({ x: parseInt(date), y: data[date] });
            });

            setUsageForAllServiceLines(
                _data && _data.length > 0
                    ? addCommasToNumber(Math.round(_data.reduce((accumulator, currentValue) => {
                          return Number(accumulator) + Number(currentValue.y);
                      }, 0)) )
                    : 0
            );
            
            series.push({
                name: "Total",
                data: _data,
                visible: true,
                color: REPORTS_CHART_COLORS[series.length % REPORTS_CHART_COLORS.length],
            });
        } else {
        assetUsage && Object.keys(assetUsage).forEach((assetNumber) => {
            series.push({
                name: assetNumber,
                data: assetUsage[assetNumber],
                visible: selectedAssetNumbers.size === 0 || selectedAssetNumbers.has(assetNumber),
                color: REPORTS_CHART_COLORS[series.length % REPORTS_CHART_COLORS.length],
            });
        });
        }

        return series;
    }, [selectedAssets, assetUsage]);

    const options: Highcharts.Options = {
        chart: {
            type: "column",
            events: {
                render: function () {
                    let series = this.series
                    let sum = 0
                    for (let i = 0; i < series.length; i++) {
                        if (series[i]?.visible) {
                            for (let j = 0; j < series[i].data.length; j++) {
                                let point = series[i].data[j]
                                if (point?.y) {
                                    sum += point.y
                                }
                            }
                        }
                    }
                    this.setTitle({ text: `${Highcharts.numberFormat(sum, 0, '.', ',')} GB` }, undefined, false)
                }
            }
        },
        title: {
            align: 'right',
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        xAxis: {
            type: "datetime",
            min: moment(startDate).subtract(0, 'hours').valueOf(),
            max: moment(endDate).add(0, 'hours').valueOf(),
        },
        yAxis: {
            title: {
                text: "Usage (GB)",
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        lang: {
            noData: "",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        tooltip: {
            valueSuffix: ' GB',
            // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            pointFormat: `<span style="color:{point.color}">●</span> {point.asset_name}: <b>{point.y}</b><br/>`,
            valueDecimals: 2,
            borderWidth: 1
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
                pointStart: startDate.valueOf(),
                pointInterval: 24 * 3600 * 1000,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: series,
    };

    function addCommasToNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>
            <div style={{ marginTop: "25px" }}>
                <div className={`paper unSelectedPaper}`}>
                    <div className='d-flex'>
                        <div className='d-flex align-center'>
                            <img src={ChartUnSelected} />
                        </div>
                        <div>
                            <div className="text">
                                {usageForAllServiceLines} GB
                            </div>
                            <div className="label">
                                ORG. TOTAL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paper elevation={1} className="starlink--reports--usage_chart--container" classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_05x' }}>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </Paper>
        </>
    );
};


const mapStateToProps = (state) => ({
    assetUsage: state.starlinkReports.assetUsage,
    assets: state.starlinkReports.assets,
});

export default withRouter(
    connect(mapStateToProps, {})(AssetUsageBarChart)
);
