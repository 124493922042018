import React, { useEffect, useLayoutEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { updatePermission } from '../../actions/Users/authenticate';
import { SERVICE_FUNCTION_TO_FEATURE } from '../../config';
import { BC_AUDIT_LOGS } from '../../constants/Constants';
import TuneIcon from '@mui/icons-material/Tune';

import { SIDE_MENU_ITEM_AUDIT_LOGS } from '../../utils/constants';
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI } from '../../utils/util';
import AuditLogData from './AuditLogData';
import AuditLogFilters from './AuditLogFilters';

import './AuditLogs.css';
import TableContextProvider from './AuditTableContext';

import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import { Button, Menu } from '@mui/material';

const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
var Loader = require("react-loader");


const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];


const AuditLogs = (props) => {

    const { authReducer, updatePermission, history, location } = props;
    const [loading, setLoading] = useState<Boolean>(false);

    const [timePeriod, setTimePeriod] = useState({});
    const [filterPopup, setFilterPopup] = useState(false);

    let breadcrumbInfo = BC_AUDIT_LOGS;
    const setBreadcrumbInfo: any = React.useRef();

    if (authReducer && !authReducer.sideMenu || SIDE_MENU_ITEM_AUDIT_LOGS !== authReducer.sideMenu.menuItem) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_AUDIT_LOGS };
    }
    
    useLayoutEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: viewVesselSummaryService,
                    serviceFunction: viewVesselSummaryFunction,
                },
                isMultiVessel: true,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);
        }
    }, [])

    useEffect(() => {
        setLoading(authReducer.loading);
    }, [authReducer?.loading])

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
      }

      const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
      }

    return (
        <TableContextProvider>
            <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading || authReducer?.userPreferencesLoading} setTimePeriod={setTimePeriod} handleSiteClick={handleSiteClick} showWantType={false} userGuide={[true, 'audit-logs']}></CommonFilters>
            <div className='audit-layout'>
                {/* {loading ? (
                    <div className='audit-loader'>
                        <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                    </div>) : <></>
                } */}
                <div className='audit-container menuScroll'>
                <Button variant="outlined" endIcon={<TuneIcon />} onClick={() => setFilterPopup(true)} className=''>
                Filter
              </Button>
              {filterPopup ?
                <Menu
                  open={filterPopup}
                  anchorOrigin={{ vertical: "top", horizontal: "left", }}
                  className="filter_menu_container_auditlogs"
                >
                    <AuditLogFilters setFilterPopup={setFilterPopup} />
                </Menu>
                 : <></>
                  }
                    <AuditLogData />
                </div>
            </div>
        </TableContextProvider>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});


export default withRouter(
    connect(mapStateToProps, {
        updatePermission
    })(AuditLogs)
);;