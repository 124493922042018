import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../asset/image/close.svg";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import { moveDeviceToWarehouse, deleteSite, inventoryChangeGroup, upgradeDevicetoLatestEOSversion, rebootDevice, backupConfigNow } from "../../actions/Users/authenticateInventory";
import DP_Dropdown from "../InventoryWarehouseTable/DP_Dropdown";
import _ from "lodash";
import ApplyConfig from "./ApplyConfig";
import { BackupConfigNow } from "./BackupConfigNow";
import ManageConfig from "./ManageConfig";
import UpgradeLicense from "./UpgradeLicense";
import InfoIcon from '@mui/icons-material/Info';

const EditActionsPopup = (props) => {
    const { authReducer, open, setOpen, row, setRow, isSelectedOuIsUserOu, isInventoryDeployedPage, confirmDialog, setConfirmDialog, moveDeviceToWarehouse, deleteSite, inventoryChangeGroup, upgradeDevicetoLatestEOSversion, rebootDevice, backupConfigNow, setUpgradeLicensePayload, isAuthorizedToBeta } = props;

    const deviceActionsList = [
        isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["isLatestEOSavailable"] && 'EOS Upgrade',
        isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.3"] && 'Upload Configuration',
        isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.3"] && 'Download Configuration',
        isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.2"] && 'EOS Reboot',
        isInventoryDeployedPage && (row?.['sn'] && row?.['sn']?.substring(0, 2) !== "AM" ? 'Move To Warehouse' : 'Delete Site'),
        isInventoryDeployedPage && row?.["showChangeGroupIcon"] && 'Change Organization',
        isInventoryDeployedPage && row.hasOwnProperty('tierAlias') && row['tierAlias'] && row.hasOwnProperty('termDisplay') && row['termDisplay'] && isAuthorizedToBeta && "Change License",

        !isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.0"] && 'Manage Config',
        !isInventoryDeployedPage &&  row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.3"] && 'Upload Configuration',
        !isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.3"] && 'Download Configuration',
        !isInventoryDeployedPage && row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && row["is_current_EOS_version_above_v6.5.2"] && 'EOS Reboot',
    ].filter(action => action);

    const getIdNameForList = (action) => {
        if (action == 'EOS Upgrade' || action == 'Upload Configuration' || action == 'Download Configuration' || action == 'EOS Reboot') {
            if ((row["konnect_status"] == "STATUS_NOT_CONNECTED" || !isSelectedOuIsUserOu || row.inprogressFlag)) {
                return 'actionType-disabled';
            }
        } else if (action == 'Move To Warehouse') {
            if (row.inprogressFlag || row.deRegisteredDate) {
                return 'actionType-disabled';
            }
        }
        return 'actionType-enabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));
    const [ou, setOu] = useState<any>({});
    const [modifyConfigData, setModifyConfigData] = useState<any>();

    const updateActionValues = (action) => {
        switch (action) {
            case 'Move To Warehouse': {
                if (!isSelectedOuIsUserOu || row?.inprogressFlag) {
                    return
                }
                setConfirmDialog({
                    isOpen: true,
                    title: CONFIRM_DIALOG_TITLE,
                    subTitle: "Are you sure to move device " + row['sn'] + " to Warehouse?",
                    onConfirm: () => {
                        moveDeviceToWarehouse(row['sn'], { moveToDP: true })
                    },
                })
                break;
            }
            case 'Delete Site': {
                if (!isSelectedOuIsUserOu) {
                    return
                }
                setConfirmDialog({
                    isOpen: true,
                    title: CONFIRM_DIALOG_TITLE,
                    subTitle: "Are you sure to delete site " + row['name'] + "?",
                    onConfirm: () => {
                        deleteSite(row["k4Id"])
                    },
                })
                break;
            }
            case 'EOS Upgrade': {
                if (row["konnect_status"] == "STATUS_NOT_CONNECTED" || !isSelectedOuIsUserOu || row.inprogressFlag) {
                    return
                }
                setModifyConfigData({
                    selectedDevice: {
                        id: row["deviceId"],
                        name: row["deviceName"],
                        productType: row["productType"],
                    },
                    selectedSite: row["k4Id"],
                    selectedSiteName: row["name"],
                    currentEOSversion: row["current_EOS_version"],
                    latestEOSversion: row["latestEOSavailable"]
                })
                break;
            }
            case 'EOS Reboot': {
                if (row["konnect_status"] == "STATUS_NOT_CONNECTED" || !isSelectedOuIsUserOu || row.inprogressFlag) {
                    return
                }
                setConfirmDialog({
                    isOpen: true,
                    title: CONFIRM_DIALOG_TITLE,
                    subTitle: "Are you sure to reboot the device " + row["deviceName"] + " ?",
                    onConfirm: () => {
                        rebootDevice(row["k4Id"], row["deviceId"]);
                        setOpen(false);
                    },
                });
                break;
            }
            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        setModifyConfigData({
            selectedDevice: {
                id: row["deviceId"],
                name: row["deviceName"],
                productType: row["productType"],
                current_EOS_version: row["current_EOS_version"]
            },
            selectedSite: row["k4Id"],
            selectedSiteName: row["name"],
            currentEOSversion: row["current_EOS_version"],
            latestEOSversion: row["latestEOSavailable"]
        })
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const changeGroup = () => {
        inventoryChangeGroup(row["k4Id"], ou?.id);
        setOpen(false);
    }

    const handleUpgrade = () => {
        upgradeDevicetoLatestEOSversion(modifyConfigData?.selectedSite, modifyConfigData?.selectedDevice.id)
        setModifyConfigData(undefined);
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
        setModifyConfigData({});
        setRow({});
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Operations</span>
                            <span className="selected-site-device">{`${row['name']} ${row['deviceId'] ? `- ${row['deviceId']}` : ''}`}</span>
                        </div>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={4} className="menuScroll device-border-right inventory-deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists' style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier.isParent ? 'Parent' : null}>
                                                    { identifier == 'Move To Warehouse' ? 
                                                    <Tooltip title={row.deRegisteredDate ? `${row['deviceId']} is in De-Register Queue` : null} arrow>
                                                        <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                    </Tooltip> :
                                                      identifier == 'Change License' ? <div className="change-license-info-div">
                                                        <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{`${identifier} *`}</Typography>
                                                        <div className="help-icon-create wan-usage-help helpIcon-reports">
                                                            <Tooltip arrow placement="right" title='Change License can be used to move from the Current License Tier / Term to a Different license Tier / Term (Subject to business rules specified under MSA). Either Or both of the License Tier, Term can be updated during the Change License.'><InfoIcon className="help-icon-actions" /></Tooltip>
                                                        </div>
                                                    </div> : 
                                                    <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                    }
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className="menuScroll">
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            <span className="edit_confirm_dialog_sub_title_text"> {confirmDialog.subTitle} </span>
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == 'EOS Upgrade' && !confirmDialog.isOpen &&
                                            <Grid container className="deploy-inventory-content EOS-version-div apply-inventory-content">
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                                                    <span id="content-left-text">Device</span>
                                                    <Grid>
                                                        <div id='content-right-text' title={modifyConfigData?.selectedDevice.name}>{modifyConfigData?.selectedDevice.name}</div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                                    <span id="content-left-text">Current EOS Version</span>
                                                    <Grid id='content-right-text'>
                                                        {!_.isEmpty(modifyConfigData?.currentEOSversion) && modifyConfigData?.currentEOSversion.toLowerCase().startsWith("v") ? modifyConfigData?.currentEOSversion : "v" + modifyConfigData?.currentEOSversion}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                                    <span id="content-left-text">Latest EOS Version</span>
                                                    <Grid id='content-right-text'>
                                                        {!_.isEmpty(modifyConfigData?.latestEOSversion) && modifyConfigData?.latestEOSversion.toLowerCase().startsWith("v") ? modifyConfigData?.latestEOSversion : "v" + modifyConfigData?.latestEOSversion}
                                                    </Grid>
                                                </Grid>
                                                <div className="edit-actions-confirm-btns">
                                                    <Divider className="confirm-dialogue-new-divider" />
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                        <Button className="confirm-dialogue-proceed" onClick={() => {
                                                            setConfirmDialog({
                                                                isOpen: true,
                                                                title: CONFIRM_DIALOG_TITLE,
                                                                subTitle: "Are you sure to upgrade the device " + modifyConfigData?.selectedDevice.name + " to latest " + modifyConfigData?.latestEOSversion + " ?",
                                                                onConfirm: () => {
                                                                    handleUpgrade()
                                                                },
                                                            })
                                                        }}>Upgrade</Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        }
                                        {selectedAction == 'Manage Config' && !confirmDialog.isOpen &&
                                            <ManageConfig
                                                selectedDevice={modifyConfigData?.selectedDevice}
                                                selectedSite={modifyConfigData?.selectedSite}
                                                selectedSiteName={modifyConfigData?.selectedSiteName}
                                                selectedRow={modifyConfigData ? modifyConfigData : {}}
                                                onClose={handleClose}
                                                setConfirmDialog={setConfirmDialog}
                                            />
                                        }
                                        {selectedAction == 'Upload Configuration' && !confirmDialog.isOpen &&
                                            <ApplyConfig
                                                selectedDevice={modifyConfigData?.selectedDevice}
                                                selectedSite={modifyConfigData?.selectedSite}
                                                selectedSiteName={modifyConfigData?.selectedSiteName}
                                                selectedRow={modifyConfigData ? modifyConfigData : {}}
                                                onClose={handleClose}
                                                setConfirmDialog={setConfirmDialog}
                                            />
                                        }
                                        {selectedAction == 'Download Configuration' && !confirmDialog.isOpen &&
                                            <BackupConfigNow
                                                authReducer={authReducer}
                                                backupConfigNow={backupConfigNow}
                                                selectedDevice={modifyConfigData?.selectedDevice}
                                                selectedSite={modifyConfigData?.selectedSite}
                                                selectedSiteName={modifyConfigData?.selectedSiteName}
                                                onClose={handleClose}
                                                setConfirmDialog={setConfirmDialog}
                                            />
                                        }
                                        {selectedAction == 'Change Organization' && !confirmDialog.isOpen &&
                                            <div className="edit-actions-overflow-hidden">
                                                <Grid container spacing={2} className="deploy-inventory-content EOS-version-div">
                                                    <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from org-change-10px-margintop" >
                                                        <span id="content-left-text">Current Organization</span>
                                                        <Grid id='content-right-text'>{`${row['dpname']}`}</Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from org-change-remove-margintop" >
                                                        <span id="content-left-text">Set Organization</span>
                                                        <Grid id='content-right-text' className="change-org-dp-dropdown"><DP_Dropdown isReports={false} setOu={setOu} /></Grid>
                                                    </Grid>
                                                </Grid>
                                                <div className="edit-actions-confirm-btns">
                                                    <Divider className="confirm-dialogue-new-divider" />
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                        <Button className="confirm-dialogue-proceed" onClick={changeGroup}>Apply</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {selectedAction == 'Change License' && !confirmDialog.isOpen &&
                                            <UpgradeLicense
                                                row={row} handleClose={handleClose} setPayload={setUpgradeLicensePayload}
                                                existingLicense={{label: row['tierAlias'], value: row['tierValue']}}
                                                existingTerm={{label: row['termDisplay'], value: row['termValue']}} 
                                            />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer
});

export default withRouter(
    connect(mapStateToProps, { moveDeviceToWarehouse, deleteSite, inventoryChangeGroup, upgradeDevicetoLatestEOSversion, rebootDevice, backupConfigNow })(EditActionsPopup)
);