// Table of Summary vessels
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import wifi from "../../asset/image/wifi.png";
import { vesselSummaryTableData, vesselSummaryTableDataCount } from "../../actions/Users/authenticateDashboard";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from "lodash";
import "./TableSummary.css";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import VSAT from "../../asset/image/VSAT.svg";
import LTE from "../../asset/image/LTE.svg";
import LTEthrottled from "../../asset/image/LTEthrottled.svg";
import KaaS from "../../asset/image/KaaS.svg";
import NoNotesGray from "../../asset/image/ActiveNotes.svg";
import ActiveNotesRed from "../../asset/image/NoNotes.svg";
import ActiveNotesBlue from "../../asset/image/activeNoteBlue.svg";
import VSATDisable from "../../asset/image/VSATDisable.svg";
import LTEDisable from "../../asset/image/LTEDisable.svg";
import KaaSDisable from "../../asset/image/KaaSDisable.svg";
import { readableBytes, readableBits, getEncodedURI, getDecodeURI, strToK4List, getDataFromLocalStorageParsed, readableBytesAsGB } from '../../utils/util';
import SortableCell from './sortableCell';
import { Link } from "react-router-dom";
import { displayToastError } from "../../server/request";
import { INTERVALS } from "../../constants/Constants";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import errorAlert from "../../asset/image/errorAlert.svg";
import GenericAccordionComp from './genericAccordionComp';
import { GET_VESSEL_SUMMARY_TABLE, GET_VESSEL_SUMMARY_TABLE_COUNT } from "../../actions/types";
import ConfirmDialog from "../AcoordionPopUp";
import { redirectToTopolgyTabs } from "../../utils/util";
import { getEdgeServerStatus } from "../../actions/Users/graphQlServices";
import EdgeLinkIcon from "../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../asset/image/linkDisabled.svg"
import { clearData, getGeneratedLinks } from "../RemoteConnections/remoteConnectionSlice";
import ImageWithFallback from "../ImageWithFallback";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Pagination } from "../Pagination";

var Loader = require("react-loader");

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      selected: {
        backgroundColor: "transparent",
        border: "1px solid #CAD8F4",
        background: "#F5F9FF",
        width: "28.07px !important",
        height: "20.07px",
      },
    },
    detailRoot: {
      // display: "table-row",
      padding:"0px"
    }
  })
);

export const getURIParams = (_q: any, authReducer: any) => {
  let ouName = authReducer?.userOu?.name;
  if (_q.hasOwnProperty("ouName") && '' != _q.ouName) {
    ouName = _q.ouName;
  }

  let _vessels: any[] = [];
  if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
    _vessels = strToK4List(_q.k4Ids);
  }

  if(_.isEmpty(_vessels) && !_.isEmpty(authReducer?.getVesselsListing)){
    if(ouName !== authReducer?.selectedOu.name){
      _vessels = [];
    } else {
      _vessels = authReducer?.getVesselsListing?.locations;
    }
  }
  return {ouName, _vessels}
}

const TableSummary = (props) => {
  const {
    authReducer,
    errorReducer,
    vesselSummaryTableData,
    vesselSummaryTableDataCount,
    setSearch,
    // setFilterBy,
    interval,
    setInterval,
    page,
    setPage,
    limit,
    setLimit,
    updatePageInfo,
    keyCallback,
    history,
    location,
    loader,
    getEdgeServerStatus,
    edgeServerStatus,
    getGeneratedLinks,
    clearData,
    isMapEnabled,
    newSummaryStartDate,
    newSummaryEndDate,
    isReadOnlyUser,
    vesselList
  } = props;
  const [roWise, SetRowWise ] = useState<any>([])
  const [finalRowWise, setFinalRoWiseData] =useState<any>([])
  const [eachRow, setEachrow] = useState();
  const [isAccordPopUpOpen, setIsAccordPopupOpen] = useState(false);
  useEffect(() => {
    if(_.isEmpty(authReducer?.vesselSummaryTableData) || authReducer?.vesselSummaryTableData?.data?.rows.length === 0) {
      SetRowWise([]);
      setFinalRoWiseData([]);
      return;
    }
    let tableData = authReducer?.vesselSummaryTableData?.data?.rows.map((item) => {
      return authReducer?.vesselSummaryTableData?.data?.columns.reduce((vsd,columnitem,i) => {
        vsd[columnitem] = item[i]
        return vsd;
      },{})
    })
    if(tableData && tableData.length > 0) {
      let rowWiseDeviceDetails = tableData.map((item) => {
         if(item?.product_device) {
           let devicelist = item?.product_device?.split("|");
           return devicelist[0]?.split('=')[1];
         }
      })
      // getEdgeServerStatus(rowWiseDeviceDetails);
            SetRowWise(tableData);
   }

  },[authReducer?.vesselSummaryTableData])

  // useEffect(() => {
  //   let statusDetails = edgeServerStatus?.data?.getVesselsEndPointStatusResponse?.endpoint_status;
  //   if(edgeServerStatus && statusDetails && statusDetails.length > 0) {
  //     let rowWiseDetails = roWise?.map((item, i) => {
  //       return {...item, konnectStatus: statusDetails[i]?.status, parentDevice: statusDetails[i]?.name }
  //     })
  //     setFinalRoWiseData(rowWiseDetails);
  //   }
  // },[edgeServerStatus])

  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "Fetching data please wait..." });
  const [vesselSummary, setVesselSummary] = React.useState<any[]>([]);
  const [sort, setSort] = useState("criticalAlertCount");
  const [sortOrder, setSortOrder] = useState("asc");
  const [createNoteDialog, setCreateNoteDialog] = useState(null);
  const dispatch = useDispatch();

  const classes = useStyles();
  const getSystemType: any = (systemType: any) => {
    let b = {};
    systemType.split('|').map(item => {
      if (b[item]) b[item]++;
      else b[item] = 1;
    })
    return Object.keys(b).map((item: any, idx: any, idexx) => <>
      {b[item] > 1 ? <Grid container>{item} ({b[item]})<Grid>{idx != (idexx.length - 1) ? "," : ""}</Grid></Grid> : <Grid container>{item}<Grid>{idx != (idexx.length - 1) ? "," : ""}</Grid></Grid>}
    </>)
  }

  useEffect(() => {
    let _requestAbortController = new AbortController();

    let _q = getDecodeURI(location?.search);
    if (_q.hasOwnProperty("sort") && '' != _q.sort) {
      setSort(_q.sort);
    } else if ("criticalAlertCount" !== sort) {
      setSort("criticalAlertCount");
    }

    if (_q.hasOwnProperty("sortOrder") && '' != _q.sortOrder) {
      setSortOrder(_q.sortOrder);
    } else if ("asc" !== sortOrder) {
      setSortOrder("asc");
    }

    if (_q.hasOwnProperty("search") && '' != _q.search) {
      setSearch(_q.search);
    } else if ("" == _q.search) {
      setSearch("");
    }else{
      setSearch("");
    }

    // if (_q.hasOwnProperty("filterBy") && '' != _q.filterBy) {
    //   setFilterBy(_q.filterBy);
    //   _filterBy = _q.filterBy;
    // }
    //  else if ("" !== _q.filterBy) {
    //   setFilterBy("");
    //   _filterBy = "";
    // }

    let __interval = INTERVALS[1];
    if (_q.interval) {
      let _interval = INTERVALS.find(item => item.value === _q.interval);
      if (_interval) {
        __interval = _interval
      }
    }
    if (__interval.value !== interval.value) {
      setInterval(__interval);
    }

    let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0;
    if (_page != page) {
      setPage(_page);
    }

    let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
    if (_limit != limit) {
      setLimit(_limit);
    }
    const { _vessels, ouName } = getURIParams(_q, authReducer);
    if (ouName != '' && !_.isEmpty(__interval) && _vessels) {
      if(_.isEmpty(_vessels)){
        dispatch({ type: GET_VESSEL_SUMMARY_TABLE, payload: {} })
        dispatch({ type: GET_VESSEL_SUMMARY_TABLE_COUNT, payload: null })
        return;
      }
      let tableDataCountfilter= "";
      let tableDatafilter = "";
      if(_q.hasOwnProperty("filterApplied")){
        let statues = _q?.internetStatus;
        let mpkStatus = 'AND (iphub_status = 1 or advanced_bonding = 1)';
        if(statues){
          statues = statues.split(",")
          statues="'" + statues.join("','") + "'";
          tableDataCountfilter = "AND status IN(" + statues+")";
          tableDatafilter = statues.includes('mpk') && statues?.split(',')?.length == 1 ? `AND criticalAlertCount IN('Online','Offline','Unknown') ${mpkStatus}` : statues.includes('mpk') ? `AND criticalAlertCount IN(${statues}) ${mpkStatus}` : "AND criticalAlertCount IN(" + statues+")";
        }
      }

      //temp fix
      if(vesselList?.length > 0 && vesselList?.length == _vessels?.length) {
        vesselSummaryTableData(vesselList, _q.search,  ((_q.page -1)* limit) +limit,
          _q.limit ? _q.limit : 15, _q.sort ? _q.sort : "criticalAlertCount", _q.sortOrder ? _q.sortOrder : "asc", __interval.value, ouName, _requestAbortController.signal, tableDatafilter, newSummaryStartDate, newSummaryEndDate);
        vesselSummaryTableDataCount(vesselList, _q.search, ((_q.page -1)* limit) +limit,
            _q.limit ? _q.limit : 15, _q.sort ? _q.sort : "criticalAlertCount", _q.sortOrder ? _q.sortOrder : "asc", __interval.value, ouName, _requestAbortController.signal, tableDatafilter)
      }
    }

    return () => {
      _requestAbortController.abort()
    }
  }, [vesselList, authReducer?.selectedOu, newSummaryStartDate, newSummaryEndDate]);

  // @TODO don't uncomment unless you know the impact
  // useEffect(() => {
  //   if (authReducer.selectedOu && interval) {
  //     callGetPageAPI();
  //   }
  // }, [authReducer.selectedVessels, search, interval, authReducer.selectedOu,
  //   page, limit, sort, sortOrder]);

  const doNavigate = (pathname, params) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const doNavigateToCriticalAlerts = (row) => {
    let q: any = getDecodeURI(location?.search);
    let params: any = {
      k4Ids: `${row.k4Id}:${row.vesselName}`,
      severity: 'critical'
    };
    doNavigate("/alerts", params);
  }

  const doNavigateToAlerts = (row) => {
    let q: any = getDecodeURI(location?.search);
    let params: any = {
      k4Ids: `${row.k4Id}:${row.vesselName}`,
    };
    doNavigate("/alerts", params);
  }

  const doNavigateToTickets = (row) => {
    let q: any = getDecodeURI(location?.search);
    let params: any = {
      k4Ids: `${row.k4Id}:${row.vesselName}`,
      status: "Open"
    };
    doNavigate("/tickets", params);
  }

  const getKeysForDropDownFilter = (activeFilter) => {
    switch (activeFilter) {
      case "STATUSLTE": return "status_lte";
      case "STATUSVSAT": return "status_vsat";
      case "UPTIMELTE": return "uptime_lte";
      case "UPTIMEVSAT": return "uptime_vsat";
      case "SPEEDLTE": return "speed_lte";
      case "SPEEDVSAT": return "speed_vsat";
      case "LATENCYLTE": return "latency_lte";
      case "LATENCYVSAT": return "latency_vsat";
      case "USAGELTEUpload": return "usage_lte_upload";
      case "USAGELTEDownload": return "usage_lte_download";
      case "USAGEVSATUpload": return "usage_vsat_upload";
      case "USAGEVSATDownload": return "usage_vsat_download";
      default: return "All";
    }
  }

  const setVesselFleetSorting = (event: any, order: any, sorting: any) => {
    if (event && event.currentTarget) {
      let params: any = getDecodeURI(location?.search);
      params.page = 0;
      params.sort = order;
      params.sortOrder = sorting;
      doNavigate(location.url, params);
    }
  };

  const setVesselFleetSortingSpcl = (event: any, order: any, sorting: any, label: any) => {
    let dropDownKey = label + "" + order;
    dropDownKey = dropDownKey.replace(' ', '');
    const keyForSort = getKeysForDropDownFilter(dropDownKey);
    if (event && event.currentTarget) {
      let params: any = getDecodeURI(location?.search);
      params.page = 0;
      params.sort = keyForSort;
      params.sortOrder = sorting;
      doNavigate(location.url, params);
    }
  };

  const switchSorting = (event: any, field: any) => {
    let sorting = "desc";
    if (sort === field) {
      sorting = "asc" === sortOrder ? "desc" : "asc";
    }
    let params: any = getDecodeURI(location?.search);
    params.page = 0;
    params.sort = field;
    params.sortOrder = sorting;
    doNavigate(location.url, params);
  };

  const switchSortingSpel = (event: any, field: any) => {
    let sorting = "desc";
    if (sort === field) {
      sorting = "asc" === sortOrder ? "desc" : "asc";
    }
    let params: any = getDecodeURI(location?.search);
    params.page = 0;
    params.sort = field;
    params.sortOrder = sorting;
    doNavigate(location.url, params);
  };

  // const callGetPageAPI = () => {
  //   if (interval && Object.keys(interval).length > 0) {
  //     let k4Ids = '';
  //     if (authReducer?.selectedVessels?.vessels) {
  //       k4Ids = authReducer?.selectedVessels?.vessels.map(item => item['k4Id'])
  //     }
  //     vesselSummaryTableData(authReducer?.selectedVessels?.vessels, search, page,
  //       limit, sort, sortOrder, interval.value, authReducer?.selectedOu?.name);
  //   }
  // };

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetVesselSummaryTable)) {
      displayToastError(errorReducer.errorGetVesselSummaryTable.description)
      errorReducer.errorGetVesselSummaryTable = {};
    }
  }, [errorReducer.errorGetVesselSummaryTable]);

  const getKeys = (list) => {
    let updatedtime = 0;
    for (var i = 0; i < list.length; i++) {
      if (list[i]) {
        if (list.length > 0) {
          let curentTime = list[i]?.keys?.endTimestamp;
          let date = new Date(curentTime)
          if (date.getTime() > updatedtime) {
            updatedtime = date.getTime();
          }
          return updatedtime;
        }
      }
    }
    return null;
  }

  useEffect(() => {
    if (roWise?.length > 0) {
      const totalCount: number = parseInt(authReducer.vesselSummaryTableDataCount?.data?.total?.data[0] ? authReducer.vesselSummaryTableDataCount?.data?.total?.data[0] : 0 );
      const pages = Math.ceil(totalCount / limit);
      updatePageInfo(page, limit, roWise?.length, pages, totalCount, "Sites");
    } else {
      updatePageInfo(page, limit, 0, 1, 0, "Sites");
    }
  }, [authReducer?.vesselSummaryTableDataCount, roWise]);

  useEffect(() => {
    if (!_.isEmpty(vesselSummary)) {
      keyCallback(getKeys(vesselSummary));
    }
  }, [vesselSummary]);


  const openCreateNote = (row) => {
    setCreateNoteDialog(row)
  }

  const closeNotes = () => {
    setCreateNoteDialog(null)
  }

  const handleAccordDetails = (row:any) => {
    setEachrow(row);
    setIsAccordPopupOpen(true);
  }

  const handleClosePopUp = () => {
    setIsAccordPopupOpen(false);
  }
  
  const EDGE_PORTAL_ADDRESS = "127.0.0.1";
  const EDGE_PORTAL_PORT = 80;

  const onConnectToEdge = async (e, deviceId: any, selectedVessel) => {
    e.preventDefault()
    let resp = await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
    if (!_.isEmpty(resp?.result)) {
        let [edgeLink] = resp.result?.filter(rc => {
            return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
        });
        if (edgeLink && edgeLink.url) {
          let url = edgeLink.url;
          const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
          let edgeToken = loggedInUser?.data?.edge_token;
          if (!_.isEmpty(edgeToken)) {
            let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
            url = objUrl?.href
          }
          window.open(url)
          // window.open(edgeLink.url)
          clearData()
        }
    } else {
        displayToastError('No remote connection link found')
    }
  }

  function GetTopAppsImage (props)  {
    const { app } = props;
    if(app) {
      let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
      return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
    } else {
      return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
    }

  }

  const getQutaStatusMap = (status) => {
    switch (status) {
      case "active": return "Normal";
      case "alerting": return "Over Quota Threashold";
      case "exceeded": return "Over Max Quota";
      default: return "NA";
    }
  }

  const getTDcolor = (currentUsage, usage, MaxQuota) => {
    if(parseFloat(readableBytesAsGB(currentUsage,false)) <  parseFloat(readableBytesAsGB(MaxQuota,false))) {
      if(parseFloat(readableBytesAsGB(usage, false)) < parseFloat(readableBytesAsGB(currentUsage,false))) {
        return ""
      }  else {
        return "yellowCell"
      }
    } else {
      if(parseFloat(readableBytesAsGB(usage, false)) < parseFloat(readableBytesAsGB(currentUsage,false))) {
        return ""
      }  else {
        return "yellowCell"
      }
    }
  }

  const quotaTableContent = (tableData, i) => {
    let eachrow = tableData?.split('|');
    let noOFQuota = [];
    // eachrow[6].toString()?.splt(',')
    console.log(eachrow[7]);
    if(eachrow[7]) {
      noOFQuota = eachrow[7]?.split(',');
    }
  let quotaTable = <table className="custom-table">
          <tbody>
            <tr className="quotaDashBoardRow">
              <th className="quotaDashBoardTableHeader">Usage(%)</th>
              <th className="quotaDashBoardTableHeader">Wan Alias</th>
              <th className="quotaDashBoardTableHeader">Quota Usage</th>
              {noOFQuota?.map((item, i) => {
                   return <th className="quotaDashBoardTableHeader">Quota {i+1}</th>
              })
              }
              <th className="quotaDashBoardTableHeader">Quota Max</th>
              <th className="quotaDashBoardTableHeader">Status</th>
              <th className="quotaDashBoardTableHeader">Period</th>
              <th className="quotaDashBoardTableHeader">EST.Max Over Date</th>
            </tr>
              <tr key={i}>
                  <td>{Math.trunc(eachrow[0])}</td>
                  <td>{eachrow[2] && JSON.parse(eachrow[2]) && JSON.parse(eachrow[2]).length > 0 ? JSON.parse(eachrow[2])?.join(',') : ''}</td>
                  <td>{readableBytesAsGB(eachrow[3])}</td>
                  {noOFQuota?.slice().reverse().map((item) => {
                     return <td className={parseFloat(readableBytesAsGB(eachrow[3])) > 0  ? getTDcolor(item,eachrow[3],eachrow[4]) : ""}>{readableBytesAsGB(item)}</td>
                  })}
                  <td>{readableBytesAsGB(eachrow[4])}</td>
                  <td>{getQutaStatusMap(eachrow[1])}</td>
                  <td>{eachrow[5]}</td>
                  <td>{eachrow[6]}</td>
              </tr>
          </tbody>
    </table>
    return quotaTable;
  };


  const getQuotaDetails = (currentQuota) => {
    let quota = []
    let quotaParent = currentQuota?.split('+')
    quota = currentQuota?.split('+').map((item) => {
      return `${Math.trunc(item.split('|')[0])}`
    })
    let quotaNames = currentQuota?.split('+').map((item) => {
      return item?.split('|')[2];
    })
    let quotaLength = quota?.length;
    let quotaDetails =  quota?.map((item,i) => {
      let quotaNamesEach = [];
      if(quotaNames[i] && JSON.parse(quotaNames[i]) && JSON.parse(quotaNames[i]).length > 0 ) {
        quotaNamesEach = JSON.parse(quotaNames[i])?.join(',');
      }
      return (quotaLength - 1) == i ? 
         <Tooltip  classes={{ tooltip: "quotatooltipParent" }} title={<Typography  className="quotaTooltip">{quotaTableContent(quotaParent[i],i)}</Typography>}
        //  enterDelay={3000}
        //   leaveDelay={9000}
         >
          <span className="EachQuota">{item} </span>
        </Tooltip> 
        : 
        <Tooltip classes={{ tooltip: "quotatooltipParent"}} title={<Typography className="quotaTooltip">{quotaTableContent(quotaParent[i],i)}</Typography>}
        // enterDelay={3000}
        // leaveDelay={9000}
        >
          <span className="EachQuota">{item}, </span>
        </Tooltip>
    })
  return quotaDetails;
  }

  return (
    
    <Grid style={{ marginBottom: "10px" }}>
      <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
        <Table aria-label="simple sticky table" stickyHeader>
          <TableHead className="usageReports-tableHead">
            <TableRow className="UsageReports-tableRow login-tableRow alertsData-tabelRow">
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell width15">
                <span style={{ whiteSpace: "nowrap" }}  className="align-items-center CF-cell">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "sitename")}>SITE</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "sitename" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "sitename", "asc")} />
                    <img src={sort === "sitename" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "sitename", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell width15">
                <span style={{ whiteSpace: "nowrap" }}  className="align-items-center CF-cell">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "fullName")}>Organization</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "fullName" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "fullName", "asc")} />
                    <img src={sort === "fullName" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "fullName", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell width15">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "location")}>LOCATION</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "location" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "location", "asc")} />
                    <img src={sort === "location" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "location", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "totalUsage")}>USAGE</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "totalUsage" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "totalUsage", "asc")} />
                    <img src={sort === "totalUsage" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "totalUsage", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "totalUsage")}>QUOTA(%)</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "max_quota" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "max_quota", "asc")} />
                    <img src={sort === "max_quota" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "max_quota", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "availibility")}>AVAILABILITY(%)</span>
                  <Grid className="sort-icon-margin">
                    <img src={sort === "availibility" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "availibility", "asc")} />
                    <img src={sort === "availibility" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "availibility", "desc")} />
                  </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="font-wt-900 topAppsFonts" >TOP APPS</span>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, "clients")}>Clients</span>
                  <Grid className="sort-icon-margin">
                  <img src={sort === "clients" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "clients", "asc")} />
                    <img src={sort === "clients" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "clients", "desc")} />
                    </Grid>
                </span>
              </TableCell>
              <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <div className="simStatusIcon align-items-center">
                  <div className="justfiyCenter justfiyCenter-internet-status internet-status">Internet Status</div >
                  <div className="interPrescSort internet-status-sort">
                    <img src={sort === "criticalAlertCount" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                      className="ascendingOrderIcon sortingImages" onClick={(event) => setVesselFleetSorting(event, "criticalAlertCount", "asc")} />
                    <img src={sort === "criticalAlertCount" && sortOrder === "desc" ? descSortingSelected : DescSort}
                      className="sortingImages" onClick={(event) => setVesselFleetSorting(event, "criticalAlertCount", "desc")} />
                  </div>
                  {loader ? (
                    <div className="commonLoader-tableSummary">
                      <Loader radius={4} length={5} lines={10} width={2} color={"#FFFFFF"} />
                    </div>
                  ) : null}
                </div>
              </TableCell>
              {!isReadOnlyUser && localStorage.getItem("readOnlyAceess") !== "undefined" && !(localStorage.getItem("readOnlyAceess") == "true") && <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900">Portal</span>
                </span>
              </TableCell>}
              {localStorage.getItem("readOnlyAceess") == "true" && <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                <span style={{ whiteSpace: "nowrap" }} className="simStatusIcon align-items-center">
                  <span className="login-history-dp-name font-wt-900">Portal</span>
                </span>
              </TableCell>
              }
            </TableRow>
          </TableHead>
          {roWise && 0 != roWise.length ? (
            <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

              {
                roWise && roWise.map((row, i) =>
                  <TableRow key={i} className="loginHistory-tableRow dashboard-tr height40 Table-Row-Opacity">
                   <Tooltip title={<span style={{ fontSize: "12px" }}>{row.sitename}</span>}>
                    <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity">
                        <div className="vesselNameBox">
                        <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/topology", row.k4Id, row.sitename, {}, true, false)} style={{ textDecoration: "none" }}>
                          {row.sitename}
                        </Link>
                        {/* {row?.quota_status=== "enabled" && <PriorityHighIcon className="quotaSatus"/>} */}
                        <Tooltip title={row?.note ? row.note : " "} arrow placement="right" >
                          <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/siteNotes", row.k4Id, row.sitename, {}, true, false)} style={{ textDecoration: "none" }}>
                            {row?.note ? row?.priority === "High" ? (<img className="noteImg" src={ActiveNotesRed} />) : (<img className="noteImg" src={NoNotesGray} />) : null}
                          </Link>
                        </Tooltip>
                        </div>
                    </TableCell>
                    </Tooltip>
                    <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity alertsTable-tableCell elipse organization-name">{row.fullName}</TableCell>
                    <Tooltip title={<span style={{ fontSize: "12px" }}>{row?.location ? row.location : 'no data'}</span>}>
                    <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity alertsTable-tableCell elipse">
                      {/* <a style={{ textDecoration: "none" }} href={`/vessel-map?dataSource=CELL&layer=Call%20Performance&k4Ids=${row?.k4Id}:${row?.sitename}`}>
                        {row?.location ? row.location : ""}
                      </a> */}
                      <Link to={redirectToTopolgyTabs(location, "/vessel-map", row.k4Id, row.sitename, {}, true, false)} style={{ textDecoration: "none" }}>
                          {row?.location ? row.location : "-"}
                        </Link>
                    </TableCell>
                    </Tooltip>
                    <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity">{readableBytesAsGB(row?.totalUsage)}</TableCell>
                    <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity">
                      <a className="quotaText" href={`/quota-management?k4Ids=${row?.k4Id}:${row?.sitename}&ouName=${authReducer?.selectedOu?.name}&ouId=${authReducer?.selectedOu?.id}`}>
                        {row?.current_quota && getQuotaDetails(row?.current_quota)}
                      </a>
                    </TableCell>
                    <TableCell className='login-left-align dash-inter-font Table-Cell-Opacity'>{row?.availibility > 0 ? (Math.round(row?.availibility)) : '-'}</TableCell>
                    <TableCell className="dash-inter-font white-bg Table-Cell-Opacity" style={{textAlign: "center"}}>
                      <Grid className="tableSites-topIcons">
                      <Link to={redirectToTopolgyTabs(location, "/reports/activity", row.k4Id, row.sitename, {}, true, false)} style={{ textDecoration: "none" }}>
                          {row?.apps?.split(',')?.map((app) => {
                            return (<GetTopAppsImage app={app} />)
                          }
                          )}
                          {/* {!row?.apps && <div>NO DATA</div>} */}
                        </Link>
                      </Grid>
                    </TableCell>
                    <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity">{row?.clients ? row?.clients : '-'}</TableCell>
                    <TableCell className='login-left-align dash-inter-font Table-Cell-Opacity'>
                      <div className="internetPresence" onClick={() => handleAccordDetails(row)}>
                        <span>{(row?.criticalAlertCount === "Unknown") ?
                          <Tooltip title={'Inactive'}>
                            <>
                              <span className="internetGray"></span>
                              <span className="internetStatusTXT">Inactive</span>
                              {row?.advanced_bonding == 1 || row?.iphub_status == 1 ? <span className="mpk-text">(mpk)</span> : null}
                            </>
                          </Tooltip> : (row?.criticalAlertCount === "Online") ? 
                            <Tooltip title={'Online'}>
                              <>
                                <span className={row?.internet_status === "Degraded" ? "internetDegraded" : "internetFound"}></span>
                                <span className="internetStatusTXT">Online</span>
                                {row?.advanced_bonding == 1 || row?.iphub_status == 1 ? <span className="mpk-text">(mpk)</span> : null}
                              </>
                            </Tooltip> :
                            <Tooltip title={'Offline'}>
                              <>
                                <span className="internetNotfound"></span>
                                <span className="internetStatusTXT">Offline</span>
                                {row?.advanced_bonding == 1 || row?.iphub_status == 1 ? <span className="mpk-text">(mpk)</span> : null}
                              </>
                            </Tooltip>}</span>
                      </div>
                    </TableCell>
                { !isReadOnlyUser  && localStorage.getItem("readOnlyAceess") !== "undefined" && !(localStorage.getItem("readOnlyAceess") == "true") && <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity">
                      {row?.konnect === "STATUS_CONNECTED" ?
                        <a className="vessel_table_cell--portal_link" onClick={(e) => onConnectToEdge(e, row?.parent_DevivceID, row?.k4Id)}>
                          <img src={EdgeLinkIcon} title="Portal" />
                        </a>
                        : row?.konnect === "STATUS_NOT_FOUND" ?
                          <a style={{ textDecoration: "none", textAlign: 'center' }}>
                            <img src={EdgeLinkIconDisabled} title="Portal" />
                          </a> : <a style={{ textDecoration: "none", textAlign: 'center' }}>
                            <img src={EdgeLinkIconDisabled} title="Portal" />
                          </a>
                      }
                    </TableCell>}
                    {localStorage.getItem("readOnlyAceess") == "true" && <TableCell className="login-left-align dash-inter-font Table-Cell-Opacity">
                      {row?.konnect === "STATUS_CONNECTED" ?
                        <a className="vessel_table_cell--portal_link" onClick={(e) => onConnectToEdge(e, row?.parent_DevivceID, row?.k4Id)}>
                          <img src={EdgeLinkIcon} title="Portal" />
                        </a>
                        : row?.konnect === "STATUS_NOT_FOUND" ?
                          <a style={{ textDecoration: "none", textAlign: 'center' }}>
                            <img src={EdgeLinkIconDisabled} title="Portal" />
                          </a> : <a style={{ textDecoration: "none", textAlign: 'center' }}>
                            <img src={EdgeLinkIconDisabled} title="Portal" />
                          </a>
                      }
                    </TableCell>}
                  </TableRow>
                )
              }
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ConfirmDialog confirmDialog={{isOpen: isAccordPopUpOpen}} row={eachRow} handleClosePopUp={handleClosePopUp} interval={interval}/>

    </Grid >
  );
};

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  edgeServerStatus: state?.authReducer?.edgeServerStatus,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
  isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

// export default TableSummary;
export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    vesselSummaryTableData,
    vesselSummaryTableDataCount,
    getEdgeServerStatus,
    getGeneratedLinks,
    clearData
  })(TableSummary)
);