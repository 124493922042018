import { useEffect, useState, useMemo, Fragment, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { utils, writeFileXLSX } from 'xlsx';

import { Box, Grid, InputAdornment, Paper, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import QuestionMarkOutlinedIcon from '@mui/icons-material/Help';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Search, Download, DataUsage } from '@mui/icons-material';

import AssetUsageBarChart from "./AssetUsageBarChart";
import AssetsTable from "./AssetsTable";
import { Pagination } from "../Pagination";

import { parentDpAndSubDpList, updatePermission } from '../../actions/Users/authenticate';
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from '../../utils/util';
import { Asset, AssetSortBy, ServiceLine, StarlinkAvailabilityAlert, StarlinkQuotaAlert, StarlinkReportsProps } from "./types";
import { clearCSV, clearComponentData, getAssetUsage, getAssets, getAssetsCSV, setQuotaAlert, setAvailabilityAlert, getAllServiceLines } from "./slice";

import "./index.css";
import { SortOrder } from "../SortArrows";
import QuotaAlertDialog from "../StarlinkQuota/AddEditQuotaDialog";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import DownloadIcon from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import AvailabiltyAlertDialog from "./AvailabiltyAlertDialog";
import { STARLINK_DATAUSAGE_CATEGORIES, STARLINK_DATAUSAGE_CATEGORIES_DATA } from "../../UserScreen/Starlink/types";
import { DEFAULT_DATE_FORMAT, YYYYMMDD_HHmmss } from "../../utils/constants";

function StarlinkReports(props: StarlinkReportsProps) {
    const { history, location, dpIds, assets, assetsCSV, assetUsage, totalAssets, getAssets, getAssetUsage, getAssetsCSV, setQuotaAlert, setAvailabilityAlert, clearComponentData, clearCSV, newSummaryStartDate, newSummaryEndDate,newSummaryServiceLine, authReducer, downloadAsCSV, getAllServiceLines } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: AssetSortBy;
        sortOrder: SortOrder;
        search: string;
        startDate: moment.Moment;
        endDate: moment.Moment;
        serviceLines: any;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AssetSortBy || 'curr_billing_cycle_usage_percent',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        serviceLines:newSummaryServiceLine
    });
    const [selectedAssets, setSelectedAssets] = useState<Asset[]>([])
    const [showCurrentBillingCycleUsage, setShowCurrentBillingCycleUsage] = useState(false);
    const [disableExport, setDisableExport] = useState(false);

    const [openQuotaAlertDialog, setOpenQuotaAlertDialog] = useState(false);
    const [serviceLineQuotaToEdit, setServiceLineQuotaToEdit] = useState<ServiceLine | null>(null);
    const [openAvailabilityAlertDialog, setOpenAvailabilityAlertDialog] = useState(false);
    const [serviceLineAvailabilityToEdit, setServiceLineAvailabilityToEdit] = useState<ServiceLine | null>(null);

    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const CsvHeaders = ['Service Line', 'Organization', 'Sub Organization', 'Service Plan', 'Billing Usage', 'Billing Date', 'Chart Usage', 'Site'];
    
    const mapToCsvRow = (row: any) => {
        const values: any[] = Object.values(row);
        const billingUsage = row.curr_billing_cycle_usage_split.replace(/'/g, '"');
        const chartUsage = row.chart_usage_split.replace(/'/g, '"');
        const billingUsageSplit: any[] = row.curr_billing_cycle_usage_split != '' && JSON.parse(billingUsage) || [];
        const chartUsageSplit: any[] = row.chart_usage_split != '' && JSON.parse(chartUsage) || [];
        return [values[9], values[1], values[5], values[15], billingUsageSplit, convertDateTimeIntoTimezone(moment(values[14]).add(1, 'months').toString(), authReducer.userTimezone, DEFAULT_DATE_FORMAT), chartUsageSplit, values[3]]
    }

    useEffect(() => {
        return () => {
            clearComponentData();
        }
    }, [])

    useEffect(() => {
        setSelectedAssets([]);
    }, [assets])

    useEffect(() => {
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = Math.round(moment.duration(endDate.diff(startDate)).asHours());
        if ( hoursDifference >= 24) {
        // clearComponentData()
        getAssetUsage({ 
            dpIds,
            startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            source:getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }
    }, [ tableParams.startDate, tableParams.endDate])

    useEffect(()=>{

        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = Math.round(moment.duration(endDate.diff(startDate)).asHours());
        if ( hoursDifference >= 24) {
        clearComponentData()
        getAssetUsage({ 
            dpIds,
            startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            source:getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });

        let _parameters = {
            dpIds,
            searchText: tableParams.search,
            chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),    
            chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page, 
            limit: tableParams.size,
            usageSource: getSource(),
            serviceLines:Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        }
        getAssets(_parameters);
    }

    },[newSummaryServiceLine])

  

    const getSource = () =>{
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if ( hoursDifference <= 24) {
                    return 'aggregation_table';
                } else if (hoursDifference > 24 ) {
                    return 'direct_source';
                } else {
                    return ''
                }
    }

    useEffect(() => {
        if (!_.isEmpty(dpIds)) {
            if (showCurrentBillingCycleUsage && selectedAssets?.length === 1) {
                const billingDayOfMonth = selectedAssets[0]?.billing_day_of_month;
                if (billingDayOfMonth) {
                    // if current day is smaller than billing day of month then previous month is current billing cycle
                    const currentBillingCycleStartDate = moment().date() < billingDayOfMonth ? moment().subtract(1, 'month').startOf('month').date(billingDayOfMonth) : moment().startOf('month').date(billingDayOfMonth);

                    const currentBillingCycleEndDate = moment().date() < billingDayOfMonth ? moment().startOf('month').date(billingDayOfMonth) : moment().add(1, 'month').startOf('month').date(billingDayOfMonth);

                    setTableParams({
                        ...tableParams,
                        startDate: currentBillingCycleStartDate,
                        endDate: currentBillingCycleEndDate
                    });
                }
            }
        }
    }, [showCurrentBillingCycleUsage])

    useEffect(() => {
        setShowCurrentBillingCycleUsage(false);
    }, [selectedAssets])

    useEffect(() => {
        if (queryParams.get('interval') === '1h' || queryParams.get('interval') === '6h' || queryParams.get('interval') === '12h') {
            queryParams.set('interval', '1d')
            queryParams.set('startDate', moment(Date.now()).subtract(1, 'day').valueOf().toString())
            queryParams.set('endDate', moment(Date.now()).valueOf().toString())
        }
    }, [])

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        // queryParams.set('startDate', tableParams.startDate.valueOf().toString())
        // queryParams.set('endDate', tableParams.endDate.valueOf().toString())
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
      
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference >= 24) {
            let _parameters = {
                dpIds,
                searchText: tableParams.search,
                chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),    
                chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                page: tableParams.page, 
                limit: tableParams.size,
                usageSource: getSource(),
                serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
            }
            // clearComponentData()
            getAssets(_parameters);
        }
    }, [ tableParams]);

    

    const transformAssetsCSV = (assetsCSV: Asset[]) => {
        // change column names
        const data = assetsCSV.map(asset => {
            const sulStart = moment(asset.subscription_line_start_date)
            const currBillingStart = moment(asset.current_billing_start_date)
            return {
                "DP ID": asset.dp_id,
                "DP Name": asset.dp_name,
                "Account Number": asset.account_number,
                "Account Name": asset.account_name,
                "Asset Number": asset.asset_number,
                "Asset Name": asset.asset_display_name,
                "Starlink ID": asset.user_terminal_id,
                "KIT Number": asset.user_terminal_serial_number,
                "Service Plan": asset.subscription_line_description,
                "Quantity": asset.subscription_line_quantity,
                "Service Plan Start Date": sulStart.isValid() ? sulStart.format('YYYY-MM-DD') : '',
                "Active": asset.active ? 'Yes' : 'No',
                "Billing Date": currBillingStart.isValid() ? currBillingStart.format('YYYY-MM-DD') : '',
                "Billing Usage (GB)": asset.curr_billing_cycle_usage,
            }
        });
        return data;
    }

    useEffect(() => {
        if (assetsCSV?.length > 0) {
            const data = transformAssetsCSV(assetsCSV);
            const ws = utils.json_to_sheet(data, {
                dateNF: 'YYYY-MM-DD'
            });
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Service Lines");

            const ws2 = utils.json_to_sheet(Object.keys(assetUsage).map(assetNumber => {
                return assetUsage[assetNumber].map(assetUsage => {
                    return {
                        "Asset Number": assetNumber,
                        "Asset Name": assetUsage.asset_name,
                        "Date": moment(assetUsage.x).format('DD MMM YYYY'),
                        "Usage (GB)": assetUsage.y
                    }
                })
            }).flat(), {
                dateNF: 'YYYY-MM-DD'
            });
            utils.book_append_sheet(wb, ws2, "Usage");
            writeFileXLSX(wb, "starlink-report.xlsx");
        }
    }, [assetsCSV]);

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSelectedAssets = (event: any) => {
        const { name, checked } = event.target;
        if (name === 'selectAll') {
            if (checked) {
                setSelectedAssets(assets);
            } else {
                setSelectedAssets([]);
            }
        } else {
            if (checked) {
                const asset = assets.find(asset => asset.asset_number === name);
                if (asset) {
                    setSelectedAssets([...selectedAssets, asset]);
                }
            } else {
                setSelectedAssets(selectedAssets.filter(asset => asset.asset_number !== name));
            }
        }
    }

    useEffect(() => {
        let _startDate = newSummaryStartDate;
        if (tableParams.startDate.isSame(_startDate) && tableParams.endDate.isSame(newSummaryEndDate) && tableParams.endDate.isSame(newSummaryServiceLine)) {
            return;
        }
        setTableParams({
            ...tableParams,
            startDate: _startDate,
            endDate: newSummaryEndDate,
            serviceLines:Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [newSummaryEndDate, newSummaryStartDate, newSummaryServiceLine])

    const handleChangeStartDate = (date: moment.Moment | null) => {
        if (date) {
            setTableParams({
                ...tableParams,
                startDate: date,
                page: 1
            });
            setShowCurrentBillingCycleUsage(false)
        }
    }

    const handleChangeEndDate = (date: moment.Moment | null) => {
        if (date) {
            setTableParams({
                ...tableParams,
                endDate: date,
                page: 1
            });
            setShowCurrentBillingCycleUsage(false)
        }
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }


    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleChangeShowCurrentBillingCycleUsage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowCurrentBillingCycleUsage(event.target.checked);
    };

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangeSorting = (sortBy: AssetSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const handleExportCSV = async () => {
        setDisableExport(true);
        handleDownloadReport();
        setDisableExport(false);
    }

    const handleOpenQuotaAlertDialog = () => {
        setOpenQuotaAlertDialog(true);
    }

    const handleCloseQuotaAlertDialog = () => {
        setOpenQuotaAlertDialog(false);
        setServiceLineQuotaToEdit(null);
    }

    const handleEditServiceLineQuota = (serviceLine: ServiceLine) => {
        setServiceLineQuotaToEdit(serviceLine);
        setOpenQuotaAlertDialog(true);
    }

    const handleSetQuotaAlert = async (quotaAlerts: StarlinkQuotaAlert[]) => {
        if (quotaAlerts.length > 0) {
            await setQuotaAlert({
                serviceLineNumbers: quotaAlerts.map(quotaAlert => quotaAlert.serviceLineNumber),
                alertingThresholds: quotaAlerts[0].alertingThresholds,
                notificationMethods: quotaAlerts[0].notificationMethods,
                usersToNotify: quotaAlerts[0].usersToNotify
            })
        }
        setOpenQuotaAlertDialog(false);
        setServiceLineQuotaToEdit(null);
        setTableParams({
            ...tableParams,
        });
    }

    const handleOpenAvailabilityAlertDialog = () => {
        setOpenAvailabilityAlertDialog(true);
    }

    const handleCloseAvailabilityAlertDialog = () => {
        setOpenAvailabilityAlertDialog(false);
        setServiceLineAvailabilityToEdit(null);
    }

    const handleEditServiceLineAvailability = (serviceLine: ServiceLine) => {
        setServiceLineAvailabilityToEdit(serviceLine);
        setOpenAvailabilityAlertDialog(true);
    }

    const handleSetAvailabilityAlert = async (availabilityAlerts: StarlinkAvailabilityAlert[]) => {
        if (availabilityAlerts.length > 0) {
            await setAvailabilityAlert({
                serviceLineNumbers: availabilityAlerts.map(quotaAlert => quotaAlert.serviceLineNumber),
                alertingThreshold: availabilityAlerts[0].alertingThreshold,
                notificationMethods: availabilityAlerts[0].notificationMethods,
                usersToNotify: availabilityAlerts[0].usersToNotify
            })
        }
        setOpenAvailabilityAlertDialog(false);
        setServiceLineAvailabilityToEdit(null);
        setTableParams({
            ...tableParams,
        });
    }

    const dataBucketFormat = (buckets: any[]) => {
        return buckets?.length > 0 ? `"${buckets?.map(bucket => {
            return Object.keys(bucket).map(key => {
                if (bucket[key] > 0) {
                return `${STARLINK_DATAUSAGE_CATEGORIES_DATA[key]} - ${bucket[key]} GB`;
                }
                return null;
            }).filter(Boolean).join(', ');
        }).join(', ')}"` : '';
    };

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'starlink-usage') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = JSON.parse(status[0]);
                    if (rows.length) {
                        DownloadCSV([CsvHeaders, ...rows.map(row => mapToCsvRow(row))], {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                2: 'ESC-COMMA',
                                3: 'ESC-COMMA',
                                4: dataBucketFormat,
                                5: 'ESC-COMMA',
                                6: dataBucketFormat
                            }
                        }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'starlink-usage',
            payload: {
                searchText: tableParams.search,
                chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalAssets,
                offset: 0,
                usageSource: getSource(),
                serviceLines:Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl !== '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_STARLINK_ASSETS_CH',
        }
        downloadAsCSV(params);
    }

    useEffect(() => {
        if(!_.isEmpty(dpIds)) {
            getAllServiceLines({dpIds: dpIds, serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl != '') : [],});
        }
    }, [dpIds, newSummaryServiceLine]);

    return (
        <Grid>
            <AssetUsageBarChart selectedAssets={selectedAssets} startDate={tableParams.startDate} endDate={tableParams.endDate} />

            <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_05x' }}>
                {/* <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={DownloadIcon} alt="" /><Button>Download</Button></div> */}

                <Grid item xs={12} sm={12} md={12} lg={12} classes={{ root: 'starlink--base--flex--justify--space_between starlink--base--flex--align--center starlink--base--mtb_07x' }}>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-starlink-rp" }}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                <Typography component={'div'} className="starlink--base--font_08x starlink--base--padding_05x">
                                 The data is retrieved directly from the Starlink cloud and may be subject to delays in availability.
                                </Typography>
                            }>
                                <QuestionMarkOutlinedIcon sx={{padding:"15px"}} className="marg-left-auto" />
                            </Tooltip>
                        </Box>
                      
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} classes={{ root: 'starlink--base--flex' }}>
                        <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleOpenAvailabilityAlertDialog} disabled={false} startIcon={<NotificationAddIcon />}>Availability</Button>
                        {/* <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleOpenQuotaAlertDialog} disabled={false} startIcon={<DataUsage />}>Quota</Button> */}
                        {totalAssets > 0 && <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleExportCSV} disabled={disableExport} startIcon={<Download />}>Download</Button>}
                    </Grid>
                </Grid>

                <Pagination count={totalAssets} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
                <AssetsTable startDate={tableParams.startDate} endDate={tableParams.endDate} sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} selectedAssets={selectedAssets} onChange={handleChangeSelectedAssets} onChangeSorting={handleChangeSorting} onEditServiceLineQuota={handleEditServiceLineQuota} onEditServiceLineAvailability={handleEditServiceLineAvailability} />
            </Paper>

            <QuotaAlertDialog open={openQuotaAlertDialog || serviceLineQuotaToEdit !== null} onClose={handleCloseQuotaAlertDialog} serviceLineToEdit={serviceLineQuotaToEdit} onSave={handleSetQuotaAlert} />

            <AvailabiltyAlertDialog open={openAvailabilityAlertDialog || serviceLineAvailabilityToEdit !== null} onClose={handleCloseAvailabilityAlertDialog} serviceLineToEdit={serviceLineAvailabilityToEdit} onSave={handleSetAvailabilityAlert} />

        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    commonFilters: state.starlinkCloud.commonFilters,
    assets: state.starlinkReports.assets,
    assetsCSV: state.starlinkReports.assetsCSV,
    assetUsage: state.starlinkReports.assetUsage,
    totalAssets: state.starlinkReports.totalAssets,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
});

export default withRouter(
    connect(mapStateToProps, {
        updatePermission,
        parentDpAndSubDpList,
        getAssets,
        getAssetUsage,
        getAssetsCSV,
        setQuotaAlert,
        setAvailabilityAlert,
        clearComponentData,
        clearCSV,
        downloadAsCSV,
        getAllServiceLines
    })(StarlinkReports)
);