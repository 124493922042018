import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./Tabs.css";

import DataUsageIcon from '@mui/icons-material/DataUsage';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import usage_select from "../../asset/image/usage_select.svg";
import usage_unselect from "../../asset/image/usage_unselect.svg";
import availability_select from "../../asset/image/availability_select.svg";
import availability_unselect from "../../asset/image/availability_unselect.svg";
import notifications_select from "../../asset/image/notifications_select.svg";
import notifications_unselect from "../../asset/image/notifications_unselect.svg";
import { getDecodeURI } from '../../utils/util';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {

  const {handleTabValue, alertStatus, setAlertStatus, location} = props;
  let params: any = getDecodeURI(location?.search);
  const [value, setValue] = React.useState(params.hasOwnProperty('tab') ? parseInt(params.tab) : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleTabValue(newValue)
  };

  const handleChangeStatus = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
      setAlertStatus({ ...alertStatus, [name]: event.target.checked })
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box className="tabs_outer_box">
        <Tabs value={value} onChange={handleChange} className="new-summary-secondary-tab tab_root_NewSummary" aria-label="basic tabs example">
          <Tab className="tab_root_NewSummary" icon={value === 0 ? <img src={usage_select} alt='alert'  className='tabs_icon_usage'/> : <img src={usage_unselect} alt='alert'  className='tabs_icon_usage'/>}  iconPosition="start" label={<span className={value === 0 ? "tab_label_NewSummary_select" : "tab_label_NewSummary"}>Usage</span>} {...a11yProps(0)} />
          <Tab className="tab_root_NewSummary" icon={value === 1 ? <img src={availability_select} alt='alert'  className='tabs_icon_availability'/> : <img src={availability_unselect} alt='alert'  className='tabs_icon_availability'/>}  iconPosition="start" label={<span className={value === 1 ? "tab_label_NewSummary_select" : "tab_label_NewSummary"}>Availability</span>} {...a11yProps(1)} />
          <Tab className="tab_root_NewSummary" icon={value === 2 ? <img src={notifications_select} alt='alert'  className='tabs_icon_alerts'/> : <img src={notifications_unselect} alt='alert'  className='tabs_icon_alerts'/>}  iconPosition="start" label={<span className={value === 2 ? "tab_label_NewSummary_select" : "tab_label_NewSummary"}>Alerts</span>} {...a11yProps(2)} />
        </Tabs>
          {value === 2 && <Grid className='tabs-alert-status'>
            <FormControlLabel control={<Checkbox name='alerting' checked={alertStatus.Alerting} disabled={alertStatus?.isCurrentAlert ? true : false} onChange={handleChangeStatus('Alerting')} />} label="Alerting" />
            <FormControlLabel control={<Checkbox name='normal' checked={alertStatus.Normal} disabled={alertStatus?.isCurrentAlert ? true : false} onChange={handleChangeStatus('Normal')} />} label="Cleared" />
          </Grid>}
      </Box>
    </Box>
  );
}
