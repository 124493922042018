import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getIamLoginHistoryFilterUser, getIamLoginHistoryFilterApp, getIamLoginHistoryFilterDescList } from "../../../actions/Users/authenticate";
import './loginHistoryFilter.css';
import _ from "lodash";

import close from "../../../asset/image/close.svg";

const LoginHistoryFilters = (props) => {
    const { authReducer, getIamLoginHistoryFilterUser, getIamLoginHistoryFilterApp, getIamLoginHistoryFilterDescList, applyFilterClick, clearFilters, setValues, values, setFilterPopup } = props;
    const { setSelectedUsers, setSelectedApp, setSelectedDesc, setTrackFilter } = setValues;
    const { startDate, endDate, searchValue, selectedUsers, selectedApp, selectedDesc, selectedOu, widgetFilt } = values;
    const [userNames, setUserNames] = useState<any>([]);
    const [appNames, setAppNames] = useState<any>([]);
    const [descriptionList, setDescriptionList] = useState<any>([]);

    useEffect(() => {
        getIamLoginHistoryFilterUser(startDate, endDate, searchValue);
        getIamLoginHistoryFilterApp(startDate, endDate, searchValue);
        getIamLoginHistoryFilterDescList(startDate, endDate, selectedOu, selectedUsers, selectedApp);
    }, [])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getIamLoginHistoryFilterUser)) {
            const data = authReducer.getIamLoginHistoryFilterUser?.data;
            if(data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const userNames: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    userNames.push(row);
                }
                setUserNames(userNames);
            }
        }
        if(!_.isEmpty(authReducer.getIamLoginHistoryFilterApp)) {
            const data = authReducer.getIamLoginHistoryFilterApp?.data;
            if(data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const appNames: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    appNames.push(row);
                }
                setAppNames(appNames);
            }
        }
        if(!_.isEmpty(authReducer.getIamLoginHistoryFilterDescList)) {
            const data = authReducer.getIamLoginHistoryFilterDescList?.data;
            if(data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const descList: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    descList.push(row);
                }
                setDescriptionList(descList);
            }
        }
    },[authReducer.getIamLoginHistoryFilterUser, authReducer.getIamLoginHistoryFilterApp, authReducer.getIamLoginHistoryFilterDescList])

    const onKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
    }

    const handleInputUserChange = (e, newValue) => {
        newValue ? setSelectedUsers([newValue]) : setSelectedUsers([]);
        setTrackFilter(false);
    }

    const handleInputAppCahnge = (e, newValue) => {
        setTrackFilter(false);
        if (e.target.checked){
            setSelectedApp([newValue]);
        } else {
            setSelectedApp([]);
        }
    }

    const handleInputDescChange = (e, newValue) => {
        setTrackFilter(false);
        if (e.target.checked) {
            setSelectedDesc([newValue]);
        } else {  
            setSelectedDesc([]);
        }
    }
    
    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid  className="dash-filter-pop">
                    <Grid className="login-history-filter-inputs">
                        <Grid className="filter-clearFilter login-filter-clearFilter">
                            <Grid>
                                <img src={close} onClick={() => setFilterPopup(false)} className="dialog_closeIcon padding-top-6" alt="Close" />
                            </Grid>
                            <div className="Filters_Title">Filters</div>
                            <div className="clearFiletrBtnDiv">
                                <Button onClick={() => {
                                    clearFilters();
                                }} className="clearFiletrBtnUsage margin-l-115">Clear Filter</Button>
                            </div>
                        </Grid>
                            <div className="rc--filter_section login-rc--filter_section">
                                <p>UserName</p>
                                <Autocomplete
                                    disableClearable={selectedUsers ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize:'13px' } }}
                                    id="tags-outlined"
                                    className="audit-filter-inputs-usage login-history-input"
                                    options={userNames}
                                    size="small"
                                    value={selectedUsers[0]}
                                    onChange={(e, newValue: any) => { handleInputUserChange(e, newValue) }}
                                    getOptionLabel={(option:any) => option.user_name}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onKeyDown={onKeyDown}
                                        placeholder="username"
                                    />
                                    )}
                                />
                            </div>
                            <div className="rc--filter_section login-rc--filter_section">
                                <p>Service</p>
                                { appNames?.map((el, i) => (
                                    el.application_name && <Grid className="app-names-checkbox">
                                        <Checkbox
                                            checked={selectedApp[0]?.application_name == el?.application_name ? true : false}
                                            onChange={(e) => handleInputAppCahnge(e, el)}
                                            size='small'
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <span>{el.application_name}</span>
                                    </Grid>
                                ))}
                            </div>
                            <div className="rc--filter_section login-rc--filter_section">
                                <p>Event Type</p>
                                { descriptionList?.map((el, i) => (
                                    el.description && <Grid className="app-names-checkbox descList-checkbox">
                                        <Checkbox
                                            checked={selectedDesc[0]?.description == el.description ? true : false}
                                            onChange={(e) => handleInputDescChange(e, el)}
                                            size='small'
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <span>{el.description}</span>
                                    </Grid>
                                ))}
                            </div>
                    </Grid>
                        <Button className="usage-applyFilterBtn" onClick={()=>{
                            applyFilterClick(selectedUsers, selectedApp, selectedDesc, widgetFilt);
                        }}>Apply Filter</Button>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
        getIamLoginHistoryFilterUser,
        getIamLoginHistoryFilterApp,
        getIamLoginHistoryFilterDescList
    })(LoginHistoryFilters)
);